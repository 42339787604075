import * as MSAL from 'msal';
import { ActionsUnion } from '../types';
import { createAction } from '../../utils/action';
import ActionTypes from '../actionTypes';
import { RestErrorResponse } from '../../types/response/RestErrorResponse';

export const Actions = {
  silentLogin: () => createAction(ActionTypes.SILENT_LOGIN),
  silentLoginSuccess: (authInfo: MSAL.AuthResponse) => createAction(ActionTypes.SILENT_LOGIN_SUCCESS, authInfo),
  silentLoginError: (error: RestErrorResponse) => createAction(ActionTypes.SILENT_LOGIN_ERROR, error),

  login: () => createAction(ActionTypes.LOGIN),
  loginSuccess: () => createAction(ActionTypes.LOGIN_SUCCESS),
  loginError: (error: RestErrorResponse) => createAction(ActionTypes.LOGIN_ERROR, error),

  logout: () => createAction(ActionTypes.LOGOUT),
  logoutSuccess: () => createAction(ActionTypes.LOGOUT_SUCCESS),
  logoutError: (error: RestErrorResponse) => createAction(ActionTypes.LOGOUT_ERROR, error),

  setNotAuthenticated: () => createAction(ActionTypes.SET_NOT_AUTHENTICATED)
};

export type AuthActions = ActionsUnion<typeof Actions>;
