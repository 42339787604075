import * as React from 'react';

import TileCheckboxListDesktopScreen from '../../CheckboxListTile/DesktopScreen/TileCheckboxListDesktopScreen';
import { CheckboxTileTypes } from '../../../core/enums/CheckboxTileTypes';

interface Props {
  url: string;
}

const TileAttendanceListDesktopScreen: React.FC<Props> = ({ url }) => {
  return <TileCheckboxListDesktopScreen url={url} tileType={CheckboxTileTypes.Attendance} />;
};

export default TileAttendanceListDesktopScreen;
