import React from 'react';
import { useTranslation } from 'react-i18next';
import TH from '../../../components/Table/TH/TH';

const TileCapacitySubcontractorInfoDays = () => {
  const { t } = useTranslation();
  return (
    <TH className="tile-capacity-subcontractor__info-days no-border no-background">
      <div className="tile-capacity-subcontractor__info-days-inner">
        {Array(6)
          .fill('')
          .map((item, index) => {
            return (
              <span key={t(`sharedText.day.${index + 1}`)} className="tile-capacity-subcontractor__info-day">
                {t(`sharedText.day.${index + 1}`).substr(0, 2)}
              </span>
            );
          })}
      </div>
    </TH>
  );
};

export default TileCapacitySubcontractorInfoDays;
