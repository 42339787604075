import * as React from 'react';
import { Overlay, Tooltip as ReactTooltip } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Breadcrumb } from '../../../../core/utils/breadcrumb/findMenuListById';
import BreadcrumbChild from '../BreadcrumbChild/BreadcrumbChild';
import { useOnClickOutside } from '../../../../core/hooks/useOnClickOutside';
import { MenuType } from '../../../../core/state/menu/types';
import BreadcrumbProjectTiles from '../BreadcrumbProjectTiles/BreadcrumbProjectTiles';

interface Props {
  breadcrumbArray: Breadcrumb[];
  breadcrumb: Breadcrumb;
  openedBreadcrumb: number;
  setOpenedBreadcrumb: React.Dispatch<React.SetStateAction<number>>;
  index: number;
  hasBreadcrumbNavigation: boolean;
}

const BreadcrumbArrow: React.FC<Props> = ({ breadcrumbArray, breadcrumb, setOpenedBreadcrumb, openedBreadcrumb, index, hasBreadcrumbNavigation }) => {
  const target = React.useRef(null);
  const overlayRef = React.useRef(null);
  const { t } = useTranslation();
  const isOpened = openedBreadcrumb === index;
  const navigationState = React.useState(-1);

  useOnClickOutside(overlayRef, (event) => {
    if (isOpened && event.target !== target.current) {
      setOpenedBreadcrumb(-1);
    }
  });

  const GFChildren = breadcrumb.children?.filter((item) => item.type === MenuType.GFBoard);
  const BRLChildren = breadcrumb.children?.filter((item) => item.type === MenuType.BRLBoard);
  const PLChildren = breadcrumb.children?.filter((item) => item.type === MenuType.Project);
  const PLTiles = breadcrumb.tiles;

  React.useEffect(() => {
    if (!isOpened) {
      const activeBoard = breadcrumbArray.find((item) => item.boardId === breadcrumb.boardId);
      if (activeBoard && activeBoard.boardId) {
        const [, setNavState] = navigationState;
        setNavState(activeBoard.boardId);
      }
    }
  }, [isOpened, GFChildren, navigationState, breadcrumb, breadcrumbArray]);
  return (
    <li
      className={
        `breadcrumb-navigation__arrow` +
        ` ${isOpened ? ' breadcrumb-navigation__arrow--opened' : ''}` +
        ` ${hasBreadcrumbNavigation ? '' : ' breadcrumb-navigation__arrow--disabled'}`
      }
    >
      <span
        ref={target}
        onKeyPress={() => {}}
        tabIndex={0}
        role="button"
        onClick={() => {
          if (hasBreadcrumbNavigation) {
            setOpenedBreadcrumb(openedBreadcrumb === index ? -1 : index);
          }
        }}
        className="breadcrumb-navigation__arrow-inner"
      >
        arrow
      </span>
      {hasBreadcrumbNavigation && (
        <Overlay target={target.current} show={isOpened} placement="bottom">
          {(props) => (
            // eslint-disable-next-line react/jsx-props-no-spreading
            <ReactTooltip id="overlay-example" className="breadcrumb-navigation__children-wrap" {...props}>
              <ul className="breadcrumb-navigation__children" ref={overlayRef}>
                {GFChildren && GFChildren.length !== 0 && (
                  <li className="breadcrumb-navigation__child-section-title pb-1">{t('navigation.GFBoards')}</li>
                )}
                {GFChildren?.map((breadcrumbChild) => (
                  <BreadcrumbChild
                    breadcrumbArray={breadcrumbArray}
                    key={breadcrumbChild.name}
                    breadcrumbChild={breadcrumbChild}
                    navigationState={navigationState}
                  />
                ))}
                {BRLChildren && BRLChildren.length !== 0 && (
                  <li className="breadcrumb-navigation__child-section-title pb-1">{t('navigation.BRLBoards')}</li>
                )}
                {BRLChildren?.map((breadcrumbChild) => (
                  <BreadcrumbChild
                    breadcrumbArray={breadcrumbArray}
                    key={breadcrumbChild.name}
                    breadcrumbChild={breadcrumbChild}
                    navigationState={navigationState}
                  />
                ))}
                {PLChildren && PLChildren.length !== 0 && (
                  <li className="breadcrumb-navigation__child-section-title pb-1">{t('navigation.Projects')}</li>
                )}
                {PLChildren?.map((breadcrumbChild) => (
                  <BreadcrumbChild
                    breadcrumbArray={breadcrumbArray}
                    key={breadcrumbChild.name}
                    breadcrumbChild={breadcrumbChild}
                    navigationState={navigationState}
                  />
                ))}
                {!!PLTiles?.length && breadcrumb.type === MenuType.PLBoard && (
                  <BreadcrumbProjectTiles breadcrumbArray={breadcrumbArray} tiles={PLTiles} />
                )}
              </ul>
            </ReactTooltip>
          )}
        </Overlay>
      )}
    </li>
  );
};

export default BreadcrumbArrow;
