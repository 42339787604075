import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { BASE_URL } from '../../../core/constants/api';
import { RootState } from '../../../core/state/state';

import { Tile } from '../../../core/state/boards/types';
import Icon from '../../../components/Icon/Icon';

import './PDFTileButtonLink.scss';

interface Props {
  pdfTile: Tile;
  className?: string;
}

const PDFTileButtonLink: React.FC<Props> = ({ pdfTile, className }) => {
  const history = useHistory();
  const boardId = useSelector((state: RootState) => state.boards.board?.boardId);

  const goToPDFTile = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    event.stopPropagation();
    const pdfTileUrl = `/boards/${boardId}/detail?id=${pdfTile.tileId}&type=PDF&url=${BASE_URL}${encodeURIComponent(pdfTile.onClickDataUrl)}`;
    history.push(pdfTileUrl);
  };

  return (
    <button className={`pdf-button-link no-font-scaling ${className || ''}`} onClick={goToPDFTile} title="PDF">
      <Icon icon="pdf" svg size="full-size" />
    </button>
  );
};
export default PDFTileButtonLink;
