import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Actions as SettingsActions } from '../../../core/state/settings/actions';
import { sleep } from '../../../core/utils/sleep';

import './TableInput.scss';

interface Props {
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onClick?: () => void;

  value: string | number | null;
  valueType?: string;
  type?: 'small';
  isLink?: true;
  isText?: boolean;
}

const TableInput: React.FC<Props> = ({ onBlur, onChange, value, onClick, type, valueType, isText = false }) => {
  const dispatch = useDispatch();
  const [isEditing, setEditing] = useState<boolean>(false);
  const inputEl = React.useRef<HTMLInputElement>(null);

  const handleClick = async () => {
    await sleep(100); // fixing click event happening before blur
    if (isText) {
      return;
    }
    setEditing(true);
    dispatch(SettingsActions.setIsFormEditing(true));

    if (onClick) {
      await onClick();
    }
  };

  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    setEditing(false);
    dispatch(SettingsActions.setIsFormEditing(false));

    if (onBlur) {
      onBlur(e);
    }
  };

  useEffect(() => {
    if (isEditing) {
      inputEl.current?.focus();
    }
  }, [inputEl, isEditing]);

  return (
    <div
      className={`table-input ${type ? `table-input--${type}` : ''} ${isEditing ? 'table-input--editing' : ''} ${isText ? 'table-input--text' : ''}`}
    >
      <input
        ref={inputEl}
        className="table-input__input"
        value={value || ''}
        onBlur={handleBlur}
        onChange={onChange ?? (() => {})}
        type={valueType || 'text'}
      />
      <span className="table-input__text">{value}</span>
      <div
        className="table-input__overlay"
        onClick={handleClick}
        role="button"
        aria-pressed="false"
        aria-label="input"
        tabIndex={0}
        onKeyDown={() => {}}
      />
    </div>
  );
};

export default TableInput;
