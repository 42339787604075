import React from 'react';
import { Link } from 'react-router-dom';

import { Breadcrumb } from '../../../../core/utils/breadcrumb/findMenuListById';
import Tooltip from '../../../../components/Tooltip/Tooltip';
import BreadcrumbArrow from '../BreadcrumbArrow/BreadcrumbArrow';

interface Props {
  breadcrumbArray: Breadcrumb[];
  breadcrumb: Breadcrumb;
  openedBreadcrumb: number;
  setOpenedBreadcrumb: React.Dispatch<React.SetStateAction<number>>;
  index: number;
  isLast: boolean;
}

const BreadcrumbLink: React.FC<Props> = ({ breadcrumbArray, breadcrumb, setOpenedBreadcrumb, index, openedBreadcrumb, isLast }) => {
  const hasBreadcrumbNavigation = !!breadcrumb.children || !!breadcrumb.tiles;
  const hasArrow = hasBreadcrumbNavigation || (!hasBreadcrumbNavigation && !isLast);

  return (
    <>
      <li className="breadcrumb-item" style={{ width: breadcrumb.width > 0 ? breadcrumb.width : 'auto' }}>
        {!isLast ? (
          <Link to={breadcrumb.url || ''} className="breadcrumb-navigation__item breadcrumb-navigation__item--link">
            {breadcrumb.width > 0 ? <Tooltip text={breadcrumb.name}>{breadcrumb.name}</Tooltip> : breadcrumb.name}
          </Link>
        ) : (
          <span className="breadcrumb-navigation__item breadcrumb-navigation__item--current">{breadcrumb.name}</span>
        )}
      </li>
      {hasArrow && (
        <BreadcrumbArrow
          breadcrumbArray={breadcrumbArray}
          hasBreadcrumbNavigation={hasBreadcrumbNavigation}
          breadcrumb={breadcrumb}
          openedBreadcrumb={openedBreadcrumb}
          setOpenedBreadcrumb={setOpenedBreadcrumb}
          index={index}
        />
      )}
    </>
  );
};

export default BreadcrumbLink;
