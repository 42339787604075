import * as React from 'react';

import './ToggleSwitch.scss';

interface Props {
  id: string;
  checked: boolean;
  onChange: (checked: boolean) => void;
}

const ToggleSwitch: React.FC<Props> = ({ id, checked, onChange }) => (
  <div className="toggle-switch">
    <input type="checkbox" className="toggle-switch__checkbox" id={id} checked={checked} onChange={(e) => onChange(e.target.checked)} />
    {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
    <label className="toggle-switch__label" htmlFor={id}>
      <span className="toggle-switch__inner" />
      <span className="toggle-switch__switch" />
    </label>
  </div>
);

export default ToggleSwitch;
