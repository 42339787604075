import * as React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { MenuType } from '../../../../core/state/menu/types';
import { Breadcrumb, BreadcrumbChild as BreadcrumbChildType } from '../../../../core/utils/breadcrumb/findMenuListById';
import Icon from '../../../../components/Icon/Icon';
import BreadcrumbChildItem from '../BreadcrumbChildItem/BreadcrumbChildItem';

interface Props {
  breadcrumbArray: Breadcrumb[];
  breadcrumbChild: BreadcrumbChildType;
  navigationState: [number, React.Dispatch<React.SetStateAction<number>>];
}

const BreadcrumbChild: React.FC<Props> = ({ breadcrumbArray, breadcrumbChild, navigationState }) => {
  const [openedNavigation, setOpenedNavigation] = navigationState;
  const isNavOpened = openedNavigation === breadcrumbChild.boardId;
  const { t } = useTranslation();

  const hasNoTiles = !breadcrumbChild.tiles?.length;
  const hasNoBoards = !breadcrumbChild.children?.length;
  const hasNoBackOffice = !breadcrumbChild.backOffice;

  const hasNoChildren = hasNoTiles && hasNoBoards && hasNoBackOffice;

  const isActive = !!breadcrumbArray.find((item) => item.boardId === breadcrumbChild.boardId);
  const isSettingsActive = !!breadcrumbArray.find((item) => item.settingId === breadcrumbChild.boardId);

  const BRLBoards = breadcrumbChild.children?.filter((item) => item.type === MenuType.BRLBoard);
  const Projects = breadcrumbChild.children?.filter((item) => item.type === MenuType.Project);
  const PLBoard = breadcrumbChild.children?.filter((item) => item.type === MenuType.PLBoard);

  const getIconName = () => {
    switch (breadcrumbChild.type) {
      case MenuType.GFBoard:
        return 'gf';
      case MenuType.BRLBoard:
        return 'brl';
      case MenuType.PLBoard:
        return 'pl';
      default:
        return 'brl';
    }
  };

  return (
    <li className="breadcrumb-navigation__child">
      <div className={`breadcrumb-navigation__child-inner ${hasNoChildren ? 'breadcrumb-navigation__child-inner--has-no-children' : ''}`}>
        <Link
          to={String(breadcrumbChild.url)}
          className={`breadcrumb-navigation__child-link ${isActive ? 'breadcrumb-navigation__child-link--active' : ''}`}
        >
          <Icon svg className="breadcrumb-navigation__child-icon" icon={getIconName()} />
          {breadcrumbChild.name}
        </Link>
        {!hasNoChildren && (
          <button
            onClick={() => {
              if (!isNavOpened) {
                setOpenedNavigation(breadcrumbChild.boardId ?? -1);
              } else {
                setOpenedNavigation(-1);
              }
            }}
            className={`breadcrumb-navigation__child-arrow ${isNavOpened ? 'breadcrumb-navigation__child-arrow--active' : ''}`}
          >
            <Icon icon="downArrow" />
          </button>
        )}
      </div>
      {isNavOpened && (
        <div className="breadcrumb-navigation__child-info">
          {!!BRLBoards?.length && (
            <div className="breadcrumb-navigation__child-section">
              <div className="breadcrumb-navigation__child-section-title">{t('navigation.BRLBoards')}</div>
              <ul className="breadcrumb-navigation__child-item-wrap">
                {BRLBoards?.map((child) => {
                  return (
                    <BreadcrumbChildItem
                      isActive={!!breadcrumbArray.find((item) => item.boardId === child.boardId)}
                      key={child.boardId}
                      name={child.name}
                      url={child.url ?? ''}
                      icon="brl"
                    />
                  );
                })}
              </ul>
            </div>
          )}
          {!!PLBoard?.length && (
            <div className="breadcrumb-navigation__child-section">
              <div className="breadcrumb-navigation__child-section-title">{t('navigation.PLBoards')}</div>
              <ul className="breadcrumb-navigation__child-item-wrap">
                {PLBoard?.map((child) => {
                  return (
                    <BreadcrumbChildItem
                      isActive={!!breadcrumbArray.find((item) => item.boardId === child.boardId && item.type === MenuType.PLBoard)}
                      key={child.boardId}
                      name={child.name}
                      url={child.url ?? ''}
                      icon="pl"
                    />
                  );
                })}
              </ul>
            </div>
          )}
          {!!Projects?.length && (
            <div className="breadcrumb-navigation__child-section">
              <div className="breadcrumb-navigation__child-section-title">{t('navigation.Projects')}</div>
              <ul className="breadcrumb-navigation__child-item-wrap">
                {Projects?.map((child) => {
                  return (
                    <BreadcrumbChildItem
                      isActive={!!breadcrumbArray.find((item) => item.boardId === child.boardId)}
                      key={child.boardId}
                      name={child.name}
                      url={child.url ?? ''}
                      icon="pl"
                    />
                  );
                })}
              </ul>
            </div>
          )}
          {!hasNoTiles && (
            <div className="breadcrumb-navigation__child-section">
              <div className="breadcrumb-navigation__child-section-title">{t('navigation.Tiles')}</div>
              <ul className="breadcrumb-navigation__child-item-wrap">
                {breadcrumbChild.tiles?.map((tile) => (
                  <BreadcrumbChildItem
                    isActive={!!breadcrumbArray.find((item) => item.tileId === tile.tileId)}
                    key={tile.tileId}
                    name={tile.name}
                    url={tile.url ?? ''}
                    icon="tile"
                  />
                ))}
              </ul>
            </div>
          )}
          {!hasNoBackOffice && (
            <div className="breadcrumb-navigation__child-section">
              <div className="breadcrumb-navigation__child-section-title">{t('navigation.Options')}</div>
              <ul className="breadcrumb-navigation__child-item-wrap">
                <BreadcrumbChildItem
                  isActive={isSettingsActive}
                  name={t('navigation.Settings')}
                  url={breadcrumbChild.backOffice ?? ''}
                  icon="backOfficeSmall"
                />
              </ul>
            </div>
          )}
        </div>
      )}
    </li>
  );
};

export default BreadcrumbChild;
