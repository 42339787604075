import { t } from '../../../../i18n';

export const getProjectName = (index?: number) => {
  if (index === 0) {
    return `${t('breadcrumb.project.page.1')}`;
  }
  if (index === 1) {
    return `${t('breadcrumb.project.page.2')}`;
  }
  if (index === 2) {
    return `${t('breadcrumb.project.page.3')}`;
  }
  return t('breadcrumb.project.page.1');
};
