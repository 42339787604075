import React from 'react';
import { OSDataStatus } from '../../core/state/occupational-safety/types';

interface Props {
  amount: number;
  text?: string;
  status?: OSDataStatus;
}

const TileOccupationalSafetyInfo: React.FC<Props> = ({ amount, text, status = OSDataStatus.Yellow }) => {
  return (
    <div className="tile-occupational-safety__table-info-item">
      <span
        className={`tile-occupational-safety__table-info-box ${status ? `tile-occupational-safety__table-info-box--${status.toLowerCase()}` : ''}`}
      />
      {amount >= 0 && <span className="tile-occupational-safety__table-info-number">{typeof amount === 'object' || amount < 0 ? '' : amount}</span>}
      {text ?? null}
    </div>
  );
};

export default TileOccupationalSafetyInfo;
