import React from 'react';
import { Link } from 'react-router-dom';

import BoardHeader from '../Board/BoardHeader/BoardHeader';

import './InfoPage.scss';

interface Props {
  title?: string;
  subtitle?: string;
  buttonRoute?: string;
  buttonText?: string;
}

const InfoPage: React.FC<Props> = ({ title, subtitle, buttonRoute, buttonText }) => {
  return (
    <>
      <BoardHeader />
      <div className="info-page">
        {title && <h1 className="info-page__title">{title}</h1>}
        {subtitle && <p className="info-page__subtitle">{subtitle}</p>}
        {buttonText && (
          <div className="info-page__inner">
            <Link to={buttonRoute ?? ''} className="button button--primary button--big">
              {buttonText}
            </Link>
          </div>
        )}
      </div>
    </>
  );
};

export default InfoPage;
