import React from 'react';
import { useTranslation } from 'react-i18next';

import Button from '../../../components/Button/Button';

import './TableFormAddRow.scss';

interface Props {
  onClick: () => any;
}

const TableFormAddRow: React.FC<Props> = ({ onClick }) => {
  const { t } = useTranslation();

  return (
    <div className="table-form-add-row d-flex justify-content-center">
      <Button buttonTypes={['primary', 'big']} onClick={() => onClick()}>
        {t('buttons.addNewRow')}
      </Button>
    </div>
  );
};

export default TableFormAddRow;
