import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';
import { format } from 'date-fns';

import Button from '../../../components/Button/Button';
import { TableFormFieldStatus } from '../../../core/enums/TableFormFieldStatus';
import Calendar from '../../../components/form/Calendar/Calendar';
import Input from '../../../components/form/Input/Input';
import TextArea from '../../../components/form/TextArea/TextAreaHookForm';
import { DATE_FORMAT_BACKEND } from '../../../core/constants/date';
import { EscalationDetail, EscalationLevel, EscalationType, MeasureAndEscalation } from '../../../core/state/table-forms/types';
import { Sizes } from '../../../core/enums/Sizes';
import Radio from '../../../components/form/Radio/Radio';
import Icon from '../../../components/Icon/Icon';
import EscalationStatus from '../../../tiles/TileEscalation/EscalationStatus/EscalationStatus';

import './MeasureEscalationModal.scss';

export interface Props {
  onCancel?: () => void;
  onConfirm?: (data: MeasureAndEscalation) => void;
  KVPText?: {
    problem: string;
    measure: string | number;
  };
  onDirtyStatusChange: (isDirty: boolean) => void;
}

const CreateEscalationForm: React.FC<Props> = ({ onCancel, onConfirm, KVPText, onDirtyStatusChange }) => {
  const { t } = useTranslation();

  const escalationTypeOptions = useMemo(
    () => [
      {
        label: (
          <>
            {t('escalationForm.type.informative')} <Icon className="ml-2" icon="info" inline />
          </>
        ),
        value: EscalationType.Informative
      },
      {
        label: (
          <>
            {t('escalationForm.type.requiresAction')} <Icon className="ml-2" icon="alert" inline />
          </>
        ),
        value: EscalationType.Action
      }
    ],
    [t]
  );

  const {
    register,
    handleSubmit,
    control,
    formState: { isDirty, isSubmitting, isSubmitSuccessful }
  } = useForm<EscalationDetail>({
    defaultValues: {
      openedAt: format(new Date(), DATE_FORMAT_BACKEND),
      deadlineAt: null,
      level: EscalationLevel.BRL,
      type: EscalationType.Informative,
      problem: KVPText?.problem ?? '',
      summary: (KVPText?.measure ?? '').toString(),
      riskPotential: '',
      status: TableFormFieldStatus.Quarter
    }
  });

  useEffect(() => {
    onDirtyStatusChange(isDirty);
  }, [onDirtyStatusChange, isDirty]);

  const onSubmit = handleSubmit(async (escalation) => {
    if (onConfirm) {
      onConfirm({ escalation, measure: null });
    }
  });

  return (
    <form onSubmit={onSubmit}>
      <div className="measure-form__row measure-form__row--radio">
        <Controller
          name="type"
          control={control}
          render={({ onChange, value }) => (
            <Radio
              id="escalationType"
              name="type"
              label={t('escalationForm.type')}
              options={escalationTypeOptions}
              defaultValue={value}
              onChange={onChange}
              inline
            />
          )}
        />
      </div>

      <TextArea defaultValue={KVPText?.problem} id="escalation-problem" name="problem" label={t('escalationForm.problem')} register={register} />

      <TextArea
        defaultValue={(KVPText?.measure ?? '').toString()}
        id="escalation-measure"
        name="summary"
        label={t('escalationForm.decision')}
        register={register}
      />

      <div className="measure-form__row">
        <div className="mr-5">
          <Controller
            name="openedAt"
            control={control}
            render={({ onChange, value }) => (
              <Calendar
                label={t('escalationForm.dateCreated')}
                isFormInput
                onChange={async (date) => {
                  onChange(date);
                }}
                isCentered={false}
                value={value}
              />
            )}
          />
        </div>

        <div>
          <Controller
            name="deadlineAt"
            control={control}
            render={({ onChange, value }) => (
              <Calendar
                label={t('escalationForm.dateDue')}
                isFormInput
                onChange={async (date) => {
                  onChange(date);
                }}
                isCentered={false}
                value={value}
              />
            )}
          />
        </div>
      </div>

      <div className="measure-form__row">
        <Input id="riskPotential" name="riskPotential" label={t('escalationForm.riskPotential')} register={register} />

        <div className="measure-form__indicator-group">
          <span className="measure-form__indicator-label">{t('escalationForm.status')}</span>
          <div className="mt-1">
            <Controller
              name="status"
              control={control}
              render={({ onChange, value }) => (
                <EscalationStatus
                  onClick={() => onChange(value === TableFormFieldStatus.Checked ? TableFormFieldStatus.Unchecked : TableFormFieldStatus.Checked)}
                  value={value}
                  size={Sizes.Large}
                />
              )}
            />
          </div>
        </div>
      </div>

      <div className="d-flex justify-content-center measure-form__buttons">
        <Button className="mr-2" buttonTypes={['big', 'secondary']} onClick={onCancel}>
          {t('sharedText.cancel')}
        </Button>
        <Button buttonTypes={['big', 'primary']} type="submit" disabled={isSubmitting || isSubmitSuccessful}>
          {t('sharedText.create')}
        </Button>
      </div>
    </form>
  );
};

export default CreateEscalationForm;
