import React, { Fragment, useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { cloneDeep } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { TileTypes } from '../../core/enums/TileTypes';
import { useTableForm } from '../../core/hooks/useTableForm';
import { BOARDS_TILE_DETAIL } from '../../core/constants/routes';
import { RootState } from '../../core/state/state';
import { generateAsyncActions, saveTableFormMeasure } from '../../core/state/table-forms/asyncActions';
import { ColumnType, MeasureAndEscalation, TableForm, TableFormRow } from '../../core/state/table-forms/types';
import { TableFormFieldStatus } from '../../core/enums/TableFormFieldStatus';

import Table from '../../components/Table/Table';
import TR from '../../components/Table/TR/TR';
import TD from '../../components/Table/TD/TD';
import THead from '../../components/Table/THead/THead';
import TH from '../../components/Table/TH/TH';
import TBody from '../../components/Table/TBody/TBody';

import TableInput from '../../components/form/TableInput/TableInputHook';

import ButtonToolbar from '../../pages/components/ButtonToolbar/ButtonToolbar';
import Icon from '../../components/Icon/Icon';
import Button from '../../components/Button/Button';
import UnsavedChangesPopup from '../../components/popup/UnsavedChangesPopup/UnsavedChangesPopup';
import Calendar from '../../components/form/Calendar/CalendarHook';
import TilePlanningQualityInfo from './TilePlanningQualityInfo';
import TableStatus from '../../components/form/TableStatus/TableStatus';
import { useRowNumber } from '../../core/hooks/useRowNumber';
import MeasureIndicator from '../TileMeasure/TileMeasureIndicator/MeasureIndicatorHook';
import MeasureEscalationModal from '../../pages/components/MeasureEscalationModal/MeasureEscalationModal';
import { getMeasureInfo, MeasureViewModel } from '../../core/utils/measures/getMeasureInfo';
import { useMeasureTableFormChange } from '../../core/hooks/measure/useMeasureTableFormChange';
import { getUpdateTableFormWithMeasure } from '../../core/utils/measures/getUpdatedTableFromWithMeasure';

import './TilePlanningQuality.scss';

interface Props {
  boardId?: number;
  url: string;
}

const TilePlanningQuality: React.FC<Props> = ({ url, boardId }) => {
  const dispatch = useDispatch();
  const tableForm = useSelector((state: RootState) => state.planningQuality.form);
  const { isFormDirty, isFormEditing } = useSelector((state: RootState) => state.settings);
  const isDetailPage = useRouteMatch(BOARDS_TILE_DETAIL);
  const { t } = useTranslation();
  const nextRowNumber = useRowNumber(tableForm);
  const [isModalOpen, setModalOpen] = useState<boolean>(false);
  const [measuresArray, setMeasuresArray] = useState<MeasureViewModel[]>([]);
  const [measuresRow, setMeasuresRow] = useState(-1);
  const selectedMeasure = getMeasureInfo(tableForm, measuresRow);

  useMeasureTableFormChange(measuresArray, setMeasuresArray, tableForm);

  const { getTableForm, updateTableForm, saveTableForm } = React.useMemo(() => generateAsyncActions(TileTypes.PlanningQuality), []);

  const { submit, useTableValue } = useTableForm<TableForm>({
    boardId,
    data: tableForm,
    valuePropertyName: 'text',
    updateActions: [updateTableForm],
    submitActions: [updateTableForm, saveTableForm]
  });

  const { useTableValue: useTableStatus } = useTableForm<TableForm>({
    boardId,
    data: tableForm,
    valuePropertyName: 'status',
    updateActions: [updateTableForm],
    submitActions: [updateTableForm, saveTableForm]
  });

  React.useEffect(() => {
    dispatch(getTableForm(url));
  }, [url, dispatch, getTableForm]);

  const clearAllFields = () => {
    const newTableForm = cloneDeep(tableForm);
    if (newTableForm) {
      newTableForm.rows = [];
      dispatch(updateTableForm(newTableForm));
    }
  };

  const handleSubmit = async () => {
    for (let i = 0; i < measuresArray.length; i += 1) {
      const item = measuresArray[i];
      if (item.measureURL) {
        // eslint-disable-next-line no-await-in-loop
        await dispatch(saveTableFormMeasure(item.measure, item.measureURL));
      }
    }
    submit();
  };

  const handleAddMeasure = async (data: MeasureAndEscalation) => {
    const newMeasuresArray = cloneDeep(measuresArray);
    const newTableForm = getUpdateTableFormWithMeasure(tableForm, data.measure, selectedMeasure?.measureURL);
    if (data.measure) {
      newMeasuresArray.push({
        measure: data.measure,
        measureURL: selectedMeasure?.measureURL
      });
    }

    if (newTableForm) {
      await dispatch(updateTableForm(newTableForm));
    }

    setMeasuresArray(newMeasuresArray);
    setModalOpen(false);
  };

  const addEmptyRow = (suppressDirtyStatus = false) => {
    const newTableForm = cloneDeep(tableForm);
    const emptyRow: TableFormRow = {
      rowNumber: nextRowNumber(),
      columns: [
        {
          columnNumber: 0,
          title: t('forms.planningQuality.column.id'),
          status: TableFormFieldStatus.Undefined,
          text: ''
        },
        {
          columnNumber: 1,
          title: t('forms.planningQuality.column.craft'),
          status: TableFormFieldStatus.Undefined,
          text: ''
        },
        {
          columnNumber: 2,
          title: t('forms.planningQuality.column.status'),
          status: TableFormFieldStatus.Undefined,
          text: ''
        },
        {
          columnNumber: 3,
          title: t('forms.planningQuality.column.coordinated'),
          status: TableFormFieldStatus.Undefined,
          text: ''
        },
        {
          columnNumber: 4,
          title: t('forms.planningQuality.column.contractFulfilled'),
          status: TableFormFieldStatus.Undefined,
          text: ''
        },
        {
          columnNumber: 5,
          title: t('forms.planningQuality.column.technicalKorrekt'),
          status: TableFormFieldStatus.Undefined,
          text: ''
        },
        {
          columnNumber: 6,
          title: t('forms.planningQuality.column.complete'),
          status: TableFormFieldStatus.Undefined,
          text: ''
        },
        {
          columnNumber: 7,
          title: t('forms.planningQuality.column.measures'),
          status: TableFormFieldStatus.Undefined,
          type: ColumnType.Measure,
          text: ''
        },
        {
          columnNumber: 8,
          title: t('sharedText.tableForm.assigned'),
          status: TableFormFieldStatus.Undefined,
          type: ColumnType.AssignedTo,
          text: ''
        },
        {
          columnNumber: 9,
          title: t('sharedText.tableForm.deadline'),
          status: TableFormFieldStatus.Undefined,
          type: ColumnType.Deadline,
          text: ''
        },
        {
          columnNumber: 10,
          title: t('sharedText.tableForm.status'),
          status: TableFormFieldStatus.Undefined,
          type: ColumnType.MeasureStatus,
          text: ''
        },
        {
          columnNumber: 11,
          title: t('sharedText.tableForm.level'),
          status: TableFormFieldStatus.Undefined,
          type: ColumnType.EscalationLevel,
          text: ''
        }
      ]
    };
    if (newTableForm) {
      newTableForm.rows.push(emptyRow);
      dispatch(updateTableForm(newTableForm, true, suppressDirtyStatus));
    }
  };

  const handleDeleteRow = (index: number) => {
    const newTableForm = cloneDeep(tableForm);

    if (newTableForm) {
      newTableForm.rows.splice(index, 1);
      newTableForm.rows = newTableForm.rows.map((row, rowIndex) => ({ ...row, rowNumber: rowIndex + 1 }));
      dispatch(updateTableForm(newTableForm));
    }
  };

  const getHeader = () => {
    return (
      <>
        <TR>
          <TH rowSpan={2} className="w-3">
            <div className="table__title text-center">{t('forms.planningQuality.column.id')}</div>
          </TH>
          <TH rowSpan={2} className="w-10">
            <div className="table__title text-center">{t('forms.planningQuality.column.craft')}</div>
          </TH>
          <TH rowSpan={2} className="w-5">
            <div className="table__title text-center">{t('forms.planningQuality.column.status')}</div>
          </TH>
          <TH colSpan={4} className="w-15">
            <div className="table__title text-center">{t('forms.planningQuality.column.criteria')}</div>
          </TH>
          <TH rowSpan={2} className="table__table-header text-center w-29">
            <div className="table__title">{t('forms.planningQuality.column.measures')}</div>
            <div className="table__subtitle">{t('forms.planningQuality.column.measures.subtitle')}</div>
          </TH>
          <TH rowSpan={2} className="table__table-header text-center w-20">
            <div className="table__title">{t('sharedText.tableForm.assigned')}</div>
          </TH>
          <TH rowSpan={2} className="table__table-header text-center w-5">
            <div className="table__title">{t('sharedText.tableForm.deadline')}</div>
          </TH>
          <TH rowSpan={2} className="table__table-header text-center w-4">
            <div className="table__title">{t('sharedText.tableForm.status')}</div>
          </TH>
          <TH rowSpan={2} className="table__table-header text-center w-5">
            <div className="table__title">{t('sharedText.tableForm.level')}</div>
          </TH>
          {isDetailPage && (
            <TH className="w-5" colSpan={2} rowSpan={2}>
              <div className="table__title text-center">{t('sharedText.tableForm.actions')}</div>
            </TH>
          )}
        </TR>
        <TR>
          <TH>
            <div className="table__title text-center">{t('forms.planningQuality.column.coordinated')}</div>
          </TH>
          <TH>
            <div className="table__title text-center">{t('forms.planningQuality.column.contractFulfilled')}</div>
          </TH>
          <TH>
            <div className="table__title text-center">{t('forms.planningQuality.column.technicalKorrekt')}</div>
          </TH>
          <TH>
            <div className="table__title text-center">{t('forms.planningQuality.column.complete')}</div>
          </TH>
        </TR>
      </>
    );
  };

  const getAllRows = () => {
    if (tableForm && tableForm.rows.length > 0) {
      return tableForm.rows.map((row, rowIndex) => {
        const isDisabled = row.isMeasureEditable !== undefined && !row.isMeasureEditable;
        return (
          <Fragment key={row.rowNumber}>
            <TR key={`${row.columns[1].text}-${row.rowNumber}-0`}>
              <TD className="text-center shrink">{rowIndex + 1}</TD>
              <TD className="shrink">
                <TableInput type={isDetailPage ? undefined : 'small'} row={rowIndex} column={1} useTableValue={useTableValue} />
              </TD>
              <TD className="text-center shrink">
                <TableStatus
                  row={rowIndex}
                  column={2}
                  useTableValue={useTableStatus}
                  type="circle"
                  statuses={[TableFormFieldStatus.Undefined, TableFormFieldStatus.Green, TableFormFieldStatus.Red]}
                  isSmall={!isDetailPage}
                />
              </TD>
              <TD className="text-center shrink">
                <TableStatus
                  row={rowIndex}
                  column={3}
                  useTableValue={useTableStatus}
                  statuses={[TableFormFieldStatus.Undefined, TableFormFieldStatus.Checked]}
                  isSmall={!isDetailPage}
                />
              </TD>
              <TD className="text-center shrink">
                <TableStatus
                  row={rowIndex}
                  column={4}
                  useTableValue={useTableStatus}
                  statuses={[TableFormFieldStatus.Undefined, TableFormFieldStatus.Checked]}
                  isSmall={!isDetailPage}
                />
              </TD>
              <TD className="text-center shrink">
                <TableStatus
                  row={rowIndex}
                  column={5}
                  useTableValue={useTableStatus}
                  statuses={[TableFormFieldStatus.Undefined, TableFormFieldStatus.Checked]}
                  isSmall={!isDetailPage}
                />
              </TD>
              <TD className="text-center shrink">
                <TableStatus
                  row={rowIndex}
                  column={6}
                  useTableValue={useTableStatus}
                  statuses={[TableFormFieldStatus.Undefined, TableFormFieldStatus.Checked]}
                  isSmall={!isDetailPage}
                />
              </TD>
              <TD className={`shrink w-13 ${isDisabled ? 'table-item-disabled' : ''}`}>
                <TableInput reInitDefaultValue type={isDetailPage ? undefined : 'small'} row={rowIndex} column={7} useTableValue={useTableValue} />
              </TD>
              <TD className={`shrink ${isDisabled ? 'table-item-disabled' : ''}`}>
                <TableInput reInitDefaultValue type={isDetailPage ? undefined : 'small'} row={rowIndex} column={8} useTableValue={useTableValue} />
              </TD>
              <TD className={`text-center shrink ${isDisabled ? 'table-item-disabled' : ''}`}>
                <Calendar reInitDefaultValue isCentered isSmall={!isDetailPage} row={rowIndex} column={9} useTableValue={useTableValue} />
              </TD>
              <TD className={`text-center shrink ${isDisabled ? 'table-item-disabled' : ''}`}>
                <MeasureIndicator
                  row={rowIndex}
                  column={10}
                  useTableValue={useTableStatus}
                  size={isDetailPage ? 'big' : 'small'}
                  statuses={[
                    TableFormFieldStatus.Undefined,
                    TableFormFieldStatus.Quarter,
                    TableFormFieldStatus.Half,
                    TableFormFieldStatus.ThreeQuarters,
                    TableFormFieldStatus.Full
                  ]}
                />
              </TD>
              <TD className={`text-center shrink ${isDisabled ? 'table-item-disabled' : ''}`}>
                <TableInput isText type={isDetailPage ? undefined : 'small'} row={rowIndex} column={11} useTableValue={useTableValue} />
              </TD>
              {isDetailPage && (
                <TD className={`text-center ${row.isMeasureEditable !== undefined && !row.isMeasureEditable ? 'table-item-disabled' : ''}`}>
                  <button
                    disabled={!row.canCreateMeasure || !!measuresArray.find((item) => item.measureURL === row.measureURL)}
                    title={t('buttons.addMeasure')}
                    className="btn btn-link"
                    onClick={() => {
                      setMeasuresRow(rowIndex);
                      setModalOpen(true);
                    }}
                  >
                    <Icon className="icon--table-plus" icon="plus" svg />
                    <span className="sr-only">{t('buttons.addMeasure')}</span>
                  </button>
                </TD>
              )}
              {isDetailPage && (
                <TD className="text-center shrink w-3">
                  <button title={t('buttons.deleteRow')} className="btn btn-link" onClick={() => handleDeleteRow(rowIndex)}>
                    <Icon icon="delete" svg />
                    <span className="sr-only">{t('buttons.deleteRow')}</span>
                  </button>
                </TD>
              )}
            </TR>
          </Fragment>
        );
      });
    }
    return null;
  };

  return (
    <div className={`tile-table ${isDetailPage ? '' : 'tile-table--small'}`}>
      <div className={`${isDetailPage ? 'container-fluid' : ''}`}>
        <div className={isDetailPage ? '' : 'px-1 pt-1 d-flex justify-content-between'}>
          {!isDetailPage && <div className="tile-table__title">{t(`forms.planningQuality.title`)}</div>}
          <div className="d-flex justify-content-end align-items-center">
            {isDetailPage && <div className="tile-table__sub-title d-flex flex-grow-1">{t(`forms.planningQuality.subtitle`)}</div>}
            {!isDetailPage && (
              <div className="d-flex align-items-center mr-2">
                <span className="tile-table__status-text mr-1">Status:</span>
                <div className={`tile-table__status-indicator tile-table__status-indicator--${tableForm && tableForm.status.toLowerCase()}`} />
              </div>
            )}
            {isDetailPage && <ButtonToolbar submit={handleSubmit} clearAll={clearAllFields} />}
          </div>
        </div>
        <div className={isDetailPage ? '' : 'd-flex justify-content-between px-1'}>
          {!isDetailPage && <div className="tile-table__sub-title d-flex flex-grow-1">{t(`forms.planningQuality.subtitle`)}</div>}
          {TilePlanningQualityInfo && <TilePlanningQualityInfo isDetailPage={isDetailPage} />}
        </div>
        <div>
          <Table className={`${isDetailPage ? 'tile-table__table' : 'tile-table__table table--small'}`}>
            <THead>{getHeader()}</THead>
            <TBody>{getAllRows()}</TBody>
          </Table>
        </div>
        {isDetailPage && (
          <div className="d-flex justify-content-center my-4">
            <Button buttonTypes={['primary', 'big']} onClick={() => addEmptyRow()}>
              {t('buttons.addNewRow')}
            </Button>
          </div>
        )}
      </div>
      <MeasureEscalationModal
        KVPText={{
          problem: tableForm?.title ?? '',
          measure: selectedMeasure?.measure?.measureText ?? '',
          assignedTo: selectedMeasure?.measure.assignedTo ?? '',
          deadline: selectedMeasure?.measure.deadline ?? null,
          status: selectedMeasure?.measure.status
        }}
        onConfirm={handleAddMeasure}
        onCancel={() => setModalOpen(false)}
        show={isModalOpen}
        hasOnlyMeasure
      />
      {isDetailPage && <UnsavedChangesPopup showIf={isFormDirty || isFormEditing} />}
    </div>
  );
};

export default TilePlanningQuality;
