import * as React from 'react';
import { Link, useParams } from 'react-router-dom';

import { Color } from '../../core/enums/Color';
import SectionHeader from '../../pages/components/SectionHeader/SectionHeader';

import { Tile as TileModel } from '../../core/state/boards/types';
import getTileComponent from '../../core/utils/tile/getTileComponent';
import getTileDetailPageUrl from '../../core/utils/tile/getTileDetailPageUrl';
import { TileTypes } from '../../core/enums/TileTypes';

import './Tile.scss';
import './TileDesktopScreen.scss';

export enum TileHeight {
  OneQuarter = 'ONE_QUARTER',
  HalfSize = 'HALF_SIZE',
  ThreeQuarters = 'THREE_QUARTERS',
  FullSize = 'FULL_SIZE'
}

interface Props {
  tile?: TileModel;
  isInteractive?: boolean;
  headingText?: string;
  headingBackgroundColor?: Color;
  height?: TileHeight;
  borderColor?: Color;
  isTransparent?: boolean;
  className?: string;
  isHorizontal?: boolean;
}

export const getHeightClass = (height?: TileHeight, className = 'tile') => {
  switch (height) {
    case TileHeight.OneQuarter:
      return `${className}--one-quarter`;
    case TileHeight.HalfSize:
      return `${className}--half-size`;
    case TileHeight.ThreeQuarters:
      return `${className}--three-quarters`;
    case TileHeight.FullSize:
    default:
      return `${className}--full-size`;
  }
};

const Tile: React.FC<Props> = ({
  tile,
  isInteractive,
  headingText,
  headingBackgroundColor,
  height,
  borderColor,
  className,
  isTransparent,
  isHorizontal
}) => {
  const { id } = useParams<{ id: string }>();
  return (
    <section className={`tile ${isHorizontal ? 'tile--horizontal' : ''} ${getHeightClass(height)} ${className || ''} `}>
      {headingText && <SectionHeader text={headingText} color={headingBackgroundColor} />}
      <div className={`tile__content ${borderColor ? `tile__content--${borderColor}` : ''} ${isTransparent ? 'tile__content--transparent' : ''}`}>
        {tile?.type !== TileTypes.Empty && (
          <>
            {isInteractive
              ? tile && getTileComponent(tile)
              : tile && (
                  <Link className="tile__link" to={getTileDetailPageUrl(tile, id)}>
                    {tile && getTileComponent(tile)}
                  </Link>
                )}
          </>
        )}
      </div>
    </section>
  );
};

export default Tile;
