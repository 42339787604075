import { RestResponse } from '../../types/response/RestResponse';
import service from '../RestService';
import { User } from '../../state/back-office/types';

const PATH = '/users';

class UsersService {
  getUsers = (query: string): RestResponse<User[]> => service.get(`${PATH}?name=${query}`);
}

export default new UsersService();
