import { ProjectAreaState } from './types';
import Actions from '../Actions';
import ActionTypes from '../actionTypes';
import { StateStatus } from '../../enums/StateStatus';

export const defaultProjectAreaState: ProjectAreaState = {
  overview: {
    title: '',
    tileId: 0,
    rows: []
  },
  statusFormsByAreaId: {},
  status: StateStatus.Initial,
  error: null
};

export default function reducer(state: ProjectAreaState = defaultProjectAreaState, action: Actions): ProjectAreaState {
  switch (action.type) {
    case ActionTypes.GET_PROJECT_AREA_STATUS_SUMMARY:
    case ActionTypes.GET_PROJECT_AREA_OVERVIEW:
      return { ...state, status: StateStatus.Busy };
    case ActionTypes.GET_PROJECT_AREA_OVERVIEW_SUCCESS:
      return { ...state, status: StateStatus.Loaded, overview: action.payload };
    case ActionTypes.GET_PROJECT_AREA_STATUS_SUMMARY_SUCCESS:
      return { ...state, statusFormsByAreaId: { ...state.statusFormsByAreaId, [action.payload.areaId]: action.payload.projectStatuses } };
    case ActionTypes.GET_PROJECT_AREA_STATUS_SUMMARY_ERROR:
    case ActionTypes.GET_PROJECT_AREA_OVERVIEW_ERROR:
      return { ...state, status: StateStatus.Error, error: action.payload };
    default:
      return state;
  }
}
