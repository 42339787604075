import * as React from 'react';
import { Link, useParams } from 'react-router-dom';

import { BASE_URL } from '../../../core/constants/api';
import { Tile } from '../../../core/state/boards/types';
import TileImage from '../../TileImage/TileImage';
import { TileTypes } from '../../../core/enums/TileTypes';
import TilePDF from '../../TilePDF/TilePDF';

interface Props {
  tile: Tile;
}

const ProjectAreaRowTile: React.FC<Props> = ({ tile }) => {
  const { id } = useParams<{ id: string }>();
  const url = `${BASE_URL}${tile.onClickDataUrl}`;
  let linkUrl;

  if (tile.type === TileTypes.PowerBI || tile.type === TileTypes.Pdf) {
    linkUrl = `/boards/${id}/detail?type=${tile.type}&url=${encodeURIComponent(url)}&title=${tile.title}`;
  } else {
    linkUrl = `${tile.onClickDataUrl}`;
  }

  return (
    <>
      <section key={`${tile.tileId}-${tile.position}`} className="tile">
        <div className="tile__content">
          {tile.onClickDataUrl && (
            <Link className="tile__link tile__link--has-pointer-events" to={linkUrl}>
              {tile.type === TileTypes.PowerBI ? <TileImage url={`${BASE_URL}${tile.previewDataUrl}`} /> : <TilePDF url={url ?? ''} view="FitH" />}
            </Link>
          )}
        </div>
      </section>
    </>
  );
};

export default ProjectAreaRowTile;
