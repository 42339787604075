import * as React from 'react';
import { Redirect, Route, Router, Switch } from 'react-router-dom';

import PageWrap from '../components/PageWrap/PageWrap';
import { PrivateRoute } from './components/PrivateRoute';
import { history } from '../core/state/history';
import { useLandingRoute } from './useLandingRoute';

import {
  BACK_OFFICE,
  BACK_OFFICE_DETAIL,
  BACK_OFFICE_PERMISSIONS,
  BOARD_HISTORY_VIEW,
  BOARD_SCREENSHOT_VIEW,
  BOARDS_ID,
  BOARDS_TILE_DETAIL,
  FORBIDDEN,
  HOME_ROUTE,
  LOGIN,
  REPORT_SCREENSHOT_VIEW,
  UNASSIGNED_BOARD
} from '../core/constants/routes';

import Page404 from '../pages/page404/Page404';
import BoardPage from '../pages/board/Board';
import TileDetail from '../pages/tile-detail/TileDetail';
import ReportScreenshotView from '../pages/report-screenshot-view/ReportScreenshotView';
import BoardScreenshotView from '../pages/board-screenshot-view/BoardScreenshotView';
import HistoryPage from '../pages/components/History/History';
import Login from '../pages/login/Login';
import UnassignedBoard from '../pages/unassigned-board/UnassignedBoard';
import BackOfficePage from '../pages/back-office/BackOfficePage';
import BackOfficeDetail from '../pages/back-office/BackOfficeDetail/BackOfficeDetail';
import BackOfficePermissions from '../pages/back-office/BackOfficePermissions/BackOfficePermissions';
import Forbidden from '../pages/forbidden/Forbidden';

export default () => {
  const route = useLandingRoute();
  return (
    <Router history={history}>
      <PageWrap>
        <Switch>
          <Redirect exact from={HOME_ROUTE} to={route} />
          <PrivateRoute exact path={UNASSIGNED_BOARD} component={UnassignedBoard} />
          <PrivateRoute exact path={BOARDS_ID} component={BoardPage} />
          <PrivateRoute exact path={BOARDS_TILE_DETAIL} component={TileDetail} />
          <PrivateRoute exact path={REPORT_SCREENSHOT_VIEW} component={ReportScreenshotView} />
          <PrivateRoute exact path={BOARD_SCREENSHOT_VIEW} component={BoardScreenshotView} />
          <PrivateRoute exact path={BOARD_HISTORY_VIEW} component={HistoryPage} />
          <PrivateRoute exact path={BACK_OFFICE} component={BackOfficePage} />
          <PrivateRoute exact path={BACK_OFFICE_PERMISSIONS} component={BackOfficePermissions} />
          <PrivateRoute exact path={BACK_OFFICE_DETAIL} component={BackOfficeDetail} />
          <Route exact path={FORBIDDEN} component={Forbidden} />
          <Route exact path={LOGIN} component={Login} />
          <Route component={Page404} />
        </Switch>
      </PageWrap>
    </Router>
  );
};
