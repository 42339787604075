import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse, Method } from 'axios';
import { isAfter } from 'date-fns';
import * as MSAL from 'msal';

import { RestResponse } from '../types/response/RestResponse';
import { API_URL } from '../constants/api';
import store from '../state/store';
import { ScreenSize } from '../enums/ScreenSize';
import { msalConfig, tokenRequest } from '../constants/login/loginConfig';
import { Actions as AuthActions } from '../state/auth/actions';
import { isDesktopScreenSize } from '../utils/isDesktopScreen';
import { history } from '../state/history';
import { FORBIDDEN, LOGIN } from '../constants/routes';

export interface RestConfig {
  baseURL: string;
}

const client = new MSAL.UserAgentApplication(msalConfig);

let isRefreshing = false;

let failedQueue: any[] = [];

const processQueue = (error: any, token: string | null = null) => {
  failedQueue.forEach((promise) => {
    if (error) {
      promise.reject(error);
    } else {
      promise.resolve(token);
    }
  });
  isRefreshing = false;
  failedQueue = [];
};

export class RestService {
  axios: AxiosInstance;

  defaultHeader = () => ({
    'Content-Type': 'application/json'
  });

  constructor(config: RestConfig) {
    const axiosConfig = this.createConfig(config);
    this.axios = axios.create(axiosConfig);
    this.axios.interceptors.request.use(this.transformRequest);
    this.axios.interceptors.response.use(this.responseInterceptorOnFulfilled, this.responseInterceptorOnRejected);
  }

  createConfig = (config: RestConfig): AxiosRequestConfig => ({
    baseURL: config.baseURL,
    headers: this.defaultHeader(),
    withCredentials: false
  });

  transformRequest = async (config: AxiosRequestConfig) => {
    const originalRequest = config;

    if (isRefreshing) {
      return new Promise((resolve, reject) => {
        failedQueue.push({ resolve, reject });
      })
        .then((token) => {
          originalRequest.headers.Authorization = `Bearer ${token}`;
          return axios(originalRequest);
        })
        .catch((err) => {
          return Promise.reject(err);
        });
    }

    const accessToken = store.getState().auth.authInfo?.accessToken;
    const expiresOn = store.getState().auth.authInfo?.expiresOn;
    if (expiresOn && isAfter(expiresOn, new Date())) {
      return {
        ...config,
        headers: {
          ...config.headers,
          Authorization: `Bearer ${accessToken}`,
          'X-SCREEN-SIZE': isDesktopScreenSize() ? ScreenSize.Desktop : ScreenSize.Tv
        }
      };
    }
    isRefreshing = true;
    try {
      const response = await client.acquireTokenSilent(tokenRequest);
      const newAccessToken = response.accessToken;
      processQueue(null, newAccessToken);
      isRefreshing = false;
      store.dispatch(AuthActions.silentLoginSuccess(response));
      return {
        ...config,
        headers: {
          ...config.headers,
          Authorization: `Bearer ${newAccessToken}`,
          'X-SCREEN-SIZE': isDesktopScreenSize() ? ScreenSize.Desktop : ScreenSize.Tv
        }
      };
    } catch (e) {
      processQueue(e, null);
      return Promise.reject(e);
    }
  };

  transformResponse = (response: AxiosResponse): RestResponse<any> => {
    if (response.status === 401) {
      store.dispatch(AuthActions.setNotAuthenticated());
      localStorage.clear();
      history.push(LOGIN);
    } else if (response.status === 403) {
      history.push(FORBIDDEN);
    }

    return {
      data: response.data,
      headers: response.headers,
      status: response.status,
      statusText: response.statusText,
      isSuccessful: response.status >= 200 && response.status <= 299,
      isBadRequest: response.status === 400,
      isUnauthorized: response.status === 401,
      isAccessDenied: response.status === 403
    };
  };

  responseInterceptorOnFulfilled = (response: any): any => this.transformResponse(response);

  responseInterceptorOnRejected = (error: any): Promise<RestResponse<any>> => Promise.reject(this.transformResponse(error.response));

  isGetRequest = (method: string | undefined): boolean => {
    if (method) {
      return String(method).toUpperCase() === 'GET';
    }
    return false;
  };

  get = (relativeURL: string, data?: any, headers?: any) => this.request('GET', relativeURL, data, headers);

  post = (relativeURL: string, data?: any, headers?: any, config?: any) => this.request('POST', relativeURL, data, headers, config);

  put = (relativeURL: string, data?: any, headers?: any) => this.request('PUT', relativeURL, data, headers);

  delete = (relativeURL: string, data?: any, headers?: any) => this.request('DELETE', relativeURL, data, headers);

  request = (method: Method, url: string, data: any, headers: any, config: any = {}) =>
    (this.axios({
      method,
      url,
      headers,
      data,
      params: this.isGetRequest(method) ? data : undefined,
      ...config
    }) as unknown) as RestResponse<any>;
}

export default new RestService({ baseURL: API_URL });
