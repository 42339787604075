import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, useRouteMatch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import cloneDeep from 'lodash/cloneDeep';

import { RootState } from '../../../core/state/state';
import { BOARDS_TILE_DETAIL } from '../../../core/constants/routes';
import TileMeasureTableHeader from '../../TileMeasure/TileMeasureTableHeader/TileMeasureTableHeader';
import TileMeasureRow from './TileMeasureRow';
import Table from '../../../components/Table/Table';
import THead from '../../../components/Table/THead/THead';
import TileMeasureEmptyRow from '../../TileMeasure/TileMeasureRow/TileMeasureEmptyRow';
import Button from '../../../components/Button/Button';
import { CheckboxAndMeasureData } from '../../../core/state/checkbox-list/types';
import generateAsyncActions from '../../../core/state/checkbox-list/asyncActions';
import { CheckboxTileTypes } from '../../../core/enums/CheckboxTileTypes';
import { TableFormFieldStatus } from '../../../core/enums/TableFormFieldStatus';
import { MeasureDetail } from '../../../core/state/table-forms/types';
import { toISODateString } from '../../../core/utils/formatDate';
import { useRowId } from '../../../core/hooks/useRowNumber';

interface Props {
  url?: string;
  boardId?: number;
  tileType: CheckboxTileTypes;
}

const TileMeasure: React.FC<Props> = ({ url, tileType }) => {
  const dispatch = useDispatch();
  const isDetailPage = useRouteMatch(BOARDS_TILE_DETAIL);
  const { t } = useTranslation();

  const tileState = useSelector((state: RootState) => state[tileType].list) as CheckboxAndMeasureData;
  const { measures } = tileState;

  const actions = React.useMemo(() => generateAsyncActions(tileType.toUpperCase()), [tileType]);
  const nextRowNumber = useRowId(tileState.measures);

  const isEmpty = !measures?.length;

  const addEmptyRow = (suppressDirtyStatus = false) => {
    const newData = cloneDeep(tileState);
    const emptyRow: MeasureDetail = {
      id: nextRowNumber(),
      type: '',
      date: toISODateString(new Date()),
      problemText: '',
      measureText: '',
      assignedTo: '',
      createdAt: toISODateString(new Date()),
      deadline: '',
      status: TableFormFieldStatus.Undefined
    };

    if (newData.measures) {
      newData.measures.push(emptyRow);
    } else {
      newData.measures = [emptyRow];
    }
    dispatch(actions.updateList(newData, true, suppressDirtyStatus));
  };

  return (
    <>
      <div className="tile-measure">
        <div className="tile-measure__content">
          <div className="tile-measure__table-wrap">
            <Table className={`${isEmpty ? 'tile-measure__table--empty' : ''} ${isDetailPage ? 'tile-measure__table' : 'table--small'}`}>
              <THead>
                <TileMeasureTableHeader />
              </THead>
              <tbody>
                {!isEmpty &&
                  measures?.map((measure, rowIndex) => (
                    <TileMeasureRow tileType={tileType} rowIndex={rowIndex} key={measure.id || `new-${rowIndex}`} measure={measure} url={url} />
                  ))}
                {isEmpty && <TileMeasureEmptyRow />}
              </tbody>
            </Table>
          </div>
          <Route exact path={BOARDS_TILE_DETAIL}>
            <div className="d-flex justify-content-center my-4">
              <Button buttonTypes={['primary', 'big']} onClick={() => addEmptyRow(false)}>
                {t('buttons.addNewRow')}
              </Button>
            </div>
          </Route>
        </div>
      </div>
    </>
  );
};

export default TileMeasure;
