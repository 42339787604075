import React from 'react';
import { useTranslation } from 'react-i18next';
import { TileTypes } from '../../core/enums/TileTypes';
import { TableTileTypes } from '../../core/enums/TableTileTypes';
import TableFormTile from '../TableFormTile/TableFormTile';
import StatusRed from '../../pages/components/StatusRed/StatusRed';
import { Sizes } from '../../core/enums/Sizes';

interface Props {
  boardId?: number;
  url: string;
}

const TileDeadlineCompliance: React.FC<Props> = ({ url, boardId }) => {
  const { t } = useTranslation();
  const StatusLegend: React.FC<any> = ({ isDetailPage }) => (
    <StatusRed condition={t('forms.deadlineCompliance.statusText')} size={isDetailPage ? Sizes.Medium : Sizes.Small} />
  );

  return (
    <TableFormTile
      url={url}
      boardId={boardId}
      tileType={TileTypes.DeadlineCompliance}
      tableTileType={TableTileTypes.DeadlineCompliance}
      InfoComponent={StatusLegend}
      columns={[
        {
          field: 'id',
          type: 'index',
          title: t('sharedText.number'),
          headerClass: 'w-3'
        },
        {
          field: 'area',
          title: t('forms.deadlineCompliance.area'),
          subtitle: t('forms.deadlineCompliance.areaSubtitle'),
          headerClass: 'w-30'
        },
        {
          field: 'milestone',
          title: t('forms.deadlineCompliance.milestone.title'),
          headerClass: 'w-33',
          columns: [
            {
              field: 'areaDescription',
              title: t('forms.deadlineCompliance.milestone.description')
            },
            {
              field: 'areaDate',
              title: t('forms.deadlineCompliance.milestone.date'),
              subtitle: t('forms.deadlineCompliance.milestone.dateSubtitle')
            },
            {
              field: 'areaStatus',
              title: t('forms.deadlineCompliance.milestone.status'),
              type: 'status-circle',
              headerClass: 'shrink'
            }
          ]
        },
        {
          field: 'completionDate',
          title: t('forms.deadlineCompliance.completionDate.title'),
          headerClass: 'w-33',
          columns: [
            {
              field: 'completionDatePlanned',
              title: t('forms.deadlineCompliance.completionDate.planned')
            },
            {
              field: 'completionDateDate',
              title: t('forms.deadlineCompliance.completionDate.date'),
              subtitle: t('forms.deadlineCompliance.completionDate.dateSubtitle')
            },
            {
              field: 'completionDateStatus',
              title: t('forms.deadlineCompliance.completionDate.status'),
              type: 'status-circle',
              headerClass: 'shrink'
            }
          ]
        }
      ]}
    />
  );
};

export default TileDeadlineCompliance;
