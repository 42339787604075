import React, { useCallback } from 'react';
import { cloneDeep, debounce } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { generateAsyncActions } from '../../core/utils/state/generateAsyncActions';
import { SupplySchedule } from '../../core/state/supply-schedule/types';
import { TileTypes } from '../../core/enums/TileTypes';
import { TileService } from '../../core/utils/state/TileService';
import { RootState } from '../../core/state/state';
import { Actions as SettingsActions } from '../../core/state/settings/actions';

const TileSupplyScheduleInput: React.FC<any> = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const form = useSelector((state: RootState) => state.supplySchedule.form);
  const [deliveryZone, setDeliveryZone] = React.useState(form?.title);
  const debouncedDirty = useCallback(() => {
    debounce(() => dispatch(SettingsActions.setIsFormDirty(true)), 500);
  }, [dispatch]);

  const { updateTileForm } = React.useMemo(() => generateAsyncActions<SupplySchedule>(TileTypes.SupplySchedule, new TileService()), []);

  const handleDeliveryZoneChange = async (value: string) => {
    if (value.length < 50) {
      debouncedDirty();
      setDeliveryZone(value);
    }
  };

  const handleBlurDeliveryZone = (value: string) => {
    const newForm = cloneDeep(form);
    if (newForm) {
      newForm.title = value;
      dispatch(updateTileForm(newForm));
    }
  };
  return (
    <div className="d-flex">
      <div className="d-flex d-flex align-items-center">
        <span className="tile-supply-schedule__date-label">{t('forms.supplySchedule.deliveryZone')}</span>
        <input
          value={deliveryZone ?? form?.title ?? ''}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleDeliveryZoneChange(e.target.value)}
          onBlur={(e: React.ChangeEvent<HTMLInputElement>) => handleBlurDeliveryZone(e.target.value)}
          className="tile-supply-schedule__date-input"
          type="text"
        />
      </div>
    </div>
  );
};

export default TileSupplyScheduleInput;
