import ActionTypes from '../actionTypes';
import Actions from '../Actions';
import { BoardsState } from './types';
import { StateStatus } from '../../enums/StateStatus';

export const defaultBoardsState: BoardsState = {
  board: null,
  boardDetails: null,
  status: StateStatus.Initial,
  error: null
};

export default function reducer(state: BoardsState = defaultBoardsState, action: Actions): BoardsState {
  switch (action.type) {
    case ActionTypes.GET_TILE:
    case ActionTypes.GET_ALL_TILES:
      return { ...state, status: StateStatus.Busy };
    case ActionTypes.GET_ALL_TILES_SUCCESS:
      return { ...state, board: action.payload };
    default:
      return state;
  }
}
