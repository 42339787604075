import { MenuList, MenuType } from '../../state/menu/types';
import { BOARDS } from '../../constants/routes';
import { BreadcrumbChild } from './findMenuListById';
import { mapTiles } from './mapTiles';
import { getBackOfficeLink } from './getBackOfficeLink';
import { getProjectName } from './getProjectName';

export const mapMenuItemChildren = (menuList: MenuList[]): BreadcrumbChild[] => {
  return (
    menuList?.map((menuListItem) => {
      return {
        boardId: menuListItem.boardId,
        name: menuListItem.name,
        url: `${BOARDS}/${menuListItem.boardId}`,
        type: menuListItem.type,
        children: menuListItem.children?.map((item, index) => ({
          name: item.type !== MenuType.PLBoard ? item.name : getProjectName(index),
          url: `${BOARDS}/${item.boardId}`,
          boardId: item.boardId,
          type: item.type,
          backOffice: item.canAccessBackoffice ? getBackOfficeLink(item.boardId) : undefined,
          tiles: mapTiles(item.tiles || [], item.boardId)
        })),
        backOffice: menuListItem.canAccessBackoffice ? getBackOfficeLink(menuListItem.boardId) : undefined,
        tiles: mapTiles(menuListItem.tiles || [], menuListItem.boardId)
      };
    }) || []
  );
};
