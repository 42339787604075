import { MenuList, MenuType } from '../../state/menu/types';
import { BACK_OFFICE, BOARDS } from '../../constants/routes';
import { Breadcrumb, BreadcrumbChild, findMenuListById } from './findMenuListById';
import { UseBreadcrumbReturnType } from '../../hooks/useBreadcrumb';
import { mapMenuItemChildren } from './mapChildren';
import { mapTiles } from './mapTiles';
import { getBackOfficeLink } from './getBackOfficeLink';
import { getProjectName } from './getProjectName';

export function getBreadcrumbArray(breadcrumbData: UseBreadcrumbReturnType) {
  let breadcrumbArray: Breadcrumb[] = [];
  const boardId = breadcrumbData.ids.id;
  const { tileId } = breadcrumbData.ids;
  const { menuItems, backOfficeItems } = breadcrumbData.data;
  const { routes, translations } = breadcrumbData;

  let currentId = Number(boardId);
  let currentTile = null;
  let isFirstItem = true;

  if (!menuItems) {
    return breadcrumbArray;
  }

  while (currentId !== 0) {
    let menuList: MenuList | null = findMenuListById(currentId, menuItems);
    if (menuList?.type === MenuType.Project && isFirstItem) {
      menuList = menuList?.children?.find((item) => item.boardId === Number(boardId)) as MenuList;
    }
    isFirstItem = false;

    if (!menuList) {
      break;
    }

    if (currentId === Number(boardId) && routes.isBoardDetail) {
      currentTile = menuList.tiles?.find((tile) => tile.tileId === Number(tileId));
      if (menuList.type === MenuType.Project) {
        const plBoard = menuList?.children?.find((item) => item.boardId === Number(boardId));
        currentTile = plBoard?.tiles?.find((tile) => tile.tileId === Number(tileId));
      }
    }

    const parentList: MenuList | null = findMenuListById(menuList?.parentId, menuItems);
    let children: BreadcrumbChild[] | undefined;

    if (menuList.parentId !== 0) {
      children = mapMenuItemChildren(parentList?.children ?? []);
    } else {
      children = mapMenuItemChildren(menuItems);
    }

    let plBoardIndex;
    if (menuList.type === 'PLBoard') {
      plBoardIndex = parentList?.children?.findIndex((item) => item.boardId === menuList?.boardId);
    }

    const newItemArray: Breadcrumb[] = [
      {
        boardId: menuList.boardId,
        name: menuList.type === 'PLBoard' ? `${getProjectName(plBoardIndex)}` : menuList.name,
        url: `${BOARDS}/${menuList.boardId}`,
        width: -1,
        children,
        type: menuList.type,
        tiles: mapTiles(menuList.tiles || [], menuList.boardId),
        backOffice: menuList.canAccessBackoffice ? getBackOfficeLink(menuList.boardId) : undefined
      }
    ];

    breadcrumbArray = newItemArray.concat(breadcrumbArray);
    currentId = menuList.parentId;
  }

  if (routes.isBoardDetail) {
    breadcrumbArray.push({
      name: currentTile?.title ?? '',
      width: -1,
      tileId: currentTile?.tileId
    });
  }

  if (routes.isBackOffice) {
    breadcrumbArray.push({
      name: translations.backOffice,
      url: BACK_OFFICE.replace(':id', String(breadcrumbData.ids.id)),
      settingId: Number(breadcrumbData.ids.id),
      width: -1
    });
  }

  if (routes.isBackOfficeDetail) {
    const backOfficeItem = backOfficeItems?.find((detailItem) => detailItem.tileId === Number(tileId));
    if (backOfficeItem) {
      breadcrumbArray.push({
        name: String(backOfficeItem.tileTitle),
        width: -1
      });
    }
  }

  return breadcrumbArray;
}
