import * as React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { RootState } from '../../../core/state/state';
import Icon from '../../../components/Icon/Icon';
import { toDisplayDateString } from '../../../core/utils/formatDate';
import * as KVPHelpers from '../TileMeasureHelpers';
import TableCellOverflowEllipses from '../../../pages/components/TableCellOverflowEllipses/TableCellOverflowEllipses';

import TD from '../../../components/Table/TD/TD';
import Table from '../../../components/Table/Table';
import TR from '../../../components/Table/TR/TR';
import { isRowHighlighted } from '../../TileMeasuresEscalation/helpers';

import './TileMeasureDesktopScreen.scss';

interface Props {
  url?: string;
  boardId?: number;
}

const TileMeasureDesktopScreen: React.FC<Props> = () => {
  const measures = useSelector((state: RootState) => state.tableForm.measuresEscalationForm.form?.measures);
  const boardType = useSelector((state: RootState) => state.boards.board?.type);
  const { t } = useTranslation();

  const getAllRows = () => {
    return measures?.map((measure, index) => (
      <TR
        key={measure.id || `key-${index}`}
        className={`tile-measure__tr-height--${boardType?.toLocaleLowerCase()} ${
          isRowHighlighted(measure.createdAt) ? 'table__row--highlighted' : ''
        }`}
      >
        <TD>
          <TableCellOverflowEllipses content={`${index + 1}. ${measure.problemText}`} />
        </TD>
        <TD>
          <div className="d-flex flex-column tile-measure__date-display-small">
            <span className={`tile-measure__date-display-small--${boardType?.toLocaleLowerCase()}`}>{toDisplayDateString(measure.date ?? '')}</span>
            <span>{measure.type}</span>
          </div>
        </TD>
        <TD>
          <div className="tile-measure__icon">
            <Icon svg icon={KVPHelpers.getKVPStatusIcon(measure.status)} size="full-size" />
          </div>
        </TD>
      </TR>
    ));
  };

  return (
    <div className="tile-measure no-font-scaling">
      <div className="tile-measure__title">{t('forms.measure.title')}</div>
      <Table>
        <tbody>{getAllRows()}</tbody>
      </Table>
    </div>
  );
};

export default TileMeasureDesktopScreen;
