import rootReducer from './rootReducer';

import { defaultCheckboxAndMeasureState, defaultCheckboxState } from './checkbox-list/reducer';
import { defaultBoardsState } from './boards/reducer';
import { defaultTableFormsState, defaultTableFormState } from './table-forms/reducer';
import { defaultNotificationsState } from './notifications/reducer';
import { defaultProjectAreaState } from './project-area/reducer';
import { defaultSettingsState } from './settings/reducer';
import { defaultMenuState } from './menu/reducer';
import { defaultProcessConfirmationState } from './process-confirmation/reducer';
import { defaultHistoryState } from './history/reducer';
import { defaultAuthState } from './auth/reducer';
import { defaultBackofficeState } from './back-office/reducer';
import { defaultTileState } from '../utils/state/TileState';

export type RootState = Omit<ReturnType<typeof rootReducer>, symbol>;

const defaultState: RootState = {
  boards: defaultBoardsState,
  attendance: defaultCheckboxState,
  vacation: defaultCheckboxState,
  tidiness: defaultCheckboxState,
  presence: defaultCheckboxState,
  confirmation: defaultCheckboxAndMeasureState,
  tableForm: defaultTableFormsState,
  notifications: defaultNotificationsState,
  projectArea: defaultProjectAreaState,
  settings: defaultSettingsState,
  menuItems: defaultMenuState,
  processConfirmation: defaultProcessConfirmationState,
  history: defaultHistoryState,
  auth: defaultAuthState,
  backOffice: defaultBackofficeState,
  requiredMaterial: defaultTableFormState,
  toDo: defaultTableFormState,
  defectProcessing: defaultTableFormState,
  defectProcessingNU: defaultTableFormState,
  trackingAAZ: defaultTableFormState,
  planningQuality: defaultTableFormState,
  additionalServicesNU: defaultTableFormState,
  cDateOAmount: defaultTableFormState,
  plannerRisks: defaultTableFormState,
  performanceNU: defaultTableFormState,
  obstacles: defaultTableFormState,
  planApprovalCustomer: defaultTableFormState,
  planning: defaultTableFormState,
  announcement: defaultTableFormState,
  sampling: defaultTableFormState,
  inspectionPlansQAList: defaultTableFormState,
  deadlineCompliance: defaultTableFormState,
  orderPlacement: defaultTableFormState,
  inspectionPlans: defaultTableFormState,
  occupationalSafety: defaultTileState,
  supplySchedule: defaultTileState,
  capacitySubcontractor: defaultTileState,
  checkpointsMasterPlan: defaultTableFormState
};

export default defaultState;
