import * as React from 'react';
import { Link } from 'react-router-dom';

import Icon from '../../../../components/Icon/Icon';
import { Icon as IconObject } from '../../../../core/constants/Icon';

interface Props {
  url: string;
  name: string;
  icon: keyof typeof IconObject;
  isActive?: boolean;
}

const BreadcrumbChildItem: React.FC<Props> = ({ url, name, icon, isActive }) => (
  <li className="breadcrumb-navigation__child-item">
    <Link to={url} className={`breadcrumb-navigation__child-item-link ${isActive ? 'breadcrumb-navigation__child-link--active' : ''}`}>
      <Icon svg className="breadcrumb-navigation__child-icon" icon={icon} />
      {name}
    </Link>
  </li>
);

export default BreadcrumbChildItem;
