import React from 'react';
import { Link } from 'react-router-dom';
import Icon from '../../../../components/Icon/Icon';

interface Props {
  url: string;
  name: string;
  isActive: boolean;
}

const BreadcrumbSingleItem: React.FC<Props> = ({ url, name, isActive }) => {
  return (
    <li className="breadcrumb-navigation__child">
      <div className="breadcrumb-navigation__child-inner ">
        <Link to={url ?? ''} className={`breadcrumb-navigation__child-link ${isActive ? 'breadcrumb-navigation__child-link--active' : ''}`}>
          <Icon svg className="breadcrumb-navigation__child-icon" icon="tile" />
          {name}
        </Link>
      </div>
    </li>
  );
};

export default BreadcrumbSingleItem;
