import * as React from 'react';
import { Link, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { BackOfficeList } from '../../../core/state/back-office/types';
import TD from '../../../components/Table/TD/TD';
import TR from '../../../components/Table/TR/TR';
import { BASE_URL } from '../../../core/constants/api';

import '../BackOfficePage.scss';

interface Props {
  backOfficeListItem: BackOfficeList;
}

const BackOfficeListItemRow: React.FC<Props> = ({ backOfficeListItem }) => {
  const { t } = useTranslation();
  const { tileId, tileTitle, tileType, onClickDataUrl, isEditable } = backOfficeListItem;
  const { id } = useParams<{ id: any }>();
  const url = `${BASE_URL}${onClickDataUrl}`;

  return (
    <TR key={tileId}>
      <TD>{tileTitle}</TD>
      <TD>
        <Link
          to={`/boards/${id}/back-office/${tileId}/?type=${tileType}&url=${encodeURIComponent(url)}`}
          className={`back-office-page__edit-link ${isEditable ? '' : 'back-office-page__edit-link--disabled'}`}
        >
          {t('backOfficeListItemRow.columnEdit')}
        </Link>
      </TD>
    </TR>
  );
};

export default BackOfficeListItemRow;
