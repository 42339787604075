import { NotificationTypes } from '../../enums/NotificationTypes';

export const DEFAULT_AUTOHIDE_DELAY = 2000;

export interface Notification {
  id?: string;
  type: NotificationTypes;
  message: string;
  autoHide?: boolean;
  delay?: number;
}

export interface NotificationsState {
  notifications: Notification[];
}
