import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { match } from 'react-router-dom';

import './TileVacationList.scss';

interface Props {
  isDetailPage: match | null;
}

const TileVacationListInfo: React.FC<Props> = ({ isDetailPage }) => {
  const { t } = useTranslation();
  return (
    <div className={`tile-vacation ${isDetailPage ? 'mb-2' : 'tile-vacation--small justify-content-end'} d-flex flex-column align-items-end `}>
      <div className={`d-flex justify-content-start ${isDetailPage ? 'my-3' : ''}`}>
        <div className="d-flex align-items-center">
          <span className="tile-vacation__info-text">{t('forms.vacationList.info')}</span>
        </div>
      </div>
    </div>
  );
};

export default TileVacationListInfo;
