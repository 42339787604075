import { createAction } from '../../utils/action';
import ActionTypes from '../actionTypes';
import { ActionsUnion } from '../types';
import { RestErrorResponse } from '../../types/response/RestErrorResponse';
import { AreaProject, BackOfficeList, BoardPermissions, CapacitySubcontractors } from './types';

export const Actions = {
  getBackOfficeList: () => createAction(ActionTypes.GET_BACK_OFFICE_LIST),
  getBackOfficeListSuccess: (list: BackOfficeList[]) => createAction(ActionTypes.GET_BACK_OFFICE_LIST_SUCCESS, list),
  getBackOfficeListError: (error: RestErrorResponse) => createAction(ActionTypes.GET_BACK_OFFICE_LIST_ERROR, error),

  getAreaProjects: () => createAction(ActionTypes.GET_AREA_PROJECTS),
  getAreaProjectsSuccess: (areaProjects: AreaProject[]) => createAction(ActionTypes.GET_AREA_PROJECTS_SUCCESS, areaProjects),
  getAreaProjectsError: (error: RestErrorResponse) => createAction(ActionTypes.GET_AREA_PROJECTS_ERROR, error),

  saveAreaProjects: () => createAction(ActionTypes.SAVE_AREA_PROJECTS),
  saveAreaProjectsSuccess: (areaProjects: AreaProject[]) => createAction(ActionTypes.SAVE_AREA_PROJECTS_SUCCESS, areaProjects),
  saveAreaProjectsError: (error: RestErrorResponse) => createAction(ActionTypes.SAVE_AREA_PROJECTS_ERROR, error),

  updateAreaProjects: (areaProjects: AreaProject[]) => createAction(ActionTypes.UPDATE_AREA_PROJECTS, areaProjects),

  getCapacitySubcontractors: () => createAction(ActionTypes.GET_CAPACITY_SUBCONTRACTORS),
  getCapacitySubcontractorsSuccess: (capacitySubcontractors: CapacitySubcontractors[]) =>
    createAction(ActionTypes.GET_CAPACITY_SUBCONTRACTORS_SUCCESS, capacitySubcontractors),
  getCapacitySubcontractorsError: (error: RestErrorResponse) => createAction(ActionTypes.GET_CAPACITY_SUBCONTRACTORS_ERROR, error),

  saveCapacitySubcontractors: () => createAction(ActionTypes.SAVE_CAPACITY_SUBCONTRACTORS),
  saveCapacitySubcontractorsSuccess: (capacitySubcontractors: CapacitySubcontractors[]) =>
    createAction(ActionTypes.SAVE_CAPACITY_SUBCONTRACTORS_SUCCESS, capacitySubcontractors),
  saveCapacitySubcontractorsError: (error: RestErrorResponse) => createAction(ActionTypes.SAVE_CAPACITY_SUBCONTRACTORS_ERROR, error),

  updateCapacitySubcontractors: (capacitySubcontractors: CapacitySubcontractors[]) =>
    createAction(ActionTypes.UPDATE_CAPACITY_SUBCONTRACTORS, capacitySubcontractors),

  getBoardPermissions: () => createAction(ActionTypes.GET_BOARD_PERMISSIONS),
  getBoardPermissionsSuccess: (permissions: BoardPermissions) => createAction(ActionTypes.GET_BOARD_PERMISSIONS_SUCCESS, permissions),
  getBoardPermissionsError: (error: RestErrorResponse) => createAction(ActionTypes.GET_BOARD_PERMISSIONS_ERROR, error),

  saveBoardPermissions: () => createAction(ActionTypes.SAVE_BOARD_PERMISSIONS),
  saveBoardPermissionsSuccess: (permissions: BoardPermissions) => createAction(ActionTypes.SAVE_BOARD_PERMISSIONS_SUCCESS, permissions),
  saveBoardPermissionsError: (error: RestErrorResponse) => createAction(ActionTypes.SAVE_BOARD_PERMISSIONS_ERROR, error)
};

export type BackofficeActions = ActionsUnion<typeof Actions>;
