import * as React from 'react';

import { Board as BoardModel, Tile as TileModel } from '../../core/state/boards/types';

import Board from '../components/Board/Board';
import BoardHeader from '../components/Board/BoardHeader/BoardHeader';
import BoardHeaders from '../components/Board/BoardHeaders/BoardHeaders';
import BoardContent from '../components/Board/BoardContent/BoardContent';
import TVScreen from '../components/MediaQueries/TVScreen';
import DesktopScreen from '../components/MediaQueries/DesktopScreen';
import PLBoardTVGrid from './PLBoardTVGrid';
import PLBoardDesktopGrid from './PLBoardDesktopGrid';
import BoardNav from '../components/Board/BoardNav/BoardNav';
import PLBoardHeaderFirst from './headers/PLBoardHeaderFirst';
import { getIndexOfBoard } from './utils';
import PLBoardHeaderSecond from './headers/PLBoardHeaderSecond';
import PLBoardHeaderThird from './headers/PLBoardHeaderThird';

interface Props {
  board: BoardModel<TileModel>;
}

const PLBoard: React.FC<Props> = ({ board }) => {
  const index = getIndexOfBoard(board);

  return (
    <Board>
      <BoardHeader text={board.name} boardType={board.type} boardId={board.boardId} />
      <BoardContent>
        <BoardHeaders>
          <div className="container-fluid">
            <div className="row">
              {index === 1 && <PLBoardHeaderFirst />}
              {index === 2 && <PLBoardHeaderSecond />}
              {index === 3 && <PLBoardHeaderThird />}
            </div>
          </div>
        </BoardHeaders>
        <TVScreen>
          <PLBoardTVGrid board={board} />
        </TVScreen>
        <DesktopScreen>
          <PLBoardDesktopGrid board={board} />
        </DesktopScreen>
        <BoardNav board={board} />
      </BoardContent>
    </Board>
  );
};

export default PLBoard;
