import * as React from 'react';

import TD from '../../../components/Table/TD/TD';
import TR from '../../../components/Table/TR/TR';

const TileEscalationEmptyRow = () => {
  const emptyArray = Array.from(Array(9).keys());

  return (
    <TR>
      {emptyArray.map((item, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <TD key={index} />
      ))}
    </TR>
  );
};

export default TileEscalationEmptyRow;
