import React, { ChangeEvent, LegacyRef } from 'react';

import './Radio.scss';

interface Props {
  className?: string;
  defaultValue?: string | number;
  label?: string;
  id: string;
  name: string;
  error?: string;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  register?: LegacyRef<any>;
  options: { label: string | JSX.Element; value: string | number }[];
  inline?: boolean;
}

const Radio: React.FC<Props> = ({ defaultValue, label, id, name, error, onChange, register, options, inline = false, className = '' }) => {
  return (
    <div className={`input ${inline ? 'input--inline' : ''} ${className}`}>
      {label && <p className="input__label">{label}</p>}
      {options.map((option) => (
        <label key={option.value} className="input__option" htmlFor={`${id}_${option.value}`}>
          <input
            className="input__radio"
            ref={register}
            id={`${id}_${option.value}`}
            type="radio"
            name={name}
            value={option.value}
            defaultChecked={defaultValue === option.value}
            onChange={onChange}
          />
          {option.label}
        </label>
      ))}
      {error && <div className="input__error">{error}</div>}
    </div>
  );
};

export default Radio;
