import * as React from 'react';

interface Props {
  text: string;
}

const TileProcessConfirmationHeaderTitle: React.FC<Props> = ({ text }) => {
  return <div className="tile-process-confirmation__header-title">{text}</div>;
};

export default TileProcessConfirmationHeaderTitle;
