import * as React from 'react';
import { match } from 'react-router-dom';

import TileMeasureLegendItem from './TileMeasureLegendItem';
import { TableFormFieldStatus } from '../../../core/enums/TableFormFieldStatus';

interface Props {
  isDetailPage: match | null;
}

const TileMeasureLegend: React.FC<Props> = ({ isDetailPage }) => {
  return (
    <div className="d-flex justify-content-end">
      <div className={`d-flex ${isDetailPage ? 'my-2' : 'mb-1 mt-0'}`}>
        <TileMeasureLegendItem isDetailPage={isDetailPage} status={TableFormFieldStatus.Quarter} translation="forms.measure.legend.quarter" />
        <TileMeasureLegendItem isDetailPage={isDetailPage} status={TableFormFieldStatus.Half} translation="forms.measure.legend.twoQuarter" />
        <TileMeasureLegendItem
          isDetailPage={isDetailPage}
          status={TableFormFieldStatus.ThreeQuarters}
          translation="forms.measure.legend.threeQuarter"
        />
        <TileMeasureLegendItem isDetailPage={isDetailPage} status={TableFormFieldStatus.Full} translation="forms.measure.legend.fourQuarter" />
      </div>
    </div>
  );
};

export default TileMeasureLegend;
