import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { ProcessConfirmationFormColumn } from '../../../core/state/process-confirmation/types';
import TileProcessConfirmationInput from './TileProcessConfirmationInput';
import TileProcessConfirmationStatus from './TileProcessConfirmationStatus';
import { TableFormFieldStatus } from '../../../core/enums/TableFormFieldStatus';
import { SelectedItem } from '../types';

interface Props {
  column: ProcessConfirmationFormColumn;
  columnIndex: number;
  rowIndex: number;
  selectedItem: SelectedItem;
  setSelectedItem: React.Dispatch<React.SetStateAction<SelectedItem>>;
  handleIndicatorStatus: (status: TableFormFieldStatus, rowIndex: number, columnIndex: number, multiLineIndex?: number) => void;
  handleBlur: (value: string, rowIndex: number, columnIndex: number, multiLineIndex?: number) => void;
}

const getItemLabel = (multiLineIndex: number) => {
  switch (multiLineIndex) {
    case 0:
      return 'forms.processConfirmation.termins.pp5';
    case 1:
      return 'forms.processConfirmation.termins.pp6';
    case 2:
      return 'forms.processConfirmation.termins.pp7';
    default:
      return '';
  }
};

const TileProcessConfirmationColumnMultiline: React.FC<Props> = ({
  column,
  columnIndex,
  rowIndex,
  handleIndicatorStatus,
  selectedItem,
  handleBlur,
  setSelectedItem
}) => {
  const { t } = useTranslation();
  const { rowIndex: sRowIndex, columnIndex: sColumnIndex, value: sValue, multiLineIndex: sMultiLineIndex } = selectedItem;

  return (
    <>
      {column.multiLineData?.map((multiLine, multiLineIndex) => (
        <div key={multiLine.columnNumber} className="tile-process-confirmation__multiline">
          <TileProcessConfirmationInput
            id={`${rowIndex}${columnIndex}${multiLineIndex}`}
            onFocus={() => {
              setSelectedItem({ ...selectedItem, columnIndex, rowIndex, multiLineIndex, value: multiLine.text });
            }}
            onChange={(e) => {
              setSelectedItem({ ...selectedItem, value: e.target.value });
            }}
            onBlur={() => {
              if (sRowIndex !== null && sColumnIndex !== null && sMultiLineIndex !== null) {
                handleBlur(sValue ?? '', sRowIndex, sColumnIndex, sMultiLineIndex);
              }
            }}
            value={rowIndex === sRowIndex && columnIndex === sColumnIndex && multiLineIndex === sMultiLineIndex ? sValue ?? '' : multiLine.text}
            label={t(getItemLabel(multiLineIndex))}
          />
          <TileProcessConfirmationStatus
            status={multiLine.status}
            onClick={() => handleIndicatorStatus(multiLine.status, rowIndex, columnIndex, multiLineIndex)}
          />
        </div>
      ))}
    </>
  );
};

export default TileProcessConfirmationColumnMultiline;
