export enum TilePositions {
  A1 = 'A1',
  A2 = 'A2',
  A3 = 'A3',
  A4 = 'A4',
  A5 = 'A5',
  A6 = 'A6',
  B1 = 'B1',
  B2 = 'B2',
  B3 = 'B3',
  B4 = 'B4',
  E1 = 'E1',
  F1 = 'F1',
  K1 = 'K1',
  L1 = 'L1',
  L2 = 'L2',
  L3 = 'L3',
  M1 = 'M1',
  P1 = 'P1',
  P2 = 'P2',
  P3 = 'P3',
  P4 = 'P4',
  P5 = 'P5',
  P6 = 'P6',
  Q1 = 'Q1',
  Q2 = 'Q2',
  Q3 = 'Q3',
  T1 = 'T1',
  T2 = 'T2',
  T3 = 'T3',
  T4 = 'T4',
  U1 = 'U1',
  U2 = 'U2',
  U3 = 'U3',
  U4 = 'U4',
  V1 = 'V1',
  Z1 = 'Z1',
  AL1 = 'AL1',
  AL2 = 'AL2',
  AL3 = 'AL3',
  AL4 = 'AL4',
  AR1 = 'AR1',
  AR2 = 'AR2',
  DZ1 = 'DZ1',
  DV1 = 'DV1',
  DV2 = 'DV2',
  MD1 = 'MD1',
  MT1 = 'MT1',
  ME1 = 'ME1',
  AAZ1 = 'AAZ1',
  AAZ2 = 'AAZ2',
  AAZ3 = 'AAZ3',
  AAZ4 = 'AAZ4'
}
