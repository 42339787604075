import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { ProcessConfirmationFormColumn } from '../../../core/state/process-confirmation/types';
import TileProcessConfirmationStatus from './TileProcessConfirmationStatus';
import TileProcessConfirmationInput from './TileProcessConfirmationInput';
import { TableFormFieldStatus } from '../../../core/enums/TableFormFieldStatus';
import { SelectedItem } from '../types';

interface Props {
  column: ProcessConfirmationFormColumn;
  columnIndex: number;
  rowIndex: number;
  selectedItem: SelectedItem;
  setSelectedItem: React.Dispatch<React.SetStateAction<SelectedItem>>;
  handleIndicatorStatus: (status: TableFormFieldStatus, rowIndex: number, columnIndex: number, multiLineIndex?: number) => void;
  handleBlur: (value: string, rowIndex: number, columnIndex: number, multiLineIndex?: number) => void;
}

const getColumnLabel = (columnIndex: number) => {
  switch (columnIndex) {
    case 1:
    case 4:
    case 5:
    case 6:
      return 'forms.processConfirmation.termins.dateFrom';
    case 2:
      return 'forms.processConfirmation.termins.pp2';
    case 3:
      return 'forms.processConfirmation.termins.targetDateWS';
    case 7:
      return 'forms.processConfirmation.termins.pp4';
    case 8:
      return 'forms.processConfirmation.termins.targetDateBSG';
    default:
      return '';
  }
};

const TileProcessConfirmationColumnDefault: React.FC<Props> = ({
  column,
  columnIndex,
  rowIndex,
  handleIndicatorStatus,
  setSelectedItem,
  selectedItem,
  handleBlur
}) => {
  const { t } = useTranslation();
  const { rowIndex: sRowIndex, columnIndex: sColumnIndex, value: sValue } = selectedItem;
  return (
    <>
      <TileProcessConfirmationStatus
        status={column.status}
        onClick={() => {
          handleIndicatorStatus(column.status, rowIndex, columnIndex);
        }}
      />
      {columnIndex !== 0 && (
        <TileProcessConfirmationInput
          id={`${rowIndex}${columnIndex}`}
          onFocus={() => {
            setSelectedItem({ ...selectedItem, columnIndex, rowIndex, value: column.text });
          }}
          onChange={(e) => {
            setSelectedItem({ ...selectedItem, value: e.target.value });
          }}
          onBlur={() => {
            if (sRowIndex !== null && sColumnIndex !== null) {
              handleBlur(sValue ?? '', sRowIndex, sColumnIndex);
            }
          }}
          value={rowIndex === sRowIndex && columnIndex === sColumnIndex ? sValue ?? '' : column.text ?? ''}
          label={t(getColumnLabel(columnIndex))}
        />
      )}
    </>
  );
};

export default TileProcessConfirmationColumnDefault;
