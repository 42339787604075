import service from './RestService';
import { RestResponse } from '../types/response/RestResponse';
import { HistoryList } from '../state/history/types';

const path = '/boards';

class HistoryService {
  getHistoryList = (boardId: number): RestResponse<HistoryList[]> => service.get(`${path}/${boardId}/history`);
}

export default new HistoryService();
