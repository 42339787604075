import { useEffect } from 'react';
import { getMeasureInfoCS, MeasureViewModel } from '../../utils/measures/getMeasureInfo';
import { CapacitySubcontractor } from '../../state/capacity-subcontractors/types';

export function useMeasureCapacitySubcontractorChange(
  measuresArray: MeasureViewModel[],
  setMeasuresArray: (measuresArray: MeasureViewModel[]) => any,
  form: CapacitySubcontractor | null
) {
  useEffect(() => {
    // update measuresArray on CapacitySubcontractor Change
    const newMeasuresArray = measuresArray.map((item) => {
      const index = form?.rows.findIndex((rowItem) => rowItem.measureURL === item.measureURL);
      if (index !== undefined && index > -1) {
        const newItem = getMeasureInfoCS(form, index);
        if (newItem) {
          return {
            ...item,
            measure: {
              ...item.measure,
              measureText: newItem.measure.measureText
            }
          };
        }
      }
      return item;
    });
    if (newMeasuresArray) {
      setMeasuresArray(newMeasuresArray);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form]);
}
