import { BASE_URL } from '../../constants/api';

interface FindableTile {
  tileId: number | null;
  onClickDataUrl: string | null;
}

export function findItem<T extends FindableTile>(items: T[], tileId: number, url?: string): T | undefined {
  let boardItem = items.find((detailItem) => detailItem.tileId === tileId);
  if (!boardItem && url) {
    boardItem = items.find((detailItem) => `${BASE_URL}${detailItem.onClickDataUrl}` === url);
  }
  return boardItem;
}
