import React from 'react';
import { addDays, addYears, format, isAfter, isBefore } from 'date-fns';
import TH from '../../../components/Table/TH/TH';
import NavArrow from '../../../pages/components/NavArrow/NavArrow';
import { DATE_FORMAT } from '../../../core/constants/date';

interface Props {
  onPrevClick: () => any;
  onNextClick: () => any;
  date: Date;
}
const TileCapacitySubcontractorHeaderControl: React.FC<Props> = ({ onPrevClick, onNextClick, date }) => {
  const startOfFirstWeek = date;
  const endOfFirstWeek = addDays(date, 5);
  const startOfSecondWeek = addDays(date, 7);
  const endOfSecondWeek = addDays(startOfSecondWeek, 5);

  return (
    <>
      <TH className="empty shrink">empty</TH>
      <TH className="empty shrink py-0">
        <NavArrow disabled={isAfter(new Date('2021-1-1'), date)} onClick={onPrevClick} />
      </TH>
      <TH className="empty shrink">empty</TH>
      <TH className="w-21 text-center">
        <div className="table__title">
          {format(startOfFirstWeek, 'dd.')}-{format(endOfFirstWeek, DATE_FORMAT)}
        </div>
      </TH>
      <TH className="empty shrink ">empty</TH>
      <TH className="w-21 text-center">
        <div className="table__title">
          {format(startOfSecondWeek, 'dd.')}-{format(endOfSecondWeek, DATE_FORMAT)}
        </div>
      </TH>
      <TH className="empty shrink">empty</TH>
      <TH className="empty shrink py-0">
        <NavArrow disabled={isBefore(addYears(new Date(), 1), date)} onClick={onNextClick} isRight />
      </TH>
      <TH className="empty shrink">empty</TH>
    </>
  );
};

export default TileCapacitySubcontractorHeaderControl;
