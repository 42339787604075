import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useRouteMatch } from 'react-router-dom';

import { BOARDS_TILE_DETAIL } from '../../../core/constants/routes';
import { EscalationDetail, EscalationLevel, EscalationType, MeasureAndEscalationForm } from '../../../core/state/table-forms/types';
import { useTableForm } from '../../../core/hooks/useTableForm';
import { RootState } from '../../../core/state/state';
import { saveMeasuresAndEscalations, updateMeasuresAndEscalations } from '../../../core/state/table-forms/asyncActions';

import TD from '../../../components/Table/TD/TD';
import TR from '../../../components/Table/TR/TR';
import Icon from '../../../components/Icon/Icon';
import Tooltip from '../../../components/Tooltip/Tooltip';

import EscalationStatus from '../EscalationStatus/EscalationStatus';
import Calendar from '../../../components/form/Calendar/CalendarHook';
import TableInput from '../../../components/form/TableInput/TableInputHook';

import { EscalationDirection, useChangeEscalationLevel, useDeleteRow, useEditRow, useReturnToMeasure } from '../../TileMeasuresEscalation/hooks';
import { toggleEscalationStatus, toggleEscalationType } from '../TileEscalationHelpers';
import { isRowHighlighted } from '../../TileMeasuresEscalation/helpers';
import { isEscalationDeletable } from '../../../core/utils/measures/isEscalationDeletable';

interface Props {
  escalation: EscalationDetail;
  level?: EscalationLevel;
  rowIndex: number;
}

const TileEscalationRow: React.FC<Props> = ({ escalation, rowIndex, level }) => {
  const { t } = useTranslation();
  const isDetailPage = useRouteMatch(BOARDS_TILE_DETAIL);

  const deleteRow = useDeleteRow('escalations');
  const editRow = useEditRow('escalations');

  const sendEscalationUp = useChangeEscalationLevel(EscalationDirection.Up);
  const sendEscalationDown = useChangeEscalationLevel(EscalationDirection.Down);

  const returnToMeasure = useReturnToMeasure(rowIndex);

  const { useTableValue } = useTableForm<MeasureAndEscalationForm>({
    data: useSelector((state: RootState) => state.tableForm.measuresEscalationForm.form),
    rowPropertyName: 'escalations',
    columnPropertyName: null,
    updateActions: [updateMeasuresAndEscalations],
    submitActions: [updateMeasuresAndEscalations, saveMeasuresAndEscalations]
  });

  const isEscalationEscalated = escalation.level !== level;

  const isEscalationEditable = level === EscalationLevel.GF || escalation.level === level;

  return (
    <TR className={isRowHighlighted(escalation.createdAt) ? 'table__row--highlighted' : ''}>
      <TD className="shrink text-center">{rowIndex + 1}</TD>
      <TD className={`${!isEscalationEditable ? 'table-item-disabled' : ''}`}>{escalation.level === level ? '-' : escalation.level}</TD>
      <TD className={`text-left ${!isEscalationEditable ? 'table-item-disabled' : ''}`}>
        <TableInput type={isDetailPage ? undefined : 'small'} row={rowIndex} column="problem" useTableValue={useTableValue} />
      </TD>
      <TD className={`${!isEscalationEditable ? 'table-item-disabled' : ''}`}>
        <TableInput type={isDetailPage ? undefined : 'small'} row={rowIndex} column="riskPotential" useTableValue={useTableValue} />
      </TD>
      <TD className={`${!isEscalationEditable ? 'table-item-disabled' : ''}`}>
        <TableInput type={isDetailPage ? undefined : 'small'} row={rowIndex} column="summary" useTableValue={useTableValue} />
      </TD>
      <TD className={`text-center ${!isEscalationEditable ? 'table-item-disabled' : ''}`}>
        <Calendar isSmall={!isDetailPage} row={rowIndex} column="openedAt" useTableValue={useTableValue} />
      </TD>
      <TD className={`text-center ${!isEscalationEditable ? 'table-item-disabled' : ''}`}>
        <Calendar isSmall={!isDetailPage} row={rowIndex} column="deadlineAt" useTableValue={useTableValue} />
      </TD>
      <TD className={`${!isEscalationEditable ? 'table-item-disabled' : ''}`}>
        <EscalationStatus
          onClick={() => {
            editRow(rowIndex, 'status', toggleEscalationStatus(escalation.status));
          }}
          value={escalation.status}
        />
      </TD>
      <TD className="text-center">
        <div className="d-flex justify-content-center">
          <Tooltip
            color="white"
            text={t(escalation.type === EscalationType.Action ? 'escalationForm.type.requiresAction' : 'escalationForm.type.informative')}
          >
            <Icon
              isButton
              size={isDetailPage ? 'medium' : 'small'}
              onClick={() => {
                editRow(rowIndex, 'type', toggleEscalationType(escalation.type));
              }}
              icon={escalation.type === EscalationType.Action ? 'alert' : 'info'}
            />
          </Tooltip>
        </div>
      </TD>
      {isDetailPage && (
        <>
          <TD className={`${!isEscalationEditable ? 'table-item-disabled' : ''}`}>
            <div className="d-flex justify-content-center">
              <Tooltip color="white" text={t('escalationForm.moveDown')} placement="left-end">
                <Icon
                  isDisabled={!escalation.canDeEscalate}
                  svg
                  isButton
                  size={isDetailPage ? 'medium' : 'small'}
                  onClick={() => {
                    sendEscalationDown(rowIndex);
                  }}
                  icon="arrowDown"
                />
              </Tooltip>
              <Tooltip color="white" text={t('escalationForm.moveUp')} placement="left-end">
                <Icon
                  isDisabled={!escalation.canEscalate}
                  svg
                  isButton
                  size={isDetailPage ? 'medium' : 'small'}
                  onClick={() => {
                    sendEscalationUp(rowIndex);
                  }}
                  icon="arrowUp"
                />
              </Tooltip>
            </div>
          </TD>
          <TD className={`shrink text-center ${!isEscalationEditable ? 'table-item-disabled' : ''}`}>
            <div className="d-flex justify-content-center">
              <Tooltip color="white" text={t('escalationForm.deescalate')} placement="left-end">
                <Icon
                  svg
                  isButton
                  size={isDetailPage ? 'medium' : 'small'}
                  onClick={() => {
                    returnToMeasure();
                  }}
                  isDisabled={isEscalationEscalated}
                  icon="deescalate"
                />
              </Tooltip>
            </div>
          </TD>
          <TD
            className={`shrink text-center ${!isEscalationDeletable(escalation.level, level) || !escalation.canDelete ? 'table-item-disabled' : ''}`}
          >
            <button
              className="btn btn-link"
              onClick={() => {
                deleteRow(rowIndex);
              }}
              title={t('buttons.deleteRow')}
            >
              <Icon icon="delete" svg />
              <span className="sr-only">{t('buttons.deleteRow')}</span>
            </button>
          </TD>
        </>
      )}
    </TR>
  );
};

export default TileEscalationRow;
