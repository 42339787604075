import { differenceInDays } from 'date-fns';
import { MeasureAndEscalationForm } from '../../core/state/table-forms/types';

export const isRowHighlighted = (date?: string) => {
  const currentDate = date ? new Date(date) : new Date();
  return differenceInDays(new Date(), currentDate) <= 7;
};

export const removeEmptyValuesFromForm = (form: MeasureAndEscalationForm) => {
  return {
    ...form,
    escalations:
      form.escalations?.map((escalation) => ({
        ...escalation,
        deadlineAt: escalation.deadlineAt ? escalation.deadlineAt : null,
        openedAt: escalation.openedAt ? escalation.openedAt : null
      })) ?? [],
    measures:
      form?.measures?.map((measures) => ({
        ...measures,
        deadline: measures.deadline ? measures.deadline : null
      })) ?? []
  };
};
