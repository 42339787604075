import React, { useCallback } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { cloneDeep } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { TileTypes } from '../../core/enums/TileTypes';
import { useTableForm } from '../../core/hooks/useTableForm';
import { BOARDS_TILE_DETAIL } from '../../core/constants/routes';
import { RootState } from '../../core/state/state';
import { generateAsyncActions } from '../../core/state/table-forms/asyncActions';
import { TableForm, TableFormColumn, TableFormRow } from '../../core/state/table-forms/types';
import { TableFormFieldStatus } from '../../core/enums/TableFormFieldStatus';

import Table from '../../components/Table/Table';
import TR from '../../components/Table/TR/TR';
import TD from '../../components/Table/TD/TD';
import THead from '../../components/Table/THead/THead';
import TH from '../../components/Table/TH/TH';
import TBody from '../../components/Table/TBody/TBody';

import TableInput from '../../components/form/TableInput/TableInputHook';
import Icon from '../../components/Icon/Icon';
import UnsavedChangesPopup from '../../components/popup/UnsavedChangesPopup/UnsavedChangesPopup';
import Calendar from '../../components/form/Calendar/CalendarHook';
import TableStatus from '../../components/form/TableStatus/TableStatus';
import { TableTileTypes } from '../../core/enums/TableTileTypes';
import { useRowNumber } from '../../core/hooks/useRowNumber';
import NumberPPInput from '../../components/form/NumberPPInput/NumberPPInput';
import TableFormTileTop from './TableFormTileTop/TableFormTileTop';
import TableFormAddRow from './TableFormAddRow/TableFormAddRow';

import './TableFormTile.scss';

type Columns = {
  field: string;
  title?: string;
  subtitle?: string;
  type?: 'index' | 'text' | 'number' | 'calendar' | 'status' | 'status-circle' | 'number-pp';
  headerClass?: string;
  headerContent?: React.FC<any>;
  columns?: Columns[];
  statusOptions?: TableFormFieldStatus[];
};

interface Props {
  tClassName?: string;
  boardId?: number;
  url: string;
  tileType: TileTypes;
  tableTileType: TableTileTypes;
  columns: Columns[];
  InfoComponent?: React.FC<any>;
  TableFooter?: React.FC<any>;
  hasNoBorder?: boolean;
  showStatus?: boolean;
}

const TableFormTile: React.FC<Props> = ({
  url,
  boardId,
  tileType,
  tableTileType,
  columns,
  InfoComponent,
  TableFooter,
  hasNoBorder,
  tClassName,
  showStatus = false
}) => {
  const dispatch = useDispatch();
  const tableForm = useSelector((state: RootState) => state[tableTileType].form);
  const { isFormDirty, isFormEditing } = useSelector((state: RootState) => state.settings);
  const isDetailPage = useRouteMatch(BOARDS_TILE_DETAIL);
  const { t } = useTranslation();

  const nextRowNumber = useRowNumber(tableForm);

  const { getTableForm, updateTableForm, saveTableForm } = React.useMemo(() => generateAsyncActions(tileType), [tileType]);

  const { submit, useTableValue } = useTableForm<TableForm>({
    boardId,
    data: tableForm,
    valuePropertyName: 'text',
    updateActions: [updateTableForm],
    submitActions: [updateTableForm, saveTableForm]
  });

  const { useTableValue: useTableStatus } = useTableForm<TableForm>({
    boardId,
    data: tableForm,
    valuePropertyName: 'status',
    updateActions: [updateTableForm],
    submitActions: [updateTableForm, saveTableForm]
  });

  const handleSubmit = () => submit();

  React.useEffect(() => {
    dispatch(getTableForm(url));
  }, [url, dispatch, getTableForm]);

  const clearAllFields = () => {
    if (tableForm) {
      const newTableForm = cloneDeep(tableForm);
      newTableForm.rows = [];
      dispatch(updateTableForm(newTableForm));
    }
  };

  const addEmptyRow = useCallback(
    (suppressDirtyStatus = false) => {
      if (tableForm) {
        const newTableForm = cloneDeep(tableForm);
        const emptyRow: TableFormRow = {
          rowNumber: nextRowNumber(),
          columns: columns
            .reduce((acc: Columns[], c) => {
              if (c.columns) {
                return [...acc, ...c.columns];
              }
              return [...acc, c];
            }, [])
            .map((column, i) => {
              return {
                columnNumber: i,
                title: column.title,
                status: TableFormFieldStatus.Undefined,
                text: ''
              };
            }) as TableFormColumn[]
        };

        newTableForm.rows.push(emptyRow);
        dispatch(updateTableForm(newTableForm, true, suppressDirtyStatus));
      }
    },
    [columns, dispatch, nextRowNumber, tableForm, updateTableForm]
  );

  const handleDeleteRow = (index: number) => {
    if (tableForm) {
      const newTableForm = cloneDeep(tableForm);
      newTableForm.rows.splice(index, 1);
      dispatch(updateTableForm(newTableForm));
    }
  };

  const getHeader = () => {
    const nestedColumns = columns.filter((column) => column.columns).flatMap((column) => column.columns) as Columns[];
    const has2ndLevelHeaders = nestedColumns.length > 0;
    const defaultRowSpan = has2ndLevelHeaders ? 2 : 1;

    return (
      <>
        <TR>
          {columns.map((column, index) => (
            <TH
              rowSpan={column.columns && column.columns?.length > 0 ? 1 : defaultRowSpan}
              colSpan={column.columns && column.columns?.length}
              key={`${column.field}-${index.toString}`}
              className={`table__table-header ${column.headerClass ?? ''}`}
            >
              {column.headerContent ? (
                <column.headerContent />
              ) : (
                <>
                  <div className="table__title">{column.title}</div>
                  {column.subtitle && <div className="table__subtitle">{column.subtitle}</div>}
                </>
              )}
            </TH>
          ))}
          {isDetailPage && (
            <TH rowSpan={defaultRowSpan} className="table__table-header shrink">
              <div className="table__title" />
            </TH>
          )}
        </TR>
        {has2ndLevelHeaders && (
          <TR>
            {nestedColumns.map((c) => (
              <TH key={c.field} className={`table__table-header ${c.headerClass ?? ''}`}>
                <div className="table__title">{c.title}</div>
                {c.subtitle && <div className="table__subtitle">{c.subtitle}</div>}
              </TH>
            ))}
          </TR>
        )}
      </>
    );
  };

  const renderColumn = (tableFormItem: TableFormRow, column: Columns, rowIndex: number, colIndex: number, key: string) => {
    let classNames = 'text-center shrink';
    if (['text', 'calendar', 'number'].includes(column.type ?? 'text')) {
      classNames = 'active-focus';
    }
    let cellContent;
    switch (column.type) {
      case 'index':
        cellContent = rowIndex + 1;
        break;
      case 'calendar':
        cellContent = <Calendar isSmall={!isDetailPage} row={rowIndex} column={colIndex} useTableValue={useTableValue} />;
        break;
      case 'number':
        cellContent = (
          <TableInput type={isDetailPage ? undefined : 'small'} valueType="number" row={rowIndex} column={colIndex} useTableValue={useTableValue} />
        );
        break;
      case 'status':
        cellContent = (
          <TableStatus
            row={rowIndex}
            column={colIndex}
            useTableValue={useTableStatus}
            statuses={column.statusOptions ?? [TableFormFieldStatus.Checked, TableFormFieldStatus.Unchecked]}
            isSmall={!isDetailPage}
          />
        );
        break;
      case 'status-circle':
        cellContent = (
          <TableStatus
            row={rowIndex}
            column={colIndex}
            useTableValue={useTableStatus}
            type="circle"
            statuses={column.statusOptions ?? [TableFormFieldStatus.Undefined, TableFormFieldStatus.Green, TableFormFieldStatus.Red]}
            isSmall={!isDetailPage}
          />
        );
        break;
      case 'number-pp':
        cellContent = <NumberPPInput type={isDetailPage ? undefined : 'small'} row={rowIndex} column={colIndex} useTableValue={useTableValue} />;
        break;
      default:
        cellContent = <TableInput type={isDetailPage ? undefined : 'small'} row={rowIndex} column={colIndex} useTableValue={useTableValue} />;
        break;
    }
    return (
      <TD key={`${key}-${column.field}`} className={classNames}>
        {cellContent}
      </TD>
    );
  };

  const getAllRows = () => {
    if (tableForm && tableForm.rows.length > 0) {
      const hasIndexColumn = columns.find((item) => item.type === 'index');
      return tableForm.rows.map((tableFormItem, index) => (
        <TR key={tableFormItem.rowNumber}>
          {columns
            .reduce((acc: Columns[], c) => {
              if (c.columns) {
                return [...acc, ...c.columns];
              }
              return [...acc, c];
            }, [])
            .map((column, cindex) => {
              const key = `${tableFormItem.rowNumber}-${cindex}`;
              const realColumnIndex = hasIndexColumn ? cindex - 1 : cindex; // Adjust for off-by-one due to virtual row index column
              return renderColumn(tableFormItem, column, index, realColumnIndex, key);
            })}
          {isDetailPage && (
            <TD className="text-center shrink">
              <button title={t('buttons.deleteRow')} className="btn btn-link" onClick={() => handleDeleteRow(index)}>
                <Icon icon="delete" svg />
                <span className="sr-only">{t('buttons.deleteRow')}</span>
              </button>
            </TD>
          )}
        </TR>
      ));
    }
    return null;
  };

  React.useEffect(() => {
    if (tableForm?.rows.length === 0) {
      addEmptyRow(true);
    }
  }, [addEmptyRow, tableForm]);

  return (
    <div className={`tile-table ${isDetailPage ? '' : 'tile-table--small'} ${tClassName ? `${tClassName}` : ''}`}>
      <div className={`${isDetailPage ? 'container-fluid' : ''}`}>
        <TableFormTileTop
          showStatus={showStatus}
          tableTileType={tableTileType}
          tableForm={tableForm}
          updateTableForm={updateTableForm}
          submit={handleSubmit}
          clearAll={clearAllFields}
          InfoComponent={InfoComponent}
        />
        <div>
          <Table className={`${isDetailPage ? 'tile-table__table' : 'tile-table__table table--small'} ${hasNoBorder ? 'table-no-border' : ''}`}>
            <THead>{getHeader()}</THead>
            <TBody>{getAllRows()}</TBody>
            {TableFooter && <TableFooter />}
          </Table>
        </div>
        {isDetailPage && <TableFormAddRow onClick={addEmptyRow} />}
      </div>

      {isDetailPage && <UnsavedChangesPopup showIf={isFormDirty || isFormEditing} />}
    </div>
  );
};

export default TableFormTile;
