import * as React from 'react';

import { useDispatch, useSelector } from 'react-redux';
import ProjectStatus from './ProjectStatus/ProjectStatus';
import { getProjectAreaStatusesByAreaUrl } from '../../core/state/project-area/asyncActions';
import { RootState } from '../../core/state/state';

import './TileProjectStatus.scss';

interface Props {
  url: string;
}

const TileProjectStatus: React.FC<Props> = ({ url }) => {
  const dispatch = useDispatch();
  const urlSearch = new URLSearchParams(url.slice(url.indexOf('?')));
  const areaId = Number(urlSearch.get('areaId'));
  const projects = useSelector((state: RootState) => state.projectArea.statusFormsByAreaId[areaId]) || [];

  React.useEffect(() => {
    dispatch(getProjectAreaStatusesByAreaUrl(areaId, url));
  }, [dispatch, areaId, url]);

  return (
    <div className="tile-project-status">
      {projects.map((project) => (
        <ProjectStatus key={projects.indexOf(project)} project={project} />
      ))}
    </div>
  );
};

export default TileProjectStatus;
