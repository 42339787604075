import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRouteMatch } from 'react-router-dom';

import { useSelector } from 'react-redux';
import { BOARDS_TILE_DETAIL } from '../../../core/constants/routes';
import { MeasureAndEscalationForm, MeasureDetail } from '../../../core/state/table-forms/types';
import { useTableForm } from '../../../core/hooks/useTableForm';
import { RootState } from '../../../core/state/state';
import { saveMeasuresAndEscalations, updateMeasuresAndEscalations } from '../../../core/state/table-forms/asyncActions';

import TR from '../../../components/Table/TR/TR';
import TD from '../../../components/Table/TD/TD';
import Icon from '../../../components/Icon/Icon';

import MeasureIndicator from '../TileMeasureIndicator/MeasureIndicator';
import Calendar from '../../../components/form/Calendar/CalendarHook';
import TableInput from '../../../components/form/TableInput/TableInputHook';

import { toggleMeasureStatus } from '../TileMeasureHelpers';
import { isRowHighlighted } from '../../TileMeasuresEscalation/helpers';
import { useDeleteRow, useEditRow, useEscalateMeasure } from '../../TileMeasuresEscalation/hooks';

interface Props {
  measure: MeasureDetail;
  rowIndex: number;
}

const TileMeasureRow: React.FC<Props> = ({ measure, rowIndex }) => {
  const { t } = useTranslation();
  const isDetailPage = useRouteMatch(BOARDS_TILE_DETAIL);

  const deleteRow = useDeleteRow('measures');
  const editRow = useEditRow('measures');
  const escalateMeasure = useEscalateMeasure(rowIndex);

  const { useTableValue } = useTableForm<MeasureAndEscalationForm>({
    data: useSelector((state: RootState) => state.tableForm.measuresEscalationForm.form),
    rowPropertyName: 'measures',
    columnPropertyName: null,
    updateActions: [updateMeasuresAndEscalations],
    submitActions: [updateMeasuresAndEscalations, saveMeasuresAndEscalations]
  });

  return (
    <TR className={isRowHighlighted(measure.createdAt) ? 'table__row--highlighted' : ''}>
      <TD className="shrink text-center">{rowIndex + 1}</TD>
      <TD>
        <Calendar isSmall={!isDetailPage} row={rowIndex} column="date" useTableValue={useTableValue} />
      </TD>
      <TD>
        <TableInput type={isDetailPage ? undefined : 'small'} row={rowIndex} column="problemText" useTableValue={useTableValue} />
      </TD>
      <TD>
        <TableInput type={isDetailPage ? undefined : 'small'} row={rowIndex} column="measureText" useTableValue={useTableValue} />
      </TD>
      <TD>
        <TableInput type={isDetailPage ? undefined : 'small'} row={rowIndex} column="assignedTo" useTableValue={useTableValue} />
      </TD>
      <TD className="text-center">
        <Calendar isSmall={!isDetailPage} row={rowIndex} column="deadline" useTableValue={useTableValue} />
      </TD>
      <TD className="text-center">
        <MeasureIndicator
          size={isDetailPage ? 'big' : 'small'}
          status={measure.status}
          onClick={() => {
            editRow(rowIndex, 'status', toggleMeasureStatus(measure.status));
          }}
        />
      </TD>
      {isDetailPage && (
        <TD className="shrink">
          <button
            className="btn btn-link"
            onClick={() => {
              escalateMeasure();
            }}
          >
            <Icon icon="levelUp" svg />
            <span className="sr-only">{t('buttons.escalate')}</span>
          </button>
        </TD>
      )}
      {isDetailPage && (
        <TD className="shrink">
          <button
            className="btn btn-link"
            onClick={() => {
              deleteRow(rowIndex);
            }}
            title={t('buttons.deleteRow')}
          >
            <Icon icon="delete" svg />
            <span className="sr-only">{t('buttons.deleteRow')}</span>
          </button>
        </TD>
      )}
    </TR>
  );
};

export default TileMeasureRow;
