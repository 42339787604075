import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd';
import { cloneDeep, orderBy } from 'lodash';

import Button from '../../../../../components/Button/Button';
import THead from '../../../../../components/Table/THead/THead';
import { getAreaProjects, saveAreaProjects, updateAreaProjects } from '../../../../../core/state/back-office/asyncActions';
import Table from '../../../../../components/Table/Table';
import TH from '../../../../../components/Table/TH/TH';
import TR from '../../../../../components/Table/TR/TR';
import TD from '../../../../../components/Table/TD/TD';
import { RootState } from '../../../../../core/state/state';
import TableInput from '../../../../../components/form/TableInput/TableInput';
import { AreaProject } from '../../../../../core/state/back-office/types';
import ToggleSwitch from '../../../../../components/form/ToggleSwitch/ToggleSwitch';
import UnsavedChangesPopup from '../../../../../components/popup/UnsavedChangesPopup/UnsavedChangesPopup';
import { history } from '../../../../../core/state/history';
import { BOARDS } from '../../../../../core/constants/routes';
import { blurActiveElement } from '../../../../../core/utils/blurActiveElement';
import { spawnNotification } from '../../../../../core/state/notifications/asyncActions';
import { NotificationTypes } from '../../../../../core/enums/NotificationTypes';
import { getMenuList } from '../../../../../core/state/menu/asyncActions';

import './BackOfficeProjectOverview.scss';

interface Props {
  url: string;
  boardId: number;
}

export type AreaProjectKeys = keyof AreaProject;

interface SelectedItem {
  rowIndex: number;
  text: string | null;
  key: AreaProjectKeys | null;
}

const BackOfficeProjectOverview: React.FC<Props> = ({ url, boardId }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { isFormDirty, isFormEditing } = useSelector((state: RootState) => state.settings);
  const areaProjects = useSelector((state: RootState) => state.backOffice.areaProjects);

  const [selectedItem, setSelectedItem] = React.useState<SelectedItem>({
    rowIndex: -1,
    text: null,
    key: null
  });

  React.useEffect(() => {
    dispatch(getAreaProjects(url));
  }, [dispatch, url]);

  const handleSubmit = async () => {
    const newAreaProjects = cloneDeep(areaProjects);
    const hasEmptyProjects = !!newAreaProjects?.find((item) => item.name === '');
    if (hasEmptyProjects) {
      dispatch(
        spawnNotification({
          type: NotificationTypes.Error,
          message: t('backOffice.projectOverview.validation')
        })
      );
      return;
    }

    if (newAreaProjects) {
      await dispatch(saveAreaProjects(url, newAreaProjects));
      dispatch(getMenuList());
      history.push(`${BOARDS}/${boardId}/back-office`);
    }
  };

  const handleOnClick = (key: AreaProjectKeys | null, index: number, text: string | null) => {
    setSelectedItem({
      key,
      rowIndex: index,
      text
    });
  };

  const handleBlur = () => {
    const newAreaProjects = cloneDeep(areaProjects);
    const { rowIndex, key, text } = selectedItem;

    if (newAreaProjects && newAreaProjects.length > 0 && rowIndex !== -1 && key !== null && text !== null) {
      (newAreaProjects[rowIndex][key] as any) = text;
      dispatch(updateAreaProjects(newAreaProjects));
    }
    setSelectedItem({
      text: null,
      key: null,
      rowIndex: -1
    });
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedItem({
      ...selectedItem,
      text: e.target.value
    });
  };

  const onDragEnd = (e: DropResult) => {
    const clonedAreaProjects = cloneDeep(areaProjects);
    const sourceIndex = e.source.index;
    const destinationIndex = e.destination?.index;

    blurActiveElement();

    if (clonedAreaProjects && destinationIndex !== undefined) {
      const newAreaProjects = Array.from(clonedAreaProjects);
      const [removedAreaProject] = newAreaProjects.splice(sourceIndex, 1);

      newAreaProjects.splice(destinationIndex, 0, removedAreaProject);

      const reorderedProjects = newAreaProjects.map((item, index) => {
        const newItems = cloneDeep(item);
        newItems.order = index;
        return newItems;
      });

      dispatch(updateAreaProjects(reorderedProjects));
    }
  };

  const toggleChange = (index: number, isEnabled: boolean) => {
    const newAreaProjects = cloneDeep(areaProjects);

    if (newAreaProjects) {
      newAreaProjects[index].isEnabled = isEnabled;
      dispatch(updateAreaProjects(newAreaProjects));
    }
  };

  const onDragStart = () => {
    blurActiveElement();
  };

  return (
    <div className="back-office-project-overview">
      <div className="d-flex justify-content-end align-items-center mx-1 my-4">
        <div className="d-flex justify-content-end align-items-end">
          <Button buttonTypes={['primary', 'big']} className="ml-3" onClick={handleSubmit}>
            {t('buttons.save')}
          </Button>
        </div>
      </div>
      <DragDropContext onDragEnd={onDragEnd} onDragStart={onDragStart}>
        <Table type="dnd">
          <THead>
            <TR>
              <TH className="text-center">{t('sharedText.number')}</TH>
              <TH>{t('backOffice.projectOverview.displayName')}</TH>
              <TH>{t('backOffice.projectOverview.projectName')}</TH>
              <TH>{t('backOffice.projectOverview.projectNumber')}</TH>
              <TH>{t('backOffice.projectOverview.masterPlanUrl')}</TH>
              <TH className="text-center">{t('sharedText.show')}</TH>
            </TR>
          </THead>
          <Droppable droppableId="droppable-1">
            {(dropProvided: any) => (
              // eslint-disable-next-line react/jsx-props-no-spreading
              <tbody ref={dropProvided.innerRef} {...dropProvided.droppableProps}>
                {orderBy(areaProjects, ['order'])?.map((areaProject, index) => {
                  return (
                    <Draggable key={areaProject.projectId} draggableId={areaProject.projectId.toString()} index={index}>
                      {(provided, snapshot) => (
                        <tr
                          className={`${snapshot.isDragging ? 'table__row-drag' : ''}`}
                          key={areaProject.projectId}
                          // eslint-disable-next-line react/jsx-props-no-spreading
                          {...provided.draggableProps}
                          // eslint-disable-next-line react/jsx-props-no-spreading
                          {...provided.dragHandleProps}
                          ref={provided.innerRef}
                        >
                          <TD className="text-center">{index + 1}</TD>
                          <TD>
                            <TableInput
                              onClick={() => {
                                handleOnClick('name', index, areaProject.name);
                              }}
                              onBlur={handleBlur}
                              onChange={handleInputChange}
                              value={selectedItem.rowIndex === index && selectedItem.key === 'name' ? selectedItem.text : areaProject.name}
                            />
                          </TD>
                          <TD>{areaProject.shortName}</TD>
                          <TD>{areaProject.code}</TD>
                          <TD>
                            <TableInput
                              onClick={() => {
                                handleOnClick('projectUrl', index, areaProject.projectUrl);
                              }}
                              onBlur={handleBlur}
                              onChange={handleInputChange}
                              value={
                                selectedItem.rowIndex === index && selectedItem.key === 'projectUrl' ? selectedItem.text : areaProject.projectUrl
                              }
                            />
                          </TD>
                          <TD className="text-center">
                            <ToggleSwitch
                              id={areaProject.projectId.toString()}
                              checked={areaProject.isEnabled}
                              onChange={(isEnabled) => toggleChange(index, isEnabled)}
                            />
                            {areaProject.isEnabled}
                          </TD>
                        </tr>
                      )}
                    </Draggable>
                  );
                })}
                {dropProvided.placeholder}
              </tbody>
            )}
          </Droppable>
        </Table>
      </DragDropContext>
      <UnsavedChangesPopup showIf={isFormDirty || isFormEditing} />
    </div>
  );
};

export default BackOfficeProjectOverview;
