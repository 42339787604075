import ActionTypes from '../actionTypes';
import Actions from '../Actions';
import { BackOfficeState } from './types';
import { StateStatus } from '../../enums/StateStatus';

export const defaultBackofficeState: BackOfficeState = {
  list: null,
  areaProjects: null,
  boardPermissions: null,
  capacitySubcontractors: null,
  status: StateStatus.Initial,
  error: null
};

export default function reducer(state: BackOfficeState = defaultBackofficeState, action: Actions): BackOfficeState {
  switch (action.type) {
    case ActionTypes.GET_BACK_OFFICE_LIST:
    case ActionTypes.GET_AREA_PROJECTS:
    case ActionTypes.SAVE_AREA_PROJECTS:
    case ActionTypes.GET_CAPACITY_SUBCONTRACTORS:
    case ActionTypes.SAVE_CAPACITY_SUBCONTRACTORS:
      return { ...state, status: StateStatus.Busy };
    case ActionTypes.GET_BACK_OFFICE_LIST_SUCCESS:
      return { ...state, status: StateStatus.Loaded, list: action.payload };
    case ActionTypes.GET_AREA_PROJECTS_SUCCESS:
    case ActionTypes.SAVE_AREA_PROJECTS_SUCCESS:
      return { ...state, status: StateStatus.Loaded, areaProjects: action.payload };
    case ActionTypes.GET_CAPACITY_SUBCONTRACTORS_SUCCESS:
    case ActionTypes.SAVE_CAPACITY_SUBCONTRACTORS_SUCCESS:
      return { ...state, status: StateStatus.Loaded, capacitySubcontractors: action.payload };
    case ActionTypes.GET_BACK_OFFICE_LIST_ERROR:
    case ActionTypes.GET_AREA_PROJECTS_ERROR:
    case ActionTypes.SAVE_AREA_PROJECTS_ERROR:
    case ActionTypes.GET_CAPACITY_SUBCONTRACTORS_ERROR:
    case ActionTypes.SAVE_CAPACITY_SUBCONTRACTORS_ERROR:
      return { ...state, status: StateStatus.Error, error: action.payload };
    case ActionTypes.UPDATE_AREA_PROJECTS:
      return { ...state, areaProjects: action.payload };
    case ActionTypes.UPDATE_CAPACITY_SUBCONTRACTORS:
      return { ...state, capacitySubcontractors: action.payload };
    case ActionTypes.SAVE_BOARD_PERMISSIONS_SUCCESS:
    case ActionTypes.GET_BOARD_PERMISSIONS_SUCCESS:
      return { ...state, boardPermissions: action.payload };
    default:
      return state;
  }
}
