import { TileMenu } from '../../state/menu/types';
import getTileDetailPageUrl from '../tile/getTileDetailPageUrl';

export const mapTiles = (tiles: TileMenu[], boardId: number | null) => {
  return (
    tiles?.map((tile) => {
      const newTile = {
        ...tile,
        type: tile.tileType
      };
      return {
        tileId: tile.tileId,
        name: tile.title ?? '',
        url: getTileDetailPageUrl(newTile, Number(boardId))
      };
    }) || []
  );
};
