import * as React from 'react';
import Popup from '../Popup/Popup';
import Button from '../../Button/Button';

import './ConfirmPopup.scss';

export interface ConfirmPopupProps {
  show?: boolean;
  onCancel?: () => void;
  onConfirm?: () => void;
  heading?: string;
  confirmText?: string;
  cancelText?: string;
}

const ConfirmPopup: React.FC<ConfirmPopupProps> = ({ show, heading, cancelText, confirmText, onCancel, onConfirm }) => (
  <Popup show={show}>
    <div className="confirm-popup">
      <h2 className="confirm-popup__heading text-center font-weight-bold">{heading}</h2>
      <div className="d-flex flex-row justify-content-center align-items-center">
        <Button buttonTypes={['secondary', 'big']} className="mr-5" onClick={onCancel}>
          {cancelText}
        </Button>
        <Button buttonTypes={['primary', 'big']} onClick={onConfirm}>
          {confirmText}
        </Button>
      </div>
    </div>
  </Popup>
);
export default ConfirmPopup;
