import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Actions as SettingsActions } from '../../../core/state/settings/actions';
import { usePrevious } from '../../../core/hooks/usePrevious';

import './TableInput.scss';

interface Props {
  type?: 'small';
  isLink?: true;
  valueType?: string;
  isText?: boolean;

  reInitDefaultValue?: boolean;

  row: string | number;
  column: string | number;
  useTableValue: (row: string | number, column: string | number) => any[]; // () => [T, (value: T) => void]
}

const TableInput: React.FC<Props> = ({ type, row, column, useTableValue, valueType, isText = false, reInitDefaultValue = false }) => {
  const dispatch = useDispatch();
  const [initialValue, saveValue] = useTableValue(row, column);
  const [value, setValue] = useState(initialValue);
  const [isEditing, setEditing] = useState<boolean>(false);
  const inputEl = React.useRef<HTMLInputElement>(null);
  const prevValue = usePrevious(initialValue);

  useEffect(() => {
    if (reInitDefaultValue && prevValue !== initialValue) {
      setValue(initialValue);
    }
  }, [initialValue, prevValue, reInitDefaultValue]);

  const handleClick = async () => {
    if (isText) {
      return;
    }
    setEditing(true);
    dispatch(SettingsActions.setIsFormEditing(true));
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
  };

  const handleBlur = () => {
    setEditing(false);
    dispatch(SettingsActions.setIsFormEditing(false));
    saveValue(value);
  };

  useEffect(() => {
    if (isEditing) {
      inputEl.current?.focus();
    }
  }, [inputEl, isEditing]);

  return (
    <div className={`table-input ${type ? `table-input--${type}` : ''} ${isEditing ? 'table-input--editing' : ''}`}>
      <input ref={inputEl} className="table-input__input" value={value} type={valueType || 'text'} onBlur={handleBlur} onChange={handleChange} />
      {!isEditing && <span className="table-input__text">{value}</span>}
      <div
        className="table-input__overlay"
        onClick={handleClick}
        role="button"
        aria-pressed="false"
        aria-label="input"
        tabIndex={0}
        onKeyDown={() => {}}
      />
    </div>
  );
};

export default TableInput;
