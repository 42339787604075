import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { Board as BoardModel, Tile as TileModel } from '../../core/state/boards/types';
import { Color } from '../../core/enums/Color';
import { TilePositions } from '../../core/enums/TilePositions';
import BoardGrid from '../components/Board/BoardGrid/BoardGrid';
import Tile from '../../components/Tile/Tile';
import { TileConfigurationDictionary } from '../../core/types/TileConfigurationDictionary';
import { getFilteredTiles } from '../../core/utils/tile/getFilteredTiles';

interface Props {
  board: BoardModel<TileModel>;
}

const TopManagementTVGrid: React.FC<Props> = ({ board }) => {
  const { t } = useTranslation();

  const tileConfigurations: TileConfigurationDictionary = {
    [TilePositions.MD1]: {
      isTransparent: true
    },
    [TilePositions.K1]: {
      headingBackgroundColor: Color.Red,
      headingText: t('mainBoard.title.measuresKvp')
    },
    [TilePositions.E1]: {
      headingBackgroundColor: Color.Red,
      headingText: t('mainBoard.title.escalation')
    },
    [TilePositions.V1]: {
      headingBackgroundColor: Color.Grey,
      headingText: t('tileProjectAreaOverview.strategicSales')
    },
    [TilePositions.ME1]: {
      headingBackgroundColor: Color.Red,
      headingText: t('mainBoard.title.measuresEscalations')
    }
  };

  return (
    <BoardGrid boardType={board.type}>
      {getFilteredTiles(board.tiles)?.map((tile) => (
        <Tile
          key={tile.tileId}
          tile={tile}
          className={tile.position.toLowerCase()}
          isInteractive={tile.isInteractive}
          isTransparent={tileConfigurations[tile.position]?.isTransparent}
          headingBackgroundColor={tileConfigurations[tile.position]?.headingBackgroundColor}
          headingText={tileConfigurations[tile.position]?.headingText}
        />
      ))}
    </BoardGrid>
  );
};

export default TopManagementTVGrid;
