import { useEffect } from 'react';
import { getMeasureInfo, MeasureViewModel } from '../../utils/measures/getMeasureInfo';
import { TableForm } from '../../state/table-forms/types';

export function useMeasureTableFormChange(
  measuresArray: MeasureViewModel[],
  setMeasuresArray: (measuresArray: MeasureViewModel[]) => any,
  tableForm: TableForm | null
) {
  useEffect(() => {
    // update measuresArray on TableForm Change
    const newMeasuresArray = measuresArray.map((item) => {
      const index = tableForm?.rows.findIndex((rowItem) => rowItem.measureURL === item.measureURL);
      if (index !== undefined && index > -1) {
        const newItem = getMeasureInfo(tableForm, index);
        if (newItem) {
          newItem.measure.problemText = item.measure.problemText;
          return newItem;
        }
      }
      return item;
    });
    if (newMeasuresArray) {
      setMeasuresArray(newMeasuresArray);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableForm]);
}
