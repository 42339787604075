import ActionTypes from '../actionTypes';
import { TableFormStateNamespaces } from './types';

const actionNamespaceMap: { [key in ActionTypes]?: string } = {
  // Generic
  [ActionTypes.GET_TABLE_FORM]: TableFormStateNamespaces.Form,
  [ActionTypes.GET_TABLE_FORM_SUCCESS]: TableFormStateNamespaces.Form,
  [ActionTypes.GET_TABLE_FORM_ERROR]: TableFormStateNamespaces.Form,
  [ActionTypes.UPDATE_TABLE_FORM]: TableFormStateNamespaces.Form,
  [ActionTypes.SAVE_TABLE_FORM]: TableFormStateNamespaces.Form,
  [ActionTypes.SAVE_TABLE_FORM_SUCCESS]: TableFormStateNamespaces.Form,
  [ActionTypes.SAVE_TABLE_FORM_ERROR]: TableFormStateNamespaces.Form,

  // Week Topics
  [ActionTypes.GET_WEEK_TOPICS]: TableFormStateNamespaces.WeekTopics,
  [ActionTypes.GET_WEEK_TOPICS_SUCCESS]: TableFormStateNamespaces.WeekTopics,
  [ActionTypes.GET_WEEK_TOPICS_ERROR]: TableFormStateNamespaces.WeekTopics,
  [ActionTypes.UPDATE_WEEK_TOPICS]: TableFormStateNamespaces.WeekTopics,
  [ActionTypes.SAVE_WEEK_TOPICS]: TableFormStateNamespaces.WeekTopics,
  [ActionTypes.SAVE_WEEK_TOPICS_SUCCESS]: TableFormStateNamespaces.WeekTopics,
  [ActionTypes.SAVE_WEEK_TOPICS_ERROR]: TableFormStateNamespaces.WeekTopics,

  // KVP
  [ActionTypes.GET_KVP_FORM]: TableFormStateNamespaces.KVP,
  [ActionTypes.GET_KVP_FORM_SUCCESS]: TableFormStateNamespaces.KVP,
  [ActionTypes.GET_KVP_FORM_ERROR]: TableFormStateNamespaces.KVP,
  [ActionTypes.UPDATE_KVP_FORM]: TableFormStateNamespaces.KVP,
  [ActionTypes.SAVE_KVP_FORM]: TableFormStateNamespaces.KVP,
  [ActionTypes.SAVE_KVP_FORM_SUCCESS]: TableFormStateNamespaces.KVP,
  [ActionTypes.SAVE_KVP_FORM_ERROR]: TableFormStateNamespaces.KVP,

  // Sick Leave
  [ActionTypes.GET_SICK_LEAVE_FORM]: TableFormStateNamespaces.SickLeave,
  [ActionTypes.GET_SICK_LEAVE_FORM_SUCCESS]: TableFormStateNamespaces.SickLeave,
  [ActionTypes.GET_SICK_LEAVE_FORM_ERROR]: TableFormStateNamespaces.SickLeave,
  [ActionTypes.UPDATE_SICK_LEAVE_FORM]: TableFormStateNamespaces.SickLeave,
  [ActionTypes.SAVE_SICK_LEAVE_FORM]: TableFormStateNamespaces.SickLeave,
  [ActionTypes.SAVE_SICK_LEAVE_FORM_SUCCESS]: TableFormStateNamespaces.SickLeave,
  [ActionTypes.SAVE_SICK_LEAVE_FORM_ERROR]: TableFormStateNamespaces.SickLeave,

  // ProjectOverview
  [ActionTypes.GET_PROJECT_OVERVIEW_FORM]: TableFormStateNamespaces.ProjectOverview,
  [ActionTypes.GET_PROJECT_OVERVIEW_FORM_SUCCESS]: TableFormStateNamespaces.ProjectOverview,
  [ActionTypes.GET_PROJECT_OVERVIEW_FORM_ERROR]: TableFormStateNamespaces.ProjectOverview,
  [ActionTypes.UPDATE_PROJECT_OVERVIEW_FORM]: TableFormStateNamespaces.ProjectOverview,
  [ActionTypes.SAVE_PROJECT_OVERVIEW_FORM]: TableFormStateNamespaces.ProjectOverview,
  [ActionTypes.SAVE_PROJECT_OVERVIEW_FORM_SUCCESS]: TableFormStateNamespaces.ProjectOverview,
  [ActionTypes.SAVE_PROJECT_OVERVIEW_FORM_ERROR]: TableFormStateNamespaces.ProjectOverview,

  // Goals
  [ActionTypes.GET_GOALS_FORM]: TableFormStateNamespaces.Goals,
  [ActionTypes.GET_GOALS_FORM_SUCCESS]: TableFormStateNamespaces.Goals,
  [ActionTypes.GET_GOALS_FORM_ERROR]: TableFormStateNamespaces.Goals,
  [ActionTypes.UPDATE_GOALS_FORM]: TableFormStateNamespaces.Goals,
  [ActionTypes.SAVE_GOALS_FORM]: TableFormStateNamespaces.Goals,
  [ActionTypes.SAVE_GOALS_FORM_SUCCESS]: TableFormStateNamespaces.Goals,
  [ActionTypes.SAVE_GOALS_FORM_ERROR]: TableFormStateNamespaces.Goals,

  // Standing Rules
  [ActionTypes.GET_STANDING_RULES]: TableFormStateNamespaces.StandingRules,
  [ActionTypes.GET_STANDING_RULES_SUCCESS]: TableFormStateNamespaces.StandingRules,
  [ActionTypes.GET_STANDING_RULES_ERROR]: TableFormStateNamespaces.StandingRules,
  [ActionTypes.UPDATE_STANDING_RULES]: TableFormStateNamespaces.StandingRules,
  [ActionTypes.SAVE_STANDING_RULES]: TableFormStateNamespaces.StandingRules,
  [ActionTypes.SAVE_STANDING_RULES_SUCCESS]: TableFormStateNamespaces.StandingRules,
  [ActionTypes.SAVE_STANDING_RULES_ERROR]: TableFormStateNamespaces.StandingRules,

  // Measures and Escalations
  [ActionTypes.GET_MEASURES_AND_ESCALATIONS]: TableFormStateNamespaces.MeasuresEscalation,
  [ActionTypes.GET_MEASURES_AND_ESCALATIONS_SUCCESS]: TableFormStateNamespaces.MeasuresEscalation,
  [ActionTypes.GET_MEASURES_AND_ESCALATIONS_ERROR]: TableFormStateNamespaces.MeasuresEscalation,
  [ActionTypes.UPDATE_MEASURES_AND_ESCALATIONS]: TableFormStateNamespaces.MeasuresEscalation
};

export default actionNamespaceMap;
