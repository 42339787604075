import * as React from 'react';
import useMedia from 'use-media';
import { MEDIA_QUERIES, MediaQueriesType } from '../../../core/constants/mediaQueries';

interface Props {
  mediaQueries: MediaQueriesType[];
}

const MQ: React.FC<Props> = ({ mediaQueries, children }) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const screens = mediaQueries.map((mq) => useMedia(MEDIA_QUERIES[mq]));
  const isInScreens = screens.find((screen) => screen);
  if (isInScreens) {
    return <>{children}</>;
  }
  return null;
};

export default MQ;
