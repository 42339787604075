import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { BoardTypes } from '../../../core/enums/BoardTypes';
import { RootState } from '../../../core/state/state';
import { getGoalsForm } from '../../../core/state/table-forms/asyncActions';
import { GoalKeys } from '../../../core/state/table-forms/types';

import Table from '../../../components/Table/Table';
import THead from '../../../components/Table/THead/THead';
import TR from '../../../components/Table/TR/TR';
import TH from '../../../components/Table/TH/TH';
import TD from '../../../components/Table/TD/TD';
import TBody from '../../../components/Table/TBody/TBody';
import TableCellOverflowEllipses from '../../../pages/components/TableCellOverflowEllipses/TableCellOverflowEllipses';
import PDFTileButtonLink from '../../../pages/components/PDFTileButtonLink/PDFTileButtonLink';

import './TileGoalSettingDesktopScreen.scss';

interface Props {
  url: string;
}

const TileGoalSettingDesktopScreen: React.FC<Props> = ({ url }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const goalsForm = useSelector((state: RootState) => state.tableForm.goalsForm.form);
  const boardType = useSelector((state: RootState) => state.boards.board?.type);

  React.useEffect(() => {
    dispatch(getGoalsForm(url));
  }, [dispatch, url]);

  const getAllRows = (formName: GoalKeys) => {
    if (!goalsForm) {
      return null;
    }
    const tableForm = goalsForm[formName];
    if (tableForm && tableForm.rows.length > 0) {
      return tableForm.rows.map((tableFormRow) => (
        <TR key={tableForm.rows.indexOf(tableFormRow)}>
          {tableFormRow.columns.map((tableFormColumn) => (
            <React.Fragment key={tableFormColumn.columnNumber}>
              <TD key={tableFormColumn.columnNumber}>
                <TableCellOverflowEllipses content={tableFormColumn.text} />
              </TD>
              <TD>
                <div className="tile-goal-setting__status-wrap">
                  <span
                    className={`tile-goal-setting__status tile-goal-setting no-font-scaling__status
                        ${tableFormColumn.status ? `tile-goal-setting no-font-scaling__status--${tableFormColumn.status.toLowerCase()}` : ''}
                        ${boardType === BoardTypes.GFBoard && formName === 'planningRight' && 'tile-goal-setting__status--small'}`}
                  >
                    {tableFormColumn.status}
                  </span>
                </div>
              </TD>
            </React.Fragment>
          ))}
        </TR>
      ));
    }
    return null;
  };

  return (
    <div className="tile-desktop-screen no-font-scaling tile-goal-setting">
      <Table className="tile-table__table">
        <THead>
          <TR>
            <TH className="table__table-header" colSpan={2}>
              <div className="d-flex">
                {boardType && boardType === BoardTypes.GFBoard ? t('forms.goalSetting.titleGF') : t('forms.goalSetting.titleBRL')}
                {goalsForm?.pdfTile && <PDFTileButtonLink pdfTile={goalsForm.pdfTile} />}
              </div>
            </TH>
          </TR>
        </THead>
        <TBody>{getAllRows('planningLeft')}</TBody>
      </Table>
    </div>
  );
};

export default TileGoalSettingDesktopScreen;
