import ActionTypes from '../actionTypes';
import Actions from '../Actions';
import { MenuState } from './types';
import { StateStatus } from '../../enums/StateStatus';

export const defaultMenuState: MenuState = {
  list: null,
  firstNavOpened: -1,
  isMenuOpened: false,
  status: StateStatus.Initial,
  error: null
};

export default function reducer(state: MenuState = defaultMenuState, action: Actions): MenuState {
  switch (action.type) {
    case ActionTypes.GET_MENU_LIST:
      return { ...state, status: StateStatus.Busy };
    case ActionTypes.GET_MENU_LIST_SUCCESS:
      return { ...state, status: StateStatus.Loaded, list: action.payload };
    case ActionTypes.GET_MENU_LIST_ERROR:
      return { ...state, status: StateStatus.Error, error: action.payload };
    case ActionTypes.SET_FIRST_NAV_OPENED:
      return { ...state, status: StateStatus.Error, firstNavOpened: action.payload };
    case ActionTypes.SET_IS_MENU_OPENED:
      return { ...state, status: StateStatus.Error, isMenuOpened: action.payload };
    default:
      return state;
  }
}
