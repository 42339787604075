import React from 'react';

import { TileTypes } from '../../core/enums/TileTypes';
import TableFormTileDesktopScreen, { ColumnTypes } from '../TableFormTile/DesktopScreen/TableFormTileDesktopScreen';
import { TableTileTypes } from '../../core/enums/TableTileTypes';

interface Props {
  boardId?: number;
  url: string;
}

const TileDeadlineComplianceDesktopScreen: React.FC<Props> = ({ url }) => {
  return (
    <TableFormTileDesktopScreen
      url={url}
      tableTileType={TableTileTypes.DeadlineCompliance}
      tileType={TileTypes.DeadlineCompliance}
      columns={[
        {
          type: ColumnTypes.Text,
          dataColumnIndex: 0,
          className: 'text-left'
        },
        {
          type: ColumnTypes.StatusCircle,
          dataColumnIndex: 3
        },
        {
          type: ColumnTypes.StatusCircle,
          dataColumnIndex: 6
        }
      ]}
    />
  );
};
export default TileDeadlineComplianceDesktopScreen;
