import { useTranslation } from 'react-i18next';
import { useRouteMatch } from 'react-router-dom';
import React from 'react';

import { BOARDS_TILE_DETAIL } from '../../../core/constants/routes';

const TileEscalationHeader = () => {
  const { t } = useTranslation();

  const isDetailPage = useRouteMatch(BOARDS_TILE_DETAIL);
  return (
    <div className={isDetailPage ? 'mb-3' : ''}>
      <div className={`${isDetailPage ? 'd-flex justify-content-between align-items-center' : ''}`}>
        <div className={isDetailPage ? '' : 'px-1'}>
          <div className={isDetailPage ? 'tile-escalation__title mb-0' : 'tile-escalation__title-small'}>{t('forms.escalation.title')}</div>
          {!isDetailPage && (
            <p className={isDetailPage ? 'tile-escalation__subtitle mb-0' : 'tile-escalation__subtitle-small mb-1'}>
              {t('forms.escalation.subtitle')}
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default TileEscalationHeader;
