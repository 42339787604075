import Actions from '../../state/Actions';
import { getActionTypeByName, getValueByKey } from '../../state/helpers';
import { StateStatus } from '../../enums/StateStatus';
import { defaultTileState, TileState } from './TileState';

export function generateTileReducer<T>(type: string) {
  return (state: TileState<T> = defaultTileState, action: Actions): TileState<T> => {
    switch (action.type) {
      case getActionTypeByName(`GET_${type}`):
      case getActionTypeByName(`SAVE_${type}`):
        return { ...state, status: StateStatus.Busy };
      case getActionTypeByName(`GET_${type}_SUCCESS`):
        return { ...state, status: StateStatus.Loaded, form: getValueByKey(action, 'payload') };
      case getActionTypeByName(`SAVE_${type}_SUCCESS`):
        return { ...state, status: StateStatus.Loaded };
      case getActionTypeByName(`UPDATE_${type}`):
        return { ...state, form: getValueByKey(action, 'payload') };
      case getActionTypeByName(`GET_${type}_ERROR`):
      case getActionTypeByName(`SAVE_${type}_ERROR`):
        return { ...state, status: StateStatus.Error, error: getValueByKey(action, 'payload') };
      default:
        return state;
    }
  };
}
