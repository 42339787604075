import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { match } from 'react-router-dom';
import TH from '../../components/Table/TH/TH';
import THead from '../../components/Table/THead/THead';
import TR from '../../components/Table/TR/TR';
import TileSickLeaveHeaderItem from './TileSickLeaveHeaderItem';

interface Props {
  isDetailPage: match | null;
}

const TileSickLeaveTableHeader: React.FC<Props> = ({ isDetailPage }) => {
  const { t } = useTranslation();
  return (
    <THead>
      <TR>
        <TileSickLeaveHeaderItem itemClass="d-flex flex-column align-items-center" translation="forms.sickLeave.tableHeader.kpi" />
        <TH colSpan={10} className="table__table-header text-center">
          <span className="table__title">{t('forms.sickLeave.tableHeader.stuff')}</span>
        </TH>
        <TH colSpan={4} className="table__table-header text-center">
          <span className="table__title">{t('forms.sickLeave.tableHeader.guarantee')}</span>
        </TH>
        {isDetailPage && <TH rowSpan={3} />}
      </TR>
      <TR>
        <TH className="border-bottom-0" />
        <TileSickLeaveHeaderItem
          cellClass="border-bottom-0"
          itemClass="d-flex flex-column align-items-center h-100"
          translation="sharedText.status"
        />
        <TileSickLeaveHeaderItem
          cellClass="border-bottom-0"
          itemClass="d-flex flex-column align-items-center h-100"
          translation="forms.sickLeave.tableHeader.accidents"
        />
        <TileSickLeaveHeaderItem
          cellClass="border-bottom-0"
          itemClass="d-flex flex-column align-items-center h-100"
          translation="forms.sickLeave.tableHeader.sick"
        />
        <TileSickLeaveHeaderItem
          colSpan={2}
          itemClass="d-flex flex-column align-items-center h-100"
          translation="forms.sickLeave.tableHeader.personal"
        />
        <TileSickLeaveHeaderItem colSpan={4} itemClass="d-flex flex-column align-items-center" translation="forms.sickLeave.tableHeader.topics" />
        <TileSickLeaveHeaderItem
          cellClass="border-bottom-0"
          itemClass="d-flex flex-column align-items-center"
          translation="forms.sickLeave.tableHeader.measures"
        />
        <TileSickLeaveHeaderItem cellClass="border-bottom-0" itemClass="d-flex flex-column align-items-center" translation="sharedText.status" />
        <TileSickLeaveHeaderItem
          colSpan={2}
          itemClass="d-flex flex-column align-items-center h-100"
          translation="forms.sickLeave.tableHeader.costs"
        />
        <TH colSpan={2} />
        <TileSickLeaveHeaderItem
          cellClass="border-bottom-0"
          itemClass="d-flex flex-column align-items-center"
          translation="forms.sickLeave.tableHeader.measures"
        />
      </TR>
      <TR>
        <TH />
        <TH />
        <TH />
        <TH />
        <TileSickLeaveHeaderItem itemClass="d-flex flex-column align-items-center h-100" translation="sharedText.soll" />
        <TileSickLeaveHeaderItem itemClass="d-flex flex-column align-items-center h-100" translation="sharedText.ist" />
        <TileSickLeaveHeaderItem itemClass="d-flex flex-column align-items-center h-100" translation="forms.sickLeave.tableHeader.education" />
        <TileSickLeaveHeaderItem itemClass="d-flex flex-column align-items-center h-100" translation="forms.sickLeave.tableHeader.employeeRatings" />
        <TileSickLeaveHeaderItem
          itemClass="d-flex flex-column align-items-center h-100"
          translation="forms.sickLeave.tableHeader.employeeMotivation"
        />
        <TileSickLeaveHeaderItem itemClass="d-flex flex-column align-items-center h-100" translation="forms.sickLeave.tableHeader.vacationPlanning" />
        <TH />
        <TH />
        <TileSickLeaveHeaderItem itemClass="d-flex flex-column align-items-center h-100" translation="forms.sickLeave.tableHeader.budget" />
        <TileSickLeaveHeaderItem itemClass="d-flex flex-column align-items-center h-100" translation="sharedText.ist" />
        <TH />
      </TR>
    </THead>
  );
};

export default TileSickLeaveTableHeader;
