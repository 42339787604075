import * as React from 'react';

import './TileCheckboxList.scss';

type LegendItem = {
  color: string;
  text: string;
};

interface Props {
  items: LegendItem[];
  className?: string;
}

const TileLegendBox: React.FC<Props> = ({ items, className }) => {
  return (
    <div className="d-flex justify-content-end w-100">
      <div className={`d-flex ${className}`}>
        {items.map((item) => (
          <div key={item.color} className="d-flex align-items-center">
            <div className={`tile-attendance-list__legend-box tile-attendance-list__legend-box--${item.color}`} />
            <span className="tile-attendance-list__legend-text">{item.text}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TileLegendBox;
