import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { Sizes } from '../../../core/enums/Sizes';
import { Actions as SettingsActions } from '../../../core/state/settings/actions';

import './NumberPPInput.scss';

interface Props {
  type?: 'small';
  defaultValue?: string;
  labelSize?: Sizes;
  row: string | number;
  className?: string;

  column: string | number;
  useTableValue: (row: string | number, column: string | number) => any[]; // () => [T, (value: T) => void]
}

const NumberPPInput: React.FC<Props> = ({ row, column, useTableValue, className, type }) => {
  const dispatch = useDispatch();
  const [initialValue, saveValue] = useTableValue(row, column);
  const [value, setValue] = useState(initialValue);
  const [, setEditing] = useState<boolean>(false);

  const onFocus = async () => {
    setEditing(true);
    dispatch(SettingsActions.setIsFormEditing(true));
  };

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const strippedString = e.target.value.replace(/\D/g, '');
    if (strippedString.length < 3) {
      setValue(strippedString);
    }
  };

  const onBlur = () => {
    setEditing(false);
    dispatch(SettingsActions.setIsFormEditing(false));
    saveValue(value);
  };

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  return (
    <div className={`number-pp-input ${type ? `number-pp-input--${type}` : ''} ${className ?? ''}`}>
      <div className="number-pp-input__wrap">
        <span className="number-pp-input__icon" />
        <span className="number-pp-input__pp">PP</span>
        <input className="number-pp-input__input" value={value || ''} onChange={onChange} onBlur={onBlur} onFocus={onFocus} />
      </div>
    </div>
  );
};

export default NumberPPInput;
