import * as React from 'react';

import TableFormTileDesktopScreen from '../../TableFormTile/DesktopScreen/TableFormTileDesktopScreen';
import { TileTypes } from '../../../core/enums/TileTypes';
import { TableTileTypes } from '../../../core/enums/TableTileTypes';
import TR from '../../../components/Table/TR/TR';
import TD from '../../../components/Table/TD/TD';
import TableCellOverflowEllipses from '../../../pages/components/TableCellOverflowEllipses/TableCellOverflowEllipses';
import { getDesktopStatusIndicator } from '../../TableFormTile/TableFormHelpers';

interface Props {
  url: string;
}

const TileInspectionPlansQADesktopScreen: React.FC<Props> = ({ url }) => {
  return (
    <TableFormTileDesktopScreen
      url={url}
      tileType={TileTypes.InspectionPlansQAList}
      tableTileType={TableTileTypes.InspectionPlansQAList}
      tableHeaderColSpan={5}
      showStatus
      bodyRenderer={(tableForm) => {
        return tableForm ? (
          <>
            {tableForm.rows.map((tableFormItem, index) => (
              <TR key={tableFormItem.rowNumber}>
                <TD className="w-50 text-left">
                  <TableCellOverflowEllipses content={`${index + 1}. ${tableFormItem.columns[0].text}`} />
                </TD>
                <TD className="shrink text-right">
                  <div
                    className={`table__indicator table__indicator--round ${getDesktopStatusIndicator(
                      tableFormItem.columns[5]?.status
                    )} no-font-scaling mr-2`}
                  />
                  <div
                    className={`table__indicator table__indicator--round ${getDesktopStatusIndicator(
                      tableFormItem.columns[6]?.status
                    )} no-font-scaling mr-2`}
                  />
                  <div
                    className={`table__indicator table__indicator--round ${getDesktopStatusIndicator(
                      tableFormItem.columns[7]?.status
                    )} no-font-scaling mr-2`}
                  />
                  <div
                    className={`table__indicator  table__indicator--round ${getDesktopStatusIndicator(
                      tableFormItem.columns[8]?.status
                    )} no-font-scaling mr-2`}
                  />
                </TD>
              </TR>
            ))}
          </>
        ) : (
          <TR />
        );
      }}
    />
  );
};

export default TileInspectionPlansQADesktopScreen;
