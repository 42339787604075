import { StateStatus } from '../../enums/StateStatus';
import { RestErrorResponse } from '../../types/response/RestErrorResponse';

export interface TileState<T> {
  form: T | null;
  status: StateStatus;
  error: RestErrorResponse | null;
}

export const defaultTileState: TileState<any> = {
  form: null,
  status: StateStatus.Initial,
  error: null
};
