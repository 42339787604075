import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import { cloneDeep } from 'lodash';

import { BOARDS, BOARDS_TILE_DETAIL } from '../../core/constants/routes';
import { RootState } from '../../core/state/state';
import { history } from '../../core/state/history';
import { EscalationLevel, MeasureAndEscalation } from '../../core/state/table-forms/types';
import { getMeasuresAndEscalations, saveMeasuresAndEscalations, updateMeasuresAndEscalations } from '../../core/state/table-forms/asyncActions';

import Button from '../../components/Button/Button';
import UnsavedChangesPopup from '../../components/popup/UnsavedChangesPopup/UnsavedChangesPopup';
import MeasureEscalationModal from '../../pages/components/MeasureEscalationModal/MeasureEscalationModal';
import { FormTypes } from '../../pages/components/MeasureEscalationModal/formTypes';
import TileMeasure from '../TileMeasure/TileMeasure';
import TileEscalation from '../TileEscalation/TileEscalation';
import { useScrollToNewItem } from './hooks';
import ConfirmPopup, { ConfirmPopupProps } from '../../components/popup/ConfirmPopup/ConfirmPopup';
import { removeEmptyValuesFromForm } from './helpers';

import './TileMeasuresEscalation.scss';

interface Props {
  url: string;
  boardId?: number;
}

const TileMeasuresEscalation: React.FC<Props> = ({ url, boardId }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const isDetailPage = useRouteMatch(BOARDS_TILE_DETAIL);

  const [confirmDialog, setConfirmDialog] = React.useState<ConfirmPopupProps>();
  const form = useSelector((state: RootState) => state.tableForm.measuresEscalationForm.form);
  const { isFormDirty, isFormEditing } = useSelector((state: RootState) => state.settings);
  const [isModalOpen, setModalOpen] = useState<boolean>(false);

  const setUpdatedType = useScrollToNewItem();

  const closeModal = useCallback(() => {
    setModalOpen(false);
  }, [setModalOpen]);

  const createRow = useCallback(
    ({ measure, escalation }: MeasureAndEscalation) => {
      const updatedForm = {
        ...form,
        escalations: form?.escalations ? [...form.escalations] : [],
        measures: form?.measures ? [...form.measures] : []
      };
      if (measure) {
        updatedForm.measures.push(measure);
        setUpdatedType(FormTypes.Measure);
      } else if (escalation) {
        const newEscalation = cloneDeep(escalation);
        newEscalation.level = form?.level ?? EscalationLevel.BRL;
        updatedForm.escalations.push(newEscalation);
        setUpdatedType(FormTypes.Escalation);
      }
      dispatch(updateMeasuresAndEscalations(updatedForm));
      closeModal();
    },
    [dispatch, closeModal, form, setUpdatedType]
  );

  const clearAll = useCallback(() => {
    const updatedForm = {
      ...form,
      escalations: form?.escalations?.filter((item) => !item.canDelete) ?? [],
      measures: []
    };
    dispatch(updateMeasuresAndEscalations(updatedForm));
  }, [dispatch, form]);

  const clearAllPopup = () => {
    setConfirmDialog({
      heading: t('dialogs.confirmDialog.heading'),
      cancelText: t('dialogs.confirmDialog.cancelText'),
      confirmText: t('dialogs.confirmDialog.confirmText'),
      show: true,
      onCancel: () => {
        setConfirmDialog({ show: false });
      },
      onConfirm: () => {
        clearAll();
        setConfirmDialog({ show: false });
      }
    });
  };

  const saveForm = useCallback(async () => {
    if (form) {
      await dispatch(saveMeasuresAndEscalations(removeEmptyValuesFromForm(form), url));
      history.push(`${BOARDS}/${boardId}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, form, url, boardId]);

  const toolbar = (
    <div className="d-flex align-items-center">
      <div>
        {isDetailPage && (
          <Button buttonTypes={['secondary', 'big', 'red']} onClick={() => setModalOpen(true)}>
            {t('buttons.addNewRow')}
          </Button>
        )}
        {isDetailPage && (
          <Button buttonTypes={['secondary', 'big']} className="ml-3" onClick={clearAllPopup}>
            {t('buttons.clearAll')}
          </Button>
        )}
        {isDetailPage && (
          <Button buttonTypes={['primary', 'big']} className="ml-3" onClick={saveForm}>
            {t('buttons.save')}
          </Button>
        )}
      </div>
    </div>
  );

  useEffect(() => {
    dispatch(getMeasuresAndEscalations(url));
  }, [dispatch, url]);

  return (
    <div className={`tile-measures-escalation ${isDetailPage ? 'container-fluid' : ''}`}>
      <div className="tile-measures-escalation__content">
        <div className={`tile-measures-escalation__measure ${isDetailPage ? '' : 'pt-0'}`}>
          <TileMeasure url={url} toolbar={toolbar} />
        </div>
        <div className={`tile-measures-escalation__escalation ${isDetailPage ? '' : 'py-0'}`}>
          <TileEscalation url={url} />
        </div>
      </div>
      <ConfirmPopup
        show={confirmDialog?.show}
        confirmText={confirmDialog?.confirmText}
        cancelText={confirmDialog?.cancelText}
        heading={confirmDialog?.heading}
        onCancel={confirmDialog?.onCancel}
        onConfirm={confirmDialog?.onConfirm}
      />
      {isModalOpen && <MeasureEscalationModal onConfirm={createRow} onCancel={closeModal} show={isModalOpen} />}
      {isDetailPage && <UnsavedChangesPopup showIf={isFormDirty || isFormEditing} />}
    </div>
  );
};

export default TileMeasuresEscalation;
