import * as React from 'react';

import { Link } from 'react-router-dom';
import ProjectOverviewRowItem, { SelectedItem } from './ProjectOverviewRowItem';
import { ProjectOverviewFormColumn, ProjectOverviewFormRow } from '../../core/state/table-forms/types';
import { BOARDS } from '../../core/constants/routes';

interface Props {
  row: ProjectOverviewFormRow;
  selectedItem?: SelectedItem;
  onBlur: () => void;
  onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  onDoubleClick: (column: ProjectOverviewFormColumn, row: ProjectOverviewFormRow) => void;
  handleStatus: (row: ProjectOverviewFormRow, column: ProjectOverviewFormColumn) => void;
  handleDeleteRow: () => void;
}

const ProjectOverviewRow: React.FC<Props> = ({ row, selectedItem, onDoubleClick, onBlur, onChange, handleStatus }) => {
  return (
    <>
      <div className="tile-project-overview__row">
        <Link className="tile-project-overview__row-item tile-project-overview__row-item--center" to={`${BOARDS}/${row.boardId}`}>
          {row.projectName}
        </Link>
        {row.items.map((column, columnIndex) => (
          <ProjectOverviewRowItem
            selectedItem={selectedItem}
            key={column.columnNumber}
            column={column}
            row={row}
            onDoubleClick={onDoubleClick}
            onBlur={onBlur}
            onChange={onChange}
            handleStatus={handleStatus}
            columnIndex={columnIndex}
          />
        ))}
      </div>
    </>
  );
};

export default ProjectOverviewRow;
