import { UNASSIGNED_BOARD } from '../../constants/routes';
import MenuService from '../../services/MenuService';
import { ReduxThunkType } from '../../types/ReduxThunkType';
import { history } from '../history';
import { Actions as MenuActions } from './actions';

export const getMenuList = (): ReduxThunkType<void> => async (dispatch) => {
  try {
    dispatch(MenuActions.getMenuList());
    const response = await MenuService.getMenuList();
    dispatch(MenuActions.getMenuListSuccess(response.data));
    if (response.data.length === 0) {
      history.push(UNASSIGNED_BOARD);
    }
  } catch (error) {
    dispatch(MenuActions.getMenuListError(error));
  }
};

export const setMenuFirstNavOpened = (index: number): ReduxThunkType<void> => (dispatch) => {
  dispatch(MenuActions.setMenuFirstNavOpened(index));
};

export const setIsMenuOpened = (opened: boolean): ReduxThunkType<void> => (dispatch) => {
  dispatch(MenuActions.setIsMenuOpened(opened));
};
