import * as React from 'react';

import './TileImage.scss';

interface Props {
  url?: string;
}

const TileImage: React.FC<Props> = ({ url }) => <div className="tile-image">{url && <img src={url} alt="tile src" />}</div>;

export default TileImage;
