import React, { Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { TileTypes } from '../../../core/enums/TileTypes';
import { RootState } from '../../../core/state/state';
import { generateAsyncActions } from '../../../core/state/table-forms/asyncActions';
import { toDisplayDateString } from '../../../core/utils/formatDate';

import Table from '../../../components/Table/Table';
import TR from '../../../components/Table/TR/TR';
import TD from '../../../components/Table/TD/TD';
import TBody from '../../../components/Table/TBody/TBody';
import TableCellOverflowEllipses from '../../../pages/components/TableCellOverflowEllipses/TableCellOverflowEllipses';

import './TileCompletionDateDesktopScreen.scss';

interface Props {
  boardId?: number;
  url: string;
}

const TileCompletionDateDesktopScreen: React.FC<Props> = ({ url }) => {
  const dispatch = useDispatch();
  const tableForm = useSelector((state: RootState) => state.cDateOAmount.form?.first);
  const { t } = useTranslation();

  const { getTableForm } = React.useMemo(() => generateAsyncActions(TileTypes.RequiredMaterial), []);

  React.useEffect(() => {
    dispatch(getTableForm(url));
  }, [url, dispatch, getTableForm]);

  return (
    <div className="tile-completion-date-desktop-screen no-font-scaling">
      <Table className="tile-completion-date__table" type="small">
        <TBody>
          {tableForm?.rows.map((tableFormItem, index) => (
            <Fragment key={tableFormItem.rowNumber}>
              {index === 0 && (
                <TR>
                  <TD className="text-left">
                    <TableCellOverflowEllipses content={t('forms.completionDate.title.short')} />
                  </TD>
                  <TD className="text-left">
                    <TableCellOverflowEllipses content={`${toDisplayDateString(tableFormItem.columns[0].text)}`} />
                  </TD>
                </TR>
              )}
              {index !== 0 && (
                <TR>
                  <TD className="text-left">
                    <TableCellOverflowEllipses content={`${index}. ${tableFormItem.columns[1].text}`} />
                  </TD>
                  <TD className="text-left">
                    <TableCellOverflowEllipses content={`${toDisplayDateString(tableFormItem.columns[2].text)}`} />
                  </TD>
                </TR>
              )}
            </Fragment>
          ))}
        </TBody>
      </Table>
    </div>
  );
};

export default TileCompletionDateDesktopScreen;
