import * as React from 'react';

import './BoardGrid.scss';

interface Props {
  boardType: string;
}

const BoardGrid: React.FC<Props> = ({ boardType, children }) => (
  <section className={`board-grid board-grid--${boardType.toLowerCase()}`}>{children}</section>
);

export default BoardGrid;
