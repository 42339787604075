import * as React from 'react';
import { useTranslation } from 'react-i18next';

import TR from '../../../components/Table/TR/TR';
import TD from '../../../components/Table/TD/TD';
import TileProcessConfirmationDescriptionItem from './TileProcessConfirmationDescriptionItem';

const TileProcessConfirmationDescription: React.FC<any> = () => {
  const { t } = useTranslation();
  return (
    <TR className="tile-process-confirmation__description">
      <TD className="tile-process-confirmation__description-item">{t('forms.processConfirmation.tableHeader.description.title')}</TD>
      <TileProcessConfirmationDescriptionItem success={{ suffix: 'fulfilled' }} warning={{ suffix: 'notFulfilled' }} />
      <TileProcessConfirmationDescriptionItem
        success={{ suffix: 'fulfilled' }}
        warning={{ suffix: 'notFulfilled' }}
        light={{ suffix: 'stillOpen' }}
      />
      <TileProcessConfirmationDescriptionItem
        success={{ suffix: 'fulfilled' }}
        warning={{ suffix: 'notFulfilled' }}
        light={{ suffix: 'stillOpen' }}
      />
      <TileProcessConfirmationDescriptionItem
        success={{ suffix: 'fulfilled' }}
        warning={{ suffix: 'notFulfilled' }}
        light={{ suffix: 'stillOpen' }}
      />
      <TileProcessConfirmationDescriptionItem
        success={{ suffix: 'yes' }}
        warning={{ suffix: 'no' }}
        light={{ suffix: 'noPlanningOrder', size: 'sm' }}
      />
      <TileProcessConfirmationDescriptionItem success={{ suffix: 'yes' }} warning={{ suffix: 'no' }} light={{ suffix: 'stillOpen' }} />
      <TileProcessConfirmationDescriptionItem
        success={{ suffix: 'fulfilled' }}
        warning={{ suffix: 'notFulfilled' }}
        light={{ suffix: 'stillOpen' }}
      />
      <TileProcessConfirmationDescriptionItem
        success={{ suffix: 'fulfilled' }}
        warning={{ suffix: 'notFulfilled' }}
        light={{ suffix: 'stillOpen' }}
      />
      <TileProcessConfirmationDescriptionItem
        success={{ suffix: 'fulfilled' }}
        warning={{ suffix: 'notFulfilled' }}
        light={{ suffix: 'stillOpen' }}
      />
    </TR>
  );
};

export default TileProcessConfirmationDescription;
