import * as React from 'react';
import { Color } from '../../core/enums/Color';

import './Background.scss';

interface Props {
  color?: Color;
  className?: string;
}

const Background: React.FC<Props> = ({ children, color = 'grey', className = '' }) => (
  <div className={`background${color ? ` background--${color}` : ''} ${className || ''}`}>{children}</div>
);

export default Background;
