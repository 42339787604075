import { createAction } from '../../utils/action';
import ActionTypes from '../actionTypes';
import { ActionsUnion } from '../types';
import { RestErrorResponse } from '../../types/response/RestErrorResponse';
import { ProcessConfirmationForm } from './types';

export const Actions = {
  getProcessConfirmationForm: () => createAction(ActionTypes.GET_PROCESS_CONFIRMATION_FORM),
  getProcessConfirmationFormSuccess: (data: ProcessConfirmationForm) => createAction(ActionTypes.GET_PROCESS_CONFIRMATION_FORM_SUCCESS, data),
  getProcessConfirmationFormError: (error: RestErrorResponse) => createAction(ActionTypes.GET_PROCESS_CONFIRMATION_FORM_ERROR, error),

  updateProcessConfirmationForm: (list: ProcessConfirmationForm) => createAction(ActionTypes.UPDATE_PROCESS_CONFIRMATION_FORM, list),

  saveProcessConfirmationForm: () => createAction(ActionTypes.SAVE_PROCESS_CONFIRMATION_FORM),
  saveProcessConfirmationFormSuccess: (data: ProcessConfirmationForm) => createAction(ActionTypes.SAVE_PROCESS_CONFIRMATION_FORM_SUCCESS, data),
  saveProcessConfirmationFormError: (error: RestErrorResponse) => createAction(ActionTypes.SAVE_PROCESS_CONFIRMATION_FORM_ERROR, error)
};

export type ProcessConfirmationActions = ActionsUnion<typeof Actions>;
