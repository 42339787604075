import React, { useCallback } from 'react';
import { TableFormFieldStatus } from '../../../core/enums/TableFormFieldStatus';

import './TableStatus.scss';

interface Props {
  type?: 'square' | 'circle';
  className?: string;
  row: string | number;
  column: string | number;
  statuses: TableFormFieldStatus[];
  useTableValue: (row: string | number, column: string | number) => any[]; // () => [T, (value: T) => void]
  disabled?: boolean;
  isSmall?: boolean;
}

const TableStatus: React.FC<Props> = ({ type = 'square', row, column, statuses, useTableValue, isSmall = false, disabled, className = '' }) => {
  const [value, saveValue] = useTableValue(row, column);

  const getNextStatus = useCallback(
    (status) => {
      const index = statuses.indexOf(status);
      if (index === statuses.length - 1) {
        return statuses[0];
      }
      return statuses[index + 1];
    },
    [statuses]
  );

  const handleChange = useCallback(() => {
    saveValue(getNextStatus(value));
  }, [getNextStatus, saveValue, value]);

  return (
    <button
      className={`table-status table-status--${type} table-status--${String(value).toLowerCase()} ${isSmall ? 'table-status--sm' : ''} ${className}`}
      aria-label={`Status ${value}`}
      tabIndex={0}
      onClick={handleChange}
      onKeyDown={handleChange}
      disabled={disabled}
    />
  );
};

export default TableStatus;
