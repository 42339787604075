export enum TableTileTypes {
  DefectProcessing = 'defectProcessing',
  DefectProcessingNU = 'defectProcessingNU',
  TrackingAAZ = 'trackingAAZ',
  PlanningQuality = 'planningQuality',
  AdditionalServicesNU = 'additionalServicesNU',
  Obstacles = 'obstacles',
  InspectionPlansQAList = 'inspectionPlansQAList',
  DeadlineCompliance = 'deadlineCompliance',
  Planning = 'planning',
  Announcement = 'announcement',
  Sampling = 'sampling',
  PlannerRisks = 'plannerRisks',
  PlanApprovalCustomer = 'planApprovalCustomer',
  OrderPlacement = 'orderPlacement',
  InspectionPlans = 'inspectionPlans',
  PerformanceNU = 'performanceNU',
  CheckpointsMasterPlan = 'checkpointsMasterPlan'
}
