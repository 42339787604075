import React from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { RootState } from '../../core/state/state';
import { TileTypes } from '../../core/enums/TileTypes';
import { CheckboxList } from '../../core/state/checkbox-list/types';
import { EMPTY_STRING } from '../../core/constants/string';
import { Actions as SettingsActions } from '../../core/state/settings/actions';

import BoardHeader from '../components/Board/BoardHeader/BoardHeader';

import TilePDF from '../../tiles/TilePDF/TilePDF';
import TileAttendanceList from '../../tiles/TileAttendanceList/TileAttendanceList';
import TileVacationList from '../../tiles/TileVacationList/TileVacationList';
import TileTidinessList from '../../tiles/TileTidinessList/TileTidinessList';
import TilePresenceList from '../../tiles/TilePresenceList/TilePresenceList';
import TileConfirmationList from '../../tiles/TileConfirmationList/TileConfirmationList';
import TileEscalation from '../../tiles/TileEscalation/TileEscalation';
import TilePreview from '../../tiles/TilePreview/TilePreview';
import TileMeasure from '../../tiles/TileMeasure/TileMeasure';
import TileWeekTopics from '../../tiles/TileWeekTopics/TileWeekTopics';
import TilePowerBI from '../../tiles/TilePowerBI/TilePowerBI';
import TileProcessConfirmation from '../../tiles/TileProcessConfirmation/TileProcessConfirmation';
import TileGoalSetting from '../../tiles/TileGoalSetting/TileGoalSetting';
import TileSickLeave from '../../tiles/TileSickLeave/TileSickLeave';
import TileStandingRules from '../../tiles/TileStandingRules/TileStandingRules';
import TileProjectAreaOverview from '../../tiles/TileProjectAreaOverview/TileProjectAreaOverview';
import TileProjectOverview from '../../tiles/TileProjectOverview/TileProjectOverview';
import TileMeasuresEscalation from '../../tiles/TileMeasuresEscalation/TileMeasuresEscalation';
import TileRequiredMaterial from '../../tiles/TileRequiredMaterial/TileRequiredMaterial';
import TileToDo from '../../tiles/TileToDo/TileToDo';
import TileDefectProcessing from '../../tiles/TileDefectProcessing/TileDefectProcessing';
import TileAdditionalServicesNU from '../../tiles/TileAdditionalServicesNU/TileAdditionalServicesNU';
import TileTrackingAAZ from '../../tiles/TileTrackingAAZ/TileTrackingAAZ';
import TilePlanningQuality from '../../tiles/TilePlanningQuality/TilePlanningQuality';
import TileCompletionDateOrderAmount from '../../tiles/TileCompletionDateOrderAmount/TileCompletionDateOrderAmount';
import TilePlannerRisks from '../../tiles/TilePlannerRisks/TilePlannerRisks';
import TilePerformanceNU from '../../tiles/TilePerformanceNU/TilePerformanceNU';
import TilePlanApprovalCustomer from '../../tiles/TilePlanApprovalCustomer/TilePlanApprovalCustomer';
import TilePlanning from '../../tiles/TilePlanning/TilePlanning';
import TileObstacles from '../../tiles/TileObstacles/TileObstacles';
import TileSampling from '../../tiles/TileSampling/TileSampling';
import TileAnnouncement from '../../tiles/TileAnnouncement/TileAnnouncement';
import TileInspectionPlansQA from '../../tiles/TileInspectionPlansQA/TileInspectionPlansQA';
import TileDeadlineCompliance from '../../tiles/TileDeadlineCompliance/TileDeadlineCompliance';
import TileOrderPlacement from '../../tiles/TileOrderPlacement/TileOrderPlacement';
import TileDefectProcessingNU from '../../tiles/TileDefectProcessingNU/TileDefectProcessingNU';
import TileInspectionPlans from '../../tiles/TileInspectionPlans/TileInspectionPlans';
import TileOccupationalSafety from '../../tiles/TileOccupationalSafety/TileOccupationalSafety';
import TileSupplySchedule from '../../tiles/TileSupplySchedule/TileSupplySchedule';
import TileCapacitySubcontractor from '../../tiles/TileCapacitySubcontractor/TileCapacitySubcontractor';
import TileCheckpointsMasterPlan from '../../tiles/TileCheckpointMasterPlan/TileCheckpointsMasterPlan';
import TileProPlan from '../../tiles/TileProPlan/TileProPlan';

import './TileDetail.scss';

interface RootParams {
  tileId: string;
  id: string;
}

const getTileDetailType = (boardId: number, type: TileTypes, url: string) => {
  switch (type) {
    case TileTypes.Pdf:
      return <TilePDF url={url} />;
    case TileTypes.ProPlan:
      return <TileProPlan url={url} />;
    case TileTypes.AttendanceForm:
      return <TileAttendanceList url={url} boardId={boardId} />;
    case TileTypes.VacationForm:
      return <TileVacationList url={url} boardId={boardId} />;
    case TileTypes.TidinessForm:
      return <TileTidinessList url={url} boardId={boardId} />;
    case TileTypes.PresenceForm:
      return <TilePresenceList url={url} boardId={boardId} />;
    case TileTypes.ConfirmationForm:
      return <TileConfirmationList url={url} boardId={boardId} />;
    case TileTypes.EscalationFrom:
      return <TileEscalation url={url} boardId={boardId} />;
    case TileTypes.ExternalApp:
      return <TilePreview url={url} />;
    case TileTypes.KVPForm:
      return <TileMeasure url={url} boardId={boardId} />;
    case TileTypes.WeeklyThemeForm:
      return <TileWeekTopics url={url} boardId={boardId} />;
    case TileTypes.PowerBI:
      return <TilePowerBI url={url} />;
    case TileTypes.ProcessConfirmation:
      return <TileProcessConfirmation url={url} boardId={boardId} />;
    case TileTypes.Goals:
      return <TileGoalSetting url={url} boardId={boardId} />;
    case TileTypes.Accidents:
      return <TileSickLeave url={url} boardId={boardId} />;
    case TileTypes.RulesOfStanding:
      return <TileStandingRules url={url} boardId={boardId} />;
    case TileTypes.ProjectAreaOverview:
      return <TileProjectAreaOverview url={url} />;
    case TileTypes.ProjectOverviewForm:
      return <TileProjectOverview url={url} />;
    case TileTypes.MeasuresAndEscalation:
      return <TileMeasuresEscalation url={url} boardId={boardId} />;
    case TileTypes.RequiredMaterial:
      return <TileRequiredMaterial url={url} boardId={boardId} />;
    case TileTypes.ToDoShortTerm:
      return <TileToDo url={url} boardId={boardId} />;
    case TileTypes.DefectProcessing:
      return <TileDefectProcessing url={url} boardId={boardId} />;
    case TileTypes.TrackingAAZ:
      return <TileTrackingAAZ url={url} boardId={boardId} />;
    case TileTypes.PlanningQuality:
      return <TilePlanningQuality url={url} boardId={boardId} />;
    case TileTypes.AdditionalServicesNU:
      return <TileAdditionalServicesNU url={url} boardId={boardId} />;
    case TileTypes.CompletionDateOrderAmount:
      return <TileCompletionDateOrderAmount url={url} boardId={boardId} />;
    case TileTypes.PlannerRisks:
      return <TilePlannerRisks url={url} boardId={boardId} />;
    case TileTypes.PerformanceNU:
      return <TilePerformanceNU url={url} boardId={boardId} />;
    case TileTypes.Obstacles:
      return <TileObstacles url={url} boardId={boardId} />;
    case TileTypes.PlanApprovalCustomer:
      return <TilePlanApprovalCustomer url={url} boardId={boardId} />;
    case TileTypes.Planning:
      return <TilePlanning url={url} boardId={boardId} />;
    case TileTypes.Sampling:
      return <TileSampling url={url} boardId={boardId} />;
    case TileTypes.Announcement:
      return <TileAnnouncement url={url} boardId={boardId} />;
    case TileTypes.InspectionPlansQAList:
      return <TileInspectionPlansQA url={url} boardId={boardId} />;
    case TileTypes.DeadlineCompliance:
      return <TileDeadlineCompliance url={url} boardId={boardId} />;
    case TileTypes.OrderPlacement:
      return <TileOrderPlacement url={url} boardId={boardId} />;
    case TileTypes.DefectProcessingNU:
      return <TileDefectProcessingNU url={url} boardId={boardId} />;
    case TileTypes.InspectionPlans:
      return <TileInspectionPlans url={url} boardId={boardId} />;
    case TileTypes.OccupationalSafety:
      return <TileOccupationalSafety url={url} boardId={boardId} />;
    case TileTypes.SupplySchedule:
      return <TileSupplySchedule url={url} boardId={boardId} />;
    case TileTypes.CapacitySubcontractor:
      return <TileCapacitySubcontractor url={url} boardId={boardId} />;
    case TileTypes.CheckpointsMasterPlan:
      return <TileCheckpointsMasterPlan url={url} boardId={boardId} />;
    default:
      return <></>;
  }
};

const TileDetail = () => {
  const { id } = useParams<RootParams>();
  const location = useLocation();
  const dispatch = useDispatch();

  const query = new URLSearchParams(location.search);
  const { isFormDirty, isFormEditing } = useSelector((state: RootState) => state.settings);
  const tileType = query.get('type') as TileTypes;
  const tileUrl = query.get('url');
  const tileUrlDecoded = tileUrl ? decodeURIComponent(tileUrl) : '';
  const attendanceState = useSelector((state: RootState) => state.attendance.list) as CheckboxList;
  const vacationState = useSelector((state: RootState) => state.vacation.list) as CheckboxList;
  const kvpTitle = useSelector((state: RootState) => state.tableForm.kvpForm.form?.title);
  const weeklyThemeTitle = useSelector((state: RootState) => state.tableForm.weekTopicsForm.form?.title);
  const escalationTitle = useSelector((state: RootState) => state.tableForm.form.form?.title);
  const processConfirmationTitle = useSelector((state: RootState) => state.processConfirmation.processConfirmationForm?.title);

  const boardId = Number(id);
  const board = useSelector((state: RootState) => {
    if (state.boards.board?.boardId === boardId) {
      return state.boards.board;
    }
    if (state.boards.boardDetails?.boardId === boardId) {
      return state.boards.boardDetails;
    }
    return null;
  });

  const getTileTitle = (type: TileTypes) => {
    switch (type) {
      case TileTypes.AttendanceForm:
        return attendanceState.title;
      case TileTypes.VacationForm:
        return vacationState.title;
      case TileTypes.EscalationFrom:
        return escalationTitle;
      case TileTypes.KVPForm:
        return kvpTitle;
      case TileTypes.WeeklyThemeForm:
        return weeklyThemeTitle;
      case TileTypes.ProcessConfirmation:
        return processConfirmationTitle;
      default:
        return board?.name || EMPTY_STRING;
    }
  };

  React.useEffect(() => {
    if (isFormDirty) {
      dispatch(SettingsActions.setIsFormDirty(false));
    }
    if (isFormEditing) {
      dispatch(SettingsActions.setIsFormEditing(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <BoardHeader boardId={boardId} text={getTileTitle(tileType)} boardType={board?.type} />
      <div className="tile-detail">
        <div className="tile-detail__content">{getTileDetailType(boardId, tileType, tileUrlDecoded)}</div>
      </div>
    </>
  );
};

export default TileDetail;
