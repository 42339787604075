import { Breadcrumb } from './findMenuListById';
import { convertRemToPixels } from './convertRemToPixels';
import { REM_WIDTH } from '../../constants/remWidth';

export function calcWidthBreadcrumbs(breadcrumbNav: HTMLDivElement, breadcrumbArray: Breadcrumb[]) {
  if (!breadcrumbArray?.length) {
    return [];
  }

  const MIN_WIDTH = convertRemToPixels(REM_WIDTH);

  const breadcrumb = breadcrumbNav?.querySelector('.breadcrumb');
  const breadcrumbInner = breadcrumbNav?.querySelector('.breadcrumb-navigation__nav');
  const clonedBreadcrumb = breadcrumb?.cloneNode(true) as HTMLElement;

  if (!clonedBreadcrumb) {
    return breadcrumbArray;
  }

  let newBreadcrumbArray = [...breadcrumbArray];

  breadcrumbInner?.appendChild(clonedBreadcrumb);
  clonedBreadcrumb?.style.setProperty('visibility', 'hidden');

  const childrenArray = Array.from(clonedBreadcrumb?.children as HTMLCollectionOf<HTMLElement>);

  childrenArray.forEach((item) => {
    item.style.removeProperty('width');
  });

  const breadcrumbNavWidth = breadcrumbNav?.getBoundingClientRect().width as number;
  const breadcrumbFullWidth = clonedBreadcrumb?.getBoundingClientRect().width as number;

  if (breadcrumbNavWidth < breadcrumbFullWidth) {
    let widthDifference = breadcrumbFullWidth - breadcrumbNavWidth;

    childrenArray
      .filter((item) => item.className === 'breadcrumb-item')
      .forEach((item, index) => {
        if (!newBreadcrumbArray[index]) {
          return;
        }

        const itemWidth = item.getBoundingClientRect().width;
        if (widthDifference > 0) {
          if (itemWidth < MIN_WIDTH + widthDifference) {
            newBreadcrumbArray[index].width = MIN_WIDTH;
            widthDifference -= itemWidth - MIN_WIDTH;
          } else {
            newBreadcrumbArray[index].width = itemWidth - widthDifference;
            widthDifference = 0;
          }
        } else {
          newBreadcrumbArray[index].width = -1;
        }
      });
  } else {
    newBreadcrumbArray = newBreadcrumbArray.map((item) => {
      return {
        ...item,
        width: -1
      };
    });
  }

  clonedBreadcrumb.remove();

  return newBreadcrumbArray;
}
