import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd';
import { cloneDeep, maxBy, orderBy } from 'lodash';
import { RootState } from '../../../../../core/state/state';
import Button from '../../../../../components/Button/Button';
import Table from '../../../../../components/Table/Table';
import THead from '../../../../../components/Table/THead/THead';
import TR from '../../../../../components/Table/TR/TR';
import TH from '../../../../../components/Table/TH/TH';
import TD from '../../../../../components/Table/TD/TD';
import TableInput from '../../../../../components/form/TableInput/TableInput';
import ToggleSwitch from '../../../../../components/form/ToggleSwitch/ToggleSwitch';
import UnsavedChangesPopup from '../../../../../components/popup/UnsavedChangesPopup/UnsavedChangesPopup';
import { history } from '../../../../../core/state/history';
import { BOARDS } from '../../../../../core/constants/routes';
import { blurActiveElement } from '../../../../../core/utils/blurActiveElement';
import { CapacitySubcontractors } from '../../../../../core/state/back-office/types';
import {
  getCapacitySubcontractors,
  saveCapacitySubcontractors,
  updateCapacitySubcontractors
} from '../../../../../core/state/back-office/asyncActions';
import { cleanStringIds } from '../../../../../core/utils/cleanStringIds';
import { isDesktopScreen } from '../../../../../core/utils/isDesktopScreen';

import './BackOfficeCapacitySubcontractors.scss';

interface Props {
  url: string;
  boardId: number;
}

export type CapacitySubcontractorsKeys = keyof CapacitySubcontractors;

interface SelectedItem {
  rowIndex: number;
  text: string | null;
  key: CapacitySubcontractorsKeys | null;
}

const BackOfficeCapacitySubcontractors: React.FC<Props> = ({ url, boardId }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const desktopScreen = isDesktopScreen();
  const { isFormDirty, isFormEditing } = useSelector((state: RootState) => state.settings);
  const capacitySubcontractors = useSelector((state: RootState) => state.backOffice.capacitySubcontractors);

  const [selectedItem, setSelectedItem] = React.useState<SelectedItem>({
    rowIndex: -1,
    text: null,
    key: null
  });

  React.useEffect(() => {
    dispatch(getCapacitySubcontractors(url));
  }, [dispatch, url]);

  const handleSubmit = async () => {
    let newCapacitySubcontractors = cloneDeep(capacitySubcontractors);
    if (newCapacitySubcontractors) {
      newCapacitySubcontractors = cleanStringIds(newCapacitySubcontractors) as CapacitySubcontractors[];
      await dispatch(saveCapacitySubcontractors(url, newCapacitySubcontractors));
      history.push(`${BOARDS}/${boardId}/back-office`);
    }
  };

  const handleAddNewRow = () => {
    const newCapacitySubcontractors = cloneDeep(capacitySubcontractors);

    if (newCapacitySubcontractors) {
      const maxIdContractor = maxBy(newCapacitySubcontractors, (i) => {
        return Number(i.id);
      })?.id;
      const newCapacitySubcontractor: CapacitySubcontractors = {
        measure: '',
        subcontractor: '',
        id: String(Number(maxIdContractor) + 1),
        isEnabled: true,
        order: newCapacitySubcontractors.length
      };
      newCapacitySubcontractors.push(newCapacitySubcontractor);
      dispatch(updateCapacitySubcontractors(newCapacitySubcontractors));
    }
  };

  const handleOnClick = (key: CapacitySubcontractorsKeys | null, index: number, text: string | null) => {
    setSelectedItem({
      key,
      rowIndex: index,
      text
    });
  };

  const handleBlur = () => {
    const newCapacitySubcontractors = cloneDeep(capacitySubcontractors);
    const { rowIndex, key, text } = selectedItem;

    if (newCapacitySubcontractors && newCapacitySubcontractors.length > 0 && rowIndex !== -1 && key !== null && text !== null) {
      (newCapacitySubcontractors[rowIndex][key] as any) = text;
      dispatch(updateCapacitySubcontractors(newCapacitySubcontractors));
    }
    setSelectedItem({
      text: null,
      key: null,
      rowIndex: -1
    });
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedItem({
      ...selectedItem,
      text: e.target.value
    });
  };

  const onDragEnd = (e: DropResult) => {
    const clonedCapacitySubcontractors = cloneDeep(capacitySubcontractors);
    const sourceIndex = e.source.index;
    const destinationIndex = e.destination?.index;

    blurActiveElement();

    if (clonedCapacitySubcontractors && destinationIndex !== undefined) {
      const newCapacitySubcontractors = Array.from(clonedCapacitySubcontractors);
      const [removedAreaProject] = newCapacitySubcontractors.splice(sourceIndex, 1);

      newCapacitySubcontractors.splice(destinationIndex, 0, removedAreaProject);

      const reorderedCapacitySubcontractors = newCapacitySubcontractors.map((item, index) => {
        const newItems = cloneDeep(item);
        newItems.order = index;
        return newItems;
      });

      dispatch(updateCapacitySubcontractors(reorderedCapacitySubcontractors));
    }
  };

  const toggleChange = (index: number, isEnabled: boolean) => {
    const newCapacitySubcontractors = cloneDeep(capacitySubcontractors);

    if (newCapacitySubcontractors) {
      newCapacitySubcontractors[index].isEnabled = isEnabled;
      dispatch(updateCapacitySubcontractors(newCapacitySubcontractors));
    }
  };

  const onDragStart = () => {
    blurActiveElement();
  };

  return (
    <div className={`back-office-capacity-subcontractors ${desktopScreen ? 'no-font-scaling' : ''}`}>
      <div className="d-flex justify-content-end align-items-center mx-1 my-4">
        <div className="d-flex justify-content-end align-items-end">
          <Button
            disabled={capacitySubcontractors !== null && capacitySubcontractors.length > 100}
            buttonTypes={['primary', 'big']}
            className="ml-3"
            onClick={handleAddNewRow}
          >
            {t('buttons.addNewRow')}
          </Button>
          <Button buttonTypes={['primary', 'big']} className="ml-3" onClick={handleSubmit}>
            {t('buttons.save')}
          </Button>
        </div>
      </div>
      <DragDropContext onDragEnd={onDragEnd} onDragStart={onDragStart}>
        <Table type="dnd">
          <THead>
            <TR>
              <TH className="text-center">{t('sharedText.number')}</TH>
              <TH>{t('backOffice.capacitySubcontractors.subcontractor')}</TH>
              <TH>{t('backOffice.capacitySubcontractors.measures')}</TH>
              <TH className="text-center">{t('sharedText.show')}</TH>
            </TR>
          </THead>
          <Droppable droppableId="droppable-1">
            {(dropProvided: any) => (
              // eslint-disable-next-line react/jsx-props-no-spreading
              <tbody ref={dropProvided.innerRef} {...dropProvided.droppableProps}>
                {orderBy(capacitySubcontractors, ['order'])?.map((capacitySubcontractor, index) => {
                  return (
                    <Draggable key={capacitySubcontractor.id} draggableId={capacitySubcontractor.id.toString()} index={index}>
                      {(provided, snapshot) => (
                        <tr
                          className={`${snapshot.isDragging ? 'table__row-drag' : ''}`}
                          key={capacitySubcontractor.id}
                          // eslint-disable-next-line react/jsx-props-no-spreading
                          {...provided.draggableProps}
                          // eslint-disable-next-line react/jsx-props-no-spreading
                          {...provided.dragHandleProps}
                          ref={provided.innerRef}
                        >
                          <TD className="text-center">{index + 1}</TD>
                          <TD>
                            <TableInput
                              onClick={async () => {
                                handleOnClick('subcontractor', index, capacitySubcontractor.subcontractor);
                              }}
                              onBlur={handleBlur}
                              onChange={handleInputChange}
                              value={
                                selectedItem.rowIndex === index && selectedItem.key === 'subcontractor'
                                  ? selectedItem.text
                                  : capacitySubcontractor.subcontractor
                              }
                            />
                          </TD>
                          <TD>
                            <TableInput isText value={capacitySubcontractor.measure} />
                          </TD>
                          <TD className="text-center">
                            <ToggleSwitch
                              id={capacitySubcontractor.id.toString()}
                              checked={capacitySubcontractor.isEnabled}
                              onChange={(isEnabled) => toggleChange(index, isEnabled)}
                            />
                            {capacitySubcontractor.isEnabled}
                          </TD>
                        </tr>
                      )}
                    </Draggable>
                  );
                })}
                {dropProvided.placeholder}
              </tbody>
            )}
          </Droppable>
        </Table>
      </DragDropContext>
      {capacitySubcontractors && capacitySubcontractors.length === 0 && (
        <div className="back-office-capacity-subcontractors__text-empty">{t('backOffice.capacitySubcontractors.nosubcontractors')}</div>
      )}
      <UnsavedChangesPopup showIf={isFormDirty || isFormEditing} />
    </div>
  );
};

export default BackOfficeCapacitySubcontractors;
