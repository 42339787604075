import * as React from 'react';
import { useTranslation } from 'react-i18next';

import SectionHeader from '../../components/SectionHeader/SectionHeader';
import { Color } from '../../../core/enums/Color';

const PLBoardHeaderFirst = () => {
  const { t } = useTranslation();
  return (
    <>
      <div className="col-5">
        <SectionHeader text={t('mainBoard.title.commonGoal')} />
      </div>
      <div className="col-5">
        <SectionHeader text={t('mainBoard.title.general')} />
      </div>
      <div className="col-5">
        <SectionHeader text={t('mainBoard.title.workSafety')} color={Color.GreenLight} />
      </div>
      <div className="col-5">
        <SectionHeader text={t('mainBoard.title.planning')} color={Color.GreenLight} />
      </div>
      <div className="col-5">
        <SectionHeader text={t('mainBoard.title.scheduleControl')} color={Color.GreenLight} />
      </div>
    </>
  );
};

export default PLBoardHeaderFirst;
