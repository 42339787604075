import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { match } from 'react-router-dom';
import TileLegendBox from '../CheckboxListTile/TileLegendBox';

interface Props {
  isDetailPage: match | null;
}

const TileTidinessListLegend: React.FC<Props> = ({ isDetailPage }) => {
  const { t } = useTranslation();
  return (
    <TileLegendBox
      className={`${isDetailPage ? 'my-3 ' : 'mb-1 mt-0 flex-column justify-conten-end align-items-end'}`}
      items={[
        {
          color: 'black',
          text: t('forms.tidinessList.legend.black')
        },
        {
          color: 'red',
          text: t('forms.tidinessList.legend.red')
        }
      ]}
    />
  );
};

export default TileTidinessListLegend;
