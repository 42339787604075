import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  addDays,
  addMinutes,
  addWeeks,
  addYears,
  format,
  getDate,
  getHours,
  getMonth,
  getWeek,
  getYear,
  isAfter,
  isBefore,
  isMonday,
  nextMonday,
  set,
  startOfISOWeek,
  subDays
} from 'date-fns';
import { cloneDeep } from 'lodash';

import { BOARDS, BOARDS_TILE_DETAIL } from '../../core/constants/routes';
import { RootState } from '../../core/state/state';
import { isDesktopScreen } from '../../core/utils/isDesktopScreen';
import UnsavedChangesPopup from '../../components/popup/UnsavedChangesPopup/UnsavedChangesPopup';
import TD from '../../components/Table/TD/TD';
import TR from '../../components/Table/TR/TR';
import Table from '../../components/Table/Table';
import THead from '../../components/Table/THead/THead';
import TH from '../../components/Table/TH/TH';
import { toDisplayTimeString, toISODateTimeString } from '../../core/utils/formatDate';
import { generateAsyncActions } from '../../core/utils/state/generateAsyncActions';
import { TileTypes } from '../../core/enums/TileTypes';
import { TileService } from '../../core/utils/state/TileService';
import TextArea from '../../components/form/TextArea/TextArea';
import { SSCellStatus, SSData, SupplySchedule } from '../../core/state/supply-schedule/types';
import { history } from '../../core/state/history';
import TableFormToolbar from '../TableFormTile/TableFormToolbar/TableFormToolbar';
import TileSupplyScheduleInput from './TileSupplyScheduleInput';

import './TileSupplySchedule.scss';

interface Props {
  url: string;
  boardId?: number;
}

const NO_WEEK_DAYS = 6;

export const resetTime = (date: Date) => {
  return set(date, { milliseconds: 0, seconds: 0, minutes: 0, hours: 0 });
};

export const getMondayOfTheWeek = () => {
  let date = new Date();
  if (isMonday(date)) {
    return resetTime(date);
  }
  while (!isMonday(date)) {
    date = subDays(date, 1);
  }
  return resetTime(date);
};

export const getWeekNumber = (d: Date) => {
  const first = set(new Date(), { year: d.getFullYear(), month: 0, date: 1 });
  const firstMonday = isMonday(first) ? first : nextMonday(first);
  const firstWeekContainsDate = getDate(firstMonday) as 1 | 2 | 3 | 4 | 5 | 6 | 7;
  return getWeek(d, { weekStartsOn: 1, firstWeekContainsDate });
};

export const useDateTitle = (date: Date) => {
  const { t } = useTranslation();
  return `${format(date, 'MMMM yyyy')}, ${t('forms.supplySchedule.calendarWeek')} ${getWeekNumber(date)}`;
};

export const getNameOfTheWeekDay = (date: Date) => date.toLocaleString('de-DE', { weekday: 'short' });

const TileSupplySchedule: React.FC<Props> = ({ url, boardId }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const isDetailPage = useRouteMatch(BOARDS_TILE_DETAIL);
  const form = useSelector((state: RootState) => state.supplySchedule.form);

  const { isFormDirty, isFormEditing } = useSelector((state: RootState) => state.settings);
  const [date, setDate] = React.useState(getMondayOfTheWeek());

  const { getTileForm, updateTileForm, saveTileForm } = React.useMemo(
    () => generateAsyncActions<SupplySchedule>(TileTypes.SupplySchedule, new TileService()),
    []
  );

  const dateTitle = useDateTitle(date);

  React.useEffect(() => {
    dispatch(getTileForm(url));
  }, [url, dispatch, getTileForm]);

  const clearAllFields = () => {
    const dateBeforeWeek = subDays(date, 1);
    const dateAfterWeek = addDays(date, 6);
    const newForm = cloneDeep(form);
    if (newForm) {
      newForm.data = newForm.data.filter((dataItem) => {
        const currentDate = new Date(dataItem.date);
        return !(isBefore(currentDate, dateAfterWeek) && isAfter(currentDate, dateBeforeWeek));
      });
      dispatch(updateTileForm(newForm));
    }
  };

  const handleSubmit = useCallback(async () => {
    if (form) {
      await dispatch(saveTileForm(url, form));
      history.push(`${BOARDS}/${boardId}`);
    }
  }, [dispatch, form, url, boardId, saveTileForm]);

  const handleNextWeek = () => {
    setDate(addWeeks(date, 1));
  };

  const handlePrevWeek = () => {
    setDate(addWeeks(date, -1));
  };

  const getDayOfWeek = (index: number) => {
    return addDays(startOfISOWeek(date), index - 1).getDate();
  };

  const getMonthNumber = (d: Date) => {
    return getMonth(d);
  };

  const changeStatus = (status: SSCellStatus) => {
    const statuses = [SSCellStatus.Undefined, SSCellStatus.Red, SSCellStatus.Green, SSCellStatus.Gray];
    const index = statuses.indexOf(status);
    if (index === statuses.length - 1) {
      return statuses[0];
    }
    return statuses[index + 1];
  };

  const clickOnStatus = (selectedDate: Date, dataIndex: number, status: SSCellStatus, statusPosition: 'statusDown' | 'statusTop') => {
    const newForm = cloneDeep(form);
    if (newForm) {
      if (dataIndex && newForm?.data[dataIndex]) {
        const data = newForm?.data;
        data[dataIndex][statusPosition] = changeStatus(status);
      } else {
        const newDataItem: SSData = {
          statusTop: SSCellStatus.Undefined,
          statusDown: SSCellStatus.Undefined,
          date: toISODateTimeString(selectedDate),
          text: null
        };
        newDataItem[statusPosition] = changeStatus(status);
        newForm?.data.push(newDataItem);
      }
      dispatch(updateTileForm(newForm));
    }
  };

  const onBlur = (selectedDate: Date, dataIndex: number, text: string) => {
    const newForm = cloneDeep(form);

    if (text.length > 30) {
      return;
    }

    if (newForm) {
      if (dataIndex && newForm?.data[dataIndex]) {
        const data = newForm?.data;
        data[dataIndex].text = text;
      } else {
        const newDataItem: SSData = {
          statusTop: SSCellStatus.Undefined,
          statusDown: SSCellStatus.Undefined,
          date: toISODateTimeString(selectedDate),
          text
        };
        newForm?.data.push(newDataItem);
      }
      dispatch(updateTileForm(newForm));
    }
  };

  const getAllRows = () => {
    let time = set(new Date(), { minutes: 0, hours: 6, seconds: 0 });
    const rows = [];
    const days = new Array(NO_WEEK_DAYS).fill(0);

    while (toDisplayTimeString(time) <= '17:00') {
      rows.push(
        <TR key={time.toString()}>
          <TD>
            <div className="d-flex justify-content-center text-grey">{toDisplayTimeString(time)}</div>
          </TD>
          {/* eslint-disable-next-line no-loop-func */}
          {days.map((column, index) => {
            const currentMonth = getMonthNumber(addDays(date, index));
            const currentDay = set(new Date(), {
              month: currentMonth,
              date: getDayOfWeek(index + 1),
              year: getYear(date),
              hours: getHours(time),
              seconds: 0,
              milliseconds: 0,
              minutes: 0
            });
            const dataIndex = form?.data.findIndex((item) => toISODateTimeString(item.date) === toISODateTimeString(currentDay)) ?? -1;
            const currentTimeSlot = form?.data[dataIndex];
            return (
              <TD key={currentDay.toISOString()} className="p-0 m-0">
                <div className="tile-supply-schedule__table-column">
                  <div className="tile-supply-schedule__table-status-wrap">
                    <button
                      onClick={() => clickOnStatus(currentDay, dataIndex, currentTimeSlot?.statusTop ?? SSCellStatus.Undefined, 'statusTop')}
                      className={`tile-supply-schedule__table-status tile-supply-schedule__table-status--${currentTimeSlot?.statusTop.toLowerCase()}`}
                    >
                      Status
                    </button>
                    <button
                      onClick={() => clickOnStatus(currentDay, dataIndex, currentTimeSlot?.statusDown ?? SSCellStatus.Undefined, 'statusDown')}
                      className={`tile-supply-schedule__table-status tile-supply-schedule__table-status--${currentTimeSlot?.statusDown.toLowerCase()}`}
                    >
                      Status
                    </button>
                  </div>
                  <div className="tile-supply-schedule__table-area">
                    <TextArea
                      defaultValue={currentTimeSlot?.text ?? ''}
                      id={currentTimeSlot?.date ?? ''}
                      onBlur={(e) => onBlur(currentDay, dataIndex, e.target.value)}
                      name={currentTimeSlot?.date ?? ''}
                      maxSize={30}
                      usingDefaultValue
                    />
                  </div>
                </div>
              </TD>
            );
          })}
        </TR>
      );
      time = addMinutes(time, 60);
    }

    return rows;
  };

  const tableClasses = () => {
    if (isDetailPage) {
      if (isDesktopScreen()) {
        return 'tile-supply-schedule__table-desktop-screen';
      }

      return 'tile-supply-schedule__table-tv-screen';
    }

    return 'table--small';
  };

  return (
    <div className={`tile-supply-schedule ${isDetailPage ? '' : 'tile-supply-schedule--small'}`}>
      <div className="tile-supply-schedule__content">
        <div className={`${isDetailPage ? 'container-fluid' : 'my-1'}`}>
          <div className={isDetailPage ? '' : 'px-1'}>
            {!isDetailPage && (
              <div className="d-flex justify-content-between">
                <div className="tile-supply-schedule__title">{t(`forms.supplySchedule.title`)}</div>
              </div>
            )}
            <div className="d-flex justify-content-between align-items-center">
              {isDetailPage && <TileSupplyScheduleInput />}
              {isDetailPage && (
                <TableFormToolbar
                  submit={handleSubmit}
                  clearAll={clearAllFields}
                  navButtons={{
                    left: { disabled: isAfter(new Date('2021-1-1'), date), onClick: handlePrevWeek },
                    right: { disabled: isBefore(addYears(new Date(), 1), date), onClick: handleNextWeek }
                  }}
                />
              )}
            </div>
            <div className="d-flex justify-content-between">
              <div className="tile-supply-schedule__info-text">{t(`forms.supplySchedule.subtitle`)}</div>
              {isDetailPage && (
                <div className="tile-supply-schedule__info-text tile-supply-schedule__info-text-title">{t(`forms.supplySchedule.info`)}</div>
              )}
            </div>
          </div>
          <div>
            <div className="tile-supply-schedule__info-wrap">
              <div className={`tile-supply-schedule__info-text-date ${isDetailPage ? '' : 'px-1'}`}>{dateTitle}</div>
            </div>
            <Table className={tableClasses()}>
              <THead>
                <TR>
                  <TH className="w-5">
                    <div className="d-flex flex-column align-items-center">
                      <div className="text-grey">{t(`forms.supplySchedule.time`)}</div>
                    </div>
                  </TH>
                  {new Array(NO_WEEK_DAYS).fill(0).map((item, index) => {
                    return (
                      // eslint-disable-next-line react/no-array-index-key
                      <TH key={index} className="w-16">
                        <div className="d-flex flex-column align-items-center">
                          <div className="tile-supply-schedule__day-name">{t(`forms.supplySchedule.day.${index + 1}`).substr(0, 2)}</div>
                          <div className="tile-supply-schedule__date">{getDayOfWeek(index + 1)}</div>
                        </div>
                      </TH>
                    );
                  })}
                </TR>
              </THead>
              <tbody>{getAllRows()}</tbody>
            </Table>
          </div>
        </div>
      </div>
      {isDetailPage && <UnsavedChangesPopup showIf={isFormDirty || isFormEditing} />}
    </div>
  );
};

export default TileSupplySchedule;
