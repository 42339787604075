import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { match } from 'react-router-dom';
import TileLegendBox from '../CheckboxListTile/TileLegendBox';

import './TileConfirmationList.scss';

interface Props {
  isDetailPage: match | null;
}

const TileAttendanceListLegend: React.FC<Props> = ({ isDetailPage }) => {
  const { t } = useTranslation();

  return (
    <div
      className={`tile-confirmation ${isDetailPage ? '' : 'tile-confirmation--small w-100'} d-flex justify-content-between align-items-start my-2`}
    >
      <div className="d-flex align-items-center ">
        <span className="tile-confirmation__info-text">{t('forms.confirmationList.subtitle')}</span>
      </div>
      <TileLegendBox
        className={`flex-column my-0 ${isDetailPage ? '' : 'mb-1 mt-0'}`}
        items={[
          {
            color: 'black',
            text: t('forms.confirmationList.legend.black')
          },
          {
            color: 'red',
            text: t('forms.confirmationList.legend.red')
          }
        ]}
      />
    </div>
  );
};

export default TileAttendanceListLegend;
