import { cloneDeep } from 'lodash';
import ActionTypes from '../actionTypes';
import Actions from '../Actions';
import { StateStatus } from '../../enums/StateStatus';
import { TableFormsState, TableFormState, TableFormStateNamespaces } from './types';
import actionNamespaceMap from './actionNameSpaceMap';
import { getActionTypeByName, getValueByKey } from '../helpers';

export const defaultTableFormState: TableFormState<any> = {
  form: null,
  status: StateStatus.Initial,
  error: null
};

export const defaultTableFormsState: TableFormsState = {
  form: {
    form: null,
    status: StateStatus.Initial,
    error: null
  },
  kvpForm: {
    form: null,
    status: StateStatus.Initial,
    error: null
  },
  sickLeaveForm: {
    form: null,
    status: StateStatus.Initial,
    error: null
  },
  projectOverviewForm: {
    form: null,
    status: StateStatus.Initial,
    error: null
  },
  goalsForm: {
    form: null,
    status: StateStatus.Initial,
    error: null
  },
  weekTopicsForm: {
    form: null,
    status: StateStatus.Initial,
    error: null
  },
  standingRulesForm: {
    form: null,
    status: StateStatus.Initial,
    error: null
  },
  measuresEscalationForm: {
    form: null,
    status: StateStatus.Initial,
    error: null
  }
};

export function generateTableFormReducer<T>(type: string) {
  return (state: TableFormState<T> = defaultTableFormState, action: Actions): TableFormState<T> => {
    switch (action.type) {
      case getActionTypeByName(`GET_${type}_TABLE_FORM`):
      case getActionTypeByName(`SAVE_${type}_TABLE_FORM`):
        return { ...state, status: StateStatus.Busy };
      case getActionTypeByName(`GET_${type}_TABLE_FORM_SUCCESS`):
        return { ...state, status: StateStatus.Loaded, form: getValueByKey(action, 'payload') };
      case getActionTypeByName(`SAVE_${type}_TABLE_FORM_SUCCESS`):
        return { ...state, status: StateStatus.Loaded };
      case getActionTypeByName(`UPDATE_${type}_TABLE_FORM`):
        return { ...state, form: getValueByKey(action, 'payload') };
      case getActionTypeByName(`GET_${type}_TABLE_FORM_ERROR`):
      case getActionTypeByName(`SAVE_${type}_TABLE_FORM_ERROR`):
        return { ...state, status: StateStatus.Error, error: getValueByKey(action, 'payload') };
      default:
        return state;
    }
  };
}

export default function reducer(state: TableFormsState = defaultTableFormsState, action: Actions): TableFormsState {
  const namespace = actionNamespaceMap[action.type] as TableFormStateNamespaces;
  const previousState = cloneDeep(state);

  switch (action.type) {
    case ActionTypes.GET_TABLE_FORM:
    case ActionTypes.GET_KVP_FORM:
    case ActionTypes.GET_PROJECT_OVERVIEW_FORM:
    case ActionTypes.GET_PROCESS_CONFIRMATION_FORM:
    case ActionTypes.GET_GOALS_FORM:
    case ActionTypes.GET_SICK_LEAVE_FORM:
    case ActionTypes.GET_WEEK_TOPICS:
    case ActionTypes.SAVE_WEEK_TOPICS:
    case ActionTypes.GET_STANDING_RULES:
    case ActionTypes.SAVE_STANDING_RULES:
    case ActionTypes.GET_MEASURES_AND_ESCALATIONS:
      return {
        ...previousState,
        [namespace]: {
          ...previousState[namespace],
          error: null,
          status: StateStatus.Busy
        }
      };

    case ActionTypes.GET_TABLE_FORM_SUCCESS:
    case ActionTypes.GET_KVP_FORM_SUCCESS:
    case ActionTypes.GET_PROJECT_OVERVIEW_FORM_SUCCESS:
    case ActionTypes.GET_PROCESS_CONFIRMATION_FORM_SUCCESS:
    case ActionTypes.GET_GOALS_FORM_SUCCESS:
    case ActionTypes.GET_SICK_LEAVE_FORM_SUCCESS:
    case ActionTypes.GET_WEEK_TOPICS_SUCCESS:
    case ActionTypes.GET_STANDING_RULES_SUCCESS:
    case ActionTypes.GET_MEASURES_AND_ESCALATIONS_SUCCESS:
      return {
        ...previousState,
        [namespace]: {
          form: action.payload,
          error: null,
          status: StateStatus.Loaded
        }
      };

    case ActionTypes.GET_TABLE_FORM_ERROR:
    case ActionTypes.GET_SICK_LEAVE_FORM_ERROR:
    case ActionTypes.GET_KVP_FORM_ERROR:
    case ActionTypes.GET_PROJECT_OVERVIEW_FORM_ERROR:
    case ActionTypes.GET_PROCESS_CONFIRMATION_FORM_ERROR:
    case ActionTypes.GET_GOALS_FORM_ERROR:
    case ActionTypes.GET_WEEK_TOPICS_ERROR:
    case ActionTypes.GET_STANDING_RULES_ERROR:
    case ActionTypes.SAVE_TABLE_FORM_ERROR:
    case ActionTypes.SAVE_GOALS_FORM_ERROR:
    case ActionTypes.SAVE_WEEK_TOPICS_ERROR:
    case ActionTypes.SAVE_SICK_LEAVE_FORM_ERROR:
    case ActionTypes.SAVE_KVP_FORM_ERROR:
    case ActionTypes.SAVE_PROJECT_OVERVIEW_FORM_ERROR:
    case ActionTypes.SAVE_PROCESS_CONFIRMATION_FORM_ERROR:
    case ActionTypes.SAVE_STANDING_RULES_ERROR:
    case ActionTypes.GET_MEASURES_AND_ESCALATIONS_ERROR:
      return {
        ...previousState,
        [namespace]: {
          ...previousState[namespace],
          error: action.payload,
          status: StateStatus.Error
        }
      };

    case ActionTypes.UPDATE_TABLE_FORM:
    case ActionTypes.UPDATE_KVP_FORM:
    case ActionTypes.UPDATE_MEASURES_AND_ESCALATIONS:
    case ActionTypes.UPDATE_PROCESS_CONFIRMATION_FORM:
    case ActionTypes.UPDATE_PROJECT_OVERVIEW_FORM:
    case ActionTypes.UPDATE_GOALS_FORM:
    case ActionTypes.UPDATE_SICK_LEAVE_FORM:
    case ActionTypes.UPDATE_WEEK_TOPICS:
    case ActionTypes.UPDATE_STANDING_RULES:
      return {
        ...previousState,
        [namespace]: {
          ...previousState[namespace],
          form: action.payload
        }
      };

    case ActionTypes.SAVE_SICK_LEAVE_FORM_SUCCESS:
    case ActionTypes.SAVE_KVP_FORM_SUCCESS:
    case ActionTypes.SAVE_PROJECT_OVERVIEW_FORM_SUCCESS:
    case ActionTypes.SAVE_PROCESS_CONFIRMATION_FORM_SUCCESS:
    case ActionTypes.SAVE_GOALS_FORM_SUCCESS:
    case ActionTypes.SAVE_WEEK_TOPICS_SUCCESS:
    case ActionTypes.SAVE_STANDING_RULES_SUCCESS:
      return {
        ...previousState,
        [namespace]: {
          ...previousState[namespace],
          form: action.payload,
          state: StateStatus.Loaded
        }
      };
    case ActionTypes.SAVE_TABLE_FORM_MEASURE_ERROR:
      return {
        ...previousState,
        form: {
          form: null,
          error: action.payload,
          status: StateStatus.Error
        }
      };
    default:
      return state;
  }
}
