import service from './RestService';
import { RestResponse } from '../types/response/RestResponse';
import { ProjectAreaOverviewForm, ProjectAreaStatusForm } from '../state/project-area/types';

class ProjectAreaService {
  getProjectAreaOverviewByUrl = (url: string): RestResponse<ProjectAreaOverviewForm> => service.get(`${url}`);

  getProjectAreaStatusesByAreaUrl = (url: string): RestResponse<ProjectAreaStatusForm> => service.get(`${url}`);
}

export default new ProjectAreaService();
