import { findLast } from 'lodash';
import { OccupationalSafety, OSDataStatus, OSMonth } from '../../core/state/occupational-safety/types';

export const findLastDay = (form: OccupationalSafety | null, status: OSDataStatus) => {
  const findLastMonth = findLast(form?.months, { days: [{ status }] }) as OSMonth | undefined;
  return findLast(findLastMonth?.days, (item) => item.status === status);
};

export const getMonthDate = (month?: number) => {
  const date = new Date();
  date.setMonth(Number(month) - 1);
  return date;
};

export const getNameOfTheMonth = (date: Date) => date.toLocaleString('de-DE', { month: 'long' });

export const changeStatus = (status: OSDataStatus) => {
  const statuses = [OSDataStatus.Yellow, OSDataStatus.Red, OSDataStatus.Green];
  const index = statuses.indexOf(status);
  if (index === statuses.length - 1) {
    return statuses[0];
  }
  return statuses[index + 1];
};

export const getDate = (year?: number, month?: number, day?: number) => {
  const currentDate = new Date();
  const currentYear = year ?? new Date().getFullYear();
  const currentMonth = Number(month) - 1;
  const currentDay = day ?? 1;

  currentDate.setFullYear(currentYear, currentMonth, currentDay);
  return currentDate;
};
