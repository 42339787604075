import React, { ChangeEvent, FocusEvent } from 'react';
import { useDispatch } from 'react-redux';
import { Actions as SettingsActions } from '../../../core/state/settings/actions';

interface Props {
  value: string;
  label: string;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  onFocus?: (e?: FocusEvent) => void;
  onBlur?: (e?: FocusEvent) => void;
  id: string;
}

const TileProcessConfirmationInput: React.FC<Props> = ({ value, label, onChange, onFocus, onBlur, id }) => {
  const processConfId = `process-confirmation-${id}`;
  const dispatch = useDispatch();

  const handleFocus = (e: FocusEvent) => {
    dispatch(SettingsActions.setIsFormEditing(true));

    if (onFocus) {
      onFocus(e);
    }
  };

  const handleBlur = (e: FocusEvent) => {
    dispatch(SettingsActions.setIsFormEditing(false));

    if (onBlur) {
      onBlur(e);
    }
  };

  return (
    <div className="tile-process-confirmation__input">
      <label htmlFor={processConfId} className="tile-process-confirmation__label">
        {label}
      </label>
      <input
        id={processConfId}
        onChange={onChange}
        onFocus={handleFocus}
        onBlur={handleBlur}
        className="tile-process-confirmation__input-control"
        value={value}
        type="text"
        placeholder="dd/mm/yyyy"
      />
    </div>
  );
};

export default TileProcessConfirmationInput;
