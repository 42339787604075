import * as React from 'react';

interface Props {
  className?: string;
  colSpan?: number;
  rowSpan?: number;
}

const TH: React.FC<Props> = ({ children, className = '', colSpan, rowSpan }) => (
  <th className={className} colSpan={colSpan} rowSpan={rowSpan}>
    {children}
  </th>
);

export default TH;
