import * as React from 'react';
import { Icon as IconObject } from '../../core/constants/Icon';

import './Icon.scss';

interface Props {
  className?: string;
  onClick?: () => void;
  icon: keyof typeof IconObject;
  size?: 'small' | 'medium' | 'full-size';
  svg?: boolean;
  isDisabled?: boolean;
  isButton?: boolean;
  inline?: boolean;
}

const Icon: React.FC<Props> = ({
  icon,
  className = '',
  size = 'medium',
  svg = false,
  onClick,
  isButton = false,
  isDisabled = false,
  inline = false
}) => {
  const SVG = IconObject[icon].svg;
  const Element = isButton ? 'button' : 'i';
  return (
    <Element
      onClick={!isDisabled ? onClick : undefined}
      type={isButton ? 'button' : undefined}
      className={`icon icon--${size} icon--${icon} ${onClick ? 'icon--clickable' : ''} ${isDisabled ? 'icon--disabled' : ''} ${
        inline ? 'icon--inline' : ''
      } ${className}`}
      disabled={isDisabled}
    >
      {svg ? <SVG /> : <img src={IconObject[icon].icon} alt={icon} />}
    </Element>
  );
};

export default Icon;
