import React, { LegacyRef } from 'react';

import { Sizes } from '../../../core/enums/Sizes';

import './Input.scss';

interface Props {
  defaultValue?: string;
  label?: string;
  id: string;
  name: string;
  error?: string;
  onChange?: () => void;
  onBlur?: () => void;
  register?: LegacyRef<any>;
  labelSize?: Sizes;
}

const Input: React.FC<Props> = ({ defaultValue = '', label, error, id, name, onChange, onBlur, register, labelSize }) => {
  return (
    <div className={`input ${error ? 'input--error' : ''}`}>
      {label && (
        <label className={`input__label ${labelSize ? `input__label--${labelSize}` : ''}`} htmlFor={id}>
          {label}
        </label>
      )}
      <input className="input__input" ref={register} defaultValue={defaultValue} id={id} name={name} onChange={onChange} onBlur={onBlur} />
      {error && <div className="input__error">{error}</div>}
    </div>
  );
};

export default Input;
