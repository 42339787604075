import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { match } from 'react-router-dom';

import './TileAdditionalServicesNU.scss';

interface Props {
  isDetailPage: match | null;
}

const TileAdditionalServicesNUInfo: React.FC<Props> = ({ isDetailPage }) => {
  const { t } = useTranslation();
  return (
    <div
      className={`tile-additionalservicesnu ${
        isDetailPage ? '' : 'tile-additionalservicesnu--small justify-content-end'
      } d-flex flex-column align-items-end mb-2`}
    >
      <div className={`tile-additionalservicesnu__info-text ${isDetailPage ? '' : 'text-right'}`}>
        <span className="tile-additionalservicesnu__info-text tile-additionalservicesnu__info-text-title">
          {t('forms.additionalServicesNU.statusDefinition')}
        </span>
        {t('forms.additionalServicesNU.statusDefinitionSubtitle')}
      </div>
      <div className={`${isDetailPage ? '' : 'd-flex text-right mt-1'}`}>
        <span className="tile-additionalservicesnu__info-text">{t('forms.additionalServicesNU.prefilledHint')}</span>
      </div>
    </div>
  );
};

export default TileAdditionalServicesNUInfo;
