import * as React from 'react';
import Modal from 'react-bootstrap/Modal';

interface Props {
  show?: boolean;
  onHide?: () => void;
  size?: 'sm' | 'lg' | 'xl';
}

const Popup: React.FC<Props> = ({ show, children, onHide = () => undefined, size = 'xl' }) => (
  <Modal show={show} onHide={onHide} aria-labelledby="contained-modal-title-vcenter" centered size={size}>
    <Modal.Body>{children}</Modal.Body>
  </Modal>
);
export default Popup;
