import * as React from 'react';
import { isDesktopScreen } from '../../../core/utils/isDesktopScreen';

import './Board.scss';

interface Props {
  background?: 'white';
}

const Board: React.FC<Props> = ({ children, background }) => {
  const desktopScreen = isDesktopScreen();
  return <main className={`board ${background ? `board--${background}` : ''}${desktopScreen ? ' no-font-scaling' : ''}`}>{children}</main>;
};

export default Board;
