import * as React from 'react';

import { TableFormFieldStatus } from '../../../core/enums/TableFormFieldStatus';
import * as KVPHelpers from '../TileMeasureHelpers';
import Icon from '../../../components/Icon/Icon';

import './MeasureIndicator.scss';

interface Props {
  status: TableFormFieldStatus;
  size?: 'big' | 'medium' | 'small';
  onClick?: (status: TableFormFieldStatus) => void;
}

const MeasureIndicator: React.FC<Props> = ({ status, size, onClick }) => (
  <div
    className={`measure-indicator m-auto ${size ? ` measure-indicator--${size}` : ''}`}
    role="button"
    aria-pressed="false"
    aria-label="indicator"
    tabIndex={0}
    onClick={() => {
      if (onClick) {
        onClick(KVPHelpers.toggleMeasureStatus(status));
      }
    }}
    onKeyDown={() => {}}
  >
    <Icon svg icon={KVPHelpers.getKVPStatusIcon(status)} />
  </div>
);

export default MeasureIndicator;
