import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import cloneDeep from 'lodash/cloneDeep';
import merge from 'lodash/merge';

import { ReduxThunkType } from '../types/ReduxThunkType';
import { history } from '../state/history';
import { BOARDS } from '../constants/routes';

type ActionList = ((...args: any[]) => ReduxThunkType<unknown>)[];

type useTableFormOptions<T> = {
  data: T | null | undefined;
  nestedTablePropertyName?: keyof T | null;
  columnPropertyName?: string | null;
  rowPropertyName?: string;
  valuePropertyName?: string;
  boardId?: number;
  updateActions: ActionList;
  submitActions: ActionList;
};

export function useTableForm<T>({
  data,
  rowPropertyName = 'rows',
  columnPropertyName = 'columns',
  nestedTablePropertyName,
  valuePropertyName,
  updateActions,
  submitActions,
  boardId
}: useTableFormOptions<T>) {
  const dispatch = useDispatch();

  const dispatchActionsSync = useCallback(
    async (actions: ActionList, payload: unknown) => {
      // We want these to run synchronously instead of using Promise.all
      // eslint-disable-next-line no-restricted-syntax
      for (const action of actions) {
        // eslint-disable-next-line no-await-in-loop
        await dispatch(action(payload));
      }
    },
    [dispatch]
  );

  const submit = useCallback(
    async (additionalData?: any) => {
      const newData = cloneDeep(data);
      if (additionalData) {
        merge(newData, additionalData);
      }

      await dispatchActionsSync(submitActions, newData);
      if (boardId) {
        history.push(`${BOARDS}/${boardId}`);
      }
    },
    [dispatchActionsSync, data, submitActions, boardId]
  );

  const handleUpdate = useCallback(
    async (rowIndex: string | number, columnIndex: string | number, value: unknown) => {
      if (!data) {
        return;
      }

      const newData = cloneDeep(data);
      const table: any = nestedTablePropertyName ? newData[nestedTablePropertyName] : newData;
      const row = table[rowPropertyName][rowIndex];
      const column = columnPropertyName ? row[columnPropertyName][columnIndex] : row[columnIndex];
      if (valuePropertyName) {
        column[valuePropertyName] = value;
      } else {
        row[columnIndex] = value;
      }
      await dispatchActionsSync(updateActions, newData);
    },
    [dispatchActionsSync, data, nestedTablePropertyName, columnPropertyName, rowPropertyName, valuePropertyName, updateActions]
  );

  const getValue = (rowIndex: string | number, columnIndex: string | number) => {
    if (!data) {
      return null;
    }
    try {
      const table: any = nestedTablePropertyName ? data[nestedTablePropertyName] : data;
      const row = table[rowPropertyName][rowIndex];
      const column = columnPropertyName ? row[columnPropertyName][columnIndex] : row[columnIndex];

      return valuePropertyName ? column[valuePropertyName] : column;
    } catch {
      return '';
    }
  };

  const useTableValue = (row: number | string, column: number | string) => [getValue(row, column), (value: any) => handleUpdate(row, column, value)];

  return {
    useTableValue,
    submit
  };
}
