import { SCREEN_4K, SCREEN_L, SCREEN_MD, SCREEN_SM, SCREEN_XL } from './screen';

export const MEDIA_QUERIES = {
  UHD: `(min-width: ${SCREEN_4K}px)`,
  XL: `(max-width: ${SCREEN_4K - 1}px) and (min-width: ${SCREEN_XL})`,
  L: `(max-width: ${SCREEN_XL - 1}px) and (min-width: ${SCREEN_L})`,
  MD: `(max-width: ${SCREEN_L - 1}px) and (min-width: ${SCREEN_MD})`,
  SM: `(max-width: ${SCREEN_MD - 1}px) and (min-width: ${SCREEN_SM})`,
  XSM: `(max-width: ${SCREEN_SM - 1}px)`,
  UHD_DOWN: `(min-width: 1px)`,
  XL_DOWN: `(max-width: ${SCREEN_4K - 1}px)`,
  L_DOWN: `(max-width: ${SCREEN_XL - 1}px)`,
  MD_DOWN: `(max-width: ${SCREEN_L - 1}px)`,
  SM_DOWN: `(max-width: ${SCREEN_MD - 1}px)`
};

export type MediaQueriesType = keyof typeof MEDIA_QUERIES;
