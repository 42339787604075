import React, { ChangeEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { MeasureAndEscalation } from '../../../core/state/table-forms/types';

import Popup from '../../../components/popup/Popup/Popup';
import Radio from '../../../components/form/Radio/Radio';
import UnsavedChangesPopup from '../../../components/popup/UnsavedChangesPopup/UnsavedChangesPopup';

import { FormTypes } from './formTypes';
import CreateEscalationForm from './CreateEscalationForm';
import CreateMeasureForm from './CreateMeasureForm';

import './MeasureEscalationModal.scss';
import { TableFormFieldStatus } from '../../../core/enums/TableFormFieldStatus';

export interface Props {
  show?: boolean;
  onCancel?: () => void;
  onConfirm?: (data: MeasureAndEscalation) => void;
  KVPText?: {
    problem: string;
    measure: string | number;
    assignedTo?: string;
    deadline?: string | null;
    status?: TableFormFieldStatus;
  };
  warnUnsavedChanges?: boolean;
  hasOnlyMeasure?: boolean;
}

const MeasureEscalationModal: React.FC<Props> = ({ show, onCancel, onConfirm, KVPText, warnUnsavedChanges = false, hasOnlyMeasure = false }) => {
  const { t } = useTranslation();

  const formTypeOptions = [
    {
      label: t('measureForm.measure'),
      value: FormTypes.Measure
    },
    {
      label: t('forms.escalation.title'),
      value: FormTypes.Escalation
    }
  ];

  const [formType, setFormType] = useState<string>(FormTypes.Measure);
  const [isMeasureFormDirty, setMeasureFormDirty] = useState<boolean>(false);
  const [isEscalationFormDirty, setEscalationFormDirty] = useState<boolean>(false);
  return (
    <Popup show={show} onHide={() => {}} size="lg">
      <div className="measure-form">
        <p className="measure-form__title">{t('measureForm.title')}</p>
        {!hasOnlyMeasure && (
          <Radio
            defaultValue={formType}
            id="type"
            name="type"
            options={formTypeOptions}
            onChange={(e: ChangeEvent<HTMLInputElement>) => setFormType(e.target.value)}
            inline
          />
        )}

        <div className={formType === FormTypes.Measure ? '' : 'd-none'}>
          <CreateMeasureForm
            KVPText={KVPText}
            onConfirm={onConfirm}
            onCancel={onCancel}
            onDirtyStatusChange={(isDirty) => setMeasureFormDirty(isDirty)}
          />
        </div>
        <div className={formType === FormTypes.Escalation ? '' : 'd-none'}>
          <CreateEscalationForm
            KVPText={KVPText}
            onConfirm={onConfirm}
            onCancel={onCancel}
            onDirtyStatusChange={(isDirty) => setEscalationFormDirty(isDirty)}
          />
        </div>
      </div>
      {warnUnsavedChanges && (
        <UnsavedChangesPopup
          showIf={(formType === FormTypes.Measure && isMeasureFormDirty) || (formType === FormTypes.Escalation && isEscalationFormDirty)}
        />
      )}
    </Popup>
  );
};

export default MeasureEscalationModal;
