import { createSelector } from 'reselect';
import { RootState } from '../state';
import { BoardPermissions } from './types';

const emptyPermissions = (boardId: number): BoardPermissions => ({ boardId, owners: [], participants: [] });

export const getBoardPermissions = (state: RootState) => state.backOffice.boardPermissions;

export const selectBoardPermissionsByBoardId = createSelector(
  [getBoardPermissions, (_: any, boardId: number) => boardId],
  (boardPermissions, boardId) => {
    return boardPermissions?.boardId === boardId ? boardPermissions : emptyPermissions(boardId);
  }
);
