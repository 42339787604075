import { TableFormFieldStatus } from '../../core/enums/TableFormFieldStatus';
import { EscalationType } from '../../core/state/table-forms/types';

export const getAttendanceListClass = (status?: TableFormFieldStatus) => {
  if (!status) {
    return '';
  }
  return `table__indicator--${String(status).toLowerCase()}`;
};

export const toggleEscalationStatus = (status: TableFormFieldStatus) => {
  if (TableFormFieldStatus.Unchecked === status) {
    return TableFormFieldStatus.Checked;
  }
  return TableFormFieldStatus.Unchecked;
};

export const toggleEscalationType = (type: EscalationType) => {
  if (EscalationType.Action === type) {
    return EscalationType.Informative;
  }
  return EscalationType.Action;
};
