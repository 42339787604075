import * as React from 'react';
import { useRouteMatch } from 'react-router-dom';
import { CheckboxListItemStatus } from '../../core/enums/CheckboxListItemStatus';
import CheckboxTile from '../CheckboxListTile/TileCheckboxList';
import { CheckboxTileTypes } from '../../core/enums/CheckboxTileTypes';
import TileConfirmationListLegend from './TileConfirmationListLegend';
import TileMeasure from './TileMeasure/TileMeasure';
import { BOARDS_TILE_DETAIL } from '../../core/constants/routes';

interface Props {
  url: string;
  boardId?: number;
}

const TileConfirmationList: React.FC<Props> = ({ url, boardId }) => {
  const isDetailPage = useRouteMatch(BOARDS_TILE_DETAIL);

  return (
    <div className="tile-measures-escalation ">
      <div className="tile-measures-escalation__content">
        <div className={`tile-measures-escalation__measure ${isDetailPage ? '' : 'pt-0'}`}>
          <CheckboxTile
            url={url}
            boardId={boardId}
            tileType={CheckboxTileTypes.Confirmation}
            getNextStatus={(status: CheckboxListItemStatus) => {
              let newStatus: CheckboxListItemStatus;
              if (status.toLowerCase() === CheckboxListItemStatus.Undefined.toLowerCase()) {
                newStatus = CheckboxListItemStatus.Black;
              } else if (status === CheckboxListItemStatus.Black) {
                newStatus = CheckboxListItemStatus.Red;
              } else if (status === CheckboxListItemStatus.Red) {
                newStatus = CheckboxListItemStatus.Undefined;
              } else {
                newStatus = CheckboxListItemStatus.Undefined;
              }
              return newStatus;
            }}
            InfoComponent={TileConfirmationListLegend}
          />
        </div>
        <div className={`tile-measures-escalation__escalation ${isDetailPage ? 'container-fluid' : 'pt-2'}`}>
          <TileMeasure url={url} tileType={CheckboxTileTypes.Confirmation} />
        </div>
      </div>
    </div>
  );
};

export default TileConfirmationList;
