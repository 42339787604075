import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useRouteMatch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import cloneDeep from 'lodash/cloneDeep';

import { BOARDS_TILE_DETAIL } from '../../../core/constants/routes';
import { MeasureDetail } from '../../../core/state/table-forms/types';
import { useTableForm } from '../../../core/hooks/useTableForm';
import { RootState } from '../../../core/state/state';

import TR from '../../../components/Table/TR/TR';
import TD from '../../../components/Table/TD/TD';
import Icon from '../../../components/Icon/Icon';

import MeasureIndicator from '../../TileMeasure/TileMeasureIndicator/MeasureIndicator';
import Calendar from '../../../components/form/Calendar/CalendarHook';
import TableInput from '../../../components/form/TableInput/TableInputHook';

import { toggleMeasureStatus } from '../../TileMeasure/TileMeasureHelpers';
import { isRowHighlighted } from '../../TileMeasuresEscalation/helpers';
import { CheckboxAndMeasureData } from '../../../core/state/checkbox-list/types';
import generateAsyncActions from '../../../core/state/checkbox-list/asyncActions';
import { CheckboxTileTypes } from '../../../core/enums/CheckboxTileTypes';

interface Props {
  url?: string;
  tileType: CheckboxTileTypes;
  measure: MeasureDetail;
  rowIndex: number;
}

type measureDetailKey = keyof MeasureDetail;

const TileMeasureRow: React.FC<Props> = ({ url, tileType, measure, rowIndex }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const isDetailPage = useRouteMatch(BOARDS_TILE_DETAIL);

  const actions = React.useMemo(() => generateAsyncActions(tileType.toUpperCase()), [tileType]);
  const tileState = useSelector((state: RootState) => state[tileType].list) as CheckboxAndMeasureData;

  function useDeleteRow(data: CheckboxAndMeasureData) {
    return useCallback(
      (index: number) => {
        const newData = cloneDeep(data);
        if (newData.measures) {
          newData.measures.splice(index, 1);

          dispatch(actions.updateList(newData));
        }
      },
      [data]
    );
  }

  const deleteRow = useDeleteRow(tileState);

  function useEditRow(data: CheckboxAndMeasureData) {
    return useCallback(
      (index: number, key: measureDetailKey, value: any) => {
        const newData = cloneDeep(data);
        if (newData.measures) {
          const updateRow: any = newData.measures[index];
          updateRow[key] = value;
          dispatch(actions.updateList(newData));
        }
      },
      [data]
    );
  }

  const editRow = useEditRow(tileState);

  const { useTableValue } = useTableForm<CheckboxAndMeasureData>({
    data: tileState,
    rowPropertyName: 'measures',
    columnPropertyName: null,
    updateActions: [actions.updateList],
    submitActions: [
      actions.updateList,
      (data) => {
        return actions.saveList(data, url);
      }
    ]
  });

  return (
    <TR className={isRowHighlighted(measure.createdAt) ? 'table__row--highlighted' : ''}>
      <TD className="shrink text-center">{rowIndex + 1}</TD>
      <TD>
        <Calendar isSmall={!isDetailPage} row={rowIndex} column="date" useTableValue={useTableValue} />
      </TD>
      <TD>
        <TableInput type={isDetailPage ? undefined : 'small'} row={rowIndex} column="problemText" useTableValue={useTableValue} />
      </TD>
      <TD>
        <TableInput type={isDetailPage ? undefined : 'small'} row={rowIndex} column="measureText" useTableValue={useTableValue} />
      </TD>
      <TD>
        <TableInput type={isDetailPage ? undefined : 'small'} row={rowIndex} column="assignedTo" useTableValue={useTableValue} />
      </TD>
      <TD className="text-center">
        <Calendar isSmall={!isDetailPage} row={rowIndex} column="deadline" useTableValue={useTableValue} />
      </TD>
      <TD className="text-center">
        <MeasureIndicator
          size={isDetailPage ? 'big' : 'small'}
          status={measure.status}
          onClick={() => {
            editRow(rowIndex, 'status', toggleMeasureStatus(measure.status));
          }}
        />
      </TD>
      {isDetailPage && (
        <TD className="shrink">
          <button
            disabled
            className="btn btn-link"
            onClick={() => {
              // escalateMeasure();
            }}
          >
            <Icon icon="levelUp" svg />
            <span className="sr-only">{t('buttons.escalate')}</span>
          </button>
        </TD>
      )}
      {isDetailPage && (
        <TD className="shrink">
          <button
            className="btn btn-link"
            onClick={() => {
              deleteRow(rowIndex);
            }}
            title={t('buttons.deleteRow')}
          >
            <Icon icon="delete" svg />
            <span className="sr-only">{t('buttons.deleteRow')}</span>
          </button>
        </TD>
      )}
    </TR>
  );
};

export default TileMeasureRow;
