export interface OccupationalSafety {
  occupationalSafetyId: number;
  lastIncidentDate: string | null;
  lastAccidentDate: string | null;
  measureURL: string;
  months: OSMonth[] | null;
}

export interface OSMonth {
  month: number;
  year: number;
  days: OSDay[] | null;
}

export interface OSDay {
  day: number;
  date: string;
  status: OSDataStatus;
}

export enum OSDataStatus {
  Red = 'Red',
  Yellow = 'Yellow',
  Green = 'Green',
  Undefined = 'Undefined'
}
