import debounce from 'lodash/debounce';
import * as React from 'react';

import './TileProPlan.scss';

interface Props {
  url?: string;
}

const CONTENT_WIDTH = 997;
const CONTENT_HEIGHT = 705;

const TileProPlan: React.FC<Props> = ({ url }) => {
  const divRef = React.useRef<HTMLDivElement>(null);
  const [scale, setScale] = React.useState<number | undefined>(1);

  React.useLayoutEffect(() => {
    const handleResize = () => {
      const availableHeight = Number(divRef.current?.offsetHeight);
      const availableWidth = Number(divRef.current?.offsetWidth);
      const currentScale = Math.min(availableWidth / CONTENT_WIDTH, availableHeight / CONTENT_HEIGHT);
      setScale(currentScale);
    };
    const debouncedHandleResize = debounce(handleResize, 500);
    window.addEventListener('resize', debouncedHandleResize);
    return () => {
      window.removeEventListener('resize', debouncedHandleResize);
    };
  }, []);

  React.useEffect(() => {
    const availableHeight = Number(divRef.current?.offsetHeight);
    const availableWidth = Number(divRef.current?.offsetWidth);
    const currentScale = Math.min(availableWidth / CONTENT_WIDTH, availableHeight / CONTENT_HEIGHT);
    setScale(currentScale);
  }, []);

  return (
    <div ref={divRef} className="tile-pro-plan no-font-scaling">
      {url && <iframe style={{ transform: ` scale(${scale}) translateX(-50%)` }} src={url} title="Pro Plan report" />}
    </div>
  );
};

export default TileProPlan;
