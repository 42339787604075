import { useCallback, useEffect, useState } from 'react';

interface DataWithRows {
  rows: { rowNumber: number }[];
}

export function useRowNumber(tableForm: DataWithRows | null) {
  const { rows } = tableForm ?? {};
  const [maxRowNumber, setMaxRowNumber] = useState<number>(rows?.length ? rows[rows.length - 1].rowNumber : 0);

  return useCallback(() => {
    const nextRowNumber = maxRowNumber + 1;
    setMaxRowNumber(nextRowNumber);
    return nextRowNumber;
  }, [maxRowNumber]);
}

interface DataWithIds {
  id?: number | string;
}

export function useRowId(data?: DataWithIds[] | null) {
  const initialData = data?.length ? data[data.length - 1].id : 0;
  const [maxNumber, setMaxRowNumber] = useState<number | string | undefined>(initialData);

  useEffect(() => {
    if (data && data?.length > 0 && maxNumber === 0) {
      const newMaxNumber = data?.length ? data[data.length - 1].id : 0;
      setMaxRowNumber(newMaxNumber);
    }
  }, [data, maxNumber]);

  return useCallback(() => {
    const nextRowNumber = Number(maxNumber) + 1;
    setMaxRowNumber(nextRowNumber);
    return String(nextRowNumber);
  }, [maxNumber]);
}
