import * as React from 'react';
import { useRouteMatch } from 'react-router-dom';
import { BOARDS_TILE_DETAIL } from '../../core/constants/routes';

import './TilePDF.scss';

interface Props {
  url?: string;
  view?: 'FitV' | 'FitH';
}

const TilePDF: React.FC<Props> = ({ url, view = 'FitB' }) => {
  const isDetailPage = useRouteMatch(BOARDS_TILE_DETAIL);

  return (
    <div className={`tile-pdf ${isDetailPage ? '' : 'tile-pdf--small'} no-font-scaling`}>
      {url && (
        <object data={`${url}#view=${view},100&toolbar=0`} type="application/pdf">
          <embed src={`${url}#view=${view},100&toolbar=0`} type="application/pdf" />
        </object>
      )}
    </div>
  );
};

export default TilePDF;
