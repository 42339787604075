import * as React from 'react';
import { TableFormFieldStatus } from '../../../core/enums/TableFormFieldStatus';

interface Props {
  status: TableFormFieldStatus;
  onClick?: () => void;
}

const TileProcessConfirmationStatus: React.FC<Props> = ({ status, onClick }) => {
  return (
    <button
      onClick={onClick}
      className={`tile-process-confirmation__status ${status ? `tile-process-confirmation__status--${status.toLowerCase()}` : ''}`}
    >
      {status}
    </button>
  );
};

export default TileProcessConfirmationStatus;
