import * as React from 'react';
import { useDispatch } from 'react-redux';

import TileMeasureDesktopScreen from '../../TileMeasure/TileMeasureDesktopScreen/TileMeasureDesktopScreen';
import TileEscalationDesktopScreen from '../../TileEscalation/DesktopScreen/TileEscalationDesktopScreen';
import { getMeasuresAndEscalations } from '../../../core/state/table-forms/asyncActions';

import './TileMeasuresEscalationDesktopScreen.scss';

interface Props {
  url: string;
  boardId?: number;
}

const TileMeasuresEscalationDesktopScreen: React.FC<Props> = ({ url }) => {
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(getMeasuresAndEscalations(url));
  }, [dispatch, url]);

  return (
    <div className="tile-measures-escalation-desktop no-font-scaling">
      <div className="tile-measures-escalation-desktop__measures">
        <TileMeasureDesktopScreen url={url} />
      </div>
      <div className="tile-measures-escalation-desktop__escalation">
        <TileEscalationDesktopScreen url={url} />
      </div>
    </div>
  );
};

export default TileMeasuresEscalationDesktopScreen;
