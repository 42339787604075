import { cloneDeep, orderBy } from 'lodash';

interface Item {
  order: number;
}
export function reorderItems<T extends Item>(items: T[]) {
  return orderBy(items, ['order']).map((item, index) => {
    const newItems = cloneDeep(item);
    newItems.order = index;
    return newItems;
  });
}
