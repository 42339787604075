import Actions from '../Actions';
import { CheckboxAndMeasureState, CheckboxState } from './types';
import { getValueByKey, getActionTypeByName } from '../helpers';
import { StateStatus } from '../../enums/StateStatus';
import { FormStatuses } from '../../enums/FormStatuses';

export const defaultCheckboxAndMeasureState: CheckboxAndMeasureState = {
  list: {
    attendance: {
      formId: null,
      status: FormStatuses.Undefined,
      title: '',
      tileId: 0,
      numberOfColumns: 0,
      rows: [],
      hierarchyId: 0
    },
    measures: []
  },
  status: StateStatus.Initial,
  error: null
};

export const defaultCheckboxState: CheckboxState = {
  list: {
    formId: null,
    status: FormStatuses.Undefined,
    title: '',
    tileId: 0,
    numberOfColumns: 0,
    rows: [],
    hierarchyId: 0
  },
  status: StateStatus.Initial,
  error: null
};

export default function generateCheckboxTileReducer(type: string) {
  return (state: CheckboxState | CheckboxAndMeasureState = defaultCheckboxState, action: Actions): CheckboxState | CheckboxAndMeasureState => {
    switch (action.type) {
      case getActionTypeByName(`GET_${type}_LIST`):
      case getActionTypeByName(`SAVE_${type}_LIST`):
        return { ...state, status: StateStatus.Busy };
      case getActionTypeByName(`GET_${type}_LIST_SUCCESS`):
        return { ...state, status: StateStatus.Loaded, list: getValueByKey(action, 'payload') };
      case getActionTypeByName(`SAVE_${type}_LIST_SUCCESS`):
        return { ...state, status: StateStatus.Loaded };
      case getActionTypeByName(`UPDATE_${type}_LIST`):
        return { ...state, list: getValueByKey(action, 'payload') };
      case getActionTypeByName(`GET_${type}_LIST_ERROR`):
      case getActionTypeByName(`SAVE_${type}_LIST_ERROR`):
        return { ...state, status: StateStatus.Error, error: getValueByKey(action, 'payload') };
      default:
        return state;
    }
  };
}
