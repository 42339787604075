import ActionTypes from '../actionTypes';
import Actions from '../Actions';
import { ProcessConfirmation } from './types';
import { StateStatus } from '../../enums/StateStatus';

export const defaultProcessConfirmationState: ProcessConfirmation = {
  processConfirmationForm: null,
  status: StateStatus.Initial,
  error: null
};

export default function reducer(state: ProcessConfirmation = defaultProcessConfirmationState, action: Actions): ProcessConfirmation {
  switch (action.type) {
    case ActionTypes.GET_PROCESS_CONFIRMATION_FORM:
      return { ...state, status: StateStatus.Busy };
    case ActionTypes.GET_PROCESS_CONFIRMATION_FORM_SUCCESS:
      return { ...state, status: StateStatus.Loaded, processConfirmationForm: action.payload };
    case ActionTypes.GET_PROCESS_CONFIRMATION_FORM_ERROR:
    case ActionTypes.SAVE_PROCESS_CONFIRMATION_FORM_ERROR:
      return { ...state, error: action.payload, status: StateStatus.Error };
    case ActionTypes.UPDATE_PROCESS_CONFIRMATION_FORM:
      return { ...state, processConfirmationForm: action.payload };
    case ActionTypes.SAVE_PROCESS_CONFIRMATION_FORM_SUCCESS:
      return { ...state, processConfirmationForm: action.payload };
    default:
      return state;
  }
}
