import * as React from 'react';

interface Props {
  text: string;
}

const TileProcessConfirmationHeaderSubTitle: React.FC<Props> = ({ text }) => {
  return <div className="tile-process-confirmation__header-sub-title">{text}</div>;
};

export default TileProcessConfirmationHeaderSubTitle;
