import React from 'react';
import { useTranslation } from 'react-i18next';
import { TileTypes } from '../../core/enums/TileTypes';
import { TableTileTypes } from '../../core/enums/TableTileTypes';
import TileTrackingAAZInfo from './TileTrackingAAZInfo';
import TableFormTile from '../TableFormTile/TableFormTile';
import TileTrackingTableFooter from './TileTrackingTableFooter';

interface Props {
  boardId?: number;
  url: string;
}

const TileTrackingAAZ: React.FC<Props> = ({ url, boardId }) => {
  const { t } = useTranslation();

  return (
    <TableFormTile
      url={url}
      boardId={boardId}
      tileType={TileTypes.TrackingAAZ}
      tableTileType={TableTileTypes.TrackingAAZ}
      hasNoBorder
      columns={[
        {
          field: 'id',
          title: t('forms.trackingAAZ.column.id'),
          type: 'index',
          headerClass: 'w-3 text-center'
        },
        {
          field: 'description',
          title: t('forms.trackingAAZ.column.description'),
          headerClass: 'text-center'
        },
        {
          field: 'amount',
          title: t('forms.trackingAAZ.column.amount'),
          type: 'number',
          headerClass: 'w-13 text-center'
        },
        {
          field: 'placedOn',
          title: t('forms.trackingAAZ.column.placedOn'),
          type: 'calendar',
          headerClass: 'w-13 text-center'
        },
        {
          field: 'assignment',
          title: t('forms.trackingAAZ.column.assignment'),
          columns: [
            {
              field: 'soll',
              title: t('forms.trackingAAZ.column.soll'),
              type: 'calendar',
              headerClass: 'w-13 text-center'
            },
            {
              field: 'ist',
              title: t('forms.trackingAAZ.column.ist'),
              type: 'calendar',
              headerClass: 'w-13 text-center'
            }
          ],
          headerClass: 'w-13 text-center'
        },
        {
          field: 'impactToContractDate',
          type: 'number',
          title: t('forms.trackingAAZ.column.impactToContractDate'),
          headerClass: 'w-13 text-center'
        }
      ]}
      InfoComponent={TileTrackingAAZInfo}
      TableFooter={TileTrackingTableFooter}
      showStatus
    />
  );
};

export default TileTrackingAAZ;
