import ActionTypes from './actionTypes';

export const getActionTypeByName = (name: string): ActionTypes | '' => {
  const ACTION_NAME = Object.values(ActionTypes).find((value) => value.indexOf(name) > -1);
  return ACTION_NAME || '';
};

export const getValueByKey = (obj: Record<string, any>, key: string): any => {
  return obj[key];
};
