import * as React from 'react';
import { match } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import MeasureIndicator from '../TileMeasureIndicator/MeasureIndicator';
import { TableFormFieldStatus } from '../../../core/enums/TableFormFieldStatus';

interface Props {
  isDetailPage: match | null;
  status: TableFormFieldStatus;
  translation: string;
}

const TileMeasureLegendItem: React.FC<Props> = ({ isDetailPage, translation, status }) => {
  const { t } = useTranslation();

  return (
    <div className="d-flex align-items-center justify-content-center">
      <MeasureIndicator status={status} size={isDetailPage ? undefined : 'small'} />
      <span className={`tile-measure__legend-text ${isDetailPage ? '' : 'tile-measure__legend-text--small text-nowrap'}`}>{t(translation)}</span>
    </div>
  );
};

export default TileMeasureLegendItem;
