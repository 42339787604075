import * as React from 'react';
import { useTranslation } from 'react-i18next';

const ProjectOverviewDescription = () => {
  const { t } = useTranslation();
  return (
    <div className="tile-project-overview__description">
      <div className="tile-project-overview__description-item">
        <div className="tile-project-overview__description-item-inner">{t('sharedText.description')}</div>
      </div>
      <div className="tile-project-overview__description-item">
        <div className="tile-project-overview__description-item-inner">{t('form.projectOverview.tableHeader.subtitle.resultPreview')}</div>
      </div>
      <div className="tile-project-overview__description-item">
        <div className="tile-project-overview__description-item-inner">{t('form.projectOverview.tableHeader.subtitle.planning')}</div>
      </div>
      <div className="tile-project-overview__description-item">
        <div className="tile-project-overview__description-item-inner">{t('form.projectOverview.tableHeader.subtitle.sampling')}</div>
      </div>
      <div className="tile-project-overview__description-item">
        <div className="tile-project-overview__description-item-inner">{t('form.projectOverview.tableHeader.subtitle.tender')}</div>
      </div>
      <div className="tile-project-overview__description-item">
        <div className="tile-project-overview__description-item-inner">{t('form.projectOverview.tableHeader.subtitle.award')}</div>
      </div>
      <div className="tile-project-overview__description-item">
        <div className="tile-project-overview__description-item-inner">{t('form.projectOverview.tableHeader.subtitle.term')}</div>
      </div>
      <div className="tile-project-overview__description-item">
        <div className="tile-project-overview__description-item-inner">{t('form.projectOverview.tableHeader.subtitle.performance')}</div>
      </div>
      <div className="tile-project-overview__description-item">
        <div className="tile-project-overview__description-item-inner">{t('form.projectOverview.tableHeader.subtitle.disability')}</div>
      </div>
      <div className="tile-project-overview__description-item">
        <div className="tile-project-overview__description-item-inner">{t('form.projectOverview.tableHeader.subtitle.creditLineUtilization')}</div>
      </div>
      <div className="tile-project-overview__description-item">
        <div className="tile-project-overview__description-item-inner">{t('form.projectOverview.tableHeader.subtitle.aaz')}</div>
      </div>
      <div className="tile-project-overview__description-item">
        <div className="tile-project-overview__description-item-inner">{t('form.projectOverview.tableHeader.subtitle.quality')}</div>
      </div>
      <div className="tile-project-overview__description-item">
        <div className="tile-project-overview__description-item-inner">{t('form.projectOverview.tableHeader.subtitle.finance')}</div>
      </div>
    </div>
  );
};

export default ProjectOverviewDescription;
