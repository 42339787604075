import * as React from 'react';
import { CheckboxListItemStatus } from '../../core/enums/CheckboxListItemStatus';
import CheckboxTile from '../CheckboxListTile/TileCheckboxList';
import { CheckboxTileTypes } from '../../core/enums/CheckboxTileTypes';

import TilePresenceListInfo from './TilePresenceListInfo';

interface Props {
  url: string;
  boardId?: number;
}

const TilePresenceList: React.FC<Props> = ({ url, boardId }) => {
  return (
    <CheckboxTile
      url={url}
      boardId={boardId}
      tileType={CheckboxTileTypes.Presence}
      getNextStatus={(status: CheckboxListItemStatus) => {
        let newStatus: CheckboxListItemStatus;
        if (!status) {
          newStatus = CheckboxListItemStatus.Green;
        } else if (status.toLowerCase() === CheckboxListItemStatus.Undefined.toLowerCase()) {
          newStatus = CheckboxListItemStatus.Green;
        } else if (status === CheckboxListItemStatus.Green) {
          newStatus = CheckboxListItemStatus.Red;
        } else if (status === CheckboxListItemStatus.Red) {
          newStatus = CheckboxListItemStatus.Yellow;
        } else if (status === CheckboxListItemStatus.Yellow) {
          newStatus = CheckboxListItemStatus.Grey;
        } else if (status === CheckboxListItemStatus.Grey) {
          newStatus = CheckboxListItemStatus.Undefined;
        } else {
          newStatus = CheckboxListItemStatus.Undefined;
        }
        return newStatus;
      }}
      InfoComponent={TilePresenceListInfo}
    />
  );
};

export default TilePresenceList;
