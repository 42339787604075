import service from '../RestService';
import { RestResponse } from '../../types/response/RestResponse';
import { AreaProject } from '../../state/back-office/types';

class BackOfficeProjectAreaService {
  getAreaProjects = (url: string): RestResponse<AreaProject[]> => service.get(`${url}`);

  saveAreaProjects = (url: string, areaProjects: AreaProject[]): RestResponse<AreaProject[]> => service.post(`${url}`, areaProjects);
}

export default new BackOfficeProjectAreaService();
