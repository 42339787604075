import * as React from 'react';
import { ProcessConfirmationFormColumn } from '../../../core/state/process-confirmation/types';
import TD from '../../../components/Table/TD/TD';
import TileProcessConfirmationColumnDefault from './TileProcessConfirmationRowColumnDefault';
import TileProcessConfirmationColumnMultiline from './TileProcessConfirmationRowColumnMultiline';
import { SelectedItem } from '../types';
import { TableFormFieldStatus } from '../../../core/enums/TableFormFieldStatus';

interface Props {
  column: ProcessConfirmationFormColumn;
  columnIndex: number;
  rowIndex: number;
  selectedItem: SelectedItem;
  setSelectedItem: React.Dispatch<React.SetStateAction<SelectedItem>>;
  handleIndicatorStatus: (status: TableFormFieldStatus, rowIndex: number, columnIndex: number, multiLineIndex?: number) => void;
  handleBlur: (value: string, rowIndex: number, columnIndex: number, multiLineIndex?: number) => void;
}

const TileProcessConfirmationColumn: React.FC<Props> = ({
  column,
  columnIndex,
  rowIndex,
  handleIndicatorStatus,
  selectedItem,
  setSelectedItem,
  handleBlur
}) => {
  return (
    <TD className="tile-process-confirmation__column">
      <div className="tile-process-confirmation__column-inner">
        {columnIndex !== 7 ? (
          <TileProcessConfirmationColumnDefault
            selectedItem={selectedItem}
            setSelectedItem={setSelectedItem}
            handleIndicatorStatus={handleIndicatorStatus}
            column={column}
            columnIndex={columnIndex}
            rowIndex={rowIndex}
            handleBlur={handleBlur}
          />
        ) : (
          <TileProcessConfirmationColumnMultiline
            selectedItem={selectedItem}
            setSelectedItem={setSelectedItem}
            handleIndicatorStatus={handleIndicatorStatus}
            column={column}
            columnIndex={columnIndex}
            rowIndex={rowIndex}
            handleBlur={handleBlur}
          />
        )}
      </div>
    </TD>
  );
};

export default TileProcessConfirmationColumn;
