import * as React from 'react';
import Notifications from '../../pages/components/Notifications/Notifications';
import { isDesktopScreen } from '../../core/utils/isDesktopScreen';

import './MasterPage.scss';

const MasterPage: React.FC<any> = ({ children }) => {
  const desktopScreen = isDesktopScreen();
  return (
    <div className={`master-page ${desktopScreen ? 'no-font-scaling' : ''}`}>
      <Notifications />
      {children}
    </div>
  );
};

export default MasterPage;
