import * as React from 'react';
import { Route, useRouteMatch } from 'react-router-dom';

import Heading from '../../../../components/Heading/Heading';
import { BoardTypes } from '../../../../core/enums/BoardTypes';
import HistoryDropDown from './HistoryDropDown/HistoryDropDown';
import { BOARD_HISTORY_VIEW, BOARDS_ID, BOARDS_TILE_DETAIL } from '../../../../core/constants/routes';
import { isDesktopScreen } from '../../../../core/utils/isDesktopScreen';

import './BoardHeading.scss';

interface Props {
  text?: string;
  boardId?: number;
  boardType?: BoardTypes;
}

const BoardHeading: React.FC<Props> = ({ text, boardId, boardType }) => {
  const isDetailPage = useRouteMatch(BOARDS_TILE_DETAIL);
  const isDesktop = isDesktopScreen();
  return (
    <div className="d-flex flex-column align-items-center">
      {!isDetailPage && (
        <>
          <Heading size="h2" className={`board-heading no-font-scaling--${isDesktop ? 'desktop-screen' : 'tv-screen'}`}>
            {text}
          </Heading>
          <Route exact path={[BOARDS_ID, BOARD_HISTORY_VIEW]}>
            <HistoryDropDown boardId={boardId} text={text} boardType={boardType} />
          </Route>
        </>
      )}
    </div>
  );
};

export default BoardHeading;
