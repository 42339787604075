import * as React from 'react';
import { useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';

import { RootState } from '../../core/state/state';
import { BOARDS_TILE_DETAIL } from '../../core/constants/routes';
import Table from '../../components/Table/Table';
import THead from '../../components/Table/THead/THead';
import TileEscalationTableHeader from './TileEscalationTableHeader/TileEscalationTableHeader';
import TileEscalationHeader from './TileEscalationHeader/TileEscalationHeader';
import TileEscalationRow from './TileEscalationRow/TileEscalationRow';
import TileEscalationEmptyRow from './TileEscalationRow/TileEscalationEmptyRow';

import './TileEscalation.scss';

interface Props {
  url?: string;
  boardId?: number;
}

const TileEscalation: React.FC<Props> = () => {
  const isDetailPage = useRouteMatch(BOARDS_TILE_DETAIL);
  const escalations = useSelector((state: RootState) => state.tableForm.measuresEscalationForm.form?.escalations);
  const level = useSelector((state: RootState) => state.tableForm.measuresEscalationForm.form?.level);

  const isEmpty = !escalations?.length;
  return (
    <div className="tile-escalation">
      <div className="tile-escalation__content">
        <TileEscalationHeader />
        <div className="tile-escalation__table-wrap">
          <Table className={`${isEmpty ? 'tile-escalation__table--empty' : ''} ${isDetailPage ? 'tile-escalation__table' : 'table--small'}`}>
            <THead>
              <TileEscalationTableHeader />
            </THead>
            <tbody>
              {!isEmpty &&
                escalations?.map((escalation, rowIndex) => (
                  <TileEscalationRow key={escalation.id || `new-${rowIndex}`} rowIndex={rowIndex} escalation={escalation} level={level} />
                ))}
              {isEmpty && <TileEscalationEmptyRow />}
            </tbody>
          </Table>
        </div>
      </div>
    </div>
  );
};

export default TileEscalation;
