import { applyMiddleware, createStore } from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';

import rootReducer from './rootReducer';
import defaultState from './state';

const devToolsExtension = composeWithDevTools(applyMiddleware(thunk));

export default createStore(rootReducer, defaultState, devToolsExtension);
