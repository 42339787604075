import React from 'react';
import { useSelector } from 'react-redux';
import { t } from '../../../i18n';
import TD from '../../components/Table/TD/TD';
import TFoot from '../../components/Table/TFoot/TFoot';
import TR from '../../components/Table/TR/TR';
import { RootState } from '../../core/state/state';

const TileTrackingTableFooter: React.FC = () => {
  const tableForm = useSelector((state: RootState) => state.trackingAAZ.form);
  return (
    <TFoot>
      <TR>
        <TD colSpan={2} className="table-sum-title text-left">
          {t('forms.trackingAAZ.column.sumOfAmount')}
        </TD>
        <TD className="table-sum-number text-left">
          {`${tableForm?.rows
            .reduce((acc, row) => {
              const a = parseFloat(row.columns[1].text) || 0;
              return acc + a;
            }, 0)
            .toLocaleString('de-DE', {
              style: 'currency',
              currency: 'EUR',
              maximumFractionDigits: 0
            })}`}
        </TD>
        <TD colSpan={3} className="table-no-border" />
        <TD className="table-sum-number text-left">
          {`${tableForm?.rows
            .reduce((acc, row) => {
              const a = parseFloat(row.columns[5].text) || 0;
              return acc + a;
            }, 0)
            .toLocaleString('de-DE', {
              style: 'unit',
              unitDisplay: 'long',
              unit: 'day',
              useGrouping: false,
              maximumFractionDigits: 0
            })}`}
        </TD>
      </TR>
    </TFoot>
  );
};

export default TileTrackingTableFooter;
