import React from 'react';
import { ProgressBar } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { toDisplayDateString } from '../../../../../core/utils/formatDate';
import { FileDetails as FileDetailsModel } from '../../../../../core/services/back-office/BackOfficeFileService';
import Icon from '../../../../../components/Icon/Icon';

interface Props {
  uploadInProgress: boolean;
  uploadProgress: number;
  uploadingFilename: string;
  fileDetails?: FileDetailsModel;
}

const FileDetails: React.FC<Props> = ({ uploadInProgress, uploadProgress, uploadingFilename, fileDetails }) => {
  const { t } = useTranslation();

  return (
    <div className="file-details">
      <div className="file-details__icon">
        <Icon icon="filePdf" size="full-size" />
      </div>
      <div className="file-details__content">
        <p className="file-details__name">{uploadInProgress ? uploadingFilename : fileDetails?.filename}</p>
        {uploadInProgress ? (
          <ProgressBar now={uploadProgress} variant="success" />
        ) : (
          <p className="file-details__details">
            {t('backOffice.file.details', { date: toDisplayDateString(fileDetails?.uploadedAt ?? new Date()), name: fileDetails?.uploadedBy ?? '' })}
          </p>
        )}
      </div>
    </div>
  );
};

export default FileDetails;
