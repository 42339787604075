import service from './RestService';
import { RestResponse } from '../types/response/RestResponse';
import { MenuList } from '../state/menu/types';

const path = '/menu';

class MenuService {
  getMenuList = (): RestResponse<MenuList[]> => service.get(`${path}/main-menu`);
}

export default new MenuService();
