import React from 'react';

import { Sizes } from '../../../core/enums/Sizes';
import { Color } from '../../../core/enums/Color';

import './StatusTextIcon.scss';

export enum StatusTextIconType {
  Circle = 'circle',
  Square = 'square'
}

interface Props {
  text?: string;
  size?: Sizes;
  color?: Color;
  type?: StatusTextIconType;
  className?: string;
}

const StatusTextIcon: React.FC<Props> = ({ text, size = Sizes.Medium, color = Color.Black, type = StatusTextIconType.Circle, className = '' }) => {
  return (
    <span className={`status-text-icon status-text-icon--${size} status-text-icon--${color} status-text-icon--${type} ${className}`}>
      <span className="status-text-icon__icon" />
      {text && <span className="status-text-icon__text">{text}</span>}
    </span>
  );
};

export default StatusTextIcon;
