import * as React from 'react';

interface Props {
  text: string;
  color: 'red' | 'green' | 'white';
  size?: 'sm';
}

const TileProcessConfirmationIndicator: React.FC<Props> = ({ text, color, size }) => {
  return (
    <div className="tile-process-confirmation__indicator">
      <div className={`tile-process-confirmation__indicator-box${color ? ` tile-process-confirmation__indicator-box--${color}` : ''}`} />
      <div className={`tile-process-confirmation__indicator-text${size ? ` tile-process-confirmation__indicator-text--${size}` : ''}`}>{text}</div>
    </div>
  );
};

export default TileProcessConfirmationIndicator;
