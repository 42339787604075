import * as React from 'react';

import TableFormTileDesktopScreen from '../../TableFormTile/DesktopScreen/TableFormTileDesktopScreen';
import { TileTypes } from '../../../core/enums/TileTypes';
import { TableTileTypes } from '../../../core/enums/TableTileTypes';

interface Props {
  url: string;
}

const TileDefectProcessingDesktopScreen: React.FC<Props> = ({ url }) => {
  return (
    <TableFormTileDesktopScreen
      url={url}
      tileType={TileTypes.DefectProcessing}
      tableTileType={TableTileTypes.DefectProcessing}
      tableHeaderColSpan={2}
      showStatus
    />
  );
};

export default TileDefectProcessingDesktopScreen;
