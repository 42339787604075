import { createAction } from '../../utils/action';
import ActionTypes from '../actionTypes';
import { RestErrorResponse } from '../../types/response/RestErrorResponse';
import { ProjectAreaOverviewForm, ProjectAreaStatusForm } from './types';
import { ActionsUnion } from '../types';

export const Actions = {
  getProjectAreaOverview: () => createAction(ActionTypes.GET_PROJECT_AREA_OVERVIEW),
  getProjectAreaOverviewSuccess: (areaOverviewData: ProjectAreaOverviewForm) =>
    createAction(ActionTypes.GET_PROJECT_AREA_OVERVIEW_SUCCESS, areaOverviewData),
  getProjectAreaOverviewError: (error: RestErrorResponse) => createAction(ActionTypes.GET_PROJECT_AREA_OVERVIEW_ERROR, error),

  getProjectAreaStatuses: () => createAction(ActionTypes.GET_PROJECT_AREA_STATUS_SUMMARY),
  getProjectAreaStatusesSuccess: (projectAreaStatuses: { areaId: number; projectStatuses: ProjectAreaStatusForm }) =>
    createAction(ActionTypes.GET_PROJECT_AREA_STATUS_SUMMARY_SUCCESS, projectAreaStatuses),
  getProjectAreaStatusesError: (error: RestErrorResponse) => createAction(ActionTypes.GET_PROJECT_AREA_STATUS_SUMMARY_ERROR, error)
};

export type ProjectAreaActions = ActionsUnion<typeof Actions>;
