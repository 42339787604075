import * as React from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../core/state/state';
import { BoardTypes } from '../../../core/enums/BoardTypes';
import Board from '../Board/Board';
import BoardHeader from '../Board/BoardHeader/BoardHeader';
import { findMenuListById } from '../../../core/utils/breadcrumb/findMenuListById';
import { MenuList } from '../../../core/state/menu/types';
import { getHistoryList, setSelectedHistoryListItem } from '../../../core/state/history/asyncActions';

import './History.scss';

const History: React.FC<any> = () => {
  const list = useSelector((state: RootState) => state.history.list);
  const menuList = useSelector((state: RootState) => state.menuItems.list);
  const dispatch = useDispatch();
  const { boardId, boardType, selectedHistoryListItemIndex } = useParams<{
    boardId: string;
    boardType: BoardTypes;
    selectedHistoryListItemIndex: string;
  }>();
  const menuItem = findMenuListById(Number(boardId), menuList) as MenuList;

  React.useEffect(() => {
    dispatch(getHistoryList(Number(boardId)));
    dispatch(setSelectedHistoryListItem(Number(selectedHistoryListItemIndex)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, boardId]);

  return (
    <Board>
      <BoardHeader boardId={Number(boardId)} text={menuItem?.name ?? ''} boardType={boardType} />
      <img src={list[Number(selectedHistoryListItemIndex)]?.downloadLink} className="history" alt="" />
    </Board>
  );
};

export default History;
