import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { RootState } from '../../../core/state/state';
import { getStandingRules } from '../../../core/state/table-forms/asyncActions';
import Icon from '../../../components/Icon/Icon';
import Tooltip from '../../../components/Tooltip/Tooltip';

import './TileStandingRulesDesktopScreen.scss';

interface Props {
  url: string;
}

const TileStandingRulesDesktopScreen: React.FC<Props> = ({ url }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  React.useEffect(() => {
    dispatch(getStandingRules(url));
  }, [dispatch, url]);

  const standingRulesForm = useSelector((state: RootState) => state.tableForm.standingRulesForm.form);

  const getTooltip = (text: string, children: any) => (
    <Tooltip placement="right-start" text={text}>
      {children}
    </Tooltip>
  );

  const getAttendeesNames = () => {
    if (standingRulesForm?.attendees) {
      return standingRulesForm?.attendees.split(',');
    }
    return [];
  };

  const getTermin = () => {
    let termin = '';
    if (standingRulesForm?.meetingTimeStart) {
      termin = standingRulesForm.meetingTimeStart;

      if (standingRulesForm.meetingTimeEnd) {
        termin += `-${standingRulesForm.meetingTimeEnd}`;
      }
    }
    return termin;
  };

  return (
    <div className="tile-standing-rules-desktop-screen no-font-scaling">
      <div className="tile-standing-rules-desktop-screen__header">
        <span className="tile-standing-rules-desktop-screen__header-content">{t('tileStandingRules.rulesOfStanding')}</span>
        <span className="d-inline-block">
          {getTooltip(
            t('tileStandingRulesDesktopScreen.tooltip'),
            <button className="tile-standing-rules-desktop-screen__button-tooltip">
              <Icon icon="menu2" svg />
            </button>
          )}
        </span>
      </div>
      <div>
        <div className="row mb-2 mt-2 ml-2">
          <div className="col d-flex flex-column">
            <span className="tile-standing-rules-desktop-screen__title mb-1">{t('tileStandingRules.manager')}</span>
            <span className="tile-standing-rules-desktop-screen__text">{standingRulesForm?.leader}</span>
          </div>
          <div className="col d-flex flex-column">
            <span className="tile-standing-rules-desktop-screen__title mb-1">{t('tileStandingRules.deputy')}</span>
            <span className="tile-standing-rules-desktop-screen__text">{standingRulesForm?.deputy}</span>
          </div>
        </div>
        <div className="row mb-2 mt-2 ml-2">
          <div className="col d-flex flex-column">
            <span className="tile-standing-rules-desktop-screen__title mb-1">{t('tileStandingRules.attendees')}</span>
            {getAttendeesNames()?.map((name, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <span key={index} className="tile-standing-rules-desktop-screen__text mb-1">
                {name}
              </span>
            ))}
          </div>
          <div className="col d-flex flex-column">
            <span className="tile-standing-rules-desktop-screen__title mb-1">{t('tileStandingRules.date')}</span>
            <span className="tile-standing-rules-desktop-screen__text mb-1">{standingRulesForm?.meetingDay}</span>
            <span className="tile-standing-rules-desktop-screen__text">{getTermin()}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TileStandingRulesDesktopScreen;
