import { createAction } from '../../utils/action';
import ActionTypes from '../actionTypes';
import { ActionsUnion } from '../types';
import { RestErrorResponse } from '../../types/response/RestErrorResponse';
import { HistoryList } from './types';

export const Actions = {
  getHistoryList: () => createAction(ActionTypes.GET_HISTORY_LIST),
  getHistoryListSuccess: (list: HistoryList[]) => createAction(ActionTypes.GET_HISTORY_LIST_SUCCESS, list),
  setSelectedHistoryListItemIndex: (index: number) => createAction(ActionTypes.SET_SELECTED_HISTORY_LIST_ITEM_INDEX, index),
  getHistoryListError: (error: RestErrorResponse) => createAction(ActionTypes.GET_HISTORY_LIST_ERROR, error)
};

export type HistoryActions = ActionsUnion<typeof Actions>;
