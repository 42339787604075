import service from './RestService';
import { RestResponse } from '../types/response/RestResponse';
import { MeasureAndEscalation, MeasureAndEscalationForm } from '../state/table-forms/types';

class MeasuresEscalationsService {
  createMeasureAndEscalation = (measuresUrl: string, measure: MeasureAndEscalation): RestResponse<void> => service.post(`${measuresUrl}`, measure);

  saveMeasuresAndEscalationsForm = (measuresUrl: string, measure: MeasureAndEscalationForm): RestResponse<MeasureAndEscalationForm> =>
    service.post(`${measuresUrl}`, measure);

  getMeasuresAndEscalations = (tileUrl: string): RestResponse<MeasureAndEscalationForm> => service.get(`${tileUrl}`);
}

export default new MeasuresEscalationsService();
