import * as React from 'react';

import './ProjectStatus.scss';

interface Props {
  project: { title: string; statuses: boolean[] };
}

const ProjectStatus: React.FC<Props> = ({ project }) => (
  <div className="project-status">
    <p className="project-status__title">{project.title}</p>
    <div className="project-status__status-bar">
      {project.statuses.map((status, index) => (
        <div
          key={`status_${index.toString()}`}
          className={`project-status__entry project-status__entry--${status ? 'complete' : 'incomplete'}`}
          data-status={status}
        />
      ))}
    </div>
  </div>
);

export default ProjectStatus;
