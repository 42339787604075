import React from 'react';
import { useTranslation } from 'react-i18next';
import { TileTypes } from '../../core/enums/TileTypes';
import { TableTileTypes } from '../../core/enums/TableTileTypes';
import TileAdditionalServicesNUInfo from './TileAdditionalServicesNUInfo';
import TableFormTile from '../TableFormTile/TableFormTile';

interface Props {
  boardId?: number;
  url: string;
}

const TileAdditionalServicesNU: React.FC<Props> = ({ url, boardId }) => {
  const { t } = useTranslation();

  return (
    <TableFormTile
      url={url}
      boardId={boardId}
      tileType={TileTypes.AdditionalServicesNU}
      tableTileType={TableTileTypes.AdditionalServicesNU}
      columns={[
        {
          field: 'id',
          title: t('forms.additionalServicesNU.column.id'),
          type: 'index',
          headerClass: 'w-3'
        },
        {
          field: 'date',
          type: 'calendar',
          title: t('forms.additionalServicesNU.column.date'),
          headerClass: 'w-13'
        },
        {
          field: 'subcontractor',
          title: t('forms.additionalServicesNU.column.subcontractor'),
          headerClass: 'w-23'
        },
        {
          field: 'descriptionOfService',
          title: t('forms.additionalServicesNU.column.descriptionOfService'),
          headerClass: 'w-23'
        },
        {
          field: 'eligible',
          title: t('forms.additionalServicesNU.column.eligible'),
          columns: [
            {
              field: 'eligible.yes',
              title: t('forms.additionalServicesNU.column.yes'),
              type: 'status'
            },
            {
              field: 'eligible.no',
              title: t('forms.additionalServicesNU.column.no'),
              type: 'status'
            }
          ]
        },
        {
          field: 'includedInReport',
          title: t('forms.additionalServicesNU.column.includedInReport'),
          columns: [
            {
              field: 'includedInReport.yes',
              title: t('forms.additionalServicesNU.column.yes'),
              type: 'status'
            },
            {
              field: 'includedInReport.no',
              title: t('forms.additionalServicesNU.column.no'),
              type: 'status'
            }
          ]
        },
        {
          field: 'orderAdditionGranted',
          title: t('forms.additionalServicesNU.column.orderAdditionGranted'),
          columns: [
            {
              field: 'orderAdditionGranted.yes',
              title: t('forms.additionalServicesNU.column.yes'),
              type: 'status'
            },
            {
              field: 'orderAdditionGranted.no',
              title: t('forms.additionalServicesNU.column.no'),
              type: 'status'
            }
          ]
        },
        {
          field: 'additionalServiceToCustomerForwardable',
          title: t('forms.additionalServicesNU.column.additionalServiceToCustomerForwardable'),
          columns: [
            {
              field: 'additionalServiceToCustomerForwardable.yes',
              title: t('forms.additionalServicesNU.column.yes'),
              type: 'status'
            },
            {
              field: 'additionalServiceToCustomerForwardable.no',
              title: t('forms.additionalServicesNU.column.no'),
              type: 'status'
            }
          ]
        }
      ]}
      InfoComponent={TileAdditionalServicesNUInfo}
      showStatus
    />
  );
};

export default TileAdditionalServicesNU;
