import ListServiceClass from '../../services/CheckboxList';
import { ReduxThunkType } from '../../types/ReduxThunkType';
import { generateActions } from './actions';
import { CheckboxAndMeasureData, CheckboxList } from './types';
import { spawnSaveSuccessNotification } from '../notifications/asyncActions';
import { Actions as SettingsActions } from '../settings/actions';

export default function generateAsyncActions(type: string) {
  const Actions = generateActions(type);
  let ListService = new ListServiceClass('/attendance-forms');
  return {
    getListByUrl: (url: string): ReduxThunkType<void> => async (dispatch) => {
      try {
        dispatch(Actions.getList());
        const response = await ListService.getList(url);
        dispatch(Actions.getListSuccess(response.data));
      } catch (error) {
        dispatch(Actions.getListError(error));
      }
    },
    getListByTileId: (tileId: number): ReduxThunkType<void> => async (dispatch) => {
      try {
        dispatch(Actions.getList());
        const response = await ListService.getListByTileId(tileId);
        dispatch(Actions.getListSuccess(response.data));
      } catch (error) {
        dispatch(Actions.getListError(error));
      }
    },
    saveList: (list: CheckboxList | CheckboxAndMeasureData, url?: string): ReduxThunkType<void> => async (dispatch) => {
      try {
        dispatch(Actions.saveList());
        if (url) {
          ListService = new ListServiceClass(url);
        }
        const response = await ListService.saveList(list);
        await dispatch(SettingsActions.setIsFormDirty(false));
        dispatch(Actions.saveListSuccess(response.data));
        dispatch(spawnSaveSuccessNotification());
      } catch (error) {
        dispatch(Actions.saveListError(error));
      }
    },
    updateList: (list: CheckboxList | CheckboxAndMeasureData, isFormDirty = true, suppressDirtyStatus = false): ReduxThunkType<void> => async (
      dispatch
    ) => {
      if (!suppressDirtyStatus) {
        await dispatch(SettingsActions.setIsFormDirty(isFormDirty));
      }
      await dispatch(Actions.updateList(list));
    }
  };
}
