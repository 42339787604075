import React, { useEffect, useState } from 'react';
import ReactSelect, { Styles } from 'react-select';

import { Sizes } from '../../../core/enums/Sizes';

import './Select.scss';

export interface SelectOption {
  value: string | number;
  label: string;
}

interface Props {
  id: string;
  className?: string;
  defaultValue?: any;
  value?: any;
  name: string;
  options: SelectOption[];
  label?: string;
  labelSize?: Sizes;
  error?: any;
  showErrorMessage?: boolean;
  onBlur?: () => void;
  onChange?: (value: any) => void;
  menuPortalTarget?: HTMLElement | null;
}

const customStyles: Styles<SelectOption, false> = {
  control: (provided) => ({
    ...provided,
    border: undefined,
    borderColor: undefined,
    borderRadius: undefined,
    backgroundColor: undefined,
    boxShadow: 'none',
    minHeight: undefined,
    flexWrap: 'nowrap'
  }),
  valueContainer: (provided) => ({
    ...provided,
    padding: undefined,
    backgroundColor: 'transparent'
  }),
  menu: (provided) => ({
    ...provided,
    borderRadius: 0,
    margin: 0
  }),
  menuList: (provided) => ({
    ...provided,
    padding: 0
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    padding: undefined
  })
};

const Select: React.FC<Props> = ({
  id,
  label,
  error,
  defaultValue,
  value,
  options,
  name,
  onChange,
  labelSize,
  className,
  showErrorMessage = true,
  menuPortalTarget
}) => {
  const hasErrorMessage = showErrorMessage && error && typeof error === 'string';
  const [selectedOption, setSelectedOption] = useState<SelectOption | undefined>(options.find((option) => option.value === value));
  const defaultOption = options.find((option) => option.value === defaultValue);

  useEffect(() => {
    setSelectedOption(options.find((option) => option.value === value));
  }, [value, options]);

  return (
    <div className={`input ${error ? 'input--error' : ''} ${className ?? ''}`}>
      {label && (
        <label className={`input__label ${labelSize ? `input__label--${labelSize}` : ''}`} htmlFor={id}>
          {label}
        </label>
      )}
      <ReactSelect
        id={id}
        name={name}
        className="input__select select"
        classNamePrefix="select"
        defaultValue={defaultOption}
        value={selectedOption}
        options={options}
        onChange={(newOption: any) => {
          const selectedValue = (newOption as SelectOption)?.value ?? null;

          if (onChange) {
            onChange(selectedValue);
          }
        }}
        styles={customStyles}
        components={{
          IndicatorSeparator: () => null
        }}
        menuPortalTarget={menuPortalTarget}
        menuPlacement="auto"
      />
      {hasErrorMessage && <div className="input__error">{error}</div>}
    </div>
  );
};

export default Select;
