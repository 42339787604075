import { StateStatus } from '../../enums/StateStatus';
import { RestErrorResponse } from '../../types/response/RestErrorResponse';
import { Tile } from '../boards/types';
import { TileTypes } from '../../enums/TileTypes';

export interface MenuList {
  boardId: number | null;
  parentId: number;
  name: string;
  children: ChildMenuList[] | null;
  tiles?: TileMenu[];
  type: MenuType;
  canAccessBackoffice: boolean;
}

export interface ChildMenuList extends MenuList {
  tiles?: TileMenu[];
}

export interface TileMenu extends Pick<Tile, 'tileId' | 'title' | 'onClickDataUrl'> {
  tileType: TileTypes;
}

export enum MenuType {
  GFBoard = 'GFBoard',
  BRLBoard = 'BRLBoard',
  PLBoard = 'PLBoard',
  Project = 'PROJECT'
}

export interface MenuState {
  list: MenuList[] | null;
  firstNavOpened: number;
  isMenuOpened: boolean;
  status: StateStatus;
  error: RestErrorResponse | null;
}
