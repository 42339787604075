import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { match } from 'react-router-dom';

import './TileTrackingAAZ.scss';

interface Props {
  isDetailPage: match | null;
}

const TileTrackingAAZInfo: React.FC<Props> = ({ isDetailPage }) => {
  const { t } = useTranslation();
  return (
    <div className={`tile-trackingaaz ${isDetailPage ? '' : 'tile-trackingaaz--small justify-content-end'} d-flex flex-column align-items-end mb-2`}>
      <div className={`tile-trackingaaz__info-text ${isDetailPage ? '' : 'text-right'}`}>
        <span className="tile-trackingaaz__info-text tile-trackingaaz__info-text-title">{t('forms.defectProcessing.statusDefinition')}</span>
        {t('forms.defectProcessing.statusDefinitionSubtitle')}
      </div>
      {isDetailPage && (
        <div className={`${isDetailPage ? '' : 'd-flex text-right mt-1'}`}>
          <span className="tile-trackingaaz__info-text">{t('forms.defectProcessing.footnote')}</span>
        </div>
      )}
    </div>
  );
};

export default TileTrackingAAZInfo;
