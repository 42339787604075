import * as React from 'react';

export interface TilePreviewProps {
  url?: string;
}

const TilePreview: React.FC<TilePreviewProps> = ({ url }) => {
  if (!url) {
    return null;
  }

  return <iframe title={url} frameBorder={0} width="100%" height="100%" src={url} />;
};

export default TilePreview;
