import { StateStatus } from '../../enums/StateStatus';
import { TableFormFieldStatus } from '../../enums/TableFormFieldStatus';
import { RestErrorResponse } from '../../types/response/RestErrorResponse';
import { Tile } from '../boards/types';
import { FormStatuses } from '../../enums/FormStatuses';

export interface TableForm {
  tableFormId: number;
  title: string;
  type: string;
  status: FormStatuses;
  tileId: number;
  hierarchyId: number;
  rows: TableFormRow[];
}

export interface TableFormRow {
  tableFormRowId?: number;
  rowNumber: number;
  measureURL?: string;
  isMeasureEditable?: boolean;
  canCreateMeasure?: boolean;
  columns: TableFormColumn[];
}

export interface MultiLineDataItem {
  status: TableFormFieldStatus;
  text: string;
  multiLineData: MultiLineDataItem[];
  columnNumber: number;
}

export enum ColumnType {
  Default = 'DEFAULT',
  Measure = 'MEASURE',
  AssignedTo = 'ASSIGNED_TO',
  Deadline = 'DEADLINE',
  MeasureStatus = 'MEASURE_STATUS',
  EscalationLevel = 'ESCALATION_LEVEL'
}

export interface TableFormColumn {
  tableFormColumnId?: number;
  columnNumber: number;
  title: string;
  text: string;
  status: TableFormFieldStatus;
  type?: ColumnType;
  multiLineData?: MultiLineDataItem[] | null;
}

export interface ProjectOverviewForm {
  title: string;
  projectOverviews: ProjectOverviewFormRow[];
}

export interface ProjectOverviewFormRow {
  projectOverviewId: number;
  projectUrl: string | undefined;
  projectName: string;
  projectId: number;
  boardId: number;
  rowNumber: number;
  items: ProjectOverviewFormColumn[];
}

export interface ProjectOverviewFormColumn {
  columnNumber: number;
  text: string;
  status: TableFormFieldStatus;
}

export interface GoalsForm {
  goalsFormId: number;
  hierarchyId: number;
  planningLeft: TableForm | null;
  planningRight: TableForm | null;
  details: TableForm | null;
  pdfTile?: Tile;
}

export type GoalKeys = 'planningLeft' | 'planningRight' | 'details';

export interface StandingRulesForm {
  rulesOfStandingId: number;
  hierarchyId: number;
  leader: string;
  deputy: string;
  attendees: string;
  meetingDay: string;
  meetingTimeStart: string;
  meetingTimeEnd: string;
  details: TableForm | null;
}

export type TableFormType = TableForm | GoalsForm | StandingRulesForm;

export interface TableFormState<T> {
  form: T | null;
  status: StateStatus;
  error: RestErrorResponse | null;
}

export enum MeasureActivity {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE'
}

export interface MeasureDetail {
  id?: number | string;
  type: string;
  date: string | null;
  problemText: string;
  measureText: string;
  assignedTo: string;
  createdAt?: string;
  modifiedAt?: string;
  deadline: string | null;
  status: TableFormFieldStatus;
  activity?: MeasureActivity;
  escalationId?: number | string | null;
}

export enum EscalationLevel {
  CON = 'CON',
  PL = 'PL',
  BRL = 'BRL',
  GF = 'GF',
  V = 'V'
}

export enum EscalationType {
  Informative = 'Informative',
  Action = 'Action'
}

export enum EscalationActivity {
  Active = 'ACTIVE',
  Disabled = 'DISABLED'
}

export interface EscalationDetail {
  id?: number | string;
  problem: string;
  riskPotential: string;
  summary: string;
  createdAt?: string;
  openedAt: string | null;
  deadlineAt: string | null;
  level: EscalationLevel | null;
  status: TableFormFieldStatus;
  activity?: EscalationActivity;
  type: EscalationType;
  measureId?: number | string | null;
  canEscalate?: boolean;
  canDeEscalate?: boolean;
  canDelete?: boolean;
  canConvertToMeasure?: boolean;
  assignedTo?: string;
}

export interface MeasureAndEscalation {
  measure: MeasureDetail | null;
  escalation: EscalationDetail | null;
}

export interface MeasureAndEscalationForm {
  measures: MeasureDetail[] | null;
  escalations: EscalationDetail[] | null;
  level?: EscalationLevel;
}

export interface DualTableForm {
  first: TableForm;
  second: TableForm;
}

export interface TableFormsState {
  form: TableFormState<TableForm>;
  sickLeaveForm: TableFormState<TableForm>;
  kvpForm: TableFormState<TableForm>;
  projectOverviewForm: TableFormState<ProjectOverviewForm>;
  goalsForm: TableFormState<GoalsForm>;
  weekTopicsForm: TableFormState<TableForm>;
  standingRulesForm: TableFormState<StandingRulesForm>;
  measuresEscalationForm: TableFormState<MeasureAndEscalationForm>;
}

export enum TableFormStateNamespaces {
  Form = 'form',
  WeekTopics = 'weekTopicsForm',
  KVP = 'kvpForm',
  SickLeave = 'sickLeaveForm',
  ProjectOverview = 'projectOverviewForm',
  Goals = 'goalsForm',
  StandingRules = 'standingRulesForm',
  MeasuresEscalation = 'measuresEscalationForm'
}
