import React, { useEffect, useLayoutEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { TableFormFieldStatus } from '../../../core/enums/TableFormFieldStatus';
import Tooltip from '../../Tooltip/Tooltip';
import Icon from '../../Icon/Icon';
import MeasureEscalationModal from '../../../pages/components/MeasureEscalationModal/MeasureEscalationModal';
import { EMPTY_STRING } from '../../../core/constants/string';

import { MeasureAndEscalation } from '../../../core/state/table-forms/types';
import { createMeasureAndEscalation, getMeasuresAndEscalations } from '../../../core/state/table-forms/asyncActions';
import { RootState } from '../../../core/state/state';
import { TileTypes } from '../../../core/enums/TileTypes';
import { BASE_URL } from '../../../core/constants/api';

import './TableTextarea.scss';

interface Props {
  onBlur?: () => void;
  onChange?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  onDoubleClick?: () => void;
  value: string | number | null;
  type?: 'small';
  center?: boolean;
  hasStatus?: boolean;
  status?: TableFormFieldStatus;
  handleStatus: () => void;
  KVPProblem: string;
}

const TableTextarea: React.FC<Props> = ({
  onBlur,
  onChange,
  value,
  onDoubleClick,
  type,
  center = false,
  hasStatus = false,
  status,
  handleStatus,
  KVPProblem
}) => {
  const dispatch = useDispatch();
  const inputEl = React.useRef<HTMLTextAreaElement>(null);
  const textEl = React.useRef<HTMLDivElement>(null);
  const spanEl = React.useRef<HTMLSpanElement>(null);

  const [editMode, setEditMode] = useState<boolean>(false);
  const [isLongText, setIsLongText] = useState<boolean>(false);
  const [isCenteredText, setIsCenteredText] = useState<boolean>(false);
  const [isPopupOpened, setIsPopupOpened] = useState<boolean>(false);

  const measureEscalationTile = useSelector((state: RootState) => state.boards.board?.tiles)?.find(
    (item) => item.type === TileTypes.MeasuresAndEscalation
  );
  const measureEscalationEndpoint = `${BASE_URL}${measureEscalationTile?.onClickDataUrl}`;

  const handleDoubleClick = () => {
    setEditMode(true);
    if (typeof onDoubleClick === 'function') {
      onDoubleClick();
    }
  };

  const handleCreatedMeasure = async (payload: MeasureAndEscalation) => {
    await dispatch(createMeasureAndEscalation(`${measureEscalationEndpoint}/add-one`, payload));
    await dispatch(getMeasuresAndEscalations(measureEscalationEndpoint));

    setIsPopupOpened(false);
  };

  useEffect(() => {
    function handleClickOutside(event: Event) {
      if (event.target instanceof HTMLElement && inputEl.current && !inputEl.current.contains(event.target)) {
        if (onBlur) {
          onBlur();
        }
        setEditMode(false);
      }
    }
    if (inputEl.current) {
      inputEl.current.focus();
      document.addEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [inputEl, onBlur]);

  useEffect(() => {
    if (textEl.current) {
      const { offsetHeight, scrollHeight } = textEl.current;
      setIsLongText(offsetHeight < scrollHeight);
    }
  }, [textEl, value]);

  useLayoutEffect(() => {
    if (spanEl.current) {
      const spanHeight = spanEl.current?.offsetHeight;
      const lineHeightInPx = document.defaultView?.getComputedStyle(spanEl.current, null).getPropertyValue('line-height');

      const lineHeight = parseFloat(String(lineHeightInPx));
      const numberOfLines = Math.floor(spanHeight / lineHeight);

      setIsCenteredText(numberOfLines <= 3);
    }
  }, [textEl, value]);

  return (
    <div className={`table-textarea ${type ? `table-textarea--${type}` : ''} ${editMode ? 'table-textarea--edit' : ''}`}>
      {editMode && <textarea ref={inputEl} className="table-textarea__textarea" value={value || ''} onChange={onChange} />}
      <div ref={textEl} className={`table-textarea__text ${isCenteredText || center ? ' table-textarea__text--center' : ''}`}>
        <span className="table-textarea__span" ref={spanEl}>
          {value}
        </span>
      </div>
      <div className="table-textarea__overlay" onDoubleClick={handleDoubleClick} />
      {hasStatus && isLongText && (
        <div className={`table-textarea__tooltip ${status ? ` table-textarea__tooltip--${status.toLowerCase()}` : ''}`}>
          <Tooltip text={String(value)}>
            <div className="table-textarea__tooltip-inner">...</div>
          </Tooltip>
        </div>
      )}
      <button className="table-textarea__add" onClick={() => setIsPopupOpened(true)}>
        <Icon icon="plus" />
      </button>
      {isPopupOpened && (
        <MeasureEscalationModal
          KVPText={{
            problem: KVPProblem,
            measure: value ?? EMPTY_STRING
          }}
          onConfirm={handleCreatedMeasure}
          onCancel={() => setIsPopupOpened(false)}
          show={isPopupOpened}
          warnUnsavedChanges
        />
      )}
      {hasStatus && (
        <button onClick={handleStatus} className="table-textarea__status">
          <div className={`table-textarea__status-inner ${status ? ` table-textarea__status-inner--${status.toLowerCase()}` : ''}`}>{status}</div>
        </button>
      )}
    </div>
  );
};

export default TableTextarea;
