import React from 'react';
import { useTranslation } from 'react-i18next';
import TD from '../../../components/Table/TD/TD';

const TileCapacitySubcontractorNumberInfo = () => {
  const { t } = useTranslation();
  return (
    <>
      <TD className="empty" />
      <TD className="tile-capacity-subcontractor__number-info">
        <div className="tile-capacity-subcontractor__number-info-inner">
          <div className="tile-capacity-subcontractor__number-info-item">{t('forms.capacitySubcontractor.planned')}</div>
          <div className="tile-capacity-subcontractor__number-info-item">{t('forms.capacitySubcontractor.actual')}</div>
          <div className="tile-capacity-subcontractor__number-info-item">{t('forms.capacitySubcontractor.delta')}</div>
        </div>
      </TD>
      <TD className="empty" />
    </>
  );
};

export default TileCapacitySubcontractorNumberInfo;
