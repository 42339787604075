import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { BoardTypes } from '../../../../../core/enums/BoardTypes';
import Tooltip from '../../../../../components/Tooltip/Tooltip';
import { logout } from '../../../../../core/state/auth/asyncActions';
import Icon from '../../../../../components/Icon/Icon';
import { ReactComponent as KoesterLogo } from '../../../../../../img/koester-logo.svg';
import { RootState } from '../../../../../core/state/state';

interface Props {
  text?: string;
  boardId?: number;
  boardType?: BoardTypes;
}

const BoardHeaderRight: React.FC<Props> = () => {
  const dispatch = useDispatch();
  const account = useSelector((state: RootState) => state.auth.authInfo?.account);
  const isNameLong = account?.name && account?.name.length > 20;
  return (
    <div className="board-header__right">
      <div className="board-header__name">
        {isNameLong ? (
          <Tooltip placement="auto-end" text={account?.name}>
            <span>{`${account?.name.substring(0, 17)}...`}</span>
          </Tooltip>
        ) : (
          account?.name
        )}
      </div>
      <button
        className="board-header__logout"
        onClick={() => {
          dispatch(logout());
        }}
      >
        <Icon icon="logout" className="board-header__logout-icon" size="full-size" />
      </button>
      <KoesterLogo className="board-header__logo" />
    </div>
  );
};

export default BoardHeaderRight;
