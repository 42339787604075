import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { Board as BoardModel, Tile as TileModel } from '../../core/state/boards/types';
import { Color } from '../../core/enums/Color';

import Board from '../components/Board/Board';
import BoardHeader from '../components/Board/BoardHeader/BoardHeader';
import BoardHeaders from '../components/Board/BoardHeaders/BoardHeaders';
import SectionHeader from '../components/SectionHeader/SectionHeader';
import BoardContent from '../components/Board/BoardContent/BoardContent';
import TVScreen from '../components/MediaQueries/TVScreen';
import TopManagementTVGrid from './TopManagementTVGrid';
import DesktopScreen from '../components/MediaQueries/DesktopScreen';
import TopManagementDesktopGrid from './TopManagementDesktopGrid';

interface Props {
  board: BoardModel<TileModel>;
}

const TopManagement: React.FC<Props> = ({ board }) => {
  const { t } = useTranslation();

  return (
    <Board>
      <BoardHeader text={board.name} boardType={board.type} boardId={board.boardId} />
      <BoardContent>
        <BoardHeaders>
          <div className="container-fluid">
            <div className="row">
              <div className="col-5">
                <SectionHeader text={t('mainBoard.title.general')} color={Color.Grey} />
              </div>
              <div className="col-15">
                <SectionHeader text={t('mainBoard.title.currentAreas')} color={Color.GreenLight} />
              </div>
              <div className="col-5">
                <SectionHeader text={t('mainBoard.title.targetPlanning')} color={Color.GreenLight} />
              </div>
            </div>
          </div>
        </BoardHeaders>
        <TVScreen>
          <TopManagementTVGrid board={board} />
        </TVScreen>
        <DesktopScreen>
          <TopManagementDesktopGrid board={board} />
        </DesktopScreen>
      </BoardContent>
    </Board>
  );
};

export default TopManagement;
