import React, { useCallback, useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { cloneDeep, findIndex, nth } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { differenceInDays, getDaysInMonth } from 'date-fns';
import { TileTypes } from '../../core/enums/TileTypes';
import { BOARDS, BOARDS_TILE_DETAIL } from '../../core/constants/routes';
import { RootState } from '../../core/state/state';

import ButtonToolbar from '../../pages/components/ButtonToolbar/ButtonToolbar';
import Button from '../../components/Button/Button';
import UnsavedChangesPopup from '../../components/popup/UnsavedChangesPopup/UnsavedChangesPopup';
import NavArrow from '../../pages/components/NavArrow/NavArrow';
import StatusTextIcon, { StatusTextIconType } from '../../pages/components/StatusTextIcon/StatusTextIcon';
import { Sizes } from '../../core/enums/Sizes';
import { Color } from '../../core/enums/Color';
import { generateAsyncActions } from '../../core/utils/state/generateAsyncActions';
import { TileService } from '../../core/utils/state/TileService';
import { OccupationalSafety, OSDataStatus, OSDay } from '../../core/state/occupational-safety/types';
import { changeStatus, findLastDay, getMonthDate, getNameOfTheMonth } from './helpers';
import { history } from '../../core/state/history';
import MeasureEscalationModal from '../../pages/components/MeasureEscalationModal/MeasureEscalationModal';
import { MeasureAndEscalation } from '../../core/state/table-forms/types';
import { createMeasureAndEscalation } from '../../core/state/table-forms/asyncActions';
import { BASE_URL } from '../../core/constants/api';
import TileOccupationalSafetyTable from './TileOccupationalSafetyTable';
import TileOccupationalSafetyInfo from './TileOccupationalSafetyInfo';

import occupationalSafetyImage from '../../../img/occupational-safety.png';

import './TileOccupationalSafety.scss';

interface Props {
  boardId?: number;
  url: string;
}

const TileOccupationalSafety: React.FC<Props> = ({ url, boardId }) => {
  const dispatch = useDispatch();
  const form = useSelector((state: RootState) => state.occupationalSafety.form);
  const { isFormDirty, isFormEditing } = useSelector((state: RootState) => state.settings);
  const isDetailPage = useRouteMatch(BOARDS_TILE_DETAIL);
  const { t } = useTranslation();
  const [selectedMonth, setSelectedMonth] = useState(-1);
  const [isModalOpen, setModalOpen] = useState<boolean>(false);

  const lastIncidentDay = findLastDay(form, OSDataStatus.Yellow);
  const lastAccidentDay = findLastDay(form, OSDataStatus.Red);

  const daysWithoutIncident = lastIncidentDay ? differenceInDays(new Date(), new Date(lastIncidentDay?.date ?? new Date())) : -1;
  const daysWithoutAccident = lastAccidentDay ? differenceInDays(new Date(), new Date(lastAccidentDay?.date ?? new Date())) : -1;

  const getThisOSMonth = () => (form?.months ? form?.months[selectedMonth] : null);
  const dateThisMonth = getMonthDate(getThisOSMonth()?.month);
  const nameOfTheMonth = getNameOfTheMonth(dateThisMonth);
  const thisMonth = getThisOSMonth();
  const daysInMonth = getDaysInMonth(dateThisMonth);

  const { getTileForm, updateTileForm, saveTileForm } = React.useMemo(
    () => generateAsyncActions<OccupationalSafety>(TileTypes.OccupationalSafety, new TileService()),
    []
  );

  React.useEffect(() => {
    dispatch(getTileForm(url));
  }, [url, dispatch, getTileForm]);

  React.useEffect(() => {
    if (form?.months && selectedMonth === -1) {
      setSelectedMonth(form?.months.length - 1);
    }
  }, [form, selectedMonth]);

  const handleCreatedMeasure = async (payload: MeasureAndEscalation) => {
    await dispatch(createMeasureAndEscalation(`${BASE_URL}${form?.measureURL}/add-one`, payload));

    setModalOpen(false);
  };

  const closeModal = useCallback(() => {
    setModalOpen(false);
  }, [setModalOpen]);

  const clearAllFields = () => {
    const newForm = cloneDeep(form);
    if (newForm) {
      newForm.months?.forEach((month) => {
        if (month?.month === getThisOSMonth()?.month) {
          month.days?.forEach((day) => {
            // eslint-disable-next-line no-param-reassign
            day.status = OSDataStatus.Undefined;
          });
        }
      });
      dispatch(updateTileForm(newForm));
    }
  };

  const handleChangeStatus = (day: number) => {
    const newForm = cloneDeep(form);
    if (selectedMonth !== -1 && newForm?.months) {
      const dayIndex = findIndex(newForm?.months[selectedMonth].days, (item) => item.day === day);
      if (dayIndex !== -1) {
        const selectedDay = cloneDeep(nth(newForm?.months[selectedMonth]?.days, dayIndex));
        if (selectedDay) {
          selectedDay.status = changeStatus(selectedDay.status);
          (newForm.months[selectedMonth].days as OSDay[])[dayIndex] = selectedDay;
          dispatch(updateTileForm(cloneDeep(newForm)));
        }
      }
    }
  };

  const handleSubmit = useCallback(async () => {
    if (form) {
      await dispatch(saveTileForm(url, form));
      history.push(`${BOARDS}/${boardId}`);
    }
  }, [dispatch, form, url, boardId, saveTileForm]);

  return (
    <div className={`tile-occupational-safety ${isDetailPage ? '' : 'tile-occupational-safety--small'}`}>
      <div className={`h-100 ${isDetailPage ? 'container-fluid' : ''}`}>
        <div className="tile-occupational-safety__wrap">
          <div className={isDetailPage ? '' : 'px-1 pt-1 d-flex justify-content-between'}>
            <div>
              {!isDetailPage && <div className="tile-occupational-safety__title">{t('forms.occupationalSafety.title')}</div>}
              <div className="d-flex justify-content-between align-items-center">
                <div className="tile-occupational-safety__sub-title">{t('forms.occupationalSafety.subtitle')}</div>
                {isDetailPage && <ButtonToolbar submit={handleSubmit} clearAll={clearAllFields} />}
              </div>
            </div>
            <div className="tile-occupational-safety__info">
              <StatusTextIcon
                className={isDetailPage ? 'mr-2' : ''}
                type={StatusTextIconType.Square}
                text={t('forms.occupationalSafety.table.1')}
                size={isDetailPage ? Sizes.Medium : Sizes.Small}
                color={Color.Green}
              />
              {!isDetailPage && <br />}
              <StatusTextIcon
                className={isDetailPage ? 'mr-2' : ''}
                type={StatusTextIconType.Square}
                text={t('forms.occupationalSafety.table.2')}
                size={isDetailPage ? Sizes.Medium : Sizes.Small}
                color={Color.Orange}
              />
              {!isDetailPage && <br />}
              <StatusTextIcon
                type={StatusTextIconType.Square}
                text={t('forms.occupationalSafety.table.3')}
                size={isDetailPage ? Sizes.Medium : Sizes.Small}
                color={Color.Red}
              />
            </div>
          </div>
          <div className={`tile-occupational-safety__wrap-left ${isDetailPage ? '' : 'px-1'}`}>
            <div className="tile-occupational-safety__left">
              <div className="tile-occupational-safety__left-top">
                <div className="tile-occupational-safety__nav-wrap">
                  <div className="tile-occupational-safety__month">
                    {nameOfTheMonth}&nbsp;
                    {getThisOSMonth()?.year}
                  </div>
                  {isDetailPage && (
                    <div className="tile-occupational-safety__nav">
                      <NavArrow
                        disabled={selectedMonth <= 0}
                        onClick={() => {
                          setSelectedMonth(selectedMonth - 1);
                        }}
                      />
                      <NavArrow
                        disabled={selectedMonth >= (form?.months?.length ?? 0) - 1}
                        onClick={() => {
                          setSelectedMonth(selectedMonth + 1);
                        }}
                        isRight
                      />
                    </div>
                  )}
                </div>
                <div className="tile-occupational-safety__table-wrap">
                  <TileOccupationalSafetyTable daysInMonth={daysInMonth} handleChangeStatus={handleChangeStatus} thisMonth={thisMonth} />
                </div>
              </div>
              <div className="tile-occupational-safety__table-info">
                <div className="tile-occupational-safety__table-info-wrap">
                  <TileOccupationalSafetyInfo
                    text={
                      daysWithoutIncident < 0
                        ? t('forms.occupationalSafety.table.daysWithoutEvent.noEvents')
                        : t('forms.occupationalSafety.table.daysWithoutEvent')
                    }
                    amount={daysWithoutIncident}
                  />
                  <TileOccupationalSafetyInfo
                    status={OSDataStatus.Red}
                    text={
                      daysWithoutAccident < 0
                        ? t('forms.occupationalSafety.table.daysWithoutAccident.noAccidents')
                        : t('forms.occupationalSafety.table.daysWithoutAccident')
                    }
                    amount={daysWithoutAccident}
                  />
                </div>
                {isDetailPage && (
                  <div>
                    <Button className="flex-grow-0" onClick={() => setModalOpen(true)} buttonTypes={['primary', 'large']}>
                      {t('forms.occupationalSafety.table.measuresButton')}
                    </Button>
                  </div>
                )}
              </div>
            </div>
            {isDetailPage && (
              <div className="tile-occupational-safety__right">
                <div className="tile-occupational-safety__image-wrap">
                  <img className="tile-occupational-safety__image" src={occupationalSafetyImage} alt="tile-occupational-safety" />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      {isDetailPage && <UnsavedChangesPopup showIf={isFormDirty || isFormEditing} />}
      {isModalOpen && (
        <MeasureEscalationModal
          KVPText={{ problem: t('forms.occupationalSafety.title'), measure: '' }}
          onConfirm={handleCreatedMeasure}
          onCancel={closeModal}
          show={isModalOpen}
          hasOnlyMeasure
        />
      )}
    </div>
  );
};

export default TileOccupationalSafety;
