import React from 'react';
import ReactDatePicker from 'react-datepicker';
import isValid from 'date-fns/isValid';
import { DATE_FORMAT } from '../../../core/constants/date';
import { toISODateString } from '../../../core/utils/formatDate';

import 'react-datepicker/dist/react-datepicker.css';
import './Calendar.scss';

interface Props {
  value: string;
  isSmall?: boolean;
  isCentered?: boolean;
  isFormInput?: boolean;
  label?: string;
  onChange: (date: string) => any;
  onCalendarOpen?: () => any;
  onCalendarClose?: () => any;
}

const Calendar: React.FC<Props> = ({
  value,
  onChange,
  onCalendarOpen,
  onCalendarClose,
  label,
  isSmall = false,
  isCentered = true,
  isFormInput = false
}) => {
  const date = value && isValid(new Date(value)) ? new Date(value) : undefined;
  return (
    <div
      className={
        `calendar${isSmall ? ' calendar--small' : ''}` +
        `${isCentered ? ' calendar--centered' : ''}` +
        `${isFormInput ? ' calendar--form-input' : ''}`
      }
    >
      {label && <div className="calendar__label">{label}</div>}
      <ReactDatePicker
        className="calendar__input"
        dateFormat={DATE_FORMAT}
        selected={date}
        onCalendarOpen={onCalendarOpen}
        onCalendarClose={onCalendarClose}
        onChange={(dateInput: Date) => {
          onChange(dateInput ? toISODateString(dateInput) : '');
        }}
        popperPlacement="auto"
        showPopperArrow
      />
    </div>
  );
};

export default Calendar;
