import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { addWeeks } from 'date-fns';

import { useRouteMatch } from 'react-router-dom';
import cloneDeep from 'lodash/cloneDeep';
import Table from '../../components/Table/Table';
import THead from '../../components/Table/THead/THead';
import TBody from '../../components/Table/TBody/TBody';
import UnsavedChangesPopup from '../../components/popup/UnsavedChangesPopup/UnsavedChangesPopup';
import { RootState } from '../../core/state/state';
import { generateAsyncActions } from '../../core/utils/state/generateAsyncActions';
import { TileTypes } from '../../core/enums/TileTypes';
import { TileService } from '../../core/utils/state/TileService';
import TileCapacitySubcontractorTableHead from './TileCapacitySubcontractorTableHead/TileCapacitySubcontractorTableHead';
import TileCapacitySubcontractorsTableRow from './TileCapacitySubconractorTableRow/TileSubcontractorTableRow';
import { getMondayOfTheWeek } from '../TileSupplySchedule/TileSupplySchedule';
import { BOARDS, BOARDS_TILE_DETAIL } from '../../core/constants/routes';
import { CapacitySubcontractor, CSROW } from '../../core/state/capacity-subcontractors/types';
import { useRowId } from '../../core/hooks/useRowNumber';
import { cleanStringIds } from '../../core/utils/cleanStringIds';
import { history } from '../../core/state/history';
import TableFormToolbar from '../TableFormTile/TableFormToolbar/TableFormToolbar';
import { saveTableFormMeasure } from '../../core/state/table-forms/asyncActions';
import { MeasureViewModel } from '../../core/utils/measures/getMeasureInfo';

import './TileCapacitySubcontractor.scss';

interface Props {
  boardId?: number;
  url: string;
}

const TileCapacitySubcontractor: React.FC<Props> = ({ url, boardId }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [date, setDate] = React.useState(getMondayOfTheWeek());
  const isDetailPage = useRouteMatch(BOARDS_TILE_DETAIL);
  const form = useSelector((state: RootState) => state.capacitySubcontractor.form);
  const nextRowNumber = useRowId(form?.rows);
  const [measuresArray, setMeasuresArray] = useState<MeasureViewModel[]>([]);

  const { isFormDirty, isFormEditing } = useSelector((state: RootState) => state.settings);

  const { getTileForm, updateTileForm, saveTileForm } = React.useMemo(
    () => generateAsyncActions<CapacitySubcontractor>(TileTypes.CapacitySubcontractor, new TileService()),
    []
  );

  const handleNextWeek = () => {
    setDate(addWeeks(date, 1));
  };

  const handlePrevWeek = () => {
    setDate(addWeeks(date, -1));
  };

  const handleSubmit = async () => {
    const newForm = cloneDeep(form);
    for (let i = 0; i < measuresArray.length; i += 1) {
      const item = measuresArray[i];
      if (item.measureURL) {
        // eslint-disable-next-line no-await-in-loop
        await dispatch(saveTableFormMeasure(item.measure, item.measureURL));
      }
    }
    if (newForm && newForm.rows) {
      newForm.rows = cleanStringIds(newForm.rows) as CSROW[];
      await dispatch(saveTileForm(url, newForm));
      history.push(`${BOARDS}/${boardId}`);
    }
  };

  const addNewRow = () => {
    const newForm = cloneDeep(form);
    if (newForm) {
      const newItem: CSROW = {
        subcontractor: '',
        measure: '',
        id: nextRowNumber(),
        days: []
      };
      newForm?.rows.push(newItem);
      dispatch(updateTileForm(newForm));
    }
  };

  React.useEffect(() => {
    dispatch(getTileForm(url));
  }, [url, dispatch, getTileForm]);

  return (
    <div className={`tile-table ${isDetailPage ? '' : 'tile-table--small'} tile-capacity-subcontractor`}>
      <div className={`${isDetailPage ? 'container-fluid' : ''} h-100`}>
        <div className="d-flex flex-column h-100">
          <div>
            <div className={isDetailPage ? '' : 'px-1 pt-1 d-flex justify-content-between'}>
              {!isDetailPage && <div className="tile-table__title">{t('forms.capacitySubcontractor.title')}</div>}
              <div className="d-flex justify-content-end align-items-baseline">
                {isDetailPage && (
                  <div className="tile-table__sub-title d-flex flex-column flex-grow-1">
                    {t('forms.capacitySubcontractor.subtitle')}
                    <div className="tile-capacity-subcontractor__note text-600">
                      {t('forms.capacitySubcontractor.note.1')}
                      <br />
                      {t('forms.capacitySubcontractor.note.2')}
                    </div>
                  </div>
                )}
                {isDetailPage && <TableFormToolbar submit={handleSubmit} addNewRow={addNewRow} />}
              </div>
            </div>
            <div className={isDetailPage ? '' : 'd-flex justify-content-between px-1'}>
              {!isDetailPage && (
                <div className="tile-table__sub-title d-flex flex-column flex-grow-1">
                  {t('forms.capacitySubcontractor.subtitle')}
                  <div className="text-600">
                    {t('forms.capacitySubcontractor.note.1')}
                    {t('forms.capacitySubcontractor.note.2')}
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="tile-capacity-subcontractor__wrap flex-grow-1 ">
            <Table className={`tile-table__table table-no-border ${isDetailPage ? '' : 'table--small'} tile-capacity-subcontractor__table`}>
              <THead>
                <TileCapacitySubcontractorTableHead date={date} onPrevClick={handlePrevWeek} onNextClick={handleNextWeek} />
              </THead>
              <TBody>
                {form?.rows?.map((item, index) => (
                  <TileCapacitySubcontractorsTableRow
                    key={item.id}
                    row={item}
                    date={date}
                    rowIndex={index}
                    rowsLength={form?.rows.length ?? 0}
                    measuresArray={measuresArray}
                    setMeasuresArray={setMeasuresArray}
                  />
                ))}
              </TBody>
            </Table>
          </div>
        </div>
      </div>
      {isDetailPage && <UnsavedChangesPopup showIf={isFormDirty || isFormEditing} />}
    </div>
  );
};

export default TileCapacitySubcontractor;
