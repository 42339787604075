import { createAction } from '../../utils/action';
import ActionTypes from '../actionTypes';
import { ActionsUnion } from '../types';

export const Actions = {
  setIsFormDirty: (isFormDirty: boolean) => createAction(ActionTypes.SET_IS_FORM_DIRTY, isFormDirty),
  setIsFormEditing: (isFormEditing: boolean) => createAction(ActionTypes.SET_IS_FORM_EDITING, isFormEditing)
};

export type SettingsActions = ActionsUnion<typeof Actions>;
