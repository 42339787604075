import * as React from 'react';

import TableTextarea from '../../components/form/TableTextarea/TableTextarea';
import { ProjectOverviewFormColumn, ProjectOverviewFormRow } from '../../core/state/table-forms/types';
import { headerItems } from './ProjectOverviewHeader';

interface Props {
  row: ProjectOverviewFormRow;
  column: ProjectOverviewFormColumn;
  center?: boolean;
  hasStatus?: boolean;
  selectedItem?: SelectedItem;
  columnIndex: number;
  onBlur: () => void;
  onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  onDoubleClick: (column: ProjectOverviewFormColumn, row: ProjectOverviewFormRow) => void;
  handleStatus: (row: ProjectOverviewFormRow, column: ProjectOverviewFormColumn) => void;
}

export interface SelectedItem {
  row?: ProjectOverviewFormRow;
  column?: ProjectOverviewFormColumn;
  value: string;
}

const getKVPProblem = (projectNumberName: string, columnIndex: number) => {
  const projectArray = projectNumberName.split('|');
  const projectName = projectArray.length > 1 ? projectArray[1] : projectNumberName;
  const { title } = headerItems[columnIndex];

  return `${projectName} | ${title}`;
};

const ProjectOverviewRowItem: React.FC<Props> = ({
  center = false,
  hasStatus = true,
  selectedItem,
  onDoubleClick,
  onBlur,
  onChange,
  column,
  row,
  handleStatus,
  columnIndex
}) => {
  const value =
    selectedItem?.row?.rowNumber === row.rowNumber && selectedItem.column?.columnNumber === column.columnNumber
      ? selectedItem.value
      : String(column?.text);
  return (
    <div
      className={
        'tile-project-overview__row-item' +
        `${column && hasStatus ? ` tile-project-overview__row-item--${column.status.toLowerCase()}` : ''}` +
        `${center ? ' tile-project-overview__row-item--center' : ''}`
      }
    >
      <TableTextarea
        center={center}
        value={value}
        hasStatus={hasStatus}
        onDoubleClick={() => onDoubleClick && onDoubleClick(column, row)}
        onBlur={onBlur}
        onChange={onChange}
        status={column.status}
        handleStatus={() => handleStatus(row, column)}
        KVPProblem={getKVPProblem(row.projectName, columnIndex)}
      />
    </div>
  );
};

export default ProjectOverviewRowItem;
