import React from 'react';
import TD from '../../../components/Table/TD/TD';

interface Props {
  rowsLength: number;
}

const TileCapacitySubcontractorEmptyColumn: React.FC<Props> = ({ rowsLength }) => {
  return <TD className="tile-capacity-subcontractor__empty-column" rowSpan={rowsLength} />;
};

export default TileCapacitySubcontractorEmptyColumn;
