import React from 'react';
import { useTranslation } from 'react-i18next';

import { HOME_ROUTE } from '../../core/constants/routes';
import InfoPage from '../components/InfoPage/InfoPage';

const Page404 = () => {
  const { t } = useTranslation();

  return (
    <InfoPage
      title={t('sharedText.error404')}
      subtitle={t('sharedText.pageNotFound')}
      buttonRoute={HOME_ROUTE}
      buttonText={t('sharedText.backToOverview')}
    />
  );
};

export default Page404;
