import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { match } from 'react-router-dom';
import TileLegendBox from '../CheckboxListTile/TileLegendBox';

import './TilePresenceList.scss';

interface Props {
  isDetailPage: match | null;
}

const TilePresenceListInfo: React.FC<Props> = ({ isDetailPage }) => {
  const { t } = useTranslation();
  return (
    <div className={`tile-presence ${isDetailPage ? 'mb-2' : 'tile-presence--small w-100'} d-flex justify-content-between align-items-start my-2`}>
      {isDetailPage && (
        <div className="d-flex align-items-center ">
          <span className="tile-presence__info-text">{t('forms.presenceList.subtitle')}</span>
        </div>
      )}
      <div className="w-100">
        <div className="d-flex flex-column align-items-end">
          <TileLegendBox
            className={`${isDetailPage ? '' : 'mb-1 mt-0'}`}
            items={[
              {
                color: 'green',
                text: t('forms.presenceList.legend.green')
              },
              {
                color: 'red',
                text: t('forms.presenceList.legend.red')
              },
              {
                color: 'yellow',
                text: t('forms.presenceList.legend.yellow')
              },
              {
                color: 'grey',
                text: t('forms.presenceList.legend.grey')
              }
            ]}
          />
          <div className="d-flex">
            <span className="tile-presence__legend-text tile-presence__legend-text-title">{t('forms.presenceList.legend.status')}</span>
            <span className="tile-presence__legend-text">{t('forms.presenceList.legend.subtitle')}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TilePresenceListInfo;
