import * as React from 'react';

import { HistoryButtonType } from '../../../../../core/enums/HistoryButtonType';
import { isDesktopScreen } from '../../../../../core/utils/isDesktopScreen';

import './HistoryButton.scss';

interface Props {
  historyButtonType: HistoryButtonType;
  historyActionHandler: (historyButtonType: HistoryButtonType) => void;
}

const HistoryButton: React.FC<Props> = ({ children, historyButtonType, historyActionHandler }) => {
  const isDesktop = isDesktopScreen();
  return (
    <button
      className={`history-button no-font-scaling history-button no-font-scaling__${
        isDesktop ? 'desktop-screen' : 'tv-screen'
      } history-button no-font-scaling__${isDesktop ? 'desktop-screen' : 'tv-screen'}--${historyButtonType}`}
      onClick={() => historyActionHandler(historyButtonType)}
    >
      {children}
    </button>
  );
};

export default HistoryButton;
