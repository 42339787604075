import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { RootState } from '../../../core/state/state';
import { getWeekTopics } from '../../../core/state/table-forms/asyncActions';
import Table from '../../../components/Table/Table';
import THead from '../../../components/Table/THead/THead';
import TR from '../../../components/Table/TR/TR';
import TH from '../../../components/Table/TH/TH';
import TD from '../../../components/Table/TD/TD';
import TBody from '../../../components/Table/TBody/TBody';
import TableCellOverflowEllipses from '../../../pages/components/TableCellOverflowEllipses/TableCellOverflowEllipses';

interface Props {
  url: string;
}

const TileWeekTopicsDesktopScreen: React.FC<Props> = ({ url }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const tableForm = useSelector((state: RootState) => state.tableForm.weekTopicsForm.form);

  React.useEffect(() => {
    dispatch(getWeekTopics(url));
  }, [url, dispatch]);

  const getTopThreeRows = () => {
    if (tableForm && tableForm.rows.length > 0) {
      return tableForm.rows.slice(0, 8).map((tableFormItem) => (
        <TR key={tableFormItem.rowNumber}>
          <TD className="text-left">
            <TableCellOverflowEllipses content={`${tableFormItem.rowNumber}. ${tableFormItem.columns[1].text}`} />
          </TD>
        </TR>
      ));
    }
    return null;
  };

  return (
    <div className="tile-desktop-screen no-font-scaling">
      <Table className="tile-table__table" type="small">
        <THead>
          <TR>
            <TH colSpan={2} className="table__table-header">
              {t('forms.weekTopics.title')}
            </TH>
          </TR>
        </THead>
        <TBody>{getTopThreeRows()}</TBody>
      </Table>
    </div>
  );
};

export default TileWeekTopicsDesktopScreen;
