import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Button from '../../components/Button/Button';
import { login } from '../../core/state/auth/asyncActions';
import { AuthStatus } from '../../core/enums/AuthStatus';
import { HOME_ROUTE } from '../../core/constants/routes';
import { RootState } from '../../core/state/state';
import Icon from '../../components/Icon/Icon';
import { isDesktopScreen } from '../../core/utils/isDesktopScreen';

import './Login.scss';

const Login = () => {
  const status = useSelector((state: RootState) => state.auth.status);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const isDesktop = isDesktopScreen();

  if (status === AuthStatus.Authenticated) {
    return <Redirect to={HOME_ROUTE} />;
  }
  return (
    <div
      className={`my-auto vh-100 d-flex align-items-center justify-content-center login-page no-font-scaling login-page no-font-scaling__${
        isDesktop ? 'desktop-screen' : 'tv-screen'
      }`}
    >
      <div className="login-page__content">
        <div className="login-page__logo">
          {isDesktop ? <Icon icon="loginLogoS" size="full-size" svg /> : <Icon icon="loginLogoL" size="full-size" svg />}
        </div>
        <div className="login-page__title">{t('login.title')}</div>
        <div className="login-page__subtitle">{t('login.subtitle')}</div>
        <div className="d-flex justify-content-center">
          <Button
            onClick={() => {
              dispatch(login());
            }}
            className="login-page__button"
          >
            {t('login.login')}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Login;
