import getWeek from 'date-fns/getWeek';

export function getCurrentWeekNumber(date?: Date) {
  const thisDate = date ?? new Date();
  return getWeek(thisDate, { weekStartsOn: 1, firstWeekContainsDate: 4 });
}

export function getCurrentDayNumber() {
  return new Date().getDate();
}
