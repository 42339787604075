import * as React from 'react';

import BootstrapTooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import { Placement } from 'react-bootstrap/esm/Overlay';

import './Tooltip.scss';

interface Props {
  text?: string;
  placement?: Placement;
  color?: 'white' | 'yellow';
}

const Tooltip: React.FC<Props> = ({ text, children, placement = 'right-start', color = 'yellow' }) => (
  <OverlayTrigger
    placement={placement}
    overlay={
      <BootstrapTooltip bsPrefix={`bootstrap-tooltip bootstrap-tooltip--${color}`} id="bootstrap-tooltip">
        {text}
      </BootstrapTooltip>
    }
  >
    <span>{children}</span>
  </OverlayTrigger>
);

export default Tooltip;
