import service from '../RestService';
import { RestResponse } from '../../types/response/RestResponse';

const path = '/tiles';

interface UploadConfig {
  onUploadProgress: (event: ProgressEvent) => void;
}

export interface FileDetails {
  filename: string;
  location: string;
  uploadedAt: string;
  uploadedBy: string;
}

class BackOfficeFileService {
  getFileDetails = (url: string): RestResponse<FileDetails> => service.get(url);

  uploadFile = (tileId: number, data: FormData, config: UploadConfig): RestResponse<FileDetails> =>
    service.post(`${path}/${tileId}/file`, data, undefined, config);
}

export default new BackOfficeFileService();
