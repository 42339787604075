import * as React from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import { debounce } from 'lodash';

import { RootState } from '../../../core/state/state';
import { Breadcrumb } from '../../../core/utils/breadcrumb/findMenuListById';
import { useBreadcrumb } from '../../../core/hooks/useBreadcrumb';
import { calcWidthBreadcrumbs } from '../../../core/utils/breadcrumb/calcWidthBreadcrumbs';
import { getBreadcrumbArray } from '../../../core/utils/breadcrumb/getBreadcrumbArray';
import BreadcrumbLink from './BreadcrumbLink/BreadcrumbLink';

import './BreadcrumbNavigation.scss';

const breadcrumbArrayInitial: Breadcrumb[] = [];
const openedBreadcrumbInitial = -1;

const BreadcrumbNavigation: React.FC<any> = () => {
  const location = useLocation();
  const breadcrumbRef = React.useRef<HTMLDivElement>(null);
  const [breadcrumbArray, setBreadcrumbArray] = React.useState(breadcrumbArrayInitial);
  const [openedBreadcrumb, setOpenedBreadcrumb] = React.useState(openedBreadcrumbInitial);

  const menuItems = useSelector((state: RootState) => state.menuItems.list);
  const backOfficeItems = useSelector((state: RootState) => state.backOffice.list);

  const { id } = useParams<{ id?: string }>();
  const breadcrumbData = useBreadcrumb();

  React.useEffect(() => {
    setOpenedBreadcrumb(-1);
  }, [location]);

  React.useEffect(() => {
    const onResize = () => {
      const breadcrumbNav = breadcrumbRef.current;
      if (breadcrumbNav) {
        const newBreadcrumbArray = calcWidthBreadcrumbs(breadcrumbNav, getBreadcrumbArray(breadcrumbData));
        setBreadcrumbArray(newBreadcrumbArray);
      }
    };
    const debouncedOnResize = debounce(onResize, 200);
    window.addEventListener('resize', debouncedOnResize);
    debouncedOnResize();

    return () => window.removeEventListener('resize', debouncedOnResize);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [menuItems, backOfficeItems, location]);

  React.useLayoutEffect(() => {
    if (id) {
      setBreadcrumbArray(getBreadcrumbArray(breadcrumbData));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [menuItems, backOfficeItems, location]);

  if (menuItems === null) {
    return null;
  }

  return (
    <div className="breadcrumb-navigation d-flex flex-grow-1" ref={breadcrumbRef}>
      <nav className="breadcrumb-navigation__nav" aria-label="breadcrumb">
        <ol className="breadcrumb">
          {breadcrumbArray.map((bcrumb, index) => {
            const isLast = index === breadcrumbArray.length - 1;
            return (
              <BreadcrumbLink
                breadcrumbArray={breadcrumbArray}
                key={bcrumb.name}
                breadcrumb={bcrumb}
                openedBreadcrumb={openedBreadcrumb}
                setOpenedBreadcrumb={setOpenedBreadcrumb}
                index={index}
                isLast={isLast}
              />
            );
          })}
        </ol>
      </nav>
    </div>
  );
};

export default BreadcrumbNavigation;
