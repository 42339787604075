import BackofficeService from '../../services/back-office/BackofficeService';
import { ReduxThunkType } from '../../types/ReduxThunkType';
import { Actions as BackOfficeActions } from './actions';
import BackOfficeProjectAreaService from '../../services/back-office/BackOfficeProjectAreaService';
import { reorderItems } from '../../utils/reorderItem';
import { spawnSaveSuccessNotification } from '../notifications/asyncActions';
import { Actions as SettingsActions } from '../settings/actions';
import { AreaProject, BoardPermissions, CapacitySubcontractors } from './types';
import BoardsService from '../../services/BoardsService';
import BackOfficeCapacitySubcontractorsService from '../../services/back-office/BackOfficeCapacitySubcontractorsService';

export const getBackOfficeList = (boardId: number): ReduxThunkType<void> => async (dispatch) => {
  try {
    dispatch(BackOfficeActions.getBackOfficeList());
    const response = await BackofficeService.getBackOfficeList(boardId);
    dispatch(BackOfficeActions.getBackOfficeListSuccess(response.data));
  } catch (error) {
    dispatch(BackOfficeActions.getBackOfficeListError(error));
  }
};

export const getAreaProjects = (url: string): ReduxThunkType<void> => async (dispatch) => {
  try {
    dispatch(BackOfficeActions.getAreaProjects());
    const response = await BackOfficeProjectAreaService.getAreaProjects(url);
    dispatch(BackOfficeActions.getAreaProjectsSuccess(reorderItems(response.data)));
  } catch (error) {
    dispatch(BackOfficeActions.getAreaProjectsError(error));
  }
};

export const saveAreaProjects = (url: string, areaProjects: AreaProject[]): ReduxThunkType<void> => async (dispatch) => {
  try {
    dispatch(BackOfficeActions.saveAreaProjects());
    const response = await BackOfficeProjectAreaService.saveAreaProjects(url, areaProjects);
    await dispatch(SettingsActions.setIsFormDirty(false));
    dispatch(BackOfficeActions.saveAreaProjectsSuccess(response.data));
    dispatch(spawnSaveSuccessNotification());
  } catch (error) {
    dispatch(BackOfficeActions.saveAreaProjectsError(error));
  }
};

export const getCapacitySubcontractors = (url: string): ReduxThunkType<void> => async (dispatch) => {
  try {
    dispatch(BackOfficeActions.getCapacitySubcontractors());
    const response = await BackOfficeCapacitySubcontractorsService.getCapacitySubcontractors(url);
    dispatch(BackOfficeActions.getCapacitySubcontractorsSuccess(reorderItems(response.data)));
  } catch (error) {
    dispatch(BackOfficeActions.getCapacitySubcontractorsError(error));
  }
};

export const saveCapacitySubcontractors = (url: string, capacitySubcontractors: CapacitySubcontractors[]): ReduxThunkType<void> => async (
  dispatch
) => {
  try {
    dispatch(BackOfficeActions.saveCapacitySubcontractors());
    const response = await BackOfficeCapacitySubcontractorsService.saveCapacitySubcontractors(url, capacitySubcontractors);
    await dispatch(SettingsActions.setIsFormDirty(false));
    dispatch(BackOfficeActions.saveCapacitySubcontractorsSuccess(response.data));
    dispatch(spawnSaveSuccessNotification());
  } catch (error) {
    dispatch(BackOfficeActions.getCapacitySubcontractorsError(error));
  }
};

export const updateCapacitySubcontractors = (
  capacitySubcontractors: CapacitySubcontractors[],
  isFormDirty = true,
  suppressDirtyStatus = false
): ReduxThunkType<void> => async (dispatch) => {
  if (!suppressDirtyStatus) {
    await dispatch(SettingsActions.setIsFormDirty(isFormDirty));
  }
  dispatch(BackOfficeActions.updateCapacitySubcontractors(capacitySubcontractors));
};

export const updateAreaProjects = (areaProjects: AreaProject[], isFormDirty = true, suppressDirtyStatus = false): ReduxThunkType<void> => async (
  dispatch
) => {
  if (!suppressDirtyStatus) {
    await dispatch(SettingsActions.setIsFormDirty(isFormDirty));
  }
  dispatch(BackOfficeActions.updateAreaProjects(areaProjects));
};

export const getBoardPermissions = (boardId: number): ReduxThunkType<void> => async (dispatch) => {
  try {
    dispatch(BackOfficeActions.getBoardPermissions());
    const { data: permissions } = await BoardsService.getBoardPermissions(boardId);
    dispatch(BackOfficeActions.getBoardPermissionsSuccess(permissions));
  } catch (error) {
    dispatch(BackOfficeActions.getBoardPermissionsError(error));
  }
};

export const saveBoardPermissions = (boardId: number, permissions: BoardPermissions): ReduxThunkType<Promise<boolean>> => async (dispatch) => {
  try {
    dispatch(BackOfficeActions.saveBoardPermissions());
    await BoardsService.updateBoardPermissions(boardId, permissions);
    dispatch(BackOfficeActions.saveBoardPermissionsSuccess(permissions));
    return Promise.resolve(true);
  } catch (error) {
    dispatch(BackOfficeActions.saveBoardPermissionsError(error));
    return Promise.resolve(false);
  }
};
