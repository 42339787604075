import React from 'react';
import { useRouteMatch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import TH from '../../../components/Table/TH/TH';
import TR from '../../../components/Table/TR/TR';
import { BOARDS_TILE_DETAIL } from '../../../core/constants/routes';

const TileEscalationTableHeader = () => {
  const { t } = useTranslation();

  const isDetailPage = useRouteMatch(BOARDS_TILE_DETAIL);
  return (
    <TR>
      <TH className="table__table-header">
        <p className="table__title">{t('sharedText.number')}</p>
      </TH>
      <TH className="table__table-header">
        <p className="table__title">{t('escalationForm.level')}</p>
      </TH>
      <TH className="table__table-header text-center">
        <p className="table__title text-left">{t('escalationForm.problem')}</p>
      </TH>
      <TH className="table__table-header text-center">
        <p className="table__title">{t('escalationForm.riskPotential')}</p>
      </TH>
      <TH className="table__table-header text-center">
        <p className="table__title text-left">{t('escalationForm.decision')}</p>
      </TH>
      <TH className="table__table-header text-center">
        <p className="table__title text-center">{t('escalationForm.dateCreated')}</p>
      </TH>
      <TH className="table__table-header text-center">
        <p className="table__title text-center">{t('escalationForm.dateDue')}</p>
      </TH>
      <TH className="table__table-header text-center">
        <p className="table__title">{t(isDetailPage ? 'escalationForm.status' : 'escalationForm.statusShort')}</p>
      </TH>
      <TH className="table__table-header text-center">
        <p className="table__title">{t('escalationForm.type')}</p>
      </TH>
      {isDetailPage && (
        <TH className="table__table-header text-center" colSpan={3}>
          <p className="table__title">{t('escalationForm.actions')}</p>
        </TH>
      )}
    </TR>
  );
};

export default TileEscalationTableHeader;
