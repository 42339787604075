import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { match } from 'react-router-dom';
import { Sizes } from '../../core/enums/Sizes';
import StatusRed from '../../pages/components/StatusRed/StatusRed';

import './TileObstacles.scss';

interface Props {
  isDetailPage: match | null;
}

const TileObstaclesInfo: React.FC<Props> = ({ isDetailPage }) => {
  const { t } = useTranslation();
  return (
    <div className={`tile-obstacles ${isDetailPage ? '' : 'tile-obstacles--small justify-content-end'} d-flex flex-column align-items-end mb-2`}>
      <StatusRed
        condition={t('forms.obstacles.statusDefinitionSubtitle')}
        size={isDetailPage ? Sizes.Medium : Sizes.Small}
        className={`${isDetailPage ? '' : 'text-right'}`}
      />
    </div>
  );
};

export default TileObstaclesInfo;
