import * as React from 'react';
import { isDesktopScreen } from '../../core/utils/isDesktopScreen';

import './Table.scss';

interface Props {
  className?: string;
  type?: 'small' | 'dnd';
}

const Table: React.FC<Props> = ({ children, type = '', className }) => {
  const desktopScreen = isDesktopScreen();
  return (
    <div className={`table ${type ? `table--${type}` : ''} ${desktopScreen ? 'no-font-scaling' : ''} ${className || ''}`}>
      <table>{children}</table>
    </div>
  );
};

export default Table;
