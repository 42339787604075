import * as React from 'react';
import { Link } from 'react-router-dom';
import Icon from '../../../components/Icon/Icon';

import './NavArrow.scss';

interface Props {
  url?: string;
  isRight?: boolean;
  onClick?: () => any;
  className?: string;
  disabled?: boolean;
}

const NavArrow: React.FC<Props> = ({ isRight, url, onClick, className, disabled }) => {
  const isButton = !!onClick;
  if (isButton) {
    return (
      <button disabled={disabled} onClick={onClick} className={`nav__arrow ${isRight ? 'nav__arrow--right' : ''} ${className ?? ''}`}>
        <Icon icon="breadcrumbDivider" />
      </button>
    );
  }
  return (
    <Link to={url ?? ''} className={`nav__arrow ${isRight ? 'nav__arrow--right' : ''} ${className ?? ''}`}>
      <Icon icon="breadcrumbDivider" />
    </Link>
  );
};

export default NavArrow;
