import { createAction } from '../../utils/action';
import { RestErrorResponse } from '../../types/response/RestErrorResponse';
import { CheckboxList, CheckboxAndMeasureData } from './types';
import { getActionTypeByName } from '../helpers';

export const generateActions = (type: string) => ({
  getList: () => createAction(getActionTypeByName(`GET_${type}_LIST`)),
  getListSuccess: (list: CheckboxList | CheckboxAndMeasureData) => createAction(getActionTypeByName(`GET_${type}_LIST_SUCCESS`), list),
  getListError: (error: RestErrorResponse) => createAction(getActionTypeByName(`GET_${type}_LIST_ERROR`), error),

  saveList: () => createAction(getActionTypeByName(`SAVE_${type}_LIST`)),
  saveListSuccess: (list: CheckboxList | CheckboxAndMeasureData) => createAction(getActionTypeByName(`SAVE_${type}_LIST_SUCCESS`), list),
  saveListError: (error: RestErrorResponse) => createAction(getActionTypeByName(`SAVE_${type}_LIST_ERROR`), error),

  updateList: (list: CheckboxList | CheckboxAndMeasureData) => createAction(getActionTypeByName(`UPDATE_${type}_LIST`), list)
});
