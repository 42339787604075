import React from 'react';
import { useTranslation } from 'react-i18next';

import { TileTypes } from '../../core/enums/TileTypes';
import TableFormTile from '../TableFormTile/TableFormTile';
import { TableTileTypes } from '../../core/enums/TableTileTypes';
import { TableFormFieldStatus } from '../../core/enums/TableFormFieldStatus';
import InspectionPlansQAQualityCheckHeader from './InspectionPlansQAQualityCheckHeader';
import TileInspectionPlansQAInfo from './TileInspectionPlansQAInfo';

import './TileInspectionPlansQA.scss';

interface Props {
  boardId?: number;
  url: string;
}

const TileInspectionPlansQA: React.FC<Props> = ({ url, boardId }) => {
  const { t } = useTranslation();

  return (
    <TableFormTile
      tClassName="tile-inspection-plans-qa"
      url={url}
      boardId={boardId}
      tileType={TileTypes.InspectionPlansQAList}
      tableTileType={TableTileTypes.InspectionPlansQAList}
      columns={[
        {
          field: 'id',
          title: t('sharedText.number'),
          type: 'index',
          headerClass: 'w-5 text-center'
        },
        {
          field: 'subcontractor',
          title: t('forms.inspectionPlansQAList.column.subcontractor'),
          headerClass: 'w-16 text-center'
        },
        {
          field: 'responsible',
          title: t('forms.inspectionPlansQAList.column.responsible'),
          headerClass: 'w-16 text-center'
        },
        {
          field: 'deputy',
          title: t('forms.inspectionPlansQAList.column.deputy'),
          headerClass: 'w-16 text-center'
        },
        {
          field: 'inspectionPlanCreated',
          title: t('forms.inspectionPlansQAList.column.inspectionPlanCreated'),
          headerClass: 'w-16 text-center'
        },
        {
          field: 'inspectionPlanDiscussed',
          title: t('forms.inspectionPlansQAList.column.inspectionPlanDiscussed'),
          headerClass: 'w-16 text-center'
        },
        {
          field: 'qualityCheck',
          title: t('forms.inspectionPlansQAList.column.qualityCheck'),
          headerContent: InspectionPlansQAQualityCheckHeader,
          headerClass: 'w-12',
          columns: [
            {
              headerClass: 'w-3 text-center',
              type: 'status-circle',
              statusOptions: [TableFormFieldStatus.Undefined, TableFormFieldStatus.Green, TableFormFieldStatus.Checked],
              field: 'obstacleRemoval1',
              title: '1.',
              subtitle: t('forms.inspectionPlansQAList.column.week')
            },
            {
              headerClass: 'w-3 text-center',
              type: 'status-circle',
              statusOptions: [TableFormFieldStatus.Undefined, TableFormFieldStatus.Green, TableFormFieldStatus.Checked],
              field: 'obstacleRemoval2',
              title: '2.',
              subtitle: t('forms.inspectionPlansQAList.column.week')
            },
            {
              headerClass: 'w-3 text-center',
              type: 'status-circle',
              statusOptions: [TableFormFieldStatus.Undefined, TableFormFieldStatus.Green, TableFormFieldStatus.Checked],
              field: 'obstacleRemoval3',
              title: '3.',
              subtitle: t('forms.inspectionPlansQAList.column.week')
            },
            {
              headerClass: 'w-3 text-center',
              type: 'status-circle',
              statusOptions: [TableFormFieldStatus.Undefined, TableFormFieldStatus.Green, TableFormFieldStatus.Checked],
              field: 'obstacleRemoval4',
              title: '4.',
              subtitle: t('forms.inspectionPlansQAList.column.week')
            }
          ]
        }
      ]}
      InfoComponent={TileInspectionPlansQAInfo}
      showStatus
    />
  );
};

export default TileInspectionPlansQA;
