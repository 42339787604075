import React from 'react';

import { TileTypes } from '../../core/enums/TileTypes';
import { TableTileTypes } from '../../core/enums/TableTileTypes';
import TableFormTileDesktopScreen, { ColumnTypes } from '../TableFormTile/DesktopScreen/TableFormTileDesktopScreen';

interface Props {
  url: string;
}

const TileSamplingDesktopScreen: React.FC<Props> = ({ url }) => {
  return (
    <TableFormTileDesktopScreen
      url={url}
      tileType={TileTypes.Sampling}
      tableTileType={TableTileTypes.Sampling}
      columns={[
        {
          type: ColumnTypes.Text,
          dataColumnIndex: 0,
          className: 'text-left'
        },
        {
          type: ColumnTypes.Text,
          dataColumnIndex: 1,
          className: 'text-left'
        },
        {
          type: ColumnTypes.Calendar,
          dataColumnIndex: 4,
          className: 'text-right shrink'
        }
      ]}
      showStatus
    />
  );
};
export default TileSamplingDesktopScreen;
