import ActionTypes from '../actionTypes';
import Actions from '../Actions';
import { AuthStatus } from '../../enums/AuthStatus';
import { AuthState } from './types';

export const defaultAuthState: AuthState = {
  authInfo: null,
  status: AuthStatus.FirstLoad,
  error: null
};

export default function reducer(state: AuthState = defaultAuthState, action: Actions): AuthState {
  switch (action.type) {
    case ActionTypes.SILENT_LOGIN:
      return { ...state, status: AuthStatus.FirstLoad, error: null };
    case ActionTypes.SILENT_LOGIN_SUCCESS:
      return { ...state, status: AuthStatus.Authenticated, authInfo: action.payload };
    case ActionTypes.SILENT_LOGIN_ERROR:
      return { ...state, status: AuthStatus.NotAuthenticated, error: action.payload };
    case ActionTypes.LOGIN:
      return { ...state, status: AuthStatus.Busy, error: null };
    case ActionTypes.LOGIN_SUCCESS:
      return { ...state };
    case ActionTypes.LOGIN_ERROR:
      return { ...state, status: AuthStatus.NotAuthenticated, error: action.payload };
    case ActionTypes.SET_NOT_AUTHENTICATED:
      return { ...state, status: AuthStatus.NotAuthenticated };
    case ActionTypes.LOGOUT:
      return { ...state };
    case ActionTypes.LOGOUT_SUCCESS:
      return { ...state };
    case ActionTypes.LOGOUT_ERROR:
      return { ...state, error: action.payload };
    default:
      return state;
  }
}
