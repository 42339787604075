import { createAction } from '../../utils/action';
import ActionTypes from '../actionTypes';
import { ActionsUnion } from '../types';
import { RestErrorResponse } from '../../types/response/RestErrorResponse';
import { GoalsForm, MeasureAndEscalationForm, ProjectOverviewForm, StandingRulesForm, TableForm } from './types';
import { getActionTypeByName } from '../helpers';

export const generateActions = <T>(type: string) => ({
  getTableForm: () => createAction(getActionTypeByName(`GET_${type}_TABLE_FORM`)),
  getTableFormSuccess: (list: T) => createAction(getActionTypeByName(`GET_${type}_TABLE_FORM_SUCCESS`), list),
  getTableFormError: (error: RestErrorResponse) => createAction(getActionTypeByName(`GET_${type}_TABLE_FORM_ERROR`), error),

  saveTableForm: () => createAction(getActionTypeByName(`SAVE_${type}_TABLE_FORM`)),
  saveTableFormSuccess: (list: T) => createAction(getActionTypeByName(`SAVE_${type}_TABLE_FORM_SUCCESS`), list),
  saveTableFormError: (error: RestErrorResponse) => createAction(getActionTypeByName(`SAVE_${type}_TABLE_FORM_ERROR`), error),

  updateTableForm: (list: T) => createAction(getActionTypeByName(`UPDATE_${type}_TABLE_FORM`), list)
});

export const Actions = {
  getTableForm: () => createAction(ActionTypes.GET_TABLE_FORM),
  getTableFormSuccess: (data: TableForm) => createAction(ActionTypes.GET_TABLE_FORM_SUCCESS, data),
  getTableFormError: (error: RestErrorResponse) => createAction(ActionTypes.GET_TABLE_FORM_ERROR, error),

  updateTableForm: (list: TableForm) => createAction(ActionTypes.UPDATE_TABLE_FORM, list),

  saveTableForm: () => createAction(ActionTypes.SAVE_TABLE_FORM),
  saveTableFormSuccess: (data: TableForm) => createAction(ActionTypes.SAVE_TABLE_FORM_SUCCESS, data),
  saveTableFormError: (error: RestErrorResponse) => createAction(ActionTypes.SAVE_TABLE_FORM_ERROR, error),

  getKVPForm: () => createAction(ActionTypes.GET_KVP_FORM),
  getKVPFormSuccess: (data: TableForm) => createAction(ActionTypes.GET_KVP_FORM_SUCCESS, data),
  getKVPFormError: (error: RestErrorResponse) => createAction(ActionTypes.GET_KVP_FORM_ERROR, error),

  updateKVPForm: (list: TableForm) => createAction(ActionTypes.UPDATE_KVP_FORM, list),

  saveKVPForm: () => createAction(ActionTypes.SAVE_KVP_FORM),
  saveKVPFormSuccess: (data: TableForm) => createAction(ActionTypes.SAVE_KVP_FORM_SUCCESS, data),
  saveKVPFormError: (error: RestErrorResponse) => createAction(ActionTypes.SAVE_KVP_FORM_ERROR, error),

  getProjectOverviewForm: () => createAction(ActionTypes.GET_PROJECT_OVERVIEW_FORM),
  getProjectOverviewFormSuccess: (data: TableForm) => createAction(ActionTypes.GET_PROJECT_OVERVIEW_FORM_SUCCESS, data),
  getProjectOverviewFormError: (error: RestErrorResponse) => createAction(ActionTypes.GET_PROJECT_OVERVIEW_FORM_ERROR, error),

  updateProjectOverviewForm: (list: ProjectOverviewForm) => createAction(ActionTypes.UPDATE_PROJECT_OVERVIEW_FORM, list),

  saveProjectOverviewForm: () => createAction(ActionTypes.SAVE_PROJECT_OVERVIEW_FORM),
  saveProjectOverviewFormSuccess: (data: ProjectOverviewForm) => createAction(ActionTypes.SAVE_PROJECT_OVERVIEW_FORM_SUCCESS, data),
  saveProjectOverviewFormError: (error: RestErrorResponse) => createAction(ActionTypes.SAVE_PROJECT_OVERVIEW_FORM_ERROR, error),

  getGoalsForm: () => createAction(ActionTypes.GET_GOALS_FORM),
  getGoalsFormSuccess: (data: GoalsForm) => createAction(ActionTypes.GET_GOALS_FORM_SUCCESS, data),
  getGoalsFormError: (error: RestErrorResponse) => createAction(ActionTypes.GET_GOALS_FORM_ERROR, error),

  updateGoalsForm: (list: GoalsForm) => createAction(ActionTypes.UPDATE_GOALS_FORM, list),

  saveGoalsForm: () => createAction(ActionTypes.SAVE_GOALS_FORM),
  saveGoalsFormSuccess: (data: GoalsForm) => createAction(ActionTypes.SAVE_GOALS_FORM_SUCCESS, data),
  saveGoalsFormError: (error: RestErrorResponse) => createAction(ActionTypes.SAVE_GOALS_FORM_ERROR, error),

  getSickLeaveForm: () => createAction(ActionTypes.GET_SICK_LEAVE_FORM),
  getSickLeaveFormSuccess: (data: TableForm) => createAction(ActionTypes.GET_SICK_LEAVE_FORM_SUCCESS, data),
  getSickLeaveFormError: (error: RestErrorResponse) => createAction(ActionTypes.GET_SICK_LEAVE_FORM_ERROR, error),

  updateSickLeaveForm: (list: TableForm) => createAction(ActionTypes.UPDATE_SICK_LEAVE_FORM, list),

  saveSickLeaveForm: () => createAction(ActionTypes.SAVE_SICK_LEAVE_FORM),
  saveSickLeaveFormSuccess: (data: TableForm) => createAction(ActionTypes.SAVE_SICK_LEAVE_FORM_SUCCESS, data),
  saveSickLeaveFormError: (error: RestErrorResponse) => createAction(ActionTypes.SAVE_SICK_LEAVE_FORM_ERROR, error),

  getWeekTopics: () => createAction(ActionTypes.GET_WEEK_TOPICS),
  getWeekTopicsSuccess: (tableForm: TableForm) => createAction(ActionTypes.GET_WEEK_TOPICS_SUCCESS, tableForm),
  getWeekTopicsError: (error: RestErrorResponse) => createAction(ActionTypes.GET_WEEK_TOPICS_ERROR, error),

  updateWeekTopics: (tableForm: TableForm) => createAction(ActionTypes.UPDATE_WEEK_TOPICS, tableForm),

  saveWeekTopics: () => createAction(ActionTypes.SAVE_WEEK_TOPICS),
  saveWeekTopicsSuccess: (tableForm: TableForm) => createAction(ActionTypes.SAVE_WEEK_TOPICS_SUCCESS, tableForm),
  saveWeekTopicsError: (error: RestErrorResponse) => createAction(ActionTypes.SAVE_WEEK_TOPICS_ERROR, error),

  getStandingRules: () => createAction(ActionTypes.GET_STANDING_RULES),
  getStandingRulesSuccess: (standingRulesForm: StandingRulesForm) => createAction(ActionTypes.GET_STANDING_RULES_SUCCESS, standingRulesForm),
  getStandingRulesError: (error: RestErrorResponse) => createAction(ActionTypes.GET_STANDING_RULES_ERROR, error),

  updateStandingRules: (standingRulesForm: StandingRulesForm) => createAction(ActionTypes.UPDATE_STANDING_RULES, standingRulesForm),

  saveStandingRules: () => createAction(ActionTypes.SAVE_STANDING_RULES),
  saveStandingRulesSuccess: (standingRulesForm: StandingRulesForm) => createAction(ActionTypes.SAVE_STANDING_RULES_SUCCESS, standingRulesForm),
  saveStandingRulesError: (error: RestErrorResponse) => createAction(ActionTypes.SAVE_STANDING_RULES_ERROR, error),

  saveMeasures: () => createAction(ActionTypes.SAVE_MEASURES),
  saveMeasuresSuccess: () => createAction(ActionTypes.SAVE_MEASURES_SUCCESS),
  saveMeasuresError: (error: RestErrorResponse) => createAction(ActionTypes.SAVE_MEASURES_ERROR, error),

  getMeasuresAndEscalations: () => createAction(ActionTypes.GET_MEASURES_AND_ESCALATIONS),
  getMeasuresAndEscalationsSuccess: (tableForm: MeasureAndEscalationForm) =>
    createAction(ActionTypes.GET_MEASURES_AND_ESCALATIONS_SUCCESS, tableForm),
  getMeasuresAndEscalationsError: (error: RestErrorResponse) => createAction(ActionTypes.GET_MEASURES_AND_ESCALATIONS_ERROR, error),

  updateMeasuresAndEscalations: (tableForm: MeasureAndEscalationForm) => createAction(ActionTypes.UPDATE_MEASURES_AND_ESCALATIONS, tableForm),

  saveMeasuresAndEscalations: () => createAction(ActionTypes.SAVE_MEASURES_AND_ESCALATIONS),
  saveMeasuresAndEscalationsSuccess: (tableForm: MeasureAndEscalationForm) =>
    createAction(ActionTypes.SAVE_MEASURES_AND_ESCALATIONS_SUCCESS, tableForm),
  saveMeasuresAndEscalationsError: (error: RestErrorResponse) => createAction(ActionTypes.SAVE_MEASURES_AND_ESCALATIONS_ERROR, error),

  saveTableFormMeasure: () => createAction(ActionTypes.SAVE_TABLE_FORM_MEASURE),
  saveTableFormMeasureSuccess: () => createAction(ActionTypes.SAVE_TABLE_FORM_MEASURE_SUCCESS),
  saveTableFormMeasureError: (error: RestErrorResponse) => createAction(ActionTypes.SAVE_TABLE_FORM_MEASURE_ERROR, error)
};

export type TableFormsActions = ActionsUnion<typeof Actions>;
