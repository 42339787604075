import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import ReactDatePicker from 'react-datepicker';
import isValid from 'date-fns/isValid';

import { DATE_FORMAT } from '../../../core/constants/date';
import { toISODateString } from '../../../core/utils/formatDate';
import { Actions as SettingsActions } from '../../../core/state/settings/actions';

import 'react-datepicker/dist/react-datepicker.css';
import './Calendar.scss';
import { usePrevious } from '../../../core/hooks/usePrevious';

interface Props {
  isSmall?: boolean;
  isCentered?: boolean;
  isFormInput?: boolean;
  label?: string;

  reInitDefaultValue?: boolean;

  row: string | number;
  column: string | number;
  useTableValue: (row: string | number, column: string | number) => any[]; // () => [T, (value: T) => void]
}

const Calendar: React.FC<Props> = ({
  row,
  column,
  useTableValue,
  label,
  isSmall = false,
  isCentered = true,
  isFormInput = false,
  reInitDefaultValue = false
}) => {
  const dispatch = useDispatch();

  const [initialValue, saveValue] = useTableValue(row, column);
  const prevValue = usePrevious(initialValue);
  const [value, setValue] = useState<Date | null | undefined>(initialValue && isValid(new Date(initialValue)) ? new Date(initialValue) : undefined);

  const handleOpen = async () => {
    dispatch(SettingsActions.setIsFormEditing(true));
  };

  const handleChange = (newValue: Date) => {
    setValue(newValue);
  };

  const handleClose = () => {
    dispatch(SettingsActions.setIsFormEditing(false));
    saveValue(value ? toISODateString(value) : '');
  };

  useEffect(() => {
    if (reInitDefaultValue && initialValue && prevValue !== initialValue) {
      const val = initialValue && isValid(new Date(initialValue)) ? new Date(initialValue) : undefined;
      setValue(val);
    }
  }, [initialValue, prevValue, reInitDefaultValue]);

  return (
    <div
      className={
        `calendar${isSmall ? ' calendar--small' : ''}` +
        `${isCentered ? ' calendar--centered' : ''}` +
        `${isFormInput ? ' calendar--form-input' : ''}`
      }
    >
      {label && <div className="calendar__label">{label}</div>}
      <ReactDatePicker
        className="calendar__input"
        dateFormat={DATE_FORMAT}
        selected={value}
        onCalendarOpen={handleOpen}
        onCalendarClose={handleClose}
        onChange={handleChange}
        popperPlacement="auto"
        showPopperArrow
      />
    </div>
  );
};

export default Calendar;
