import * as React from 'react';
import { useDispatch } from 'react-redux';
import { getPowerBITile } from '../../core/state/project-area/asyncActions';
import TilePowerBIPreview from './Preview/TilePowerBIPreview';

import './TilePowerBI.scss';

export interface TilePowerBIProps {
  url: string;
}

const TilePowerBI: React.FC<TilePowerBIProps> = ({ url }) => {
  const dispatch = useDispatch();

  const [accessToken, setAccessToken] = React.useState<string>();
  const [embedUrl, setEmbedUrl] = React.useState<string>();
  const [reportId, seReportId] = React.useState<string>();

  React.useEffect(() => {
    const fetchData = async () => {
      const response: any = await dispatch(getPowerBITile(url));
      await setAccessToken(response && response.accessToken ? response.accessToken : '');
      await seReportId(response && response.reportId ? response.reportId : '');
      await setEmbedUrl(response && response.embedUrl ? response.embedUrl : '');
    };
    setAccessToken('');
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    fetchData();
  }, [dispatch, url]);

  if (!accessToken || !embedUrl || !reportId) {
    return null;
  }

  return (
    <div className="tile-power-bi">
      <div className="tile-power-bi__content">
        {accessToken && reportId && embedUrl ? <TilePowerBIPreview accessToken={accessToken} reportId={reportId} embedUrl={embedUrl} /> : null}
      </div>
    </div>
  );
};

export default TilePowerBI;
