import React from 'react';
import { useRouteMatch } from 'react-router-dom';
import { cloneDeep } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { TileTypes } from '../../core/enums/TileTypes';
import { useTableForm } from '../../core/hooks/useTableForm';
import { BOARDS_TILE_DETAIL } from '../../core/constants/routes';
import { RootState } from '../../core/state/state';
import { generateAsyncActions } from '../../core/state/table-forms/asyncActions';
import { TableForm, TableFormRow } from '../../core/state/table-forms/types';
import { TableFormFieldStatus } from '../../core/enums/TableFormFieldStatus';
import { useRowNumber } from '../../core/hooks/useRowNumber';

import Table from '../../components/Table/Table';
import TR from '../../components/Table/TR/TR';
import TD from '../../components/Table/TD/TD';
import THead from '../../components/Table/THead/THead';
import TH from '../../components/Table/TH/TH';
import TBody from '../../components/Table/TBody/TBody';

import TableInput from '../../components/form/TableInput/TableInputHook';
import Calendar from '../../components/form/Calendar/CalendarHook';

import ButtonToolbar from '../../pages/components/ButtonToolbar/ButtonToolbar';
import Icon from '../../components/Icon/Icon';
import Button from '../../components/Button/Button';
import UnsavedChangesPopup from '../../components/popup/UnsavedChangesPopup/UnsavedChangesPopup';

import './TileRequiredMaterial.scss';

interface Props {
  boardId?: number;
  url: string;
}

const TileRequiredMaterial: React.FC<Props> = ({ url, boardId }) => {
  const dispatch = useDispatch();
  const tableForm = useSelector((state: RootState) => state.requiredMaterial.form);
  const { isFormDirty, isFormEditing } = useSelector((state: RootState) => state.settings);
  const isDetailPage = useRouteMatch(BOARDS_TILE_DETAIL);
  const { t } = useTranslation();
  const nextRowNumber = useRowNumber(tableForm);

  const { getTableForm, updateTableForm, saveTableForm } = React.useMemo(() => generateAsyncActions(TileTypes.RequiredMaterial), []);

  const { submit, useTableValue } = useTableForm<TableForm>({
    boardId,
    data: tableForm,
    valuePropertyName: 'text',
    updateActions: [updateTableForm],
    submitActions: [updateTableForm, saveTableForm]
  });

  const handleSubmit = () => submit();

  React.useEffect(() => {
    dispatch(getTableForm(url));
  }, [url, dispatch, getTableForm]);

  const clearAllFields = () => {
    const newTableForm = cloneDeep(tableForm);
    if (newTableForm) {
      newTableForm.rows = [];
      dispatch(updateTableForm(newTableForm));
    }
  };

  const addEmptyRow = (suppressDirtyStatus = false) => {
    const newTableForm = cloneDeep(tableForm);
    const emptyRow: TableFormRow = {
      rowNumber: nextRowNumber(),
      columns: [
        {
          columnNumber: 1,
          title: t('sharedText.number'),
          status: TableFormFieldStatus.Undefined,
          text: ''
        },
        {
          columnNumber: 2,
          status: TableFormFieldStatus.Undefined,
          title: t('forms.requiredMaterial.datum'),
          text: ''
        },
        {
          columnNumber: 3,
          status: TableFormFieldStatus.Undefined,
          title: t('forms.requiredMaterial.name'),
          text: ''
        },
        {
          columnNumber: 4,
          status: TableFormFieldStatus.Undefined,
          title: t('forms.requiredMaterial.material'),
          text: ''
        },
        {
          columnNumber: 5,
          status: TableFormFieldStatus.Undefined,
          title: t('forms.requiredMaterial.requiredAmount'),
          text: ''
        },
        {
          columnNumber: 6,
          status: TableFormFieldStatus.Undefined,
          title: t('forms.requiredMaterial.byWhenNeeded'),
          text: ''
        },
        {
          columnNumber: 7,
          status: TableFormFieldStatus.Undefined,
          title: t('forms.requiredMaterial.quantityChecked'),
          text: ''
        },
        {
          columnNumber: 8,
          status: TableFormFieldStatus.Undefined,
          title: t('forms.requiredMaterial.orderedQuantity'),
          text: ''
        },
        {
          columnNumber: 9,
          status: TableFormFieldStatus.Undefined,
          title: t('forms.requiredMaterial.deliveryDate'),
          text: ''
        },
        {
          columnNumber: 10,
          status: TableFormFieldStatus.Undefined,
          title: t('forms.requiredMaterial.arrived'),
          text: ''
        }
      ]
    };

    if (newTableForm) {
      newTableForm.rows.push(emptyRow);
      dispatch(updateTableForm(newTableForm, true, suppressDirtyStatus));
    }
  };

  const handleDeleteRow = (index: number) => {
    const newTableForm = cloneDeep(tableForm);

    if (newTableForm) {
      newTableForm.rows.splice(index, 1);
      dispatch(updateTableForm(newTableForm));
    }
  };

  const getAllRows = () => {
    if (tableForm && tableForm.rows.length > 0) {
      return tableForm.rows.map((tableFormItem, index) => (
        <TR key={tableFormItem.rowNumber}>
          <TD className="text-center shrink">{index + 1}</TD>
          <TD className="text-center shrink">
            <Calendar isSmall={!isDetailPage} row={index} column={1} useTableValue={useTableValue} />
          </TD>
          <TD>
            <TableInput type={isDetailPage ? undefined : 'small'} row={index} column={2} useTableValue={useTableValue} />
          </TD>
          <TD>
            <TableInput type={isDetailPage ? undefined : 'small'} row={index} column={3} useTableValue={useTableValue} />
          </TD>
          <TD>
            <TableInput type={isDetailPage ? undefined : 'small'} row={index} column={4} useTableValue={useTableValue} />
          </TD>
          <TD className="text-center shrink">
            <Calendar isSmall={!isDetailPage} row={index} column={5} useTableValue={useTableValue} />
          </TD>
          <TD>
            <TableInput type={isDetailPage ? undefined : 'small'} row={index} column={6} useTableValue={useTableValue} />
          </TD>
          <TD>
            <TableInput type={isDetailPage ? undefined : 'small'} row={index} column={7} useTableValue={useTableValue} />
          </TD>
          <TD className="text-center">
            <Calendar isSmall={!isDetailPage} row={index} column={8} useTableValue={useTableValue} />
          </TD>
          <TD className="text-center">
            <Calendar isSmall={!isDetailPage} row={index} column={9} useTableValue={useTableValue} />
          </TD>
          {isDetailPage && (
            <TD className="text-center shrink">
              <button title={t('buttons.deleteRow')} className="btn btn-link" onClick={() => handleDeleteRow(index)}>
                <Icon icon="delete" svg />
                <span className="sr-only">{t('buttons.deleteRow')}</span>
              </button>
            </TD>
          )}
        </TR>
      ));
    }
    return null;
  };

  return (
    <div className={`tile-required-material ${isDetailPage ? '' : 'tile-required-material--small'}`}>
      <div className={`${isDetailPage ? 'container-fluid' : ''}`}>
        <div className={isDetailPage ? '' : 'px-1 pt-1'}>
          {!isDetailPage && <div className="tile-required-material__title">{t('forms.requiredMaterial.title')}</div>}
          <div className="d-flex justify-content-between align-items-center">
            <div className="tile-required-material__sub-title">{t('forms.requiredMaterial.subtitle')}</div>
            {isDetailPage && <ButtonToolbar submit={handleSubmit} clearAll={clearAllFields} />}
          </div>
        </div>
        <div>
          <Table className={isDetailPage ? 'tile-required-material__table' : 'tile-required-material__table table--small'}>
            <THead>
              <TR>
                <TH className="first-cell">
                  <div className="table__title">{t('sharedText.number')}</div>
                </TH>
                <TH className="table__table-header">
                  <div className="table__title text-left">{t('forms.requiredMaterial.datum')}</div>
                </TH>
                <TH className="table__table-header">
                  <div className="table__title text-left">{t('forms.requiredMaterial.name')}</div>
                </TH>
                <TH className="table__table-header">
                  <div className="table__title text-left">{t('forms.requiredMaterial.material')}</div>
                </TH>
                <TH className="table__table-header">
                  <div className="table__title text-center">{t('forms.requiredMaterial.requiredAmount')}</div>
                </TH>
                <TH className="table__table-header">
                  <div className="table__title text-center">{t('forms.requiredMaterial.byWhenNeeded')}</div>
                </TH>
                <TH className="table__table-header">
                  <div className="table__title text-center">{t('forms.requiredMaterial.quantityChecked')}</div>
                </TH>
                <TH className="table__table-header">
                  <div className="table__title text-center">{t('forms.requiredMaterial.orderedQuantity')}</div>
                </TH>
                <TH className="table__table-header">
                  <div className="table__title text-center">{t('forms.requiredMaterial.deliveryDate')}</div>
                </TH>
                <TH className="table__table-header">
                  <div className="table__title text-center">{t('forms.requiredMaterial.arrived')}</div>
                </TH>
                {isDetailPage && <TH />}
              </TR>
            </THead>
            <TBody>{getAllRows()}</TBody>
          </Table>
        </div>
        {isDetailPage && (
          <div className="d-flex justify-content-center my-4">
            <Button buttonTypes={['primary', 'big']} onClick={() => addEmptyRow()}>
              {t('buttons.addNewRow')}
            </Button>
          </div>
        )}
      </div>

      {isDetailPage && <UnsavedChangesPopup showIf={isFormDirty || isFormEditing} />}
    </div>
  );
};

export default TileRequiredMaterial;
