import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { history } from '../../../core/state/history';
import { RootState } from '../../../core/state/state';
import { getBoardPermissions, saveBoardPermissions } from '../../../core/state/back-office/asyncActions';
import { selectBoardPermissionsByBoardId } from '../../../core/state/back-office/selectors';

import EmployeeService from '../../../core/services/back-office/UsersService';
import { User } from '../../../core/state/back-office/types';
import { Sizes } from '../../../core/enums/Sizes';

import { spawnNotification } from '../../../core/state/notifications/asyncActions';
import { NotificationTypes } from '../../../core/enums/NotificationTypes';

import UnsavedChangesPopup from '../../../components/popup/UnsavedChangesPopup/UnsavedChangesPopup';
import BoardHeader from '../../components/Board/BoardHeader/BoardHeader';
import Board from '../../components/Board/Board';
import Button from '../../../components/Button/Button';
import ConfirmPopup, { ConfirmPopupProps } from '../../../components/popup/ConfirmPopup/ConfirmPopup';
import Autocomplete, { AutocompleteOption } from '../../../components/form/Autocomplete/Autocomplete';

const userToAutocompleteOption = (user: User): AutocompleteOption => ({
  value: user.employeeId.toString(),
  label: user.displayName,
  autocompleteLabel: `${user.displayName} (${user.email})`,
  data: user
});

const autocompleteOptionToUser = (option: AutocompleteOption) => option.data as User;

const validationSchema = yup.object().shape({
  owners: yup.array().required().min(1),
  participants: yup.array().required().min(0)
});

const BackOfficePermissions: React.FC<any> = () => {
  const { t } = useTranslation();
  const { id } = useParams<{ id: string | undefined }>();
  const boardId = Number(id);
  const dispatch = useDispatch();
  const [isDirty, setIsDirty] = useState(false);

  const { owners, participants } = useSelector((state: RootState) => selectBoardPermissionsByBoardId(state, boardId));

  const [confirmDialog, setConfirmDialog] = React.useState<ConfirmPopupProps>();

  const { handleSubmit, control, formState, reset, setValue, getValues, errors } = useForm({
    defaultValues: {
      owners: owners.map(userToAutocompleteOption),
      participants: participants.map(userToAutocompleteOption)
    },
    resolver: yupResolver(validationSchema)
  });
  const { isSubmitting, isSubmitSuccessful, submitCount, isSubmitted } = formState;

  useEffect(() => {
    dispatch(getBoardPermissions(boardId));
  }, [dispatch, boardId]);

  useEffect(() => {
    setValue('owners', owners.map(userToAutocompleteOption));
    setValue('participants', participants.map(userToAutocompleteOption));
  }, [setValue, owners, participants]);

  const validationNotification = useMemo(
    () => ({
      type: NotificationTypes.Error,
      message: t('backOffice.permissions.validationError')
    }),
    [t]
  );

  const errorNotification = useMemo(
    () => ({
      type: NotificationTypes.Error,
      message: t('sharedText.errorGeneric')
    }),
    [t]
  );

  useEffect(() => {
    if (!isSubmitSuccessful && !isSubmitting && submitCount > 0) {
      dispatch(spawnNotification(validationNotification));
    }
  }, [dispatch, validationNotification, isSubmitting, isSubmitSuccessful, submitCount, errors]);

  const dispatchSavePermissions = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-shadow
    async ({ owners, participants }) => {
      const isSuccessful: boolean = await dispatch<any>(
        saveBoardPermissions(boardId, {
          boardId,
          owners: owners.map(autocompleteOptionToUser),
          participants: participants.map(autocompleteOptionToUser)
        })
      );
      if (isSuccessful) {
        setTimeout(() => {
          history.push(`/boards/${boardId}/back-office`);
        }, 0);
      } else {
        dispatch(spawnNotification(errorNotification));
      }
    },
    [dispatch, boardId, errorNotification]
  );

  const loadOptions = async (query: string) => {
    const { data: users } = await EmployeeService.getUsers(query);
    return users.map(userToAutocompleteOption);
  };

  const removeFromOtherField = (fieldName: string, onChange: (...event: any[]) => void) => (values: AutocompleteOption[]) => {
    const optionValues = values.map((option) => option.value);
    setIsDirty(true);
    setValue(
      fieldName,
      (getValues(fieldName) as AutocompleteOption[]).filter(({ value }) => !optionValues.includes(value))
    );
    onChange(values);
  };

  return (
    <Board background="white">
      <BoardHeader boardId={boardId} text={t('backOffice.permissions.title')} noBottomMargin />
      <form className="back-office-page container-fluid" onSubmit={handleSubmit(dispatchSavePermissions)}>
        <div className="back-office-page__toolbar">
          <Button
            type="button"
            className="ml-auto"
            buttonTypes={['secondary', 'big']}
            onClick={() => {
              setConfirmDialog({
                heading: t('dialogs.confirmDialog.heading'),
                cancelText: t('dialogs.confirmDialog.cancelText'),
                confirmText: t('dialogs.confirmDialog.confirmText'),
                show: true,
                onCancel: () => {
                  setConfirmDialog({ show: false });
                },
                onConfirm: () => {
                  setIsDirty(true);
                  reset({
                    owners: [],
                    participants: []
                  });
                  setConfirmDialog({ show: false });
                }
              });
            }}
          >
            {t('buttons.clearAll')}
          </Button>
          <Button buttonTypes={['primary', 'big']} className="ml-3" type="submit">
            {t('buttons.save')}
          </Button>
        </div>

        <div className="back-office-page__content pt-0">
          <Controller
            control={control}
            name="owners"
            render={({ onChange, value }) => (
              <Autocomplete
                id="owners"
                name="owners"
                label={t('backOffice.permissions.owners')}
                labelSize={Sizes.Large}
                options={loadOptions}
                defaultValue={value}
                value={value}
                onChange={removeFromOtherField('participants', onChange)}
                error={errors.owners}
                showErrorMessage={false}
              />
            )}
          />

          <Controller
            control={control}
            name="participants"
            render={({ onChange, value }) => (
              <Autocomplete
                id="participants"
                name="participants"
                label={t('backOffice.permissions.participants')}
                labelSize={Sizes.Large}
                options={loadOptions}
                defaultValue={value}
                value={value}
                onChange={removeFromOtherField('owners', onChange)}
                showErrorMessage={false}
              />
            )}
          />

          <ConfirmPopup
            show={confirmDialog?.show}
            confirmText={confirmDialog?.confirmText}
            cancelText={confirmDialog?.cancelText}
            heading={confirmDialog?.heading}
            onCancel={confirmDialog?.onCancel}
            onConfirm={confirmDialog?.onConfirm}
          />
          <UnsavedChangesPopup showIf={isDirty && !isSubmitted} />
        </div>
      </form>
    </Board>
  );
};

export default BackOfficePermissions;
