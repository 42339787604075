import * as React from 'react';

import './Button.scss';

type ButtonType = 'primary' | 'secondary' | 'big' | 'small' | 'large' | 'red';

interface Props {
  type?: 'button' | 'submit' | 'reset';
  className?: string;
  buttonTypes?: ButtonType[];
  onClick?: () => void;
  disabled?: boolean;
}

const Button: React.FC<Props> = ({ type = 'button', buttonTypes = ['primary'], onClick, className = '', children, disabled = false }) => (
  <button
    type={type}
    onClick={onClick}
    className={`${className} button${buttonTypes.map((item) => ` button--${item}`).join('')}`}
    disabled={disabled}
  >
    {children}
  </button>
);

export default Button;
