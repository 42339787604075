import { RestResponse } from '../../types/response/RestResponse';
import service from '../../services/RestService';

export class TileService<T> {
  getTile = (tileUrl: string): RestResponse<T> => service.get(`${tileUrl}`);

  saveTile = (tileUrl: string, formData: T): RestResponse<T> => service.post(`${tileUrl}`, formData);
}

export default new TileService();
