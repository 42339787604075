import React from 'react';
import { useTranslation } from 'react-i18next';

import InfoPage from '../components/InfoPage/InfoPage';
import { HOME_ROUTE } from '../../core/constants/routes';

const Forbidden = () => {
  const { t } = useTranslation();

  return (
    <InfoPage
      title={t('sharedText.error403')}
      subtitle={t('sharedText.error403Text')}
      buttonRoute={HOME_ROUTE}
      buttonText={t('sharedText.backToOverview')}
    />
  );
};

export default Forbidden;
