import * as React from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { RootState } from '../../core/state/state';
import { getAllTiles } from '../../core/state/boards/asyncActions';
import { Board } from '../../core/state/boards/types';
import { BoardTypes } from '../../core/enums/BoardTypes';

import ShopFloorManagement from '../shopfloor-management/ShopFloorManagement';
import TopManagement from '../top-management/TopManagement';
import PLBoard from '../pl-board/PLBoard';
import { getHistoryList } from '../../core/state/history/asyncActions';

interface RootParams {
  id: string;
}

const getBoardComponent = (board: Board<any> | null) => {
  switch (board?.type) {
    case BoardTypes.BRLBoard:
      return <ShopFloorManagement board={board} />;
    case BoardTypes.GFBoard:
      return <TopManagement board={board} />;
    case BoardTypes.PLBoard:
      return <PLBoard board={board} />;
    default:
      return <></>;
  }
};

const BoardPage: React.FC<any> = () => {
  const { id }: RootParams = useParams();
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(getAllTiles(Number(id)));
    dispatch(getHistoryList(Number(id)));
  }, [dispatch, id]);

  const board = useSelector((state: RootState) => state.boards.board);

  return getBoardComponent(board);
};

export default BoardPage;
