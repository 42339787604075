import cloneDeep from 'lodash/cloneDeep';
import { ColumnType, MeasureDetail, TableForm, TableFormRow } from '../../state/table-forms/types';
import { TableFormFieldStatus } from '../../enums/TableFormFieldStatus';
import { CapacitySubcontractor } from '../../state/capacity-subcontractors/types';

export const getUpdateTableFormWithMeasure = (tableForm: TableForm | null, measure: MeasureDetail | null, measureURL?: string) => {
  const index = tableForm?.rows.findIndex((item) => item.measureURL === measureURL);
  if (index !== undefined && index > -1) {
    const newTableForm = cloneDeep(tableForm);
    const updatedColumns = newTableForm?.rows[index].columns.map((column) => {
      const newColumn = cloneDeep(column);
      switch (column.type) {
        case ColumnType.Measure:
          newColumn.text = measure?.measureText ?? '';
          break;
        case ColumnType.AssignedTo:
          newColumn.text = measure?.assignedTo ?? '';
          break;
        case ColumnType.MeasureStatus:
          newColumn.status = measure?.status ?? TableFormFieldStatus.Undefined;
          break;
        case ColumnType.Deadline:
          newColumn.text = measure?.deadline ?? '';
          break;
        default:
          break;
      }
      return newColumn;
    });
    if (updatedColumns) {
      (newTableForm?.rows[index] as TableFormRow).columns = updatedColumns;
    }
    return newTableForm;
  }
  return tableForm;
};

export const getUpdateCapacitySubcontractorWithMeasure = (form: CapacitySubcontractor | null, measure: MeasureDetail | null, measureURL?: string) => {
  const index = form?.rows.findIndex((item) => item.measureURL === measureURL);
  if (index !== undefined && index > -1) {
    const newForm = cloneDeep(form);
    const updatedRow = newForm?.rows[index];
    if (newForm && updatedRow) {
      updatedRow.measure = measure?.measureText ?? '';
      newForm.rows[index] = updatedRow;
    }
    return newForm;
  }
  return form;
};
