import React from 'react';

import { TileTypes } from '../../../core/enums/TileTypes';
import { TableTileTypes } from '../../../core/enums/TableTileTypes';
import TableFormTileDesktopScreen, { ColumnTypes } from '../../TableFormTile/DesktopScreen/TableFormTileDesktopScreen';

interface Props {
  boardId?: number;
  url: string;
}

const TilePlanningQualityDesktopScreen: React.FC<Props> = ({ url }) => {
  return (
    <TableFormTileDesktopScreen
      url={url}
      tileType={TileTypes.PlanningQuality}
      tableTileType={TableTileTypes.PlanningQuality}
      columns={[
        {
          type: ColumnTypes.Text,
          dataColumnIndex: 1
        },
        {
          type: ColumnTypes.StatusCircle,
          dataColumnIndex: 2
        },
        {
          type: ColumnTypes.Status,
          dataColumnIndex: 3
        },
        {
          type: ColumnTypes.Status,
          dataColumnIndex: 4
        },
        {
          type: ColumnTypes.Status,
          dataColumnIndex: 5
        },
        {
          type: ColumnTypes.Calendar,
          dataColumnIndex: 9
        }
      ]}
      showStatus
    />
  );
};

export default TilePlanningQualityDesktopScreen;
