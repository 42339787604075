import * as React from 'react';

import { Tile } from '../../state/boards/types';
import { TileTypes } from '../../enums/TileTypes';
import { getTileURL } from './getTile';

import DesktopScreen from '../../../pages/components/MediaQueries/DesktopScreen';
import TVScreen from '../../../pages/components/MediaQueries/TVScreen';

import TilePDF from '../../../tiles/TilePDF/TilePDF';
import TileAttendanceList from '../../../tiles/TileAttendanceList/TileAttendanceList';
import TileAttendanceListDesktopScreen from '../../../tiles/TileAttendanceList/DesktopScreen/TileAttendanceListDesktopScreen';
import TileVacationList from '../../../tiles/TileVacationList/TileVacationList';
import TileVacationListDesktopScreen from '../../../tiles/TileVacationList/DesktopScreen/TileVacationListDesktopScreen';
import TileTidinessList from '../../../tiles/TileTidinessList/TileTidinessList';
import TileTidinessListDesktopScreen from '../../../tiles/TileTidinessList/DesktopScreen/TileTidinessListDesktopScreen';
import TilePresenceList from '../../../tiles/TilePresenceList/TilePresenceList';
import TilePresenceListDesktopScreen from '../../../tiles/TilePresenceList/DesktopScreen/TilePresenceListDesktopScreen';
import TileConfirmationList from '../../../tiles/TileConfirmationList/TileConfirmationList';
import TileConfirmationListDesktopScreen from '../../../tiles/TileConfirmationList/DesktopScreen/TileConfirmationListDesktopScreen';
import TilePreview from '../../../tiles/TilePreview/TilePreview';
import TileWeekTopics from '../../../tiles/TileWeekTopics/TileWeekTopics';
import TileWeekTopicsDesktopScreen from '../../../tiles/TileWeekTopics/DesktopScreen/TileWeekTopicsDesktopScreen';
import TileEscalation from '../../../tiles/TileEscalation/TileEscalation';
import TileEscalationDesktopScreen from '../../../tiles/TileEscalation/DesktopScreen/TileEscalationDesktopScreen';
import TileProjectOverview from '../../../tiles/TileProjectOverview/TileProjectOverview';
import TileProjectAreaOverview from '../../../tiles/TileProjectAreaOverview/TileProjectAreaOverview';
import TileProcessConfirmation from '../../../tiles/TileProcessConfirmation/TileProcessConfirmation';
import TileProcessConfirmationDesktopScreen from '../../../tiles/TileProcessConfirmation/DesktopScreen/TileProcessConfirmationDesktopScreen';
import TileGoalSetting from '../../../tiles/TileGoalSetting/TileGoalSetting';
import TileGoalSettingDesktopScreen from '../../../tiles/TileGoalSetting/DesktopScreen/TileGoalSettingDesktopScreen';
import TileSickLeave from '../../../tiles/TileSickLeave/TileSickLeave';
import TileImage from '../../../tiles/TileImage/TileImage';
import TileStandingRules from '../../../tiles/TileStandingRules/TileStandingRules';
import TileStandingRulesDesktopScreen from '../../../tiles/TileStandingRules/DesktopScreen/TileStandingRulesDesktopScreen';
import TileMeasuresEscalation from '../../../tiles/TileMeasuresEscalation/TileMeasuresEscalation';
import TileMeasuresEscalationDesktopScreen from '../../../tiles/TileMeasuresEscalation/TileMeasuresEscalationDesktopScreen/TileMeasuresEscalationDesktopScreen';
import TileMeasure from '../../../tiles/TileMeasure/TileMeasure';
import TileMeasureDesktopScreen from '../../../tiles/TileMeasure/TileMeasureDesktopScreen/TileMeasureDesktopScreen';
import TileRequiredMaterial from '../../../tiles/TileRequiredMaterial/TileRequiredMaterial';
import TileRequiredMaterialDesktopScreen from '../../../tiles/TileRequiredMaterial/DesktopScreen/TileRequiredMaterialDesktopScreen';
import TileToDo from '../../../tiles/TileToDo/TileToDo';
import TileToDoDesktopScreen from '../../../tiles/TileToDo/DesktopScreen/TileToDoDesktopScreen';
import TileDefectProcessing from '../../../tiles/TileDefectProcessing/TileDefectProcessing';
import TileDefectProcessingDesktopScreen from '../../../tiles/TileDefectProcessing/DesktopScreen/TileDefectProcessingDesktopScreen';
import TileTrackingAAZ from '../../../tiles/TileTrackingAAZ/TileTrackingAAZ';
import TileTrackingAAZDesktopScreen from '../../../tiles/TileTrackingAAZ/DesktopScreen/TileTrackingAAZDesktopScreen';
import TilePlanningQuality from '../../../tiles/TilePlanningQuality/TilePlanningQuality';
import TilePlanningQualityDesktopScreen from '../../../tiles/TilePlanningQuality/DesktopScreen/TilePlanningQualityDesktopScreen';
import TileAdditionalServicesNU from '../../../tiles/TileAdditionalServicesNU/TileAdditionalServicesNU';
import TileAdditionalServicesNUDesktopScreen from '../../../tiles/TileAdditionalServicesNU/DesktopScreen/TileAdditionalServicesNUDesktopScreen';
import TileCompletionDateOrderAmount from '../../../tiles/TileCompletionDateOrderAmount/TileCompletionDateOrderAmount';
import TileCompletionDateOrderAmountDesktopScreen from '../../../tiles/TileCompletionDateOrderAmount/TileCompletionDateOrderAmountDesktopScreen/TileCompletionDateOrderAmountDesktopScreen';
import TilePlannerRisks from '../../../tiles/TilePlannerRisks/TilePlannerRisks';
import TilePlannerRisksDesktopScreen from '../../../tiles/TilePlannerRisks/TilePlannerRisksDesktopScreen';
import TilePerformanceNU from '../../../tiles/TilePerformanceNU/TilePerformanceNU';
import TilePerformanceNUDesktopScreen from '../../../tiles/TilePerformanceNU/TilePerformanceNUDesktopScreen';
import TileObstacles from '../../../tiles/TileObstacles/TileObstacles';
import TileObstaclesDesktopScreen from '../../../tiles/TileObstacles/DesktopScreen/TileObstaclesDesktopScreen';
import TilePlanApprovalCustomer from '../../../tiles/TilePlanApprovalCustomer/TilePlanApprovalCustomer';
import TilePlanApprovalCustomerDesktopScreen from '../../../tiles/TilePlanApprovalCustomer/TilePlanApprovalCustomerDesktopScreen';
import TilePlanning from '../../../tiles/TilePlanning/TilePlanning';
import TilePlanningDesktopScreen from '../../../tiles/TilePlanning/TilePlanningDesktopScreen';
import TileSampling from '../../../tiles/TileSampling/TileSampling';
import TileSamplingDesktopScreen from '../../../tiles/TileSampling/TileSamplingDesktopScreen';
import TileAnnouncement from '../../../tiles/TileAnnouncement/TileAnnouncement';
import TileAnnouncementDesktopScreen from '../../../tiles/TileAnnouncement/TileAnnouncementDesktopScreen';
import TileInspectionPlansQA from '../../../tiles/TileInspectionPlansQA/TileInspectionPlansQA';
import TileInspectionPlansQADesktopScreen from '../../../tiles/TileInspectionPlansQA/DesktopScreen/TileInspectionPlansQADesktopScreen';
import TileDeadlineCompliance from '../../../tiles/TileDeadlineCompliance/TileDeadlineCompliance';
import TileDeadlineComplianceDesktopScreen from '../../../tiles/TileDeadlineCompliance/TileDeadlineComplianceDesktopScreen';
import TileOrderPlacement from '../../../tiles/TileOrderPlacement/TileOrderPlacement';
import TileOrderPlacementDesktopScreen from '../../../tiles/TileOrderPlacement/TileOrderPlacementDesktopScreen';
import TileDefectProcessingNU from '../../../tiles/TileDefectProcessingNU/TileDefectProcessingNU';
import TileDefectProcessingNUDesktopScreen from '../../../tiles/TileDefectProcessingNU/TileDefectProcessingNUDesktopScreen';
import TileInspectionPlans from '../../../tiles/TileInspectionPlans/TileInspectionPlans';
import TileInspectionPlansDesktopScreen from '../../../tiles/TileInspectionPlans/TileInspectionPlansDesktopScreen';
import TileOccupationalSafety from '../../../tiles/TileOccupationalSafety/TileOccupationalSafety';
import TileOccupationalSafetyDesktopScreen from '../../../tiles/TileOccupationalSafety/DesktopScreen/TileOccupationalSafetyDesktopScreen';
import TileSupplySchedule from '../../../tiles/TileSupplySchedule/TileSupplySchedule';
import TileSupplyScheduleDesktopScreen from '../../../tiles/TileSupplySchedule/DesktopScreen/TileSupplyScheduleDesktopScreen';
import TileCapacitySubcontractor from '../../../tiles/TileCapacitySubcontractor/TileCapacitySubcontractor';
import TileCapacitySubcontractorDesktopScreen from '../../../tiles/TileCapacitySubcontractor/Desktop/TileCapacitySubcontractorDesktopScreen';
import TileCheckpointsMasterPlan from '../../../tiles/TileCheckpointMasterPlan/TileCheckpointsMasterPlan';
import TileCheckpointsMasterPlanDesktopScreen from '../../../tiles/TileCheckpointMasterPlan/DesktopScreen/TileCheckpointsMasterPlanDesktopScreen';
import TileProPlan from '../../../tiles/TileProPlan/TileProPlan';

const getTileComponent = (tile: Tile) => {
  switch (tile.type) {
    case TileTypes.Pdf:
      return <TilePDF url={getTileURL(tile)} />;
    case TileTypes.ProPlan:
      return <TileProPlan url={getTileURL(tile)} />;
    case TileTypes.AttendanceForm:
      return (
        <>
          <TVScreen>
            <TileAttendanceList url={getTileURL(tile)} />
          </TVScreen>
          <DesktopScreen>
            <TileAttendanceListDesktopScreen url={getTileURL(tile)} />
          </DesktopScreen>
        </>
      );
    case TileTypes.VacationForm:
      return (
        <>
          <TVScreen>
            <TileVacationList url={getTileURL(tile)} />
          </TVScreen>
          <DesktopScreen>
            <TileVacationListDesktopScreen url={getTileURL(tile)} />
          </DesktopScreen>
        </>
      );
    case TileTypes.TidinessForm:
      return (
        <>
          <TVScreen>
            <TileTidinessList url={getTileURL(tile)} />
          </TVScreen>
          <DesktopScreen>
            <TileTidinessListDesktopScreen url={getTileURL(tile)} />
          </DesktopScreen>
        </>
      );
    case TileTypes.PresenceForm:
      return (
        <>
          <TVScreen>
            <TilePresenceList url={getTileURL(tile)} />
          </TVScreen>
          <DesktopScreen>
            <TilePresenceListDesktopScreen url={getTileURL(tile)} />
          </DesktopScreen>
        </>
      );
    case TileTypes.ConfirmationForm:
      return (
        <>
          <TVScreen>
            <TileConfirmationList url={getTileURL(tile)} />
          </TVScreen>
          <DesktopScreen>
            <TileConfirmationListDesktopScreen url={getTileURL(tile)} />
          </DesktopScreen>
        </>
      );
    case TileTypes.EscalationFrom:
      return (
        <>
          <TVScreen>
            <TileEscalation url={getTileURL(tile)} />
          </TVScreen>
          <DesktopScreen>
            <TileEscalationDesktopScreen url={getTileURL(tile)} />
          </DesktopScreen>
        </>
      );
    case TileTypes.ExternalApp:
      return <TilePreview url={getTileURL(tile)} />;
    case TileTypes.KVPForm:
      return (
        <>
          <TVScreen>
            <TileMeasure url={getTileURL(tile)} />
          </TVScreen>
          <DesktopScreen>
            <TileMeasureDesktopScreen url={getTileURL(tile)} />
          </DesktopScreen>
        </>
      );
    case TileTypes.MeasuresAndEscalation:
      return (
        <>
          <TVScreen>
            <TileMeasuresEscalation url={getTileURL(tile)} />
          </TVScreen>
          <DesktopScreen>
            <TileMeasuresEscalationDesktopScreen url={getTileURL(tile)} />
          </DesktopScreen>
        </>
      );
    case TileTypes.WeeklyThemeForm:
      return (
        <>
          <TVScreen>
            <TileWeekTopics url={getTileURL(tile)} />
          </TVScreen>
          <DesktopScreen>
            <TileWeekTopicsDesktopScreen url={getTileURL(tile)} />
          </DesktopScreen>
        </>
      );
    case TileTypes.ProjectOverviewForm:
      return <TileProjectOverview url={getTileURL(tile)} />;
    case TileTypes.ProjectAreaOverview:
      return <TileProjectAreaOverview url={getTileURL(tile)} />;
    case TileTypes.PowerBI:
      return <TileImage url={getTileURL(tile, true)} />;
    case TileTypes.ProcessConfirmation:
      return (
        <>
          <TVScreen>
            <TileProcessConfirmation url={getTileURL(tile)} />
          </TVScreen>
          <DesktopScreen>
            <TileProcessConfirmationDesktopScreen url={getTileURL(tile)} />
          </DesktopScreen>
        </>
      );
    case TileTypes.AdditionalServicesNU:
      return (
        <>
          <TVScreen>
            <TileAdditionalServicesNU url={getTileURL(tile)} />
          </TVScreen>
          <DesktopScreen>
            <TileAdditionalServicesNUDesktopScreen url={getTileURL(tile)} />
          </DesktopScreen>
        </>
      );
    case TileTypes.Goals:
      return (
        <>
          <TVScreen>
            <TileGoalSetting url={getTileURL(tile)} />
          </TVScreen>
          <DesktopScreen>
            <TileGoalSettingDesktopScreen url={getTileURL(tile, true)} />
          </DesktopScreen>
        </>
      );
    case TileTypes.Accidents:
      return <TileSickLeave url={getTileURL(tile)} />;
    case TileTypes.RulesOfStanding:
      return (
        <>
          <TVScreen>
            <TileStandingRules url={getTileURL(tile)} />
          </TVScreen>
          <DesktopScreen>
            <TileStandingRulesDesktopScreen url={getTileURL(tile)} />
          </DesktopScreen>
        </>
      );
    case TileTypes.RequiredMaterial:
      return (
        <>
          <TVScreen>
            <TileRequiredMaterial url={getTileURL(tile)} />
          </TVScreen>
          <DesktopScreen>
            <TileRequiredMaterialDesktopScreen url={getTileURL(tile)} />
          </DesktopScreen>
        </>
      );
    case TileTypes.ToDoShortTerm:
      return (
        <>
          <TVScreen>
            <TileToDo url={getTileURL(tile)} />
          </TVScreen>
          <DesktopScreen>
            <TileToDoDesktopScreen url={getTileURL(tile)} />
          </DesktopScreen>
        </>
      );
    case TileTypes.DefectProcessing:
      return (
        <>
          <TVScreen>
            <TileDefectProcessing url={getTileURL(tile)} />
          </TVScreen>
          <DesktopScreen>
            <TileDefectProcessingDesktopScreen url={getTileURL(tile)} />
          </DesktopScreen>
        </>
      );
    case TileTypes.TrackingAAZ:
      return (
        <>
          <TVScreen>
            <TileTrackingAAZ url={getTileURL(tile)} />
          </TVScreen>
          <DesktopScreen>
            <TileTrackingAAZDesktopScreen url={getTileURL(tile)} />
          </DesktopScreen>
        </>
      );
    case TileTypes.PlanningQuality:
      return (
        <>
          <TVScreen>
            <TilePlanningQuality url={getTileURL(tile)} />
          </TVScreen>
          <DesktopScreen>
            <TilePlanningQualityDesktopScreen url={getTileURL(tile)} />
          </DesktopScreen>
        </>
      );
    case TileTypes.CompletionDateOrderAmount:
      return (
        <>
          <TVScreen>
            <TileCompletionDateOrderAmount url={getTileURL(tile)} />
          </TVScreen>
          <DesktopScreen>
            <TileCompletionDateOrderAmountDesktopScreen url={getTileURL(tile)} />
          </DesktopScreen>
        </>
      );
    case TileTypes.PlannerRisks:
      return (
        <>
          <TVScreen>
            <TilePlannerRisks url={getTileURL(tile)} />
          </TVScreen>
          <DesktopScreen>
            <TilePlannerRisksDesktopScreen url={getTileURL(tile)} />
          </DesktopScreen>
        </>
      );
    case TileTypes.PerformanceNU:
      return (
        <>
          <TVScreen>
            <TilePerformanceNU url={getTileURL(tile)} />
          </TVScreen>
          <DesktopScreen>
            <TilePerformanceNUDesktopScreen url={getTileURL(tile)} />
          </DesktopScreen>
        </>
      );
    case TileTypes.Obstacles:
      return (
        <>
          <TVScreen>
            <TileObstacles url={getTileURL(tile)} />
          </TVScreen>
          <DesktopScreen>
            <TileObstaclesDesktopScreen url={getTileURL(tile)} />
          </DesktopScreen>
        </>
      );
    case TileTypes.PlanApprovalCustomer:
      return (
        <>
          <TVScreen>
            <TilePlanApprovalCustomer url={getTileURL(tile)} />
          </TVScreen>
          <DesktopScreen>
            <TilePlanApprovalCustomerDesktopScreen url={getTileURL(tile)} />
          </DesktopScreen>
        </>
      );
    case TileTypes.Planning:
      return (
        <>
          <TVScreen>
            <TilePlanning url={getTileURL(tile)} />
          </TVScreen>
          <DesktopScreen>
            <TilePlanningDesktopScreen url={getTileURL(tile)} />
          </DesktopScreen>
        </>
      );
    case TileTypes.Sampling:
      return (
        <>
          <TVScreen>
            <TileSampling url={getTileURL(tile)} />
          </TVScreen>
          <DesktopScreen>
            <TileSamplingDesktopScreen url={getTileURL(tile)} />
          </DesktopScreen>
        </>
      );
    case TileTypes.Announcement:
      return (
        <>
          <TVScreen>
            <TileAnnouncement url={getTileURL(tile)} />
          </TVScreen>
          <DesktopScreen>
            <TileAnnouncementDesktopScreen url={getTileURL(tile)} />
          </DesktopScreen>
        </>
      );
    case TileTypes.InspectionPlansQAList:
      return (
        <>
          <TVScreen>
            <TileInspectionPlansQA url={getTileURL(tile)} />
          </TVScreen>
          <DesktopScreen>
            <TileInspectionPlansQADesktopScreen url={getTileURL(tile)} />
          </DesktopScreen>
        </>
      );
    case TileTypes.DeadlineCompliance:
      return (
        <>
          <TVScreen>
            <TileDeadlineCompliance url={getTileURL(tile)} />
          </TVScreen>
          <DesktopScreen>
            <TileDeadlineComplianceDesktopScreen url={getTileURL(tile)} />
          </DesktopScreen>
        </>
      );
    case TileTypes.OrderPlacement:
      return (
        <>
          <TVScreen>
            <TileOrderPlacement url={getTileURL(tile)} />
          </TVScreen>
          <DesktopScreen>
            <TileOrderPlacementDesktopScreen url={getTileURL(tile)} />
          </DesktopScreen>
        </>
      );
    case TileTypes.DefectProcessingNU:
      return (
        <>
          <TVScreen>
            <TileDefectProcessingNU url={getTileURL(tile)} />
          </TVScreen>
          <DesktopScreen>
            <TileDefectProcessingNUDesktopScreen url={getTileURL(tile)} />
          </DesktopScreen>
        </>
      );
    case TileTypes.InspectionPlans:
      return (
        <>
          <TVScreen>
            <TileInspectionPlans url={getTileURL(tile)} />
          </TVScreen>
          <DesktopScreen>
            <TileInspectionPlansDesktopScreen url={getTileURL(tile)} />
          </DesktopScreen>
        </>
      );
    case TileTypes.OccupationalSafety:
      return (
        <>
          <TVScreen>
            <TileOccupationalSafety url={getTileURL(tile)} />
          </TVScreen>
          <DesktopScreen>
            <TileOccupationalSafetyDesktopScreen url={getTileURL(tile)} />
          </DesktopScreen>
        </>
      );
    case TileTypes.SupplySchedule:
      return (
        <>
          <TVScreen>
            <TileSupplySchedule url={getTileURL(tile)} />
          </TVScreen>
          <DesktopScreen>
            <TileSupplyScheduleDesktopScreen url={getTileURL(tile)} />
          </DesktopScreen>
        </>
      );
    case TileTypes.CapacitySubcontractor:
      return (
        <>
          <TVScreen>
            <TileCapacitySubcontractor url={getTileURL(tile)} />
          </TVScreen>
          <DesktopScreen>
            <TileCapacitySubcontractorDesktopScreen url={getTileURL(tile)} />
          </DesktopScreen>
        </>
      );
    case TileTypes.CheckpointsMasterPlan:
      return (
        <>
          <TVScreen>
            <TileCheckpointsMasterPlan url={getTileURL(tile)} />
          </TVScreen>
          <DesktopScreen>
            <TileCheckpointsMasterPlanDesktopScreen url={getTileURL(tile)} />
          </DesktopScreen>
        </>
      );
    default:
      return <></>;
  }
};
export default getTileComponent;
