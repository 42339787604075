import * as React from 'react';
import { useTranslation } from 'react-i18next';

import TH from '../../components/Table/TH/TH';

interface Props {
  cellClass?: string;
  itemClass: string;
  translation: string;
  colSpan?: number;
}

const TileSickLeaveHeaderItem: React.FC<Props> = ({ colSpan, translation, cellClass, itemClass }) => {
  const { t } = useTranslation();

  return (
    <TH colSpan={colSpan} className={cellClass}>
      <div className={itemClass}>
        <span className="table__title">{t(translation)}</span>
      </div>
    </TH>
  );
};

export default TileSickLeaveHeaderItem;
