import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Route } from 'react-router-dom';

import TH from '../../../components/Table/TH/TH';
import { BOARDS_TILE_DETAIL } from '../../../core/constants/routes';
import Button from '../../../components/Button/Button';
import TileProcessConfirmationHeaderItem from './TileProcessConfirmationHeaderItem';
import TR from '../../../components/Table/TR/TR';

interface Props {
  handleSubmit: () => void;
}

const TileProcessConfirmationHeader: React.FC<Props> = ({ handleSubmit }) => {
  const { t } = useTranslation();

  return (
    <TR>
      <TH className="text-center">
        <Route exact path={BOARDS_TILE_DETAIL}>
          <Button buttonTypes={['large', 'primary']} className="mt-1" onClick={handleSubmit}>
            {t('buttons.save')}
          </Button>
        </Route>
      </TH>
      <TileProcessConfirmationHeaderItem translationSuffix="projectCast" />
      <TileProcessConfirmationHeaderItem translationSuffix="projectHandover" />
      <TileProcessConfirmationHeaderItem translationSuffix="awardSchedule" />
      <TileProcessConfirmationHeaderItem translationSuffix="workshop" />
      <TileProcessConfirmationHeaderItem translationSuffix="planManager" />
      <TileProcessConfirmationHeaderItem translationSuffix="docma" />
      <TileProcessConfirmationHeaderItem translationSuffix="scheduling" />
      <TileProcessConfirmationHeaderItem translationSuffix="checkPoints" />
      <TileProcessConfirmationHeaderItem translationSuffix="bsg" />
    </TR>
  );
};

export default TileProcessConfirmationHeader;
