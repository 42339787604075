import { TilePositions } from '../../enums/TilePositions';
import { Tile } from '../../state/boards/types';
import { TileTypes } from '../../enums/TileTypes';
import { BASE_URL } from '../../constants/api';

interface TileLocations {
  type?: TileTypes;
  onClickDataUrl: string | null;
  previewDataUrl?: string | null;
}

const checkUrl = (url: string | null | undefined) => {
  return url && url.startsWith('/');
};

export const getTileURL = <T extends TileLocations>(tile?: T, isPreview = false): string => {
  if (tile) {
    if (tile.type === TileTypes.ExternalApp || tile.type === TileTypes.ProPlan) {
      return tile.onClickDataUrl ?? '';
    }
    if (isPreview) {
      return `${BASE_URL}${checkUrl(tile.previewDataUrl) ? '' : '/'}${tile.previewDataUrl}`;
    }
    return `${BASE_URL}${checkUrl(tile.onClickDataUrl) ? '' : '/'}${tile.onClickDataUrl}`;
  }
  return '';
};

export const bindGetTileURL = (tiles?: Tile[]) => (position: TilePositions) => {
  const selectedTile = tiles?.find((tile: Tile) => tile.position === position);
  return getTileURL(selectedTile);
};

export const bindGetTile = (tiles?: Tile[]) => (position: TilePositions) => tiles?.find((tile: Tile) => tile.position === position);
