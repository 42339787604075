import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { Board as BoardModel, Tile as TileModel } from '../../core/state/boards/types';
import { Color } from '../../core/enums/Color';
import { TilePositions } from '../../core/enums/TilePositions';
import BoardGrid from '../components/Board/BoardGrid/BoardGrid';
import Tile from '../../components/Tile/Tile';
import { TileConfigurationDictionary } from '../../core/types/TileConfigurationDictionary';
import { getFilteredTiles } from '../../core/utils/tile/getFilteredTiles';
import { getIndexOfBoard } from './utils';
import SectionHeader from '../components/SectionHeader/SectionHeader';

interface Props {
  board: BoardModel<TileModel>;
}

const PLBoardTVGrid: React.FC<Props> = ({ board }) => {
  const { t } = useTranslation();
  const index = getIndexOfBoard(board);
  const tileConfigurations: TileConfigurationDictionary[] = [];

  tileConfigurations[0] = {
    [TilePositions.L1]: {
      headingBackgroundColor: Color.GreenLight,
      headingText: t('mainBoard.title.logistics')
    },
    [TilePositions.E1]: {
      headingBackgroundColor: Color.Red,
      headingText: t('mainBoard.title.escalation')
    },
    [TilePositions.Q1]: {
      headingBackgroundColor: Color.GreenLight,
      headingText: t('mainBoard.title.quality')
    }
  };

  tileConfigurations[1] = {
    [TilePositions.L1]: {
      headingBackgroundColor: Color.GreenLight,
      headingText: t('mainBoard.title.logistics')
    },
    [TilePositions.Q1]: {
      headingBackgroundColor: Color.GreenLight,
      headingText: t('mainBoard.title.quality')
    }
  };

  tileConfigurations[2] = {
    [TilePositions.E1]: {
      headingBackgroundColor: Color.Red,
      headingText: t('mainBoard.title.escalation')
    },
    [TilePositions.A1]: {
      headingBackgroundColor: Color.GreenLight,
      headingText: t('mainBoard.title.acceptanceDocumentation')
    }
  };

  return (
    <BoardGrid boardType={`${board.type}-${index}`}>
      {index === 2 && <SectionHeader className="mb-0 gh" text={t('mainBoard.title.workSafety')} color={Color.GreenLight} />}
      {getFilteredTiles(board.tiles)?.map((tile) => (
        <Tile
          key={tile.tileId}
          tile={tile}
          className={tile.position.toLowerCase()}
          isInteractive={tile.isInteractive}
          isTransparent={tileConfigurations[index - 1][tile.position]?.isTransparent}
          headingBackgroundColor={tileConfigurations[index - 1][tile.position]?.headingBackgroundColor}
          headingText={tileConfigurations[index - 1][tile.position]?.headingText}
        />
      ))}
    </BoardGrid>
  );
};

export default PLBoardTVGrid;
