import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import { cloneDeep } from 'lodash';

import { BOARDS_TILE_DETAIL } from '../../core/constants/routes';
import { RootState } from '../../core/state/state';
import { generateAsyncActions } from '../../core/state/table-forms/asyncActions';
import UnsavedChangesPopup from '../../components/popup/UnsavedChangesPopup/UnsavedChangesPopup';
import TileCompletionDate from '../TIleCompletionDate/TileCompletionDate';
import TileOrderAmount from '../TileOrderAmount/TileOrderAmount';
import { TileTypes } from '../../core/enums/TileTypes';
import { useTableForm } from '../../core/hooks/useTableForm';
import { DualTableForm } from '../../core/state/table-forms/types';
import ButtonToolbar from '../../pages/components/ButtonToolbar/ButtonToolbar';
import { TableFormFieldStatus } from '../../core/enums/TableFormFieldStatus';

import './TileCompletionDateOrderAmount.scss';

interface Props {
  url: string;
  boardId?: number;
}

const TileCompletionDateOrderAmount: React.FC<Props> = ({ url, boardId }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const isDetailPage = useRouteMatch(BOARDS_TILE_DETAIL);

  const tableForm = useSelector((state: RootState) => state.cDateOAmount.form);
  const { isFormDirty, isFormEditing } = useSelector((state: RootState) => state.settings);

  const { getTableForm, updateTableForm, saveTableForm } = React.useMemo(() => generateAsyncActions(TileTypes.CompletionDateOrderAmount), []);

  const { submit } = useTableForm<DualTableForm>({
    boardId,
    data: tableForm,
    updateActions: [updateTableForm],
    submitActions: [updateTableForm, saveTableForm]
  });

  React.useEffect(() => {
    dispatch(getTableForm(url));
  }, [url, dispatch, getTableForm]);

  const clearAllFields = () => {
    const newTableForm = cloneDeep(tableForm);
    if (newTableForm) {
      newTableForm.first.rows = [
        {
          rowNumber: 0,
          columns: [
            {
              columnNumber: 0,
              title: t('forms.completionDate.contractCompletionDate'),
              status: TableFormFieldStatus.Undefined,
              text: ''
            }
          ]
        }
      ];
      newTableForm.second.rows = [
        {
          rowNumber: 0,
          columns: [
            {
              columnNumber: 0,
              title: t('contractOrderAmount'),
              status: TableFormFieldStatus.Undefined,
              text: ''
            }
          ]
        }
      ];
      dispatch(updateTableForm(newTableForm));
    }
  };

  const handleSubmit = () => submit();

  return (
    <div className={`tile-completion-date-order-amount ${isDetailPage ? 'container-fluid' : 'tile-completion-date-order-amount--small px-1'}`}>
      <div className="py-1">
        <div className={`${isDetailPage ? 'd-flex justify-content-between align-items-center' : ''}`}>
          {!isDetailPage && <div className="tile-completion-date-order-amount__title">{t('forms.completionDate.orderAmount.title')}</div>}
          <div className="tile-completion-date-order-amount__sub-title">{t('forms.completionDate.orderAmount.subtitle')}</div>
          {isDetailPage && <ButtonToolbar submit={handleSubmit} clearAll={clearAllFields} />}
        </div>
      </div>
      <div className="tile-completion-date-order-amount__content">
        <div className={`tile-completion-date-order-amount__completion-date ${isDetailPage ? '' : 'pt-0'}`}>
          <TileCompletionDate url={url} />
        </div>
        <div className={`tile-completion-date-order-amount__order-amount ${isDetailPage ? '' : 'pt-0'}`}>
          <TileOrderAmount url={url} />
        </div>
      </div>
      {isDetailPage && <UnsavedChangesPopup showIf={isFormDirty || isFormEditing} />}
    </div>
  );
};

export default TileCompletionDateOrderAmount;
