import React from 'react';
import { useDispatch } from 'react-redux';
import cloneDeep from 'lodash/cloneDeep';
import { useTranslation } from 'react-i18next';

import { FormStatuses } from '../../../core/enums/FormStatuses';
import { Actions as SettingsActions } from '../../../core/state/settings/actions';
import { DualTableForm, TableForm } from '../../../core/state/table-forms/types';
import { ReduxThunkType } from '../../../core/types/ReduxThunkType';

import './FormStatus.scss';

interface Props {
  tableForm: TableForm | null;
  updateTableForm: (tableForm: TableForm | DualTableForm) => ReduxThunkType<void>;
  validStatuses?: FormStatuses[]; // First status is the default
  isSmall?: boolean;
}

const FormStatus: React.FC<Props> = ({
  tableForm,
  updateTableForm,
  validStatuses = [FormStatuses.Undefined, FormStatuses.Green, FormStatuses.Red],
  isSmall = false
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const defaultStatus = validStatuses.length ? validStatuses[0] : FormStatuses.Undefined;
  const [status, setStatus] = React.useState<FormStatuses>(defaultStatus);

  React.useEffect(() => {
    if (tableForm) {
      const initialStatus = validStatuses.includes(tableForm.status) ? tableForm.status : defaultStatus;
      setStatus(initialStatus);
    }
  }, [validStatuses, tableForm, defaultStatus]);

  const toggleStatus = async () => {
    let newStatus: FormStatuses;

    const index = validStatuses.indexOf(status);
    if (index < validStatuses.length - 1) {
      newStatus = validStatuses[index + 1];
    } else {
      newStatus = validStatuses[0]; // eslint-disable-line prefer-destructuring
    }

    setStatus(newStatus);
    if (tableForm) {
      await dispatch(updateTableForm(cloneDeep({ ...tableForm, status: newStatus })));
      await dispatch(SettingsActions.setIsFormDirty(true));
    }
  };

  return (
    <div className={`form-status mr-2 ${isSmall ? 'form-status--small' : ''}`}>
      <span className="form-status__text mr-1">{t('sharedText.status')}:</span>
      <button
        aria-label="status indicator"
        tabIndex={0}
        onClick={toggleStatus}
        onKeyDown={toggleStatus}
        className={`form-status__indicator form-status__indicator--${status.toLowerCase()}`}
      />
    </div>
  );
};

export default FormStatus;
