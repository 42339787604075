import * as React from 'react';
import { Link } from 'react-router-dom';

import TileProjectStatus from '../../TileProjectStatus/TileProjectStatus';
import { Tile } from '../../../core/state/boards/types';
import { TileTypes } from '../../../core/enums/TileTypes';
import ProjectAreaRowTile from '../ProjectAreaRowTile/ProjectAreaRowTile';
import ProjectAreaRowTitle from '../ProjectAreaRowTitle/ProjectAreaRowTitle';
import { Color } from '../../../core/enums/Color';
import { getTileURL } from '../../../core/utils/tile/getTile';
import { ProjectAreaOverviewFormRow } from '../../../core/state/project-area/types';

interface Props {
  row: ProjectAreaOverviewFormRow;
}

const ProjectAreaRow: React.FC<Props> = ({ row }) => {
  const projectStatusTile = row.tiles.find((tile) => tile.type === TileTypes.GfAreaProjects);
  const boardUrl = projectStatusTile?.onClickDataUrl;

  return (
    <>
      <ProjectAreaRowTitle text={row.title} color={Color.Grey1} url={boardUrl} />
      {row.tiles.map((tile: Tile) => {
        if (tile.type === TileTypes.PowerBI || tile.type === TileTypes.Pdf) {
          return <ProjectAreaRowTile key={tile.position} tile={tile} />;
        }

        return (
          <section className="tile" key={tile.position}>
            <div className="tile__content">
              <div className="tile__data">
                {tile.type !== TileTypes.Empty && (
                  <>
                    <Link className="tile__link" to={tile.onClickDataUrl || ''}>
                      <TileProjectStatus url={getTileURL(tile, true)} />
                    </Link>
                  </>
                )}
              </div>
            </div>
          </section>
        );
      })}
    </>
  );
};

export default ProjectAreaRow;
