import uniqueId from 'lodash/uniqueId';

import { ReduxThunkType } from '../../types/ReduxThunkType';
import { Actions as NotificationsActions } from './actions';
import { DEFAULT_AUTOHIDE_DELAY, Notification } from './types';
import { NotificationTypes } from '../../enums/NotificationTypes';
import { t } from '../../../../i18n';

export const spawnNotification = (notification: Notification): ReduxThunkType<void> => (dispatch) => {
  const uniqueNotification = {
    ...notification,
    id: uniqueId(),
    delay: notification.delay || DEFAULT_AUTOHIDE_DELAY
  };
  dispatch(NotificationsActions.spawnNotification(uniqueNotification));
};

export const spawnSaveSuccessNotification = (): ReduxThunkType<void> => (dispatch) => {
  dispatch(
    spawnNotification({
      type: NotificationTypes.Success,
      message: t('sharedText.successNotification')
    })
  );
};
