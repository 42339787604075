import { useDispatch, useSelector } from 'react-redux';
import { useCallback, useLayoutEffect, useState } from 'react';
import { EscalationDetail, EscalationLevel, EscalationType, MeasureAndEscalationForm, MeasureDetail } from '../../core/state/table-forms/types';
import { updateMeasuresAndEscalations } from '../../core/state/table-forms/asyncActions';
import { RootState } from '../../core/state/state';
import { TableFormFieldStatus } from '../../core/enums/TableFormFieldStatus';
import { FormTypes } from '../../pages/components/MeasureEscalationModal/formTypes';
import { escalationLevels } from '../../core/constants/escalationLevelsArray';

type measureEscalationFormKey = keyof Omit<MeasureAndEscalationForm, 'level'>;
export type measureEscalationKey = keyof MeasureDetail | keyof EscalationDetail;

export function useDeleteRow(key: measureEscalationFormKey) {
  const dispatch = useDispatch();
  const form = useSelector((state: RootState) => state.tableForm.measuresEscalationForm.form);

  return useCallback(
    (index: number) => {
      if (form) {
        const updatedForm = {
          ...form,
          escalations: form?.escalations ? [...form.escalations] : [],
          measures: form?.measures ? [...form.measures] : []
        };

        if (key === 'escalations') {
          updatedForm.escalations.splice(index, 1);
        } else {
          updatedForm.measures.splice(index, 1);
        }

        dispatch(updateMeasuresAndEscalations(updatedForm));
      }
    },
    [dispatch, form, key]
  );
}

export function useEditRow(keyForm: measureEscalationFormKey) {
  const dispatch = useDispatch();
  const form = useSelector((state: RootState) => state.tableForm.measuresEscalationForm.form);

  return useCallback(
    (index: number, key: measureEscalationKey, value: any) => {
      if (form) {
        const updatedForm = {
          ...form,
          escalations: form?.escalations ? [...form.escalations] : [],
          measures: form?.measures ? [...form.measures] : []
        };

        const updateRow: any = updatedForm[keyForm][index];
        updateRow[key] = value;
        dispatch(updateMeasuresAndEscalations(updatedForm));
      }
    },
    [dispatch, form, keyForm]
  );
}

export function useEscalateMeasure(rowIndex: number) {
  const dispatch = useDispatch();
  const form = useSelector((state: RootState) => state.tableForm.measuresEscalationForm.form);

  return useCallback(() => {
    if (form) {
      const updatedForm = {
        ...form,
        escalations: form?.escalations ? [...form.escalations] : [],
        measures: form?.measures ? [...form.measures] : []
      };

      const currentMeasure = updatedForm.measures[rowIndex];

      const newEscalation: EscalationDetail = {
        problem: currentMeasure.problemText,
        summary: currentMeasure.measureText,
        openedAt: currentMeasure.date,
        level: form.level ?? EscalationLevel.CON,
        type: EscalationType.Informative,
        riskPotential: '',
        deadlineAt: currentMeasure.deadline ? currentMeasure.deadline : null,
        status: TableFormFieldStatus.Unchecked,
        measureId: currentMeasure.id,
        assignedTo: currentMeasure.assignedTo
      };

      updatedForm.measures.splice(rowIndex, 1);
      updatedForm.escalations.push(newEscalation);

      dispatch(updateMeasuresAndEscalations(updatedForm));
    }
  }, [dispatch, form, rowIndex]);
}

export function useReturnToMeasure(rowIndex: number) {
  const dispatch = useDispatch();
  const form = useSelector((state: RootState) => state.tableForm.measuresEscalationForm.form);

  return useCallback(() => {
    if (form) {
      const updatedForm = {
        ...form,
        escalations: form?.escalations ? [...form.escalations] : [],
        measures: form?.measures ? [...form.measures] : []
      };

      const currentEscalation = updatedForm.escalations[rowIndex];

      const newMeasure: MeasureDetail = {
        date: currentEscalation.openedAt,
        problemText: currentEscalation.problem,
        measureText: currentEscalation.summary,
        assignedTo: currentEscalation.assignedTo ?? '',
        deadline: currentEscalation.deadlineAt ? currentEscalation.deadlineAt : null,
        status: TableFormFieldStatus.Undefined,
        type: '',
        escalationId: currentEscalation.id
      };

      updatedForm.escalations.splice(rowIndex, 1);
      updatedForm.measures.push(newMeasure);

      dispatch(updateMeasuresAndEscalations(updatedForm));
    }
  }, [dispatch, form, rowIndex]);
}

export function useScrollToNewItem() {
  const form = useSelector((state: RootState) => state.tableForm.measuresEscalationForm.form);
  const [updatedType, setUpdatedType] = useState<FormTypes>();

  const scrollContainer = useCallback((selector: string) => {
    const element = document.querySelector(selector);
    if (element) {
      element.scrollTop = element.scrollHeight;
    }
  }, []);

  useLayoutEffect(() => {
    if (updatedType === FormTypes.Measure) {
      scrollContainer('.tile-kvp-form__table-wrap');
      setUpdatedType(undefined);
    } else if (updatedType === FormTypes.Escalation) {
      scrollContainer('.tile-escalation-form__table-wrap');
      setUpdatedType(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form]);

  return setUpdatedType;
}

export enum EscalationDirection {
  Up = 'UP',
  Down = 'DOWN'
}

export function useChangeEscalationLevel(direction: EscalationDirection) {
  const dispatch = useDispatch();
  const form = useSelector((state: RootState) => state.tableForm.measuresEscalationForm.form);

  return useCallback(
    (index: number) => {
      if (form) {
        const updatedForm = {
          ...form,
          escalations: form?.escalations ? [...form.escalations] : [],
          measures: form?.measures ? [...form.measures] : []
        };
        const currentLevel = updatedForm.escalations[index].level;
        const currentIndex = escalationLevels.findIndex((item) => item === currentLevel);

        if (currentIndex > -1) {
          if (direction === EscalationDirection.Up) {
            updatedForm.escalations[index].level = escalationLevels[currentIndex + 1];
          } else {
            updatedForm.escalations[index].level = escalationLevels[currentIndex - 1];
          }
        }

        dispatch(updateMeasuresAndEscalations(updatedForm));
      }
    },
    [dispatch, form, direction]
  );
}
