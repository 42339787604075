import { createAction } from '../action';
import { getActionTypeByName } from '../../state/helpers';
import { RestErrorResponse } from '../../types/response/RestErrorResponse';

export const generateActions = <T>(type: string) => ({
  getTile: () => createAction(getActionTypeByName(`GET_${type}`)),
  getTileSuccess: (form: T) => createAction(getActionTypeByName(`GET_${type}_SUCCESS`), form),
  getTileError: (error: RestErrorResponse) => createAction(getActionTypeByName(`GET_${type}_ERROR`), error),

  saveTile: () => createAction(getActionTypeByName(`SAVE_${type}`)),
  saveTileSuccess: (form: T) => createAction(getActionTypeByName(`SAVE_${type}_SUCCESS`), form),
  saveTileError: (error: RestErrorResponse) => createAction(getActionTypeByName(`SAVE_${type}_ERROR`), error),

  updateTile: (form: T) => createAction(getActionTypeByName(`UPDATE_${type}`), form)
});
