import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';
import { format } from 'date-fns';

import Button from '../../../components/Button/Button';
import { TableFormFieldStatus } from '../../../core/enums/TableFormFieldStatus';
import Calendar from '../../../components/form/Calendar/Calendar';
import Input from '../../../components/form/Input/Input';
import TextArea from '../../../components/form/TextArea/TextAreaHookForm';
import { DATE_FORMAT_BACKEND } from '../../../core/constants/date';
import { MeasureAndEscalation, MeasureDetail } from '../../../core/state/table-forms/types';

import './MeasureEscalationModal.scss';

export interface Props {
  onCancel?: () => void;
  onConfirm?: (data: MeasureAndEscalation) => void;
  KVPText?: {
    problem: string;
    measure: string | number;
    assignedTo?: string;
    deadline?: string | null;
    status?: TableFormFieldStatus;
  };
  onDirtyStatusChange: (isDirty: boolean) => void;
}

const removeEmptyDates = (measure: MeasureDetail): MeasureDetail => {
  return {
    ...measure,
    status: TableFormFieldStatus.Quarter,
    date: measure.date || null,
    deadline: measure.deadline || null
  };
};

const CreateMeasureForm: React.FC<Props> = ({ onCancel, onConfirm, KVPText, onDirtyStatusChange }) => {
  const { t } = useTranslation();

  const {
    register,
    handleSubmit,
    control,
    formState: { isDirty, isSubmitting, isSubmitSuccessful }
  } = useForm<MeasureDetail>({
    defaultValues: {
      date: format(new Date(), DATE_FORMAT_BACKEND),
      problemText: KVPText?.problem ?? '',
      measureText: (KVPText?.measure ?? '').toString(),
      assignedTo: KVPText?.assignedTo ?? '',
      deadline: KVPText?.deadline ?? null,
      status: KVPText?.status ?? TableFormFieldStatus.Undefined
    }
  });

  useEffect(() => {
    onDirtyStatusChange(isDirty);
  }, [onDirtyStatusChange, isDirty]);

  const onSubmit = handleSubmit(async (measure) => {
    if (onConfirm) {
      onConfirm({ measure: removeEmptyDates(measure), escalation: null });
    }
  });

  return (
    <form onSubmit={onSubmit}>
      <Controller
        name="date"
        control={control}
        render={({ onChange, value }) => (
          <Calendar
            label={t('measureForm.date')}
            isFormInput
            onChange={async (date) => {
              onChange(date);
            }}
            isCentered={false}
            value={value}
          />
        )}
      />

      <TextArea id="problemText" name="problemText" label={t('measureForm.problem')} register={register} />
      <TextArea id="measureText" name="measureText" label={t('measureForm.decision')} register={register} />
      <Input id="assignedTo" name="assignedTo" label={t('measureForm.responsible')} register={register} />

      <div className="d-flex flex-row">
        <div>
          <Controller
            name="deadline"
            control={control}
            render={({ onChange, value }) => (
              <Calendar
                label={t('measureForm.appointment')}
                isFormInput
                onChange={async (date) => {
                  onChange(date);
                }}
                isCentered={false}
                value={value}
              />
            )}
          />
        </div>
      </div>

      <div className="d-flex justify-content-center measure-form__buttons">
        <Button className="mr-2" buttonTypes={['big', 'secondary']} onClick={onCancel}>
          {t('sharedText.cancel')}
        </Button>
        <Button buttonTypes={['big', 'primary']} type="submit" disabled={isSubmitting || isSubmitSuccessful}>
          {t('sharedText.create')}
        </Button>
      </div>
    </form>
  );
};

export default CreateMeasureForm;
