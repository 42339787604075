import * as React from 'react';
import BoardHistory from '../../BoardHistory/BoardHistory';
import { BoardTypes } from '../../../../../core/enums/BoardTypes';

interface Props {
  text?: string;
  boardId?: number;
  boardType?: BoardTypes;
}

const BoardHeaderCenter: React.FC<Props> = ({ text, boardType, boardId }) => {
  return (
    <div className="board-header__center">
      <BoardHistory text={text} boardId={boardId} boardType={boardType} />
    </div>
  );
};

export default BoardHeaderCenter;
