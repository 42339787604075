import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { useSelector } from 'react-redux';

import MasterPage from '../../components/MasterPage/MasterPage';
import { RootState } from '../../core/state/state';
import { AuthStatus } from '../../core/enums/AuthStatus';
import { LOGIN } from '../../core/constants/routes';

interface Props {
  component: any;
  exact?: boolean;
  path: string;
}

export const PrivateRoute: React.FC<Props> = ({ component: Component, exact, path }) => {
  const status = useSelector((state: RootState) => state.auth.status);
  return (
    <Route
      exact={exact}
      path={path}
      render={({ history, location, match, staticContext }) => {
        if (status === AuthStatus.Authenticated) {
          return (
            <MasterPage>
              <Component history={history} location={location} match={match} staticContext={staticContext} />
            </MasterPage>
          );
        }
        return (
          <Redirect
            to={{
              pathname: LOGIN,
              state: { from: location }
            }}
          />
        );
      }}
    />
  );
};
