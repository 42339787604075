import React from 'react';
import { useTranslation } from 'react-i18next';
import { TileTypes } from '../../core/enums/TileTypes';

import TableFormMeasuresTile, { ColumnInputTypes } from '../TableFormMeasuresTile/TableFormMeasuresTile';
import { ColumnType } from '../../core/state/table-forms/types';

interface Props {
  boardId?: number;
  url: string;
}

const TileDefectProcessingNU: React.FC<Props> = ({ boardId, url }) => {
  const { t } = useTranslation();

  return (
    <TableFormMeasuresTile
      tileType={TileTypes.DefectProcessingNU}
      boardId={boardId}
      url={url}
      stateKey="defectProcessingNU"
      title={t('forms.defectProcessingNU.title')}
      subtitle1={t('forms.defectProcessingNU.subtitle')}
      headers={[
        {
          title: t('sharedText.number'),
          className: 'w-5'
        },
        {
          title: t('forms.defectProcessingNU.column1'),
          subtitle: t('forms.defectProcessingNU.column1subtitle'),
          className: 'w-20'
        },
        {
          title: t('forms.defectProcessingNU.column2'),
          subtitle: t('forms.defectProcessingNU.column2subtitle'),
          className: 'w-20'
        },
        {
          title: t('forms.defectProcessingNU.column3'),
          subtitle: t('forms.defectProcessingNU.column3subtitle'),
          className: 'w-15'
        },
        {
          title: t('sharedText.tableForm.assigned'),
          className: 'w-20'
        },
        {
          title: t('sharedText.tableForm.deadline'),
          className: 'w-5'
        },
        {
          title: t('sharedText.tableForm.status'),
          className: 'w-4'
        },
        {
          title: t('sharedText.tableForm.level'),
          className: 'w-5'
        }
      ]}
      columns={[
        [
          {
            inputType: ColumnInputTypes.Index,
            title: 'Index',
            className: 'text-center'
          },
          {
            inputType: ColumnInputTypes.Text,
            title: t('forms.defectProcessingNU.column1'),
            dataColumnIndex: 0
          },
          {
            inputType: ColumnInputTypes.Text,
            title: t('forms.defectProcessingNU.column2'),
            dataColumnIndex: 1
          },
          {
            inputType: ColumnInputTypes.Text,
            title: t('forms.defectProcessingNU.column3'),
            dataColumnIndex: 2,
            columnType: ColumnType.Measure
          },

          {
            inputType: ColumnInputTypes.Text,
            title: t('sharedText.tableForm.assigned'),
            dataColumnIndex: 3,
            columnType: ColumnType.AssignedTo
          },
          {
            inputType: ColumnInputTypes.Calendar,
            title: t('sharedText.tableForm.deadline'),
            dataColumnIndex: 4,
            columnType: ColumnType.Deadline
          },
          {
            inputType: ColumnInputTypes.Status,
            title: t('sharedText.tableForm.status'),
            dataColumnIndex: 5,
            columnType: ColumnType.MeasureStatus
          },
          {
            inputType: ColumnInputTypes.PlainText,
            className: 'text-center',
            title: t('sharedText.tableForm.level'),
            dataColumnIndex: 6,
            columnType: ColumnType.EscalationLevel
          }
        ]
      ]}
      statusText={t('forms.defectProcessingNU.statusText')}
      showStatus
    />
  );
};
export default TileDefectProcessingNU;
