export enum Color {
  Black = 'black',
  Red = 'red',
  Red3 = 'red3',
  Grey = 'grey',
  White = 'white',
  Green = 'green',
  GreenLight = 'green3',
  Grey1 = 'grey1',
  Orange = 'orange'
}
