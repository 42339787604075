import { useSelector } from 'react-redux';
import { RootState } from '../core/state/state';
import { BOARDS, UNASSIGNED_BOARD } from '../core/constants/routes';

export function useLandingRoute() {
  const menuState = useSelector((state: RootState) => state.menuItems);

  if (menuState.list && menuState.list.length > 0) {
    return `${BOARDS}/${menuState.list[0].boardId}`;
  }
  if (menuState.list && menuState.list.length === 0) {
    return UNASSIGNED_BOARD;
  }
  return '';
}
