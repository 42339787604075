import * as React from 'react';
import { Link } from 'react-router-dom';

interface Props {
  url: string;
  isActive: boolean;
}

const BoardNavDot: React.FC<Props> = ({ url, isActive }) => (
  <Link to={url} className={`board-nav__dot ${isActive ? 'board-nav__dot--active' : ''}`} />
);

export default BoardNavDot;
