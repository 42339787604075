import React, { useCallback, useState } from 'react';
import { addDays, isSameDay } from 'date-fns';
import { useDispatch, useSelector } from 'react-redux';
import cloneDeep from 'lodash/cloneDeep';
import { useRouteMatch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import TD from '../../../components/Table/TD/TD';
import TileCapacitySubcontractorNumberInfo from '../TileSubcontractorColumnInfo/TileCapacitySubcontractorNumberInfo';
import TileCapacitySubcontractorEmptyColumn from '../TileSubcontractorColumnInfo/TileCapacitySubcontractorEmptyColumn';
import TR from '../../../components/Table/TR/TR';
import TextArea from '../../../components/form/TextArea/TextArea';
import Icon from '../../../components/Icon/Icon';
import { CapacitySubcontractor, CSDay, CSROW } from '../../../core/state/capacity-subcontractors/types';
import TableInput from '../../../components/form/TableInput/TableInput';
import { RootState } from '../../../core/state/state';
import { generateAsyncActions } from '../../../core/utils/state/generateAsyncActions';
import { TileTypes } from '../../../core/enums/TileTypes';
import { TileService } from '../../../core/utils/state/TileService';
import { toISODateTimeString } from '../../../core/utils/formatDate';
import MeasureEscalationModal from '../../../pages/components/MeasureEscalationModal/MeasureEscalationModal';
import { MeasureAndEscalation } from '../../../core/state/table-forms/types';
import { BOARDS_TILE_DETAIL } from '../../../core/constants/routes';
import { getNumberOfWorkers, workersType } from '../helpers';
import { getMeasureInfoCS, MeasureViewModel } from '../../../core/utils/measures/getMeasureInfo';
import { useMeasureCapacitySubcontractorChange } from '../../../core/hooks/measure/useMeasureCapacitySubcontractorChange';
import { getUpdateCapacitySubcontractorWithMeasure } from '../../../core/utils/measures/getUpdatedTableFromWithMeasure';

interface Props {
  date: Date;
  row: CSROW;
  rowIndex: number;
  rowsLength: number;
  measuresArray: MeasureViewModel[];
  setMeasuresArray: (measureArray: MeasureViewModel[]) => any;
}

interface Selected {
  type?: workersType;
  rowIndex?: number;
  date?: Date;
  value?: number | string;
}

const TileCapacitySubcontractorsTableRow: React.FC<Props> = ({ date, row, rowIndex, rowsLength, measuresArray, setMeasuresArray }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [selected, setSelected] = useState<Selected>();
  const form = useSelector((state: RootState) => state.capacitySubcontractor.form);
  const { updateTileForm } = React.useMemo(() => generateAsyncActions<CapacitySubcontractor>(TileTypes.CapacitySubcontractor, new TileService()), []);
  const [isModalOpen, setModalOpen] = useState<boolean>(false);
  const [measuresRow, setMeasuresRow] = useState(-1);
  const selectedMeasure = getMeasureInfoCS(form, measuresRow);

  useMeasureCapacitySubcontractorChange(measuresArray, setMeasuresArray, form);

  const isDetailPage = useRouteMatch(BOARDS_TILE_DETAIL);

  const handleCreatedMeasure = async (data: MeasureAndEscalation) => {
    const newMeasuresArray = cloneDeep(measuresArray);
    const newTableForm = getUpdateCapacitySubcontractorWithMeasure(form, data.measure, selectedMeasure?.measureURL);
    if (data.measure) {
      newMeasuresArray.push({
        measure: data.measure,
        measureURL: selectedMeasure?.measureURL
      });
    }

    if (newTableForm) {
      await dispatch(updateTileForm(newTableForm));
    }

    setMeasuresArray(newMeasuresArray);
    setModalOpen(false);

    setModalOpen(false);
  };

  const closeModal = useCallback(() => {
    setModalOpen(false);
  }, [setModalOpen]);

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.replace(/[^0-9]/g, '');
    if (value.length > 3) {
      return;
    }
    const transformedValue = Number(value);
    if (transformedValue === 0) {
      setSelected({ ...selected, value: '0' });
    } else {
      setSelected({ ...selected, value: transformedValue });
    }
  };

  const handleOnBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    const newForm = cloneDeep(form);
    if (newForm) {
      const dayIndex = newForm?.rows[rowIndex].days.findIndex((i) => isSameDay(new Date(i?.date ?? ''), new Date(selected?.date ?? '')));
      if (dayIndex >= 0) {
        if (selected?.type === 'actual') {
          (newForm?.rows[rowIndex].days[dayIndex] as CSDay).actual = Number(e.target.value);
        } else {
          (newForm?.rows[rowIndex].days[dayIndex] as CSDay).planned = Number(e.target.value);
        }
      } else {
        newForm?.rows[rowIndex].days.push({
          actual: selected?.type === 'actual' ? Number(e.target.value) : undefined,
          planned: selected?.type === 'planned' ? Number(e.target.value) : undefined,
          date: toISODateTimeString(selected?.date ?? new Date())
        });
      }
      dispatch(updateTileForm(newForm));
    }
  };

  const handleBlurOnTextarea = (e: React.FocusEvent<HTMLTextAreaElement>, property: 'subcontractor' | 'measure') => {
    const newForm = cloneDeep(form);
    if (newForm) {
      (newForm?.rows[rowIndex] as CSROW)[property] = e.target.value;
      dispatch(updateTileForm(newForm));
    }
  };

  const generateRow = (currentDate: Date, type: workersType) => {
    return (
      <div className="tile-capacity-subcontractor__number-row">
        {Array(6)
          .fill(0)
          .map((item, index) => {
            const thisDate = addDays(currentDate, index);
            const rowDays = row.days;
            const itemDay = rowDays.find((day) => isSameDay(new Date(day.date), thisDate));

            const numberOfWorkers = getNumberOfWorkers(type, itemDay);

            const handleOnClick = () => {
              setSelected({ date: thisDate, rowIndex, type, value: numberOfWorkers });
            };

            const value =
              selected?.type === type && selected?.rowIndex === rowIndex && isSameDay(new Date(selected?.date ?? ''), thisDate)
                ? selected.value
                : numberOfWorkers;

            return (
              <span key={thisDate.toString()} className="tile-capacity-subcontractor__number">
                {type !== 'sum' ? (
                  <>
                    <TableInput onChange={handleOnChange} onClick={handleOnClick} onBlur={handleOnBlur} value={value ?? ''} type="small" />
                  </>
                ) : (
                  <span
                    className={`tile-capacity-subcontractor__result ${(numberOfWorkers ?? 0) < 0 ? 'tile-capacity-subcontractor__result--red' : ''}`}
                  >
                    {itemDay ? numberOfWorkers : ''}
                  </span>
                )}
              </span>
            );
          })}
      </div>
    );
  };

  return (
    <TR>
      <TD className="vertical-top text-center text-600">{rowIndex + 1}</TD>
      <TD>
        <TextArea
          onBlur={(e) => handleBlurOnTextarea(e, 'subcontractor')}
          id="subcontractor"
          name="subcontractor"
          defaultValue={row.subcontractor ?? ''}
          usingDefaultValue
        />
      </TD>
      <TileCapacitySubcontractorNumberInfo />
      <TD className="tile-capacity-subcontractor__number-row-wrap">
        {generateRow(date, 'planned')}
        {generateRow(date, 'actual')}
        {generateRow(date, 'sum')}
      </TD>
      <TD className="empty">empty</TD>
      <TD className="tile-capacity-subcontractor__number-row-wrap">
        {generateRow(addDays(date, 7), 'planned')}
        {generateRow(addDays(date, 7), 'actual')}
        {generateRow(addDays(date, 7), 'sum')}
      </TD>
      <TD className="empty">empty</TD>
      {rowIndex === 0 && <TileCapacitySubcontractorEmptyColumn rowsLength={rowsLength} />}
      <TD className="empty">empty</TD>
      <TD className={`${row.isMeasureEditable !== undefined && !row.isMeasureEditable ? 'table-item-disabled' : ''}`} colSpan={isDetailPage ? 1 : 2}>
        <TextArea usingDefaultValue id="measure" name="measure" defaultValue={row.measure ?? ''} onBlur={(e) => handleBlurOnTextarea(e, 'measure')} />
      </TD>
      {isDetailPage && (
        <TD
          className={`shrink w-2 text-center tile-capacity-subcontractor__add-measure ${
            row.isMeasureEditable !== undefined && !row.isMeasureEditable ? 'table-item-disabled' : ''
          }`}
        >
          <span className="d-inline-block" title={t('buttons.addMeasure')}>
            <Icon
              icon="plus"
              svg
              isButton
              onClick={() => {
                setMeasuresRow(rowIndex);
                setModalOpen(true);
              }}
              isDisabled={!row.canCreateMeasure || !!measuresArray.find((item) => item.measureURL === row.measureURL)}
            />
          </span>
          {isModalOpen && (
            <MeasureEscalationModal
              hasOnlyMeasure
              KVPText={{ problem: t('forms.capacitySubcontractor.title'), measure: row.measure ?? '' }}
              onConfirm={handleCreatedMeasure}
              onCancel={closeModal}
              show={isModalOpen}
            />
          )}
        </TD>
      )}
    </TR>
  );
};

export default TileCapacitySubcontractorsTableRow;
