import React from 'react';
import BoardPage from '../board/Board';

import './BoardScreenshotView.scss';

const BoardScreenshotView = () => (
  <div className="board-screenshot-view">
    <BoardPage />
  </div>
);

export default BoardScreenshotView;
