import React, { useCallback, useEffect, useState } from 'react';
import { FileRejection, useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { NotificationTypes } from '../../../../../core/enums/NotificationTypes';
import { SIZE_25MB } from '../../../../../core/constants/file';
import { BASE_URL } from '../../../../../core/constants/api';
import BackOfficeFileService, { FileDetails as FileDetailsModel } from '../../../../../core/services/back-office/BackOfficeFileService';
import { spawnNotification } from '../../../../../core/state/notifications/asyncActions';

import Button from '../../../../../components/Button/Button';
import Icon from '../../../../../components/Icon/Icon';
import TilePDF from '../../../../../tiles/TilePDF/TilePDF';

import FileDetails from './FileDetails';

import './BackOfficeAreaInformation.scss';

interface Props {
  url: string;
  boardId: number;
  tileId: number;
}

const BackOfficeAreaInformation: React.FC<Props> = ({ url, tileId }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [fileDetails, setFileDetails] = useState<FileDetailsModel>();
  const [uploadInProgress, setUploadInProgress] = useState<boolean>(false);
  const [uploadProgress, setUploadProgress] = useState<number>(0);
  const [uploadingFilename, setUploadingFilename] = useState<string>('');

  const onDropAccepted = useCallback(
    async (files: File[]) => {
      const [file] = files;
      setUploadInProgress(true);
      setUploadingFilename(file.name);
      setUploadProgress(0);

      const formData = new FormData();
      formData.append('file', file, file.name);

      const config = {
        onUploadProgress: (progressEvent: ProgressEvent) => {
          const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          setUploadProgress(percentCompleted);
        }
      };

      const response = await BackOfficeFileService.uploadFile(tileId, formData, config);
      setUploadInProgress(false);
      setFileDetails(response.data);
    },
    [tileId, setUploadInProgress, setUploadProgress, setUploadingFilename, setFileDetails]
  );

  const onDropRejected = useCallback(
    (rejections: FileRejection[]) => {
      const errorCode = rejections[0].errors[0].code;
      let message;
      switch (errorCode) {
        case 'file-too-large':
          message = t('backOffice.file.sizeValidation');
          break;

        case 'file-invalid-type':
          message = t('backOffice.file.typeValidation');
          break;

        case 'too-many-files':
          message = t('backOffice.file.countValidation');
          break;

        default:
          message = t('bacoffice.file.unknownError');
      }

      dispatch(
        spawnNotification({
          type: NotificationTypes.Error,
          message
        })
      );
    },
    [dispatch, t]
  );

  const { getRootProps, getInputProps } = useDropzone({
    accept: 'application/pdf',
    maxFiles: 1,
    maxSize: SIZE_25MB,
    onDropAccepted,
    onDropRejected
  });

  useEffect(() => {
    (async () => {
      try {
        const response = await BackOfficeFileService.getFileDetails(url);
        setFileDetails(response.data);
      } catch (e) {
        spawnNotification({
          type: NotificationTypes.Error,
          message: t('backOffice.file.error')
        });
      }
    })();
  }, [url, dispatch, t]);

  return (
    <section className="pdf-upload">
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <div className="pdf-upload__drop-area" {...getRootProps()}>
        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
        <input {...getInputProps()} />
        {t('backOffice.file.dragAndDrop')}
        <Button buttonTypes={['primary', 'big']} className="mt-3">
          {t('backOffice.file.browse')}
        </Button>
      </div>

      <div className="pdf-upload__file-details">
        {!uploadInProgress && !fileDetails ? (
          t('backOffice.file.empty')
        ) : (
          <FileDetails
            uploadInProgress={uploadInProgress}
            uploadProgress={uploadProgress}
            uploadingFilename={uploadingFilename}
            fileDetails={fileDetails}
          />
        )}
      </div>

      <div className="pdf-upload__preview">
        {fileDetails ? (
          <TilePDF url={`${BASE_URL}${fileDetails.location}`} />
        ) : (
          <div className="pdf-upload__preview-empty">
            <Icon icon="fileImage" className="pdf-upload__preview-empty-icon" />
            <span>{t('backOffice.file.noPreview')}</span>
          </div>
        )}
      </div>
    </section>
  );
};

export default BackOfficeAreaInformation;
