import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import { cloneDeep } from 'lodash';

import Table from '../../components/Table/Table';
import THead from '../../components/Table/THead/THead';
import TBody from '../../components/Table/TBody/TBody';

import TileProcessConfirmationRow from './TileProcessConfirmationRow/TileProcessConfirmationRow';
import TileProcessConfirmationHeader from './TileProcessConfirmationHeader/TileProcessConfirmationHeader';
import TileProcessConfirmationDescription from './TileProcessConfirmationDescription/TileProcessConfirmationDescription';
import {
  getProcessConfirmationForm,
  saveProcessConfirmationForm,
  updateProcessConfirmationForm
} from '../../core/state/process-confirmation/asyncActions';
import { RootState } from '../../core/state/state';
import { BOARDS, BOARDS_TILE_DETAIL } from '../../core/constants/routes';
import UnsavedChangesPopup from '../../components/popup/UnsavedChangesPopup/UnsavedChangesPopup';
import { TableFormFieldStatus } from '../../core/enums/TableFormFieldStatus';
import { SelectedItem } from './types';
import { history } from '../../core/state/history';

import './TileProcessConfirmation.scss';

interface Props {
  url: string;
  boardId?: number;
}

const defaultSelectedItem: SelectedItem = {
  rowIndex: null,
  columnIndex: null,
  multiLineIndex: null,
  value: null
};

const getNewStatus = (status: TableFormFieldStatus) => {
  switch (status) {
    case TableFormFieldStatus.Undefined:
      return TableFormFieldStatus.Green;
    case TableFormFieldStatus.Green:
      return TableFormFieldStatus.Red;
    case TableFormFieldStatus.Red:
      return TableFormFieldStatus.Undefined;
    default:
      return TableFormFieldStatus.Undefined;
  }
};

const TileProcessConfirmation: React.FC<Props> = ({ url, boardId }) => {
  const dispatch = useDispatch();
  const processConf = useSelector((state: RootState) => state.processConfirmation.processConfirmationForm);
  const isDetailPage = useRouteMatch(BOARDS_TILE_DETAIL);

  const [selectedItem, setSelectedItem] = React.useState<SelectedItem>(defaultSelectedItem);

  const { isFormDirty, isFormEditing } = useSelector((state: RootState) => state.settings);

  const handleIndicatorStatus = (status: TableFormFieldStatus, rowIndex: number, columnIndex: number, multiLineIndex?: number) => {
    const newList = cloneDeep(processConf);
    if (!(newList && newList.processConfirmations.length > 0)) {
      return;
    }
    if (multiLineIndex !== undefined) {
      const multiLineData = cloneDeep(newList.processConfirmations[rowIndex].items[columnIndex].multiLineData);
      if (multiLineData) {
        multiLineData[multiLineIndex].status = getNewStatus(status);
        newList.processConfirmations[rowIndex].items[columnIndex].multiLineData = multiLineData;
      }
    } else {
      newList.processConfirmations[rowIndex].items[columnIndex].status = getNewStatus(status);
    }
    dispatch(updateProcessConfirmationForm(newList));
  };

  const handleBlur = (value: string, rowIndex: number, columnIndex: number, multiLineIndex?: number) => {
    const newList = cloneDeep(processConf);
    if (!(newList && newList.processConfirmations.length > 0)) {
      return;
    }
    if (multiLineIndex !== undefined) {
      const multiLineData = cloneDeep(newList.processConfirmations[rowIndex].items[columnIndex].multiLineData);
      if (multiLineData) {
        multiLineData[multiLineIndex].text = value;
        newList.processConfirmations[rowIndex].items[columnIndex].multiLineData = multiLineData;
      }
    } else {
      newList.processConfirmations[rowIndex].items[columnIndex].text = value;
    }
    dispatch(updateProcessConfirmationForm(newList));
  };

  const handleSubmit = async () => {
    const newList = cloneDeep(processConf);
    if (newList && boardId) {
      await dispatch(updateProcessConfirmationForm(newList));
      await dispatch(saveProcessConfirmationForm(newList, boardId));
      history.push(`${BOARDS}/${boardId}`);
    }
  };

  React.useEffect(() => {
    dispatch(getProcessConfirmationForm(url));
  }, [url, dispatch]);

  return (
    <div className={`tile-process-confirmation ${isDetailPage ? '' : 'tile-process-confirmation--small'}`}>
      <div className={`${isDetailPage ? 'my-2 container-fluid ' : ''}`}>
        <Table>
          <THead>
            <TileProcessConfirmationHeader handleSubmit={handleSubmit} />
          </THead>
          <TBody>
            <TileProcessConfirmationDescription />
            {processConf &&
              processConf.processConfirmations.map((row, rowIndex) => (
                <TileProcessConfirmationRow
                  key={row.processConfirmationId}
                  handleIndicatorStatus={handleIndicatorStatus}
                  selectedItem={selectedItem}
                  setSelectedItem={setSelectedItem}
                  row={row}
                  rowIndex={rowIndex}
                  handleBlur={handleBlur}
                />
              ))}
          </TBody>
        </Table>
      </div>
      {isDetailPage && <UnsavedChangesPopup showIf={isFormDirty || isFormEditing} />}
    </div>
  );
};

export default TileProcessConfirmation;
