import { ReduxThunkType } from '../../types/ReduxThunkType';
import { Actions as SettingsActions } from '../../state/settings/actions';
import { spawnSaveSuccessNotification } from '../../state/notifications/asyncActions';
import { TileService } from './TileService';
import { generateActions } from './generateActions';

export function generateAsyncActions<T>(type: string, Service: TileService<T>) {
  const Actions = generateActions(type);

  return {
    getTileForm: (tileUrl: string): ReduxThunkType<void> => async (dispatch) => {
      try {
        dispatch(Actions.getTile());
        const response = await Service.getTile(tileUrl);
        dispatch(Actions.getTileSuccess(response.data));
      } catch (error) {
        dispatch(Actions.getTileError(error));
      }
    },

    updateTileForm: (tile: T, isFormDirty = true, suppressDirtyStatus = false): ReduxThunkType<void> => async (dispatch) => {
      if (!suppressDirtyStatus) {
        await dispatch(SettingsActions.setIsFormDirty(isFormDirty));
      }
      await dispatch(Actions.updateTile(tile));
    },

    saveTileForm: (tileUrl: string, formData: T): ReduxThunkType<void> => async (dispatch) => {
      try {
        dispatch(Actions.saveTile());
        const response = await Service.saveTile(tileUrl, formData);
        await dispatch(SettingsActions.setIsFormDirty(false));
        dispatch(Actions.saveTileSuccess(response.data));
        dispatch(spawnSaveSuccessNotification());
      } catch (error) {
        dispatch(Actions.saveTileError(error));
      }
    }
  };
}
