import React from 'react';

import { TileTypes } from '../../core/enums/TileTypes';
import { TableTileTypes } from '../../core/enums/TableTileTypes';
import TableFormTileDesktopScreen, { ColumnTypes } from '../TableFormTile/DesktopScreen/TableFormTileDesktopScreen';

interface Props {
  boardId?: number;
  url: string;
}

const TileOrderPlacementDesktopScreen: React.FC<Props> = ({ url }) => {
  return (
    <TableFormTileDesktopScreen
      url={url}
      tableTileType={TableTileTypes.OrderPlacement}
      tileType={TileTypes.OrderPlacement}
      columns={[
        {
          type: ColumnTypes.Text,
          dataColumnIndex: 0,
          className: 'text-left'
        },
        {
          type: ColumnTypes.Text,
          dataColumnIndex: 2,
          className: 'text-right shrink'
        },
        {
          type: ColumnTypes.Calendar,
          dataColumnIndex: 5,
          className: 'text-right shrink'
        }
      ]}
      showStatus
    />
  );
};
export default TileOrderPlacementDesktopScreen;
