import React from 'react';
import { isToday } from 'date-fns';
import TR from '../../components/Table/TR/TR';
import TD from '../../components/Table/TD/TD';
import { getDate } from './helpers';
import { OSMonth } from '../../core/state/occupational-safety/types';

interface Props {
  thisMonth: OSMonth | null;
  daysInMonth: number;
  handleChangeStatus?: (day: number) => any;
}
const TileOccupationalSafetyTable: React.FC<Props> = ({ thisMonth, daysInMonth, handleChangeStatus }) => {
  const displayMonths = () => {
    const rows = Array(7).fill('');
    const columns = Array(7).fill('');
    let day = 0;

    return (
      <tbody>
        {rows.map((row, rowIndex) => {
          const rowKey = rowIndex;
          const hasNoRow = [0, 1, 5, 6].includes(rowIndex);
          return (
            <TR key={rowKey}>
              {columns.map((column, columnIndex) => {
                const columnKey = columnIndex;
                const hasNoColumn = [0, 1, 5, 6].includes(columnIndex);

                if (hasNoRow && hasNoColumn) {
                  return <TD key={columnKey} className="tile-occupational-safety__td-empty" />;
                }
                if ((columnIndex === 2 && rowIndex === 6) || (columnIndex === 4 && rowIndex === 6)) {
                  return <TD key={columnKey} className="tile-occupational-safety__td-grey" />;
                }

                day += 1;
                const thisDay = thisMonth?.days?.find((item) => item.day === day);
                const thisDayForHandlingStatus = day;
                const currentDate = getDate(thisMonth?.year, thisMonth?.month, day);
                const isDayToday = isToday(currentDate);

                return (
                  <TD key={columnKey} className={`tile-occupational-safety__td ${isDayToday ? 'tile-occupational-safety__td--today' : ''}`}>
                    <button
                      onClick={() => handleChangeStatus && handleChangeStatus(thisDayForHandlingStatus)}
                      disabled={day > daysInMonth}
                      className={`tile-occupational-safety__date tile-occupational-safety__date--${thisDay?.status.toLowerCase()}`}
                    >
                      {day}
                    </button>
                  </TD>
                );
              })}
            </TR>
          );
        })}
      </tbody>
    );
  };

  return <table className="tile-occupational-safety__table">{displayMonths()}</table>;
};

export default TileOccupationalSafetyTable;
