export enum TileTypes {
  Pdf = 'PDF',
  ProPlan = 'PROPLAN',
  AttendanceForm = 'ATTENDANCE_FORM',
  VacationForm = 'VACATION_ABSENCE',
  TidinessForm = 'TIDINESS_CLEANLINESS',
  PresenceForm = 'PRESENCE',
  ConfirmationForm = 'CONFIRMATION_CURRENT_PLANS',
  WeeklyThemeForm = 'WEEKLY_TOPICS_FORM',
  ExternalApp = 'EXTERNAL_APP',
  KVPForm = 'KVP_FORM',
  EscalationFrom = 'ESCALATION_FORM',
  ManagerForm = 'MANAGER_FORM',
  ProjectOverviewForm = 'PROJECT_OVERVIEW_FORM',
  ProjectAreaOverview = 'GF_AREA_FORM',
  PowerBI = 'POWER_BI',
  Accidents = 'ACCIDENTS',
  ProcessConfirmation = 'PROCESS_CONFIRMATION',
  Goals = 'GOALS',
  GfAreaProjects = 'GF_AREA_PROJECTS',
  Empty = 'EMPTY',
  RulesOfStanding = 'RULES_OF_STANDING',
  MeasuresAndEscalation = 'MEASURES_AND_ESCALATIONS',
  RequiredMaterial = 'REQUIRED_MATERIAL',
  ToDoShortTerm = 'TO_DO_SHORT_TERM',
  DefectProcessing = 'DEFECT_PROCESSING',
  DefectProcessingNU = 'DEFECT_PROCESSING_NU',
  TrackingAAZ = 'TRACKING_AAZ',
  PlanningQuality = 'PLANNING_QUALITY',
  AdditionalServicesNU = 'ADDITIONAL_SERVICES_NU',
  CompletionDateOrderAmount = 'COMPLETION_DATE_ORDER_AMOUNT',
  PlannerRisks = 'PLANNER_RISKS',
  PerformanceNU = 'PERFORMANCE_NU',
  Obstacles = 'OBSTACLES',
  PlanApprovalCustomer = 'PLAN_APPROVAL_CUSTOMER',
  Planning = 'PLANNING',
  Sampling = 'SAMPLING',
  Announcement = 'ANNOUNCEMENT',
  InspectionPlansQAList = 'INSPECTION_PLANS_QA_LIST',
  DeadlineCompliance = 'DEADLINE_COMPLIANCE',
  OrderPlacement = 'ORDER_PLACEMENT',
  InspectionPlans = 'INSPECTION_PLANS',
  OccupationalSafety = 'OCCUPATIONAL_SAFETY',
  SupplySchedule = 'SUPPLY_SCHEDULE',
  CapacitySubcontractor = 'CAPACITY_SUBCONTRACTOR',
  CheckpointsMasterPlan = 'CHECKPOINTS_MASTERPLAN'
}
