import React, { ReactFragment } from 'react';
import { useTranslation } from 'react-i18next';
import { useRouteMatch } from 'react-router-dom';

import { BOARDS_TILE_DETAIL } from '../../../core/constants/routes';
import TileMeasureLegend from '../TileMeasureLegend/TileMeasureLegend';

interface Props {
  toolbar?: ReactFragment;
}

const TileMeasureHeader: React.FC<Props> = ({ toolbar }) => {
  const { t } = useTranslation();
  const isDetailPage = useRouteMatch(BOARDS_TILE_DETAIL);

  return (
    <div className={isDetailPage ? 'mb-3' : 'px-1'}>
      <div className={isDetailPage ? 'd-flex justify-content-between' : 'd-flex align-items-end'}>
        <div className={isDetailPage ? 'd-flex justify-content-between align-items-center' : 'mr-2'}>
          <div className={isDetailPage ? 'tile-measure__title mb-0' : 'tile-measure__title-small'}>{t('forms.measure.title')}</div>
          {!isDetailPage && (
            <p className={isDetailPage ? 'tile-measure__subtitle mb-0' : 'tile-measure__subtitle-small mb-1'}>{t('forms.measure.subtitle')}</p>
          )}
        </div>
        <div className="d-flex">
          <TileMeasureLegend isDetailPage={isDetailPage} />
          {isDetailPage && toolbar}
        </div>
      </div>
    </div>
  );
};

export default TileMeasureHeader;
