import * as React from 'react';

import TableFormTileDesktopScreen from '../../TableFormTile/DesktopScreen/TableFormTileDesktopScreen';
import { TileTypes } from '../../../core/enums/TileTypes';
import { t } from '../../../../i18n';
import { TableTileTypes } from '../../../core/enums/TableTileTypes';
import TR from '../../../components/Table/TR/TR';
import TD from '../../../components/Table/TD/TD';

interface Props {
  url: string;
}

const TileDefectProcessingDesktopScreen: React.FC<Props> = ({ url }) => {
  return (
    <TableFormTileDesktopScreen
      url={url}
      tileType={TileTypes.TrackingAAZ}
      tableTileType={TableTileTypes.TrackingAAZ}
      bodyRenderer={(tableForm) => {
        return tableForm ? (
          <>
            {tableForm.rows.map((tableFormItem, index) => (
              <TR key={tableFormItem.rowNumber}>
                <TD className="text-left">{`${index + 1}. ${tableFormItem.columns[0].text}`}</TD>
                <TD className="text-right">
                  {parseFloat(tableFormItem.columns[5]?.text)
                    ? parseFloat(tableFormItem.columns[5]?.text).toLocaleString('de-DE', {
                        style: 'unit',
                        unitDisplay: 'narrow',
                        unit: 'day',
                        useGrouping: false,
                        maximumFractionDigits: 0
                      })
                    : ''}
                </TD>
                <TD className="text-right">
                  {parseFloat(tableFormItem.columns[1].text)
                    ? parseFloat(tableFormItem.columns[1].text).toLocaleString('de-DE', {
                        style: 'currency',
                        currency: 'EUR',
                        maximumFractionDigits: 0
                      })
                    : ''}
                </TD>
              </TR>
            ))}
            <TR className="last-row">
              <TD className="text-left title">{t('forms.trackingAAZ.column.sumOfAmount')}</TD>
              <TD className="text-right sum w-20">
                {`${tableForm?.rows
                  .reduce((acc, row) => {
                    const a = parseFloat(row.columns[5].text) || 0;
                    return acc + a;
                  }, 0)
                  .toLocaleString('de-DE', {
                    style: 'unit',
                    unitDisplay: 'narrow',
                    unit: 'day',
                    useGrouping: false,
                    maximumFractionDigits: 0
                  })}`}
              </TD>
              <TD className="text-right sum w-30">
                {`${tableForm?.rows
                  .reduce((acc, row) => {
                    const a = parseFloat(row.columns[1].text) || 0;
                    return acc + a;
                  }, 0)
                  .toLocaleString('de-DE', {
                    style: 'currency',
                    currency: 'EUR',
                    maximumFractionDigits: 0
                  })}`}
              </TD>
            </TR>
          </>
        ) : (
          <TR />
        );
      }}
      tableHeaderColSpan={3}
      showStatus
    />
  );
};

export default TileDefectProcessingDesktopScreen;
