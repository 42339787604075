import * as React from 'react';
import { ChangeEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, useRouteMatch } from 'react-router-dom';
import { cloneDeep } from 'lodash';
import { useTranslation } from 'react-i18next';

import { RootState } from '../../core/state/state';
import Button from '../../components/Button/Button';
import { BOARDS, BOARDS_TILE_DETAIL } from '../../core/constants/routes';
import ConfirmPopup, { ConfirmPopupProps } from '../../components/popup/ConfirmPopup/ConfirmPopup';
import { history } from '../../core/state/history';
import { getSickLeaveForm, updateSickLeaveForm, saveSickLeaveForm } from '../../core/state/table-forms/asyncActions';
import { TableForm, TableFormColumn, TableFormRow } from '../../core/state/table-forms/types';
import { TableFormFieldStatus } from '../../core/enums/TableFormFieldStatus';
import TD from '../../components/Table/TD/TD';
import Table from '../../components/Table/Table';
import TBody from '../../components/Table/TBody/TBody';
import TR from '../../components/Table/TR/TR';
import { ALL_STRING_CHARACTERS } from '../../core/constants/regex';
import Icon from '../../components/Icon/Icon';
import TFoot from '../../components/Table/TFoot/TFoot';
import UnsavedChangesPopup from '../../components/popup/UnsavedChangesPopup/UnsavedChangesPopup';
import TableInput from '../../components/form/TableInput/TableInput';
import TileSickLeaveTableHeader from './TileSickLeaveHeader';
import { useRowNumber } from '../../core/hooks/useRowNumber';

import './TileSickLeave.scss';

interface Props {
  url: string;
  boardId?: number;
}

const COLUMN_COUNT = 15;

const getSickLeaveFormClass = (status?: TableFormFieldStatus) => {
  if (!status || status === TableFormFieldStatus.Undefined) {
    return '';
  }
  return `tile-sick-leave__indicator--${status.toLowerCase()}`;
};

const TileSickLeave: React.FC<Props> = ({ url, boardId }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [itemForEditRow, setItemForEditRow] = React.useState<number | null>();
  const [itemForEditColumn, setItemForEditColumn] = React.useState<number | null>();
  const [itemEditData, setItemEditData] = React.useState('');
  const { isFormDirty, isFormEditing } = useSelector((state: RootState) => state.settings);
  const list: TableForm | null = useSelector((state: RootState) => state.tableForm.sickLeaveForm.form);
  const [confirmDialog, setConfirmDialog] = React.useState<ConfirmPopupProps>();
  const isDetailPage = useRouteMatch(BOARDS_TILE_DETAIL);
  const nextRowNumber = useRowNumber(list);

  React.useEffect(() => {
    dispatch(getSickLeaveForm(url));
  }, [dispatch, url]);

  const handleSubmit = async () => {
    const newList = cloneDeep(list);
    if (newList) {
      await dispatch(updateSickLeaveForm(newList));
      await dispatch(saveSickLeaveForm(newList));
      history.push(`${BOARDS}/${boardId}`);
    }
  };

  const handleBlur = (columnIndex: number, rowIndex: number) => {
    const newList = cloneDeep(list);
    if (newList && newList.rows.length > 0 && itemForEditColumn !== null && itemForEditRow !== null) {
      newList.rows[rowIndex].columns[columnIndex].text = String(itemEditData);
      dispatch(updateSickLeaveForm(newList));
    }
    setItemEditData('');
    setItemForEditRow(null);
    setItemForEditColumn(null);
  };

  const setItem = (columnIndex: number, rowIndex: number, value: string) => {
    setItemEditData(value);
    setItemForEditRow(rowIndex);
    setItemForEditColumn(columnIndex);
  };

  const handleIndicatorStatus = (status: TableFormFieldStatus, rowIndex: number, itemIndex: number) => {
    const newList = cloneDeep(list);
    if (newList && newList.rows.length > 0) {
      if (status === TableFormFieldStatus.Undefined) {
        newList.rows[rowIndex].columns[itemIndex].status = TableFormFieldStatus.Green;
      } else if (status === TableFormFieldStatus.Green) {
        newList.rows[rowIndex].columns[itemIndex].status = TableFormFieldStatus.Red;
      } else if (status === TableFormFieldStatus.Red) {
        newList.rows[rowIndex].columns[itemIndex].status = TableFormFieldStatus.Undefined;
      } else {
        newList.rows[rowIndex].columns[itemIndex].status = TableFormFieldStatus.Undefined;
      }
    }
    if (newList && newList.rows.length > 0) {
      dispatch(updateSickLeaveForm(newList));
    }
  };

  const generateEmptyRowFields = () => {
    const container = [];
    for (let index = 1; index <= COLUMN_COUNT; index += 1) {
      container.push({
        columnNumber: index,
        status: TableFormFieldStatus.Undefined,
        text: '',
        title: '',
        multiLineData: null
      });
    }
    return container;
  };

  const addEmptyRow = (suppressDirtyStatus = false) => {
    const newList = cloneDeep(list);
    const emptyRow: TableFormRow = {
      rowNumber: nextRowNumber(),
      columns: generateEmptyRowFields()
    };

    if (newList) {
      newList.rows.push(emptyRow);
      dispatch(updateSickLeaveForm(newList, true, suppressDirtyStatus));
    }
  };
  const clearAllFields = () => {
    const newList = cloneDeep(list);
    if (newList) {
      newList.rows = [];
      dispatch(updateSickLeaveForm(newList));
    }
  };

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setItemEditData(e.target.value);
  };

  const handleDeleteRow = (index: number) => {
    const newList = cloneDeep(list);

    if (newList && newList.rows.length > 0) {
      newList.rows.splice(index, 1);
      dispatch(updateSickLeaveForm(newList));
    }
  };

  const generateColumns = (items: TableFormColumn[], i: number) => {
    const container = [];
    for (let index = 0; index < items.length; index += 1) {
      if (index === 1 || index === 11) {
        container.push(
          <TD key={`column-${i}-${index}`}>
            <div
              role="button"
              aria-pressed="false"
              aria-label="indicatior"
              tabIndex={0}
              onClick={() => handleIndicatorStatus(items[index].status, i, index)}
              onKeyDown={() => handleIndicatorStatus(items[index].status, i, index)}
              className={`tile-sick-leave__indicator m-auto ${isDetailPage ? '' : 'tile-sick-leave__indicator--small'} ${getSickLeaveFormClass(
                items[index].status
              )}`}
            />
          </TD>
        );
      } else if (index === 0) {
        container.push(
          <TD key={`column-${i}-${index}`} className="text-center">
            {i + 1}
          </TD>
        );
      } else {
        container.push(
          <TD key={`column-${i}-${index}`} className={`column-${index}`}>
            <TableInput
              type={isDetailPage ? undefined : 'small'}
              onClick={() => setItem(index, i, items[index].text)}
              onBlur={() => handleBlur(index, i)}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleInputChange(e)}
              value={itemForEditRow === i && itemForEditColumn === index ? itemEditData : items[index].text}
            />
          </TD>
        );
      }
    }
    if (isDetailPage) {
      container.push(
        <TD key={`delete-${i}`} className="text-center shrink">
          <button title={t('buttons.deleteRow')} className="btn btn-link" onClick={() => handleDeleteRow(i)}>
            <Icon icon="delete" svg />
            <span className="sr-only">{t('buttons.deleteRow')}</span>
          </button>
        </TD>
      );
    }
    return <>{container}</>;
  };

  const getAllRows = () => {
    const container = [];
    if (list && list.rows.length > 0) {
      container.push(list.rows.map((item: TableFormRow, index: number) => <TR key={item.rowNumber}>{generateColumns(item.columns, index)}</TR>));
    } else {
      addEmptyRow(true);
    }

    return container;
  };

  const getColumnPrice = (columnNumber: number) => {
    let sum = 0;
    if (list && list.rows.length > 0) {
      for (let index = 0; index < list.rows.length; index += 1) {
        const formatValue = list.rows[index].columns[columnNumber - 1].text;
        if (formatValue) {
          sum += Number(formatValue.replace(ALL_STRING_CHARACTERS, ''));
        }
      }
    }
    return sum;
  };

  const generateFooterColumns = () => {
    const container = [];
    for (let index = 1; index <= COLUMN_COUNT; index += 1) {
      if (index !== 1 && index !== 2 && index !== 12) {
        container.push(
          <TD className={index === COLUMN_COUNT ? 'border-right-0' : ''} key={`sum-${index}`}>
            {getColumnPrice(index)}
          </TD>
        );
      } else {
        container.push(<TD key={`sum-${index}`}>{index === 1 ? t('forms.sickLeave.sum') : ''}</TD>);
      }
    }
    if (isDetailPage) {
      container.push(<TD key="last-column" className="border-left-0" />);
    }
    return container;
  };

  const getTableFooter = () => {
    const container = [];
    container.push(<TR key="footer">{generateFooterColumns()}</TR>);

    return container;
  };

  return (
    <div className={`tile-sick-leave ${isDetailPage ? '' : 'tile-sick-leave--small'}`}>
      <div className="tile-sick-leave__content">
        <div className={`container-fluid ${isDetailPage ? 'my-2' : ''}`}>
          <Route exact path={BOARDS_TILE_DETAIL}>
            <div className="tile-sick-leave__title">{t('forms.sickLeave.title')}</div>
            <div className={`d-flex justify-content-end ${isDetailPage ? 'pb-4' : ''}`}>
              <Route exact path={BOARDS_TILE_DETAIL}>
                <div>
                  <Button
                    buttonTypes={['secondary', 'big']}
                    onClick={() => {
                      setConfirmDialog({
                        heading: t('dialogs.confirmDialog.heading'),
                        cancelText: t('dialogs.confirmDialog.cancelText'),
                        confirmText: t('dialogs.confirmDialog.confirmText'),
                        show: true,
                        onCancel: () => {
                          setConfirmDialog({ show: false });
                        },
                        onConfirm: () => {
                          clearAllFields();
                          setConfirmDialog({ show: false });
                        }
                      });
                    }}
                  >
                    {t('buttons.clearAll')}
                  </Button>
                  <Button buttonTypes={['primary', 'big']} className="ml-3" onClick={handleSubmit}>
                    {t('buttons.save')}
                  </Button>
                </div>
              </Route>
            </div>
          </Route>

          <div className={`${isDetailPage ? '' : 'row'}`}>
            <Table className={isDetailPage ? 'tile-sick-leave__table' : 'table--small'}>
              <TileSickLeaveTableHeader isDetailPage={isDetailPage} />
              <TBody>{getAllRows()}</TBody>
              <Route exact path={BOARDS_TILE_DETAIL}>
                <TFoot>
                  <TR>
                    <TD colSpan={15} className="border-right-0">
                      <div className="d-flex justify-content-center mx-2">
                        <Button buttonTypes={['primary', 'big']} onClick={() => addEmptyRow()}>
                          {t('buttons.addNewRow')}
                        </Button>
                      </div>
                    </TD>
                    {isDetailPage && <TD className="border-left-0" />}
                  </TR>
                  {getTableFooter()}
                </TFoot>
              </Route>
            </Table>
          </div>
        </div>
      </div>
      <ConfirmPopup
        show={confirmDialog?.show}
        confirmText={confirmDialog?.confirmText}
        cancelText={confirmDialog?.cancelText}
        heading={confirmDialog?.heading}
        onCancel={confirmDialog?.onCancel}
        onConfirm={confirmDialog?.onConfirm}
      />
      {isDetailPage && <UnsavedChangesPopup showIf={isFormDirty || isFormEditing} />}
    </div>
  );
};

export default TileSickLeave;
