import React from 'react';
import { useTranslation } from 'react-i18next';
import BreadcrumbSingleItem from '../BreadcrumbSingleItem/BreadcrumbSingleItem';
import { Breadcrumb, BreadCrumbTile } from '../../../../core/utils/breadcrumb/findMenuListById';

interface Props {
  tiles: BreadCrumbTile[];
  breadcrumbArray: Breadcrumb[];
}

const BreadcrumbProjectTiles: React.FC<Props> = ({ tiles, breadcrumbArray }) => {
  const { t } = useTranslation();
  return (
    <>
      <li className="breadcrumb-navigation__child-section-title pb-1">{t('navigation.ProjectTiles')}</li>
      {tiles.map((tile) => (
        <BreadcrumbSingleItem
          key={tile.tileId}
          isActive={!!breadcrumbArray.find((item) => item.tileId === tile.tileId)}
          url={tile.url ?? ''}
          name={tile.name}
        />
      ))}
    </>
  );
};

export default BreadcrumbProjectTiles;
