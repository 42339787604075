import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { addDays, isSameDay } from 'date-fns';

import { RootState } from '../../../core/state/state';
import { getCheckboxListClass } from '../TileCheckboxListHelpers';
import Table from '../../../components/Table/Table';
import THead from '../../../components/Table/THead/THead';
import TBody from '../../../components/Table/TBody/TBody';
import TR from '../../../components/Table/TR/TR';
import TH from '../../../components/Table/TH/TH';
import TD from '../../../components/Table/TD/TD';
import TableCellOverflowEllipses from '../../../pages/components/TableCellOverflowEllipses/TableCellOverflowEllipses';
import { CheckboxTileTypes } from '../../../core/enums/CheckboxTileTypes';
import generateAsyncActions from '../../../core/state/checkbox-list/asyncActions';
import { CheckboxInput, CheckboxList } from '../../../core/state/checkbox-list/types';
import { getValueByKey } from '../../../core/state/helpers';
import { getStartDate } from '../TileCheckboxList';

import './TileCheckboxListDesktopScreen.scss';

interface Props {
  url: string;
  tileType: CheckboxTileTypes;
}

const TileCheckboxListDesktopScreen: React.FC<Props> = ({ url, tileType }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const list = useSelector((state: RootState) =>
    tileType === CheckboxTileTypes.Confirmation ? getValueByKey(state[tileType].list, 'attendance') : state[tileType].list
  ) as CheckboxList;
  const boardType = useSelector((state: RootState) => state.boards.board?.type);

  const actions = React.useMemo(() => generateAsyncActions(tileType.toUpperCase()), [tileType]);

  React.useEffect(() => {
    dispatch(actions.getListByUrl(url));
  }, [actions, dispatch, url]);

  const getCheckboxes = (inputs: CheckboxInput[]) => {
    const container = [];
    const today = getStartDate();
    for (let i = 0; i < 5; i += 1) {
      const thisDay = addDays(today, i);
      const input = inputs.find((item) => item.date && isSameDay(new Date(item.date), thisDay));
      container.push(
        <div
          key={i}
          role="button"
          aria-pressed="false"
          aria-label="indicator"
          tabIndex={0}
          className={`table__indicator ${getCheckboxListClass(input?.status)} no-font-scaling mr-1`}
        />
      );
    }

    return <div>{container}</div>;
  };

  return (
    <div className="tile-attendance-list">
      <div className="tile-attendance-list__content">
        <Table type="small" className="tile-attendance-list-desktop-screen no-font-scaling">
          <THead>
            <TR>
              <TH colSpan={2}>
                {t(`forms.${tileType}List.title`)}
                {tileType === CheckboxTileTypes.Presence && <div className={`status status-${list.status.toLowerCase()}`} />}
              </TH>
            </TR>
          </THead>
          <TBody>
            {list &&
              list.rows.length > 0 &&
              list.rows.map((checkboxListItem, index) => (
                <TR
                  key={checkboxListItem.rowNumber}
                  className={`tile-attendance-list-desktop-screen no-font-scaling__tr-height--${boardType?.toLocaleLowerCase()}`}
                >
                  <TD className="text-left">
                    <TableCellOverflowEllipses content={checkboxListItem.firstColumn} />
                  </TD>
                  {/* eslint-disable-next-line react/no-array-index-key */}
                  <TD key={index}>{getCheckboxes(checkboxListItem.inputs)}</TD>
                </TR>
              ))}
          </TBody>
        </Table>
      </div>
    </div>
  );
};

export default TileCheckboxListDesktopScreen;
