import React from 'react';

import { TileTypes } from '../../../core/enums/TileTypes';
import { TableTileTypes } from '../../../core/enums/TableTileTypes';
import TableFormTileDesktopScreen, { ColumnTypes } from '../../TableFormTile/DesktopScreen/TableFormTileDesktopScreen';

interface Props {
  boardId?: number;
  url: string;
}

const TileCheckpointsMasterPlanDesktopScreen: React.FC<Props> = ({ url }) => {
  return (
    <TableFormTileDesktopScreen
      url={url}
      tileType={TileTypes.CheckpointsMasterPlan}
      tableTileType={TableTileTypes.CheckpointsMasterPlan}
      columns={[
        {
          type: ColumnTypes.NumberPP,
          dataColumnIndex: 1
        },
        {
          type: ColumnTypes.Calendar,
          dataColumnIndex: 2,
          className: 'w-10'
        },
        {
          type: ColumnTypes.StatusCircle,
          dataColumnIndex: 3
        }
      ]}
    />
  );
};

export default TileCheckpointsMasterPlanDesktopScreen;
