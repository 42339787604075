import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { differenceInDays, getDaysInMonth } from 'date-fns';

import { TileTypes } from '../../../core/enums/TileTypes';
import { RootState } from '../../../core/state/state';
import TileOccupationalSafetyTable from '../TileOccupationalSafetyTable';
import { findLastDay, getMonthDate, getNameOfTheMonth } from '../helpers';
import { OccupationalSafety, OSDataStatus } from '../../../core/state/occupational-safety/types';
import { TileService } from '../../../core/utils/state/TileService';
import { generateAsyncActions } from '../../../core/utils/state/generateAsyncActions';
import TileOccupationalSafetyInfo from '../TileOccupationalSafetyInfo';

import './TileOccupationalSafetyDesktopScreen.scss';

interface Props {
  boardId?: number;
  url: string;
}

const TileOccupationalSafetyDesktopScreen: React.FC<Props> = ({ url }) => {
  const dispatch = useDispatch();
  const form = useSelector((state: RootState) => state.occupationalSafety.form);
  const { t } = useTranslation();

  const { getTileForm } = React.useMemo(() => generateAsyncActions<OccupationalSafety>(TileTypes.OccupationalSafety, new TileService()), []);
  const getThisOSMonth = () => (form?.months ? form?.months[form?.months.length - 1] : null);
  const dateThisMonth = getMonthDate(getThisOSMonth()?.month);
  const thisMonth = getThisOSMonth();
  const daysInMonth = getDaysInMonth(dateThisMonth);
  const nameOfTheMonth = getNameOfTheMonth(dateThisMonth);

  const lastIncidentDay = findLastDay(form, OSDataStatus.Yellow);
  const lastAccidentDay = findLastDay(form, OSDataStatus.Red);

  const daysWithoutIncident = differenceInDays(new Date(), new Date(lastIncidentDay?.date ?? new Date()));
  const daysWithoutAccident = differenceInDays(new Date(), new Date(lastAccidentDay?.date ?? new Date()));

  React.useEffect(() => {
    dispatch(getTileForm(url));
  }, [url, dispatch, getTileForm]);

  return (
    <div className="tile-occupational-safety-desktop-screen no-font-scaling">
      <div className="tile-occupational-safety-desktop-screen__table-header">{t(`forms.occupationalSafety.title`)}</div>
      <div className="tile-occupational-safety-desktop-screen__content">
        <div className="tile-occupational-safety-desktop-screen__table-wrap">
          <TileOccupationalSafetyTable daysInMonth={daysInMonth} thisMonth={thisMonth} />
        </div>
        <div className="tile-occupational-safety-desktop-screen__right">
          <div className="tile-occupational-safety-desktop-screen__month">{nameOfTheMonth}</div>
          <TileOccupationalSafetyInfo amount={daysWithoutIncident} />
          <TileOccupationalSafetyInfo amount={daysWithoutAccident} status={OSDataStatus.Red} />
        </div>
      </div>
    </div>
  );
};

export default TileOccupationalSafetyDesktopScreen;
