import service from './RestService';
import { RestResponse } from '../types/response/RestResponse';
import { DualTableForm, MeasureDetail, TableForm } from '../state/table-forms/types';
import { BASE_URL } from '../constants/api';

const path = '/table-forms';

function isTableForm(table: TableForm | DualTableForm): table is TableForm {
  return (table as TableForm).tableFormId !== undefined;
}

class TableFormsService {
  getTableFormByUrl = (tileUrl: string): RestResponse<TableForm> => service.get(`${tileUrl}`);

  saveTableForm = (list: TableForm): RestResponse<TableForm> => service.post(`${path}/${list.tableFormId}`, list);

  getTableFormByUrlGeneric = (tileUrl: string): RestResponse<TableForm | DualTableForm> => service.get(`${tileUrl}`);

  saveTableFormGeneric = (list: TableForm | DualTableForm): RestResponse<TableForm> => {
    if (isTableForm(list)) {
      return service.post(`${path}/${list.tableFormId}`, list);
    }
    return service.post(`${path}/${list.first.tableFormId}/dual`, list);
  };

  saveTableFormMeasure = (measure: MeasureDetail, url: string): RestResponse<TableForm> => service.post(`${BASE_URL}/${url}`, measure);
}

export default new TableFormsService();
