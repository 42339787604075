import React from 'react';
import { useTranslation } from 'react-i18next';
import TR from '../../../components/Table/TR/TR';
import TH from '../../../components/Table/TH/TH';
import TileCapacitySubcontractorHeaderControl from './TileCapacitySubcontractorHeaderControl';
import TileCapacitySubcontractorInfoDays from './TileCapacitySubcontractorInfoDays';

interface Props {
  onPrevClick: () => any;
  onNextClick: () => any;
  date: Date;
}

const TileCapacitySubcontractorTableHead: React.FC<Props> = ({ onNextClick, onPrevClick, date }) => {
  const { t } = useTranslation();
  return (
    <>
      <TR>
        <TH rowSpan={2} className="w-3 text-center vertical-top">
          <div className="table__title">{t('sharedText.number')}</div>
        </TH>
        <TH rowSpan={2} className="w-21 text-center vertical-top">
          <div className="table__title">{t('forms.capacitySubcontractor.subcontractor')}</div>
        </TH>
        <TileCapacitySubcontractorHeaderControl date={date} onPrevClick={onPrevClick} onNextClick={onNextClick} />
        <TH colSpan={2} rowSpan={2} className="w-26">
          <div className="table__title text-center">{t('forms.capacitySubcontractor.measures')}</div>
          <div className="table__subtitle">{t('forms.capacitySubcontractor.measuresSubtitle')}</div>
        </TH>
      </TR>
      <TR>
        <TH colSpan={3} className="empty shrink bottom">
          empty
        </TH>
        <TileCapacitySubcontractorInfoDays />
        <TH className="empty shrink bottom">empty</TH>
        <TileCapacitySubcontractorInfoDays />
        <TH colSpan={3} className="empty shrink bottom">
          empty
        </TH>
      </TR>
    </>
  );
};

export default TileCapacitySubcontractorTableHead;
