import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRouteMatch } from 'react-router-dom';
import FormStatus from '../../../pages/components/FormStatus/FormStatus';
import { FormStatuses } from '../../../core/enums/FormStatuses';
import { BOARDS_TILE_DETAIL } from '../../../core/constants/routes';
import { TableTileTypes } from '../../../core/enums/TableTileTypes';
import { DualTableForm, TableForm } from '../../../core/state/table-forms/types';
import { ReduxThunkType } from '../../../core/types/ReduxThunkType';
import TableFormToolbar from '../TableFormToolbar/TableFormToolbar';

import './TableFormTileTop.scss';

interface Props {
  showStatus: boolean;
  tableTileType: TableTileTypes;
  InfoComponent?: React.FC<any>;
  tableForm: TableForm | null;
  updateTableForm: (tableForm: TableForm | DualTableForm) => ReduxThunkType<void>;
  clearAll?: () => void;
  submit: (...args: any) => void;
}

const TableFormTileTop: React.FC<Props> = ({ tableTileType, InfoComponent, tableForm, updateTableForm, submit, clearAll, showStatus = false }) => {
  const { t } = useTranslation();
  const isDetailPage = useRouteMatch(BOARDS_TILE_DETAIL);

  return (
    <div className={`table-form-tile-top ${isDetailPage ? '' : 'table-form-tile-top--small'}`}>
      <div className={isDetailPage ? '' : 'd-flex justify-content-between'}>
        {!isDetailPage && <div className="tile-table__title">{t(`forms.${tableTileType}.title`)}</div>}
        <div className="d-flex justify-content-end align-items-center">
          {isDetailPage && <div className="tile-table__sub-title d-flex flex-grow-1">{t(`forms.${tableTileType}.subtitle`)}</div>}
          {showStatus && (
            <FormStatus
              tableForm={tableForm}
              updateTableForm={updateTableForm}
              isSmall={!isDetailPage}
              validStatuses={[FormStatuses.Green, FormStatuses.Red]}
            />
          )}
          {isDetailPage && <TableFormToolbar submit={submit} clearAll={clearAll} />}
        </div>
      </div>
      <div className={isDetailPage ? '' : 'd-flex justify-content-between'}>
        {!isDetailPage && <div className="tile-table__sub-title d-flex flex-grow-1">{t(`forms.${tableTileType}.subtitle`)}</div>}
      </div>
      {InfoComponent && (
        <div className="table-form-tile-top__info d-flex justify-content-end">
          <InfoComponent isDetailPage={isDetailPage} />
        </div>
      )}
    </div>
  );
};

export default TableFormTileTop;
