import React from 'react';
import { useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';

import { t } from '../../../i18n';
import TD from '../../components/Table/TD/TD';
import TFoot from '../../components/Table/TFoot/TFoot';
import TR from '../../components/Table/TR/TR';
import { RootState } from '../../core/state/state';
import { BOARDS_TILE_DETAIL } from '../../core/constants/routes';

const TileObstaclesTableFooter: React.FC = () => {
  const tableForm = useSelector((state: RootState) => state.obstacles.form);
  const isDetailPage = useRouteMatch(BOARDS_TILE_DETAIL);
  return (
    <TFoot>
      <TR>
        <TD colSpan={isDetailPage ? 7 : 6} className="table-no-border" />
        <TD colSpan={isDetailPage ? 4 : 5} className={`table-sum-title text-left ${isDetailPage ? '' : 'pl-1'}`}>
          {t('forms.obstacles.sumDeclaration')}
        </TD>
        <TD className={`table-sum-number text-left ${isDetailPage ? '' : 'pl-1'}`}>
          {`${tableForm?.rows
            .reduce((acc, row) => {
              const a = parseFloat(row.columns[10].text) || 0;
              return acc + a;
            }, 0)
            .toLocaleString('de-DE', {
              style: 'unit',
              unitDisplay: 'long',
              unit: 'day',
              useGrouping: false,
              maximumFractionDigits: 0
            })}`}
        </TD>
        <TD className="table-sum-number text-left">
          {`${tableForm?.rows
            .reduce((acc, row) => {
              const a = parseFloat(row.columns[11].text) || 0;
              return acc + a;
            }, 0)
            .toLocaleString('de-DE', {
              style: 'currency',
              currency: 'EUR',
              maximumFractionDigits: 0
            })}`}
        </TD>
      </TR>
    </TFoot>
  );
};

export default TileObstaclesTableFooter;
