import React from 'react';
import * as EscalationHelpers from '../TileEscalationHelpers';
import { Sizes } from '../../../core/enums/Sizes';

interface Props {
  onClick: (e: any) => void;
  onKeyDown?: (e: any) => void;
  value: any;
  size?: Sizes.Medium | Sizes.Large;
}

const EscalationStatus: React.FC<Props> = ({ onClick, onKeyDown, value, size = Sizes.Medium }) => (
  <div
    role="button"
    aria-pressed="false"
    aria-label="indicatior"
    tabIndex={0}
    onClick={onClick}
    onKeyDown={onKeyDown}
    className={`table__indicator table__indicator--${size} m-auto d-flex ${EscalationHelpers.getAttendanceListClass(value)}`}
  />
);

export default EscalationStatus;
