import React from 'react';
import { useTranslation } from 'react-i18next';

import InfoPage from '../components/InfoPage/InfoPage';

const UnassignedBoard: React.FC<any> = () => {
  const { t } = useTranslation();
  return <InfoPage title={t('unnassigned.title')} subtitle={t('unnassigned.subtitle')} />;
};

export default UnassignedBoard;
