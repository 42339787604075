import React from 'react';
import { Link, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { RootState } from '../../core/state/state';
import Board from '../components/Board/Board';
import BoardHeader from '../components/Board/BoardHeader/BoardHeader';
import Table from '../../components/Table/Table';
import TBody from '../../components/Table/TBody/TBody';
import TR from '../../components/Table/TR/TR';
import TD from '../../components/Table/TD/TD';
import BackOfficeListItemRow from './BackOfficeListItemRow/BackOfficeListItemRow';
import { getBackOfficeList } from '../../core/state/back-office/asyncActions';
import { findMenuListById } from '../../core/utils/breadcrumb/findMenuListById';
import { MenuList, MenuType } from '../../core/state/menu/types';

import './BackOfficePage.scss';

const BackOfficePage: React.FC<any> = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();

  const backOfficeListItems = useSelector((state: RootState) => state.backOffice.list);
  const menuItems = useSelector((state: RootState) => state.menuItems.list);

  const currentMenuItem = findMenuListById(Number(id), menuItems) as MenuList | null;
  const showPermissionsForTiles = currentMenuItem?.type !== MenuType.GFBoard;

  React.useEffect(() => {
    dispatch(getBackOfficeList(Number(id)));
  }, [dispatch, id]);

  if (!currentMenuItem) {
    return null;
  }

  return (
    <Board>
      <BoardHeader boardId={Number(id)} text={t('backOffice.headerText')} boardType={undefined} noBottomMargin />
      <div className="back-office-page">
        <div className="back-office-page__content">
          {showPermissionsForTiles && (
            <>
              <div className="back-office-page__title">{t('backOffice.title.tiles')}</div>
              <Table className="back-office-page__table">
                <TBody>
                  {backOfficeListItems?.map((backOfficeListItem) => (
                    <React.Fragment key={backOfficeListItem.tileId}>
                      <BackOfficeListItemRow backOfficeListItem={backOfficeListItem} />
                    </React.Fragment>
                  ))}
                </TBody>
              </Table>
            </>
          )}
          <div className="back-office-page__title">{t('backOffice.title.users')}</div>
          <Table className="back-office-page__table">
            <TBody>
              <TR>
                <TD>{t('backOffice.permissions.title')}</TD>
                <TD>
                  <Link to={`/boards/${id}/back-office/permissions`} className="back-office-page__edit-link">
                    {t('backOfficeListItemRow.columnEdit')}
                  </Link>
                </TD>
              </TR>
            </TBody>
          </Table>
        </div>
      </div>
    </Board>
  );
};

export default BackOfficePage;
