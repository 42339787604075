import * as React from 'react';

import { Board as BoardModel, Tile as TileModel } from '../../../../core/state/boards/types';
import { BOARDS } from '../../../../core/constants/routes';
import NavArrow from '../../NavArrow/NavArrow';
import BoardNavDot from './BoardNavDot';
import { getIndexOfBoard } from '../../../pl-board/utils';

import './BoardNav.scss';

interface Props {
  board: BoardModel<TileModel>;
}

const BoardNav: React.FC<Props> = ({ board }) => {
  const index = getIndexOfBoard(board);
  const length = board.projectBoardIds?.length;
  return (
    <div className="board-nav">
      {index !== 1 && board.projectBoardIds && <NavArrow url={`${BOARDS}/${board.projectBoardIds[index - 2]}`} />}
      {index !== length && board.projectBoardIds && <NavArrow isRight url={`${BOARDS}/${board.projectBoardIds[index]}`} />}
      <div className="board-nav__dots">
        {board.projectBoardIds?.map((id) => (
          <BoardNavDot key={id} url={`${BOARDS}/${id}`} isActive={id === board.boardId} />
        ))}
      </div>
    </div>
  );
};

export default BoardNav;
