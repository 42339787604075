export interface SupplySchedule {
  supplyScheduleId: number;
  title: string | null;
  data: SSData[];
}

export interface SSData {
  date: string;
  statusDown: SSCellStatus;
  statusTop: SSCellStatus;
  supplyScheduleDataId?: number;
  text: string | null;
}

export enum SSCellStatus {
  Undefined = 'Undefined',
  Gray = 'Gray',
  Red = 'Red',
  Green = 'Green'
}
