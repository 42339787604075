import { createAction } from '../../utils/action';
import ActionTypes from '../actionTypes';
import { ActionsUnion } from '../types';
import { RestErrorResponse } from '../../types/response/RestErrorResponse';
import { Board, Tile } from './types';

export const Actions = {
  getTile: () => createAction(ActionTypes.GET_TILE),
  getTileSuccess: (tile: Tile) => createAction(ActionTypes.GET_TILE_SUCCESS, tile),
  getTileError: (error: RestErrorResponse) => createAction(ActionTypes.GET_TILE_ERROR, error),

  getAllTiles: () => createAction(ActionTypes.GET_ALL_TILES),
  getAllTilesSuccess: (tiles: Board<Tile>) => createAction(ActionTypes.GET_ALL_TILES_SUCCESS, tiles),
  getAllTilesError: (error: RestErrorResponse) => createAction(ActionTypes.GET_ALL_TILES_ERROR, error)
};

export type BoardsActions = ActionsUnion<typeof Actions>;
