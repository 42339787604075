import * as React from 'react';
import { ChangeEvent } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { cloneDeep } from 'lodash';
import useMedia from 'use-media';

import { MEDIA_QUERIES } from '../../core/constants/mediaQueries';
import ProjectOverviewRow from './ProjectOverviewRow';
import { Actions as TableFormsActions } from '../../core/state/table-forms/actions';
import { ProjectOverviewFormColumn, ProjectOverviewFormRow } from '../../core/state/table-forms/types';
import { getProjectOverviewForm, saveProjectOverviewForm } from '../../core/state/table-forms/asyncActions';
import { RootState } from '../../core/state/state';
import ProjectOverviewHeader from './ProjectOverviewHeader';
import ProjectOverviewDescription from './ProjectOverviewDescription';
import { SelectedItem } from './ProjectOverviewRowItem';
import { TableFormFieldStatus } from '../../core/enums/TableFormFieldStatus';
import { BOARDS_TILE_DETAIL } from '../../core/constants/routes';

import './TileProjectOverview.scss';

interface Props {
  url: string;
}

const defaultSelectedItem: SelectedItem = {
  row: undefined,
  column: undefined,
  value: ''
};

const TileProjectOverview: React.FC<Props> = ({ url }) => {
  const dispatch = useDispatch();
  const projectOverviewForm = useSelector((state: RootState) => state.tableForm.projectOverviewForm.form);
  const [selectedItem, setSelectedItem] = React.useState<SelectedItem>(defaultSelectedItem);
  const isDetailPage = useRouteMatch(BOARDS_TILE_DETAIL);

  React.useEffect(() => {
    dispatch(getProjectOverviewForm(url));
  }, [url, dispatch]);

  const handleDoubleClick = (column: ProjectOverviewFormColumn, row: ProjectOverviewFormRow) => {
    setSelectedItem({
      ...selectedItem,
      column,
      row,
      value: column.text
    });
  };

  const handleInputChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setSelectedItem({ ...selectedItem, value: e.target.value });
  };

  const handleOnBlur = () => {
    const newProjectOverviewForm = cloneDeep(projectOverviewForm);
    const rowIndex = newProjectOverviewForm?.projectOverviews.findIndex((row) => row.rowNumber === selectedItem.row?.rowNumber);
    const columnIndex = newProjectOverviewForm?.projectOverviews[Number(rowIndex)].items.findIndex(
      (column) => column.columnNumber === selectedItem.column?.columnNumber
    );

    if (newProjectOverviewForm) {
      newProjectOverviewForm.projectOverviews[Number(rowIndex)].items[Number(columnIndex)].text = selectedItem.value;
      dispatch(TableFormsActions.updateProjectOverviewForm(newProjectOverviewForm));
      dispatch(saveProjectOverviewForm(newProjectOverviewForm, url));
    }
    setSelectedItem(defaultSelectedItem);
  };

  const handleStatus = (row: ProjectOverviewFormRow, column: ProjectOverviewFormColumn) => {
    const newProjectOverviewForm = cloneDeep(projectOverviewForm);
    const rowIndex = newProjectOverviewForm?.projectOverviews.findIndex((rowItem) => rowItem.rowNumber === row?.rowNumber);
    const columnIndex = newProjectOverviewForm?.projectOverviews[Number(rowIndex)].items.findIndex(
      (columnItem) => columnItem.columnNumber === column?.columnNumber
    );

    if (newProjectOverviewForm) {
      let newStatus;
      if (column.status === TableFormFieldStatus.Green) {
        newStatus = TableFormFieldStatus.Red;
      } else if (column.status === TableFormFieldStatus.Red) {
        newStatus = TableFormFieldStatus.Undefined;
      } else {
        newStatus = TableFormFieldStatus.Green;
      }
      newProjectOverviewForm.projectOverviews[Number(rowIndex)].items[Number(columnIndex)].status = newStatus;
      dispatch(TableFormsActions.updateProjectOverviewForm(newProjectOverviewForm));
      dispatch(saveProjectOverviewForm(newProjectOverviewForm, url));
    }
    setSelectedItem(defaultSelectedItem);
  };

  const handleDeleteRow = (rowIndex: number) => {
    const newProjectOverviewForm = cloneDeep(projectOverviewForm);
    if (newProjectOverviewForm) {
      newProjectOverviewForm.projectOverviews.splice(rowIndex, 1);
      newProjectOverviewForm.projectOverviews = newProjectOverviewForm.projectOverviews.map((row, newIndex) => ({ ...row, rowNumber: newIndex + 1 }));
      dispatch(TableFormsActions.updateProjectOverviewForm(newProjectOverviewForm));
      dispatch(saveProjectOverviewForm(newProjectOverviewForm, url));
    }
  };

  const isDesktopScreen = useMedia(MEDIA_QUERIES.XL_DOWN);

  return (
    <div className={`tile-project-overview ${isDetailPage && 'tile-project-overview__small'} ${isDesktopScreen ? 'no-font-scaling' : ''}`}>
      <div className="tile-project-overview__table">
        <ProjectOverviewHeader />
        <ProjectOverviewDescription />
        {projectOverviewForm?.projectOverviews.map((row, index) => (
          <ProjectOverviewRow
            selectedItem={selectedItem}
            key={row.rowNumber}
            row={row}
            onDoubleClick={handleDoubleClick}
            onChange={handleInputChange}
            onBlur={handleOnBlur}
            handleStatus={handleStatus}
            handleDeleteRow={() => handleDeleteRow(index)}
          />
        ))}
      </div>
    </div>
  );
};

export default TileProjectOverview;
