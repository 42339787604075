import * as React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Table from '../../../components/Table/Table';
import TBody from '../../../components/Table/TBody/TBody';
import TR from '../../../components/Table/TR/TR';
import TD from '../../../components/Table/TD/TD';
import { RootState } from '../../../core/state/state';
import * as EscalationHelpers from '../TileEscalationHelpers';
import TableCellOverflowEllipses from '../../../pages/components/TableCellOverflowEllipses/TableCellOverflowEllipses';
import { toDisplayDateString } from '../../../core/utils/formatDate';
import { isRowHighlighted } from '../../TileMeasuresEscalation/helpers';

import './TileEscalationDesktopScreen.scss';

interface Props {
  url?: string;
}

const TileEscalationDesktopScreen: React.FC<Props> = () => {
  const escalations = useSelector((state: RootState) => state.tableForm.measuresEscalationForm.form?.escalations);
  const boardType = useSelector((state: RootState) => state.boards.board?.type);
  const { t } = useTranslation();

  const getAllRows = () => {
    return escalations?.map((escalation, index) => (
      <TR
        key={escalation.id || `key-${index}`}
        className={`tile-escalation-desktop-screen__tr-height--${boardType?.toLocaleLowerCase()} ${
          isRowHighlighted(escalation.createdAt) ? 'table__row--highlighted' : ''
        }`}
      >
        <TD>
          <TableCellOverflowEllipses content={`${index + 1}. ${escalation.summary}`} />
        </TD>
        <TD>{toDisplayDateString(escalation.openedAt ?? '')}</TD>
        <TD>
          <div
            role="button"
            aria-pressed="false"
            aria-label="indicator"
            tabIndex={0}
            className={`table__indicator m-auto d-flex ${EscalationHelpers.getAttendanceListClass(escalation.status)}
                tile-escalation-desktop-screen no-font-scaling__indicator`}
          />
        </TD>
      </TR>
    ));
  };

  return (
    <div className="tile-escalation">
      <div className="tile-escalation__content">
        <div className="tile-escalation-desktop-screen__title no-font-scaling">{t('forms.escalation.title')}</div>
        <Table className="tile-escalation-desktop-screen no-font-scaling">
          <TBody>{getAllRows()}</TBody>
        </Table>
      </div>
    </div>
  );
};

export default TileEscalationDesktopScreen;
