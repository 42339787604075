import * as React from 'react';
import './SectionHeader.scss';
import Heading from '../../../components/Heading/Heading';
import { Color } from '../../../core/enums/Color';
import Background from '../../../components/Background/Background';
import Tooltip from '../../../components/Tooltip/Tooltip';
import Icon from '../../../components/Icon/Icon';
import { Icon as IconObject } from '../../../core/constants/Icon';

interface Props {
  color?: Color;
  text: string;
  size?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
  className?: string;
  tooltipIcon?: keyof typeof IconObject;
  tooltipText?: string;
}

const SectionHeader: React.FC<Props> = ({ color, text, size = 'h2', className = '', tooltipIcon = 'cog', tooltipText, children }) => (
  <header className={`section-header ${className}`}>
    <Background color={color}>
      <div className="section-header__text">
        <Heading size={size} className={`text-center ${color === Color.White ? 'text-black' : 'text-white'}`}>
          {text}
        </Heading>
      </div>
      <div className="section-header__right p-2">
        {children && <div className="section-header__tooltip">{children}</div>}
        {tooltipText && (
          <Tooltip text={tooltipText}>
            <Icon icon={tooltipIcon} />
          </Tooltip>
        )}
      </div>
    </Background>
  </header>
);

export default SectionHeader;
