import * as React from 'react';
import { useRouteMatch } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { UNASSIGNED_BOARD } from '../../../../../core/constants/routes';
import BreadcrumbNavigation from '../../../BreadcrumbNavigation/BreadcrumbNavigation';
import { RootState } from '../../../../../core/state/state';

interface Props {
  boardId?: number;
}

const BoardHeaderLeft: React.FC<Props> = () => {
  const isUnassigned = useRouteMatch(UNASSIGNED_BOARD);

  const historyState = useSelector((state: RootState) => state.history);
  const { selectedHistoryListItemIndex } = historyState;

  return <div className="board-header__left">{selectedHistoryListItemIndex === 0 && !isUnassigned && <BreadcrumbNavigation />}</div>;
};

export default BoardHeaderLeft;
