import React from 'react';
import { useTranslation } from 'react-i18next';

import { TileTypes } from '../../core/enums/TileTypes';
import TableFormTile from '../TableFormTile/TableFormTile';
import { TableTileTypes } from '../../core/enums/TableTileTypes';
import TileObstaclesTableFooter from './TileObstaclesTableFooter';
import TileObstaclesInfo from './TileObstaclesInfo';

import './TileObstacles.scss';

interface Props {
  boardId?: number;
  url: string;
}

const TileObstacles: React.FC<Props> = ({ url, boardId }) => {
  const { t } = useTranslation();

  return (
    <TableFormTile
      tClassName="tile-obstacles"
      url={url}
      boardId={boardId}
      tileType={TileTypes.Obstacles}
      tableTileType={TableTileTypes.Obstacles}
      hasNoBorder
      columns={[
        {
          field: 'id',
          title: t('forms.obstacles.column.id'),
          type: 'index',
          headerClass: 'w-5'
        },
        {
          field: 'obstacle',
          title: t('forms.obstacles.column.obstacle'),
          columns: [
            {
              headerClass: 'w-7',
              type: 'calendar',
              field: 'obstacle1',
              subtitle: t('forms.obstacles.column.obstacleSubtitle1')
            },
            {
              headerClass: 'w-22',
              field: 'obstacle2',
              subtitle: t('forms.obstacles.column.obstacleSubtitle2')
            }
          ]
        },
        {
          field: 'replyCustomer',
          title: t('forms.obstacles.column.replyCustomer'),
          columns: [
            {
              headerClass: 'shrink',
              type: 'status',
              field: 'replyCustomer1',
              subtitle: t('forms.obstacles.column.replyCustomerSubtitle1')
            },
            {
              headerClass: 'w-7',
              type: 'calendar',
              field: 'replyCustomer2',
              subtitle: t('forms.obstacles.column.replyCustomerSubtitle2')
            }
          ]
        },
        {
          field: 'obstacleRemoval',
          title: t('forms.obstacles.column.obstacleRemoval'),
          columns: [
            {
              headerClass: 'w-7',
              type: 'calendar',
              field: 'obstacleRemoval1',
              subtitle: t('forms.obstacles.column.obstacleRemovalSubtitle1')
            },
            {
              headerClass: 'w-7',
              type: 'number',
              field: 'obstacleRemoval2',
              subtitle: t('forms.obstacles.column.obstacleRemovalSubtitle2')
            },
            {
              headerClass: 'w-7',
              type: 'number',
              field: 'obstacleRemoval3',
              subtitle: t('forms.obstacles.column.obstacleRemovalSubtitle3')
            },
            {
              headerClass: 'w-7',
              field: 'obstacleRemoval4',
              subtitle: t('forms.obstacles.column.obstacleRemovalSubtitle4')
            }
          ]
        },
        {
          field: 'decisionCustomer',
          title: t('forms.obstacles.column.decisionCustomer'),
          columns: [
            {
              headerClass: 'shrink',
              type: 'status',
              field: 'decisionCustomer1',
              subtitle: t('forms.obstacles.column.decisionCustomerSubtitle1')
            },
            {
              headerClass: 'w-7',
              type: 'calendar',
              field: 'decisionCustomer2',
              subtitle: t('forms.obstacles.column.decisionCustomerSubtitle2')
            },
            {
              headerClass: 'w-7',
              type: 'number',
              field: 'decisionCustomer3',
              subtitle: t('forms.obstacles.column.decisionCustomerSubtitle3')
            },
            {
              headerClass: 'w-7',
              type: 'number',
              field: 'decisionCustomer4',
              subtitle: t('forms.obstacles.column.decisionCustomerSubtitle4')
            }
          ]
        }
      ]}
      InfoComponent={TileObstaclesInfo}
      TableFooter={TileObstaclesTableFooter}
      showStatus
    />
  );
};

export default TileObstacles;
