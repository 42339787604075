import * as React from 'react';

import TableFormTileDesktopScreen from '../../TableFormTile/DesktopScreen/TableFormTileDesktopScreen';
import { TileTypes } from '../../../core/enums/TileTypes';
import { t } from '../../../../i18n';
import { TableTileTypes } from '../../../core/enums/TableTileTypes';
import TR from '../../../components/Table/TR/TR';
import TD from '../../../components/Table/TD/TD';
import TableCellOverflowEllipses from '../../../pages/components/TableCellOverflowEllipses/TableCellOverflowEllipses';
import { getDesktopStatusIndicator } from '../../TableFormTile/TableFormHelpers';

interface Props {
  url: string;
}

const TileObstaclesDesktopScreen: React.FC<Props> = ({ url }) => {
  return (
    <TableFormTileDesktopScreen
      url={url}
      tileType={TileTypes.Obstacles}
      tableTileType={TableTileTypes.Obstacles}
      tableHeaderColSpan={4}
      bodyRenderer={(tableForm) => {
        return tableForm ? (
          <>
            {tableForm.rows.map((tableFormItem, index) => (
              <TR key={tableFormItem.rowNumber}>
                <TD className="text-left">
                  <TableCellOverflowEllipses content={`${index + 1}. ${tableFormItem.columns[1]?.text}`} />
                </TD>
                <TD>
                  <div className={`table__indicator ${getDesktopStatusIndicator(tableFormItem.columns[8]?.status)} no-font-scaling`} />
                </TD>
                <TD className="text-right">
                  {parseFloat(tableFormItem.columns[10]?.text)
                    ? parseFloat(tableFormItem.columns[10]?.text).toLocaleString('de-DE', {
                        style: 'unit',
                        unitDisplay: 'narrow',
                        unit: 'day',
                        useGrouping: false,
                        maximumFractionDigits: 0
                      })
                    : ''}
                </TD>
                <TD className="text-right">
                  {parseFloat(tableFormItem.columns[11]?.text)
                    ? parseFloat(tableFormItem.columns[11]?.text).toLocaleString('de-DE', {
                        style: 'currency',
                        currency: 'EUR',
                        maximumFractionDigits: 0
                      })
                    : ''}
                </TD>
              </TR>
            ))}
            <TR className="last-row">
              <TD colSpan={2} className="text-left title">
                {t('forms.trackingAAZ.column.sumOfAmount')}
              </TD>
              <TD className="text-right sum w-20">
                {`${tableForm?.rows
                  .reduce((acc, row) => {
                    const a = parseFloat(row.columns[10].text) || 0;
                    return acc + a;
                  }, 0)
                  .toLocaleString('de-DE', {
                    style: 'unit',
                    unitDisplay: 'narrow',
                    unit: 'day',
                    useGrouping: false,
                    maximumFractionDigits: 0
                  })}`}
              </TD>
              <TD className="text-right sum w-20">
                {`${tableForm?.rows
                  .reduce((acc, row) => {
                    const a = parseFloat(row.columns[11]?.text) || 0;
                    return acc + a;
                  }, 0)
                  .toLocaleString('de-DE', {
                    style: 'currency',
                    currency: 'EUR',
                    maximumFractionDigits: 0
                  })}`}
              </TD>
            </TR>
          </>
        ) : (
          <TR />
        );
      }}
      showStatus
    />
  );
};

export default TileObstaclesDesktopScreen;
