import React, { ReactFragment } from 'react';
import { useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';

import { RootState } from '../../core/state/state';
import { BOARDS_TILE_DETAIL } from '../../core/constants/routes';
import TileMeasureTableHeader from './TileMeasureTableHeader/TileMeasureTableHeader';
import TileMeasureHeader from './TileMeasureHeader/TileMeasureHeader';
import TileMeasureRow from './TileMeasureRow/TileMeasureRow';
import Table from '../../components/Table/Table';
import THead from '../../components/Table/THead/THead';
import TileMeasureEmptyRow from './TileMeasureRow/TileMeasureEmptyRow';

import './TileMeasure.scss';

interface Props {
  url?: string;
  boardId?: number;
  toolbar?: ReactFragment;
}

const TileMeasure: React.FC<Props> = ({ toolbar }) => {
  const isDetailPage = useRouteMatch(BOARDS_TILE_DETAIL);
  const measures = useSelector((state: RootState) => state.tableForm.measuresEscalationForm.form?.measures);

  const isEmpty = !measures?.length;

  return (
    <div className="tile-measure">
      <div className="tile-measure__content">
        <TileMeasureHeader toolbar={toolbar} />
        <div className="tile-measure__table-wrap">
          <Table className={`${isEmpty ? 'tile-measure__table--empty' : ''} ${isDetailPage ? 'tile-measure__table' : 'table--small'}`}>
            <THead>
              <TileMeasureTableHeader />
            </THead>
            <tbody>
              {!isEmpty &&
                measures?.map((measure, rowIndex) => <TileMeasureRow rowIndex={rowIndex} key={measure.id || `new-${rowIndex}`} measure={measure} />)}
              {isEmpty && <TileMeasureEmptyRow />}
            </tbody>
          </Table>
        </div>
      </div>
    </div>
  );
};

export default TileMeasure;
