import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { useRouteMatch } from 'react-router-dom';
import TH from '../../../components/Table/TH/TH';
import TR from '../../../components/Table/TR/TR';
import { BOARDS_TILE_DETAIL } from '../../../core/constants/routes';

const TileMeasureTableHeader = () => {
  const { t } = useTranslation();
  const isDetailPage = useRouteMatch(BOARDS_TILE_DETAIL);

  return (
    <TR>
      <TH className="shrink">
        <p className="table__title">{t('forms.measure.tableHeader.number')}</p>
      </TH>
      <TH className="table__table-header">
        <p className="table__title">{t('forms.measure.tableHeader.date')}</p>
      </TH>
      <TH className="table__table-header">
        <p className="table__title text-left">{t('forms.measure.tableHeader.problem')}</p>
      </TH>
      <TH className="table__table-header">
        <p className="table__title text-left">{t('forms.measure.tableHeader.decision')}</p>
      </TH>
      <TH className="table__table-header">
        <p className="table__title text-left">{t('forms.measure.tableHeader.responsible')}</p>
      </TH>
      <TH className="table__table-header">
        <p className="table__title text-center">{t('forms.measure.tableHeader.meeting')}</p>
      </TH>
      <TH className="table__table-header">
        <p className="table__title">{t('sharedText.status')}</p>
      </TH>
      {isDetailPage && (
        <TH colSpan={2}>
          <p className="table__title">{t('sharedText.actions')}</p>
        </TH>
      )}
    </TR>
  );
};

export default TileMeasureTableHeader;
