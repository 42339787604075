import { useLocation, useParams, useRouteMatch } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { useTranslation } from 'react-i18next';
import { BACK_OFFICE, BACK_OFFICE_DETAIL, BOARDS_TILE_DETAIL } from '../constants/routes';
import { RootState } from '../state/state';

export function useBreadcrumb() {
  const location = useLocation();
  const { t } = useTranslation();

  const isBoardDetail = useRouteMatch(BOARDS_TILE_DETAIL);
  const isBackOffice = useRouteMatch(BACK_OFFICE);
  const isBackOfficeDetail = useRouteMatch(BACK_OFFICE_DETAIL);

  const { id, tileId } = useParams<{ id?: string; tileId?: string }>();
  const query = new URLSearchParams(location.search);

  const menuItems = useSelector((state: RootState) => state.menuItems.list);
  const backOfficeItems = useSelector((state: RootState) => state.backOffice.list);

  return {
    routes: {
      isBoardDetail: !!isBoardDetail,
      isBackOffice: !!isBackOffice,
      isBackOfficeDetail: !!isBackOfficeDetail
    },
    ids: {
      tileId: isBoardDetail ? query.get('id') : tileId,
      id
    },
    data: {
      menuItems,
      backOfficeItems
    },
    translations: {
      backOffice: t('backOffice.backOffice')
    }
  };
}

export type UseBreadcrumbReturnType = ReturnType<typeof useBreadcrumb>;
