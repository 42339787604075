import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Dropdown from 'react-bootstrap/Dropdown';
import { useTranslation } from 'react-i18next';

import { RootState } from '../../../../../core/state/state';
import { BoardTypes } from '../../../../../core/enums/BoardTypes';
import { history } from '../../../../../core/state/history';
import { setSelectedHistoryListItem } from '../../../../../core/state/history/asyncActions';
import { BOARDS } from '../../../../../core/constants/routes';
import Icon from '../../../../../components/Icon/Icon';
import { isDesktopScreen } from '../../../../../core/utils/isDesktopScreen';
import { toDisplayDateString } from '../../../../../core/utils/formatDate';

import './HistoryDropDown.scss';

interface Props {
  text?: string;
  boardId?: number;
  boardType?: BoardTypes;
}

type ButtonProps = {
  onClick: (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
};

type RefType = number;

const HistoryDropDown: React.FC<Props> = ({ boardId, boardType }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const historyState = useSelector((state: RootState) => state.history);
  const { list, selectedHistoryListItemIndex } = historyState;
  const isDesktop = isDesktopScreen();

  const TODAY = t('historyDropDown.today');

  const historyDateHandler = (index: number) => {
    dispatch(setSelectedHistoryListItem(index));
    if (index === 0) {
      history.push(`${BOARDS}/${boardId}`);
    } else {
      history.push(`${BOARDS}/${boardId}/${boardType}/history-view/${index}`);
    }
  };

  const CustomToggle = React.forwardRef<RefType, ButtonProps>(({ children, onClick }, ref: any) => (
    <a
      href="#/"
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      <div className="d-flex flex-row justify-content-center align-items-center">
        {children}
        <div className="history-dropdown__icon">
          <Icon icon="downArrow" size="full-size" />
        </div>
      </div>
    </a>
  ));

  return (
    <div className={`history-dropdown no-font-scaling history-dropdown no-font-scaling--${isDesktop ? 'desktop-screen' : 'tv-screen'}`}>
      <Dropdown>
        <Dropdown.Toggle disabled={list.length === 0} as={CustomToggle}>
          <span className="history-dropdown__title">
            {selectedHistoryListItemIndex === 0 ? TODAY : toDisplayDateString(list[selectedHistoryListItemIndex]?.historyDate)}
          </span>
        </Dropdown.Toggle>

        <Dropdown.Menu className="history-dropdown__dropdown-menu">
          {list.length > 0 &&
            list.map((item, index) => (
              <Dropdown.Item
                onClick={() => historyDateHandler(index)}
                key={item.screenshotId}
                value={`${index === 0 ? TODAY : item.historyDate}`}
                className={`${selectedHistoryListItemIndex === index ? 'history-dropdown__selected' : ''}`}
              >
                {index === 0 ? TODAY : toDisplayDateString(item.historyDate)}
              </Dropdown.Item>
            ))}
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};

export default HistoryDropDown;
