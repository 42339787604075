import * as React from 'react';

import './Heading.scss';

interface Props {
  className?: string;
  size?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
}

const Heading: React.FC<Props> = ({ children, className, size = 'h1' }) => {
  const HX = size;
  return <HX className={`heading ${className || ''}`}>{children}</HX>;
};

export default Heading;
