import * as React from 'react';
import TR from '../../../components/Table/TR/TR';
import { ProcessConfirmationFormRow } from '../../../core/state/process-confirmation/types';
import TD from '../../../components/Table/TD/TD';
import TileProcessConfirmationColumn from './TileProcessConfirmationColumn';
import { SelectedItem } from '../types';
import { TableFormFieldStatus } from '../../../core/enums/TableFormFieldStatus';

interface Props {
  row: ProcessConfirmationFormRow;
  rowIndex: number;
  selectedItem: SelectedItem;
  setSelectedItem: React.Dispatch<React.SetStateAction<SelectedItem>>;
  handleIndicatorStatus: (status: TableFormFieldStatus, rowIndex: number, columnIndex: number, multiLineIndex?: number) => void;
  handleBlur: (value: string, rowIndex: number, columnIndex: number, multiLineIndex?: number) => void;
}

const TileProcessConfirmationRow: React.FC<Props> = ({ row, selectedItem, setSelectedItem, handleIndicatorStatus, rowIndex, handleBlur }) => {
  return (
    <TR className="tile-process-confirmation__row">
      <TD className="tile-process-confirmation__title">{row.projectName}</TD>
      {row.items.map((column, columnIndex) => (
        <TileProcessConfirmationColumn
          key={column.columnNumber}
          handleIndicatorStatus={handleIndicatorStatus}
          column={column}
          columnIndex={columnIndex}
          rowIndex={rowIndex}
          selectedItem={selectedItem}
          setSelectedItem={setSelectedItem}
          handleBlur={handleBlur}
        />
      ))}
    </TR>
  );
};

export default TileProcessConfirmationRow;
