import * as React from 'react';
import { useReport } from 'powerbi-report-component';

import './TilePowerBIPreview.scss';

interface Props {
  accessToken: string;
  embedUrl: string;
  reportId: string;
}

const TilePowerBIPreview: React.FC<Props> = ({ accessToken, embedUrl, reportId }) => {
  const reportRef = React.useRef(null);
  const [, setEmbed] = useReport();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const reportConfig: any = {
    embedType: 'report',
    tokenType: 'Embed',
    accessToken,
    embedUrl,
    embedId: reportId,
    extraSettings: {
      filterPaneEnabled: false,
      navContentPaneEnabled: false
    }
  };

  React.useEffect(() => {
    setEmbed(reportRef, reportConfig);
  }, [reportConfig, setEmbed]);

  return (
    <div className="tile-power-bi-preview">
      <div className="tile-power-bi-preview__report" ref={reportRef} />
    </div>
  );
};

export default TilePowerBIPreview;
