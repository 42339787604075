import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { Board as BoardModel, Tile as TileModel } from '../../core/state/boards/types';
import { TileConfigurationDictionary } from '../../core/types/TileConfigurationDictionary';
import { TilePositions } from '../../core/enums/TilePositions';
import { Color } from '../../core/enums/Color';

import SectionHeader from '../components/SectionHeader/SectionHeader';
import BoardGrid from '../components/Board/BoardGrid/BoardGrid';
import Tile from '../../components/Tile/Tile';
import { getFilteredTiles } from '../../core/utils/tile/getFilteredTiles';

interface Props {
  board: BoardModel<TileModel>;
}

const activePositions = [
  TilePositions.A1,
  TilePositions.A2,
  TilePositions.A3,
  TilePositions.A4,
  TilePositions.A5,
  TilePositions.B1,
  TilePositions.B2,
  TilePositions.E1,
  TilePositions.M1,
  TilePositions.P1,
  TilePositions.MT1,
  TilePositions.ME1
];

const ShopFloorManagementTVGrid: React.FC<Props> = ({ board }) => {
  const { t } = useTranslation();

  const tileConfigurations: TileConfigurationDictionary = {
    [TilePositions.M1]: {
      headingBackgroundColor: Color.Red,
      headingText: t('mainBoard.title.measuresKvp')
    },
    [TilePositions.E1]: {
      headingBackgroundColor: Color.Red,
      headingText: t('mainBoard.title.escalation')
    },
    [TilePositions.B1]: {
      headingBackgroundColor: Color.GreenLight,
      headingText: t('mainBoard.title.areaInformation')
    },
    [TilePositions.ME1]: {
      isHorizontal: true,
      headingBackgroundColor: Color.Red,
      headingText: t('mainBoard.title.measuresEscalations')
    }
  };

  return (
    <BoardGrid boardType={board.type}>
      <SectionHeader className="mb-0 gh" text={t('mainBoard.title.distribution')} color={Color.Red3} />
      {getFilteredTiles(board.tiles, activePositions)?.map((tile) => (
        <Tile
          key={tile.tileId}
          tile={tile}
          className={tile.position.toLowerCase()}
          isInteractive={tile.isInteractive}
          isTransparent={tileConfigurations[tile.position]?.isTransparent}
          headingBackgroundColor={tileConfigurations[tile.position]?.headingBackgroundColor}
          headingText={tileConfigurations[tile.position]?.headingText}
          isHorizontal={tileConfigurations[tile.position]?.isHorizontal}
        />
      ))}
    </BoardGrid>
  );
};

export default ShopFloorManagementTVGrid;
