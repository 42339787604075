import React from 'react';
import { useTranslation } from 'react-i18next';
import TableFormTile from '../TableFormTile/TableFormTile';
import { TileTypes } from '../../core/enums/TileTypes';
import { TableTileTypes } from '../../core/enums/TableTileTypes';
import TileCheckpointsMasterPlanInfo from './TileCheckpointsInfo';

import './TileCheckpointsMasterPlan.scss';

interface Props {
  boardId?: number;
  url: string;
}

const TileCheckpointsMasterPlan: React.FC<Props> = ({ url, boardId }) => {
  const { t } = useTranslation();

  return (
    <TableFormTile
      url={url}
      boardId={boardId}
      tileType={TileTypes.CheckpointsMasterPlan}
      tableTileType={TableTileTypes.CheckpointsMasterPlan}
      tClassName="tile-checkpoints-master-plan"
      columns={[
        {
          field: 'milestones',
          type: 'number-pp',
          title: t('forms.checkpointsMasterPlan.milestones'),
          headerClass: 'text-center'
        },
        {
          field: 'milestoneTitle',
          headerClass: 'text-left',
          title: t('forms.checkpointsMasterPlan.milestoneTitle')
        },
        {
          field: 'date',
          title: t('forms.checkpointsMasterPlan.date'),
          type: 'calendar',
          headerClass: 'text-center'
        },
        {
          field: 'status',
          title: t('forms.checkpointsMasterPlan.status'),
          type: 'status-circle',
          headerClass: 'text-center'
        }
      ]}
      InfoComponent={TileCheckpointsMasterPlanInfo}
    />
  );
};

export default TileCheckpointsMasterPlan;
