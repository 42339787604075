import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { TileTypes } from '../../../core/enums/TileTypes';
import { TableTileTypes } from '../../../core/enums/TableTileTypes';
import { TableFormFieldStatus } from '../../../core/enums/TableFormFieldStatus';
import { RootState } from '../../../core/state/state';
import { generateAsyncActions } from '../../../core/state/table-forms/asyncActions';
import { TableForm, TableFormRow } from '../../../core/state/table-forms/types';
import { toDisplayDateString } from '../../../core/utils/formatDate';
import { useTableForm } from '../../../core/hooks/useTableForm';

import Table from '../../../components/Table/Table';
import TR from '../../../components/Table/TR/TR';
import TD from '../../../components/Table/TD/TD';
import THead from '../../../components/Table/THead/THead';
import TH from '../../../components/Table/TH/TH';
import TBody from '../../../components/Table/TBody/TBody';

import TableCellOverflowEllipses from '../../../pages/components/TableCellOverflowEllipses/TableCellOverflowEllipses';
import TableStatus from '../../../components/form/TableStatus/TableStatus';
import NumberPPInput from '../../../components/form/NumberPPInput/NumberPPInput';

export enum ColumnTypes {
  Text = 'text',
  Calendar = 'calendar',
  Status = 'status',
  StatusCircle = 'status-circle',
  NumberPP = 'number-pp'
}

interface ColumnDefinition {
  type: ColumnTypes;
  dataColumnIndex: number;
  className?: string;
}

interface Props {
  boardId?: number;
  url: string;
  tileType: TileTypes;
  tableTileType: TableTileTypes;
  tableHeaderColSpan?: number;
  bodyRenderer?: (rows: TableForm | null) => JSX.Element | JSX.Element[];
  columns?: ColumnDefinition[];
  showStatus?: boolean;
}

const TableFormTileDesktopScreen: React.FC<Props> = ({
  url,
  tileType,
  tableTileType,
  bodyRenderer,
  tableHeaderColSpan = 1,
  columns,
  showStatus = false
}) => {
  const dispatch = useDispatch();
  const tableForm = useSelector((state: RootState) => state[tableTileType].form);
  const { getTableForm } = React.useMemo(() => generateAsyncActions(tileType), [tileType]);
  const { t } = useTranslation();

  const { useTableValue } = useTableForm<TableForm>({
    data: tableForm,
    valuePropertyName: 'text',
    updateActions: [],
    submitActions: []
  });

  const { useTableValue: useTableStatus } = useTableForm<TableForm>({
    data: tableForm,
    valuePropertyName: 'status',
    updateActions: [],
    submitActions: []
  });

  React.useEffect(() => {
    dispatch(getTableForm(url));
  }, [url, dispatch, getTableForm]);

  const getHeaderColSpan = () => {
    if (columns) {
      return columns.length;
    }
    return tableHeaderColSpan;
  };

  const getCell = ({ type, dataColumnIndex, className }: ColumnDefinition, row: TableFormRow, rowIndex: number, columnIndex: number) => {
    let cellContent: any;
    let defaultClass = '';
    switch (type) {
      case ColumnTypes.Calendar:
        defaultClass = 'date';
        cellContent = toDisplayDateString(row.columns[dataColumnIndex].text);
        break;
      case ColumnTypes.Status:
        defaultClass = 'shrink';
        cellContent = (
          <TableStatus
            className="no-font-scaling"
            row={rowIndex}
            column={dataColumnIndex}
            statuses={[TableFormFieldStatus.Checked, TableFormFieldStatus.Unchecked]}
            useTableValue={useTableStatus}
            isSmall
            disabled
          />
        );
        break;
      case ColumnTypes.StatusCircle:
        defaultClass = 'shrink';
        cellContent = (
          <TableStatus
            className="no-font-scaling"
            type="circle"
            row={rowIndex}
            column={dataColumnIndex}
            statuses={[TableFormFieldStatus.Undefined, TableFormFieldStatus.Green, TableFormFieldStatus.Red]}
            useTableValue={useTableStatus}
            isSmall
            disabled
          />
        );
        break;
      case ColumnTypes.NumberPP:
        cellContent = <NumberPPInput row={rowIndex} column={dataColumnIndex - 1} useTableValue={useTableValue} />;
        break;
      case ColumnTypes.Text:
      default: {
        defaultClass = 'text-left';
        let textContent = row.columns[dataColumnIndex].text;
        if (columnIndex === 0) {
          textContent = `${rowIndex + 1}. ${textContent}`;
        }
        cellContent = <TableCellOverflowEllipses content={textContent} />;
      }
    }

    return (
      <TD key={columnIndex} className={`${className ?? ''} ${defaultClass}`}>
        {cellContent}
      </TD>
    );
  };

  const getRows = () => {
    if (bodyRenderer) {
      return bodyRenderer(tableForm);
    }

    if (columns) {
      return tableForm?.rows.map((row: TableFormRow, rowIndex: number) => (
        <TR key={row.rowNumber}>{columns.map((column, columnIndex) => getCell(column, row, rowIndex, columnIndex))}</TR>
      ));
    }

    return tableForm?.rows.map((tableFormItem) => (
      <TR key={tableFormItem.rowNumber}>
        <TD className="text-left">{tableFormItem.columns[0].text}</TD>
        <TD className="text-right">{tableFormItem.columns[2].text}</TD>
      </TR>
    ));
  };

  return (
    <div className="tile-desktop-screen no-font-scaling">
      <Table className="tile-table__table" type="small">
        <THead>
          <TR>
            <TH colSpan={getHeaderColSpan()} className="table__table-header">
              {t(`forms.${tableTileType}.title`)}
              {showStatus && tableForm && <div className={`status status-${tableForm.status.toLowerCase()}`} />}
            </TH>
          </TR>
        </THead>
        <TBody>{getRows()}</TBody>
      </Table>
    </div>
  );
};

export default TableFormTileDesktopScreen;
