import * as React from 'react';
import { ChangeEvent } from 'react';
import { cloneDeep } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { Route, useRouteMatch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Table from '../../components/Table/Table';
import THead from '../../components/Table/THead/THead';
import TBody from '../../components/Table/TBody/TBody';
import TR from '../../components/Table/TR/TR';
import TH from '../../components/Table/TH/TH';
import TD from '../../components/Table/TD/TD';

import { RootState } from '../../core/state/state';
import { history } from '../../core/state/history';
import { BOARDS, BOARDS_TILE_DETAIL } from '../../core/constants/routes';
import { StandingRulesForm, TableFormRow } from '../../core/state/table-forms/types';
import { getStandingRules, saveStandingRules, updateStandingRules } from '../../core/state/table-forms/asyncActions';
import { TableFormFieldStatus } from '../../core/enums/TableFormFieldStatus';
import ConfirmPopup, { ConfirmPopupProps } from '../../components/popup/ConfirmPopup/ConfirmPopup';
import UnsavedChangesPopup from '../../components/popup/UnsavedChangesPopup/UnsavedChangesPopup';
import Button from '../../components/Button/Button';
import TableInput from '../../components/form/TableInput/TableInput';
import Icon from '../../components/Icon/Icon';
import { useRowNumber } from '../../core/hooks/useRowNumber';

import './TileStandingRules.scss';

interface Props {
  url: string;
  boardId?: number;
}

type ItemKeys = keyof Omit<StandingRulesForm, 'rulesOfStandingId' | 'details' | 'hierarchyId'>;

interface SelectedBottomItem {
  rowIndex: number;
  value: string;
}

interface SelectedInput {
  value: string;
  itemKey: ItemKeys | null;
}

const defaultSelectInput: SelectedInput = {
  value: '',
  itemKey: null
};

const defaultSelectedBottomItem: SelectedBottomItem = {
  rowIndex: -1,
  value: ''
};

const TileStandingRules: React.FC<Props> = ({ url, boardId }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [confirmDialog, setConfirmDialog] = React.useState<ConfirmPopupProps>();
  const standingRulesForm = useSelector((state: RootState) => state.tableForm.standingRulesForm.form);
  const [selectedBottomItem, setSelectedBottomItem] = React.useState<SelectedBottomItem>(defaultSelectedBottomItem);
  const [selectedInput, setSelectedInput] = React.useState<SelectedInput>(defaultSelectInput);
  const { isFormDirty, isFormEditing } = useSelector((state: RootState) => state.settings);
  const isDetailPage = useRouteMatch(BOARDS_TILE_DETAIL);
  const nextRowNumber = useRowNumber(standingRulesForm?.details ?? null);

  React.useEffect(() => {
    dispatch(getStandingRules(url));
  }, [dispatch, url]);

  const handleSelectItem = (itemKey: ItemKeys, value: string) => {
    setSelectedInput({
      value,
      itemKey
    });
  };

  const handleSelectBottomItem = (value: string, rowIndex: number) => {
    setSelectedBottomItem({
      rowIndex,
      value
    });
  };

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSelectedInput({
      ...selectedInput,
      value: e.target.value
    });
  };

  const handleBottomInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSelectedBottomItem({
      ...selectedBottomItem,
      value: e.target.value
    });
  };

  const handleInputBlur = async () => {
    const newStandingRulesForm = cloneDeep(standingRulesForm);
    if (newStandingRulesForm && selectedInput.itemKey) {
      newStandingRulesForm[selectedInput.itemKey] = selectedInput.value || '';
      await dispatch(updateStandingRules(newStandingRulesForm));
    }
    setSelectedInput({
      value: '',
      itemKey: null
    });
  };

  const handleBottomBlur = async () => {
    const newStandingRulesForm = cloneDeep(standingRulesForm);
    const { rowIndex, value } = selectedBottomItem;
    if (newStandingRulesForm && newStandingRulesForm?.details && newStandingRulesForm.details.rows.length > 0 && selectedBottomItem.rowIndex > -1) {
      if (newStandingRulesForm.details.rows[rowIndex]) {
        newStandingRulesForm.details.rows[rowIndex].columns[1].text = String(value);
      }
      await dispatch(updateStandingRules(newStandingRulesForm));
    }
    setSelectedBottomItem({
      rowIndex: -1,
      value: ''
    });
  };

  const handleSubmit = async () => {
    const newStandingRulesForm = cloneDeep(standingRulesForm);
    if (newStandingRulesForm) {
      await dispatch(updateStandingRules(newStandingRulesForm));
      await dispatch(saveStandingRules(newStandingRulesForm));
      history.push(`${BOARDS}/${boardId}`);
    }
  };

  const clearAllFields = () => {
    const newStandingRulesForm = cloneDeep(standingRulesForm);
    if (newStandingRulesForm && newStandingRulesForm.details) {
      newStandingRulesForm.leader = '';
      newStandingRulesForm.deputy = '';
      newStandingRulesForm.attendees = '';
      newStandingRulesForm.meetingDay = '';
      newStandingRulesForm.meetingTimeStart = '';
      newStandingRulesForm.meetingTimeEnd = '';
      newStandingRulesForm.details.rows = [];
      newStandingRulesForm.meetingTimeEnd = '';
      dispatch(updateStandingRules(newStandingRulesForm));
    }
  };

  const handleDeleteItem = async (itemName: ItemKeys) => {
    const newStandingRulesForm = cloneDeep(standingRulesForm);
    if (newStandingRulesForm) {
      newStandingRulesForm[itemName] = '';
      await dispatch(updateStandingRules(newStandingRulesForm));
    }
  };

  const deleteDates = async () => {
    const newStandingRulesForm = cloneDeep(standingRulesForm);
    if (newStandingRulesForm) {
      newStandingRulesForm.meetingTimeEnd = '';
      newStandingRulesForm.meetingTimeStart = '';
      await dispatch(updateStandingRules(newStandingRulesForm));
    }
  };

  const addEmptyRow = (suppressDirtyStatus = false) => {
    const newsStandingRulesForm = cloneDeep(standingRulesForm);
    const emptyRow: TableFormRow = {
      rowNumber: nextRowNumber(),
      columns: [
        {
          columnNumber: 1,
          title: t('sharedText.number'),
          status: TableFormFieldStatus.Undefined,
          text: ''
        },
        {
          columnNumber: 2,
          status: TableFormFieldStatus.Undefined,
          title: 'Agenda',
          text: ''
        }
      ]
    };

    if (!suppressDirtyStatus && newsStandingRulesForm && newsStandingRulesForm.details) {
      newsStandingRulesForm.details.rows.push(emptyRow);
      dispatch(updateStandingRules(newsStandingRulesForm, true, suppressDirtyStatus));
    }
  };

  const handleDeleteRow = (index: number) => {
    const newStandingRulesForm = cloneDeep(standingRulesForm);

    if (newStandingRulesForm && newStandingRulesForm.details) {
      newStandingRulesForm.details.rows.splice(index, 1);
      dispatch(updateStandingRules(newStandingRulesForm));
    }
  };

  const getAllRows = () => {
    const { rowIndex, value } = selectedBottomItem;
    if (standingRulesForm?.details && standingRulesForm?.details.rows.length > 0) {
      return standingRulesForm?.details.rows.map((tableFormItem, index) => (
        <TR key={tableFormItem.rowNumber}>
          <TD className="text-center">{index + 1}</TD>
          <TD>
            <TableInput
              type={isDetailPage ? undefined : 'small'}
              onClick={() => {
                handleSelectBottomItem(tableFormItem.columns[1].text, index);
              }}
              onBlur={handleBottomBlur}
              onChange={handleBottomInputChange}
              value={index === rowIndex ? value : tableFormItem.columns[1].text}
            />
          </TD>
          {isDetailPage && (
            <TD className="text-center">
              <button title={t('buttons.deleteRow')} className="btn btn-link" onClick={() => handleDeleteRow(index)}>
                <Icon icon="delete" svg />
                <span className="sr-only">{t('buttons.deleteRow')}</span>
              </button>
            </TD>
          )}
        </TR>
      ));
    }
    return null;
  };

  if (!standingRulesForm) {
    return null;
  }
  return (
    <div className={`tile-standing-rules ${isDetailPage ? '' : 'tile-standing-rules--small'}`}>
      <div className={isDetailPage ? 'container-fluid' : ''}>
        <div className={`d-flex justify-content-between align-items-center mx-1 ${isDetailPage ? 'my-4' : 'my-1'}`}>
          {!isDetailPage && <div className="tile-standing-rules__title">{t('tileStandingRules.rulesOfStanding')}</div>}
          {isDetailPage && <div className="tile-standing-rules__sub-title">{t('tileStandingRules.followUp')}</div>}
          <Route exact path={BOARDS_TILE_DETAIL}>
            <div className="d-flex justify-content-end align-items-end">
              <Button
                buttonTypes={['secondary', 'big']}
                onClick={() => {
                  setConfirmDialog({
                    heading: t('dialogs.confirmDialog.heading'),
                    cancelText: t('dialogs.confirmDialog.cancelText'),
                    confirmText: t('dialogs.confirmDialog.confirmText'),
                    show: true,
                    onCancel: () => {
                      setConfirmDialog({ show: false });
                    },
                    onConfirm: () => {
                      clearAllFields();
                      setConfirmDialog({ show: false });
                    }
                  });
                }}
              >
                {t('buttons.clearAll')}
              </Button>
              <Button buttonTypes={['primary', 'big']} className="ml-3" onClick={handleSubmit}>
                {t('buttons.save')}
              </Button>
            </div>
          </Route>
        </div>
        <div className="tile-standing-rules__top mb-1">
          <div className="tile-standing-rules__top-left">
            <Table className={`tile-standing-rules__table-moderation ${isDetailPage ? 'mb-4' : 'mb-1'}`}>
              <THead>
                <TR>
                  <TH colSpan={3}>{t('tileStandingRules.moderation')}</TH>
                </TR>
              </THead>
              <TBody>
                <TR>
                  <TD>{t('tileStandingRules.manager')}</TD>
                  <TD>
                    <TableInput
                      type={isDetailPage ? undefined : 'small'}
                      onClick={() => {
                        handleSelectItem('leader', standingRulesForm?.leader || '');
                      }}
                      onBlur={handleInputBlur}
                      onChange={handleInputChange}
                      value={selectedInput.itemKey === 'leader' ? selectedInput.value : standingRulesForm.leader}
                    />
                  </TD>
                  {isDetailPage && (
                    <TD className="text-center shrink">
                      <button title={t('buttons.deleteRow')} className="btn btn-link" onClick={() => handleDeleteItem('leader')}>
                        <Icon icon="delete" svg />
                        <span className="sr-only">{t('buttons.deleteRow')}</span>
                      </button>
                    </TD>
                  )}
                </TR>
                <TR>
                  <TD>{t('tileStandingRules.deputy')}</TD>
                  <TD>
                    <TableInput
                      type={isDetailPage ? undefined : 'small'}
                      onClick={() => {
                        handleSelectItem('deputy', standingRulesForm?.deputy || '');
                      }}
                      onBlur={handleInputBlur}
                      onChange={handleInputChange}
                      value={selectedInput.itemKey === 'deputy' ? selectedInput.value : standingRulesForm.deputy}
                    />
                  </TD>
                  {isDetailPage && (
                    <TD className="text-center shrink">
                      <button title={t('buttons.deleteRow')} className="btn btn-link" onClick={() => handleDeleteItem('deputy')}>
                        <Icon icon="delete" svg />
                        <span className="sr-only">{t('buttons.deleteRow')}</span>
                      </button>
                    </TD>
                  )}
                </TR>
                <TR>
                  <TD>{t('tileStandingRules.attendees')}</TD>
                  <TD>
                    <TableInput
                      type={isDetailPage ? undefined : 'small'}
                      onClick={() => {
                        handleSelectItem('attendees', standingRulesForm?.attendees || '');
                      }}
                      onBlur={handleInputBlur}
                      onChange={handleInputChange}
                      value={selectedInput.itemKey === 'attendees' ? selectedInput.value : standingRulesForm.attendees}
                    />
                  </TD>
                  {isDetailPage && (
                    <TD className="text-center shrink">
                      <button title={t('buttons.deleteRow')} className="btn btn-link" onClick={() => handleDeleteItem('attendees')}>
                        <Icon icon="delete" svg />
                        <span className="sr-only">{t('buttons.deleteRow')}</span>
                      </button>
                    </TD>
                  )}
                </TR>
              </TBody>
            </Table>
            <Table className="tile-standing-rules__table-termin">
              <THead>
                <TR>
                  <TH colSpan={6}>{t('tileStandingRules.date')}</TH>
                </TR>
              </THead>
              <TBody>
                <TR>
                  <TD>
                    <TableInput
                      type={isDetailPage ? undefined : 'small'}
                      onClick={() => {
                        handleSelectItem('meetingDay', standingRulesForm?.meetingDay || '');
                      }}
                      onBlur={handleInputBlur}
                      onChange={handleInputChange}
                      value={selectedInput.itemKey === 'meetingDay' ? selectedInput.value : standingRulesForm.meetingDay}
                    />
                  </TD>
                  {!isDetailPage && (
                    <TD>
                      {standingRulesForm?.meetingTimeStart} - {standingRulesForm?.meetingTimeEnd} Uhr
                    </TD>
                  )}
                  {isDetailPage && (
                    <>
                      <TD className="text-center shrink">
                        <button title={t('buttons.deleteRow')} className="btn btn-link" onClick={() => handleDeleteItem('meetingDay')}>
                          <Icon icon="delete" svg />
                          <span className="sr-only">{t('buttons.deleteRow')}</span>
                        </button>
                      </TD>
                      <TD className="text-center">
                        <TableInput
                          type={isDetailPage ? undefined : 'small'}
                          onClick={() => {
                            handleSelectItem('meetingTimeStart', standingRulesForm?.meetingTimeStart || '');
                          }}
                          onBlur={handleInputBlur}
                          onChange={handleInputChange}
                          value={selectedInput.itemKey === 'meetingTimeStart' ? selectedInput.value : standingRulesForm.meetingTimeStart}
                        />
                      </TD>
                      <TD className="text-center shrink px-3">-</TD>
                      <TD>
                        <TableInput
                          type={isDetailPage ? undefined : 'small'}
                          onClick={() => {
                            handleSelectItem('meetingTimeEnd', standingRulesForm?.meetingTimeEnd || '');
                          }}
                          onBlur={handleInputBlur}
                          onChange={handleInputChange}
                          value={selectedInput.itemKey === 'meetingTimeEnd' ? selectedInput.value : standingRulesForm.meetingTimeEnd}
                        />
                      </TD>
                      <TD className="text-center shrink">
                        <button title={t('buttons.deleteRow')} className="btn btn-link" onClick={deleteDates}>
                          <Icon icon="delete" svg />
                          <span className="sr-only">{t('buttons.deleteRow')}</span>
                        </button>
                      </TD>
                    </>
                  )}
                </TR>
              </TBody>
            </Table>
          </div>
          <div className="tile-standing-rules__top-right">
            <Table className="tile-standing-rules__table-right">
              <THead>
                <TR>
                  <TH>{t('tileStandingRules.rules')}</TH>
                </TR>
              </THead>
              <TBody>
                <TR>
                  <TD>
                    <ol>
                      <li>{t('tileStandingRules.rules.1')}</li>
                      <li>{t('tileStandingRules.rules.2')}</li>
                      <li>{t('tileStandingRules.rules.3')}</li>
                      <li>{t('tileStandingRules.rules.4')}</li>
                      <li>{t('tileStandingRules.rules.5')}</li>
                    </ol>
                  </TD>
                </TR>
              </TBody>
            </Table>
          </div>
        </div>
        <div>
          <Table className="tile-standing-rules__table-bottom">
            <THead>
              <TR>
                <TH>{t('sharedText.number')}</TH>
                <TH>{t('tileStandingRules.agenda')}</TH>
                {isDetailPage && <TH />}
              </TR>
            </THead>
            <TBody>{getAllRows()}</TBody>
          </Table>
        </div>
        <Route exact path={BOARDS_TILE_DETAIL}>
          <div className="d-flex justify-content-center my-4">
            <Button buttonTypes={['primary', 'big']} onClick={() => addEmptyRow()}>
              {t('buttons.addNewRow')}
            </Button>
          </div>
        </Route>
      </div>
      <ConfirmPopup
        show={confirmDialog?.show}
        confirmText={confirmDialog?.confirmText}
        cancelText={confirmDialog?.cancelText}
        heading={confirmDialog?.heading}
        onCancel={confirmDialog?.onCancel}
        onConfirm={confirmDialog?.onConfirm}
      />
      {isDetailPage && <UnsavedChangesPopup showIf={isFormDirty || isFormEditing} />}
    </div>
  );
};

export default TileStandingRules;
