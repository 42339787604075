import * as React from 'react';
import { useTranslation } from 'react-i18next';

import SectionHeader from '../../components/SectionHeader/SectionHeader';
import { Color } from '../../../core/enums/Color';

const PLBoardHeaderThird = () => {
  const { t } = useTranslation();
  return (
    <>
      <div className="col-5">
        <SectionHeader text={t('mainBoard.title.procurement')} color={Color.GreenLight} />
      </div>
      <div className="col-5">
        <SectionHeader text={t('mainBoard.title.scheduleControl')} color={Color.GreenLight} />
      </div>
      <div className="col-5">
        <SectionHeader text={t('mainBoard.title.obstructions')} color={Color.GreenLight} />
      </div>
      <div className="col-5">
        <SectionHeader text={t('mainBoard.title.quality')} color={Color.GreenLight} />
      </div>
      <div className="col-5">
        <SectionHeader text={t('mainBoard.title.financesAccounting')} color={Color.GreenLight} />
      </div>
    </>
  );
};

export default PLBoardHeaderThird;
