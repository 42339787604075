import { match } from 'react-router-dom';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

import StatusTextIcon from '../../pages/components/StatusTextIcon/StatusTextIcon';
import { Color } from '../../core/enums/Color';
import { Sizes } from '../../core/enums/Sizes';

interface Props {
  isDetailPage: match | null;
}

const TileCheckpointsMasterPlanInfo: React.FC<Props> = ({ isDetailPage }) => {
  const { t } = useTranslation();

  return (
    <div className="tile-checkpoints-master-plan__info">
      <StatusTextIcon color={Color.White} text={t('forms.checkpointsMasterPlan.open')} size={isDetailPage ? Sizes.Medium : Sizes.Small} />
      <StatusTextIcon color={Color.Red} text={t('forms.checkpointsMasterPlan.released')} size={isDetailPage ? Sizes.Medium : Sizes.Small} />
      <StatusTextIcon color={Color.Green} text={t('forms.checkpointsMasterPlan.overdue')} size={isDetailPage ? Sizes.Medium : Sizes.Small} />
    </div>
  );
};

export default TileCheckpointsMasterPlanInfo;
