import React from 'react';
import { useTranslation } from 'react-i18next';

import { Sizes } from '../../../core/enums/Sizes';
import StatusTextIcon from '../StatusTextIcon/StatusTextIcon';
import { Color } from '../../../core/enums/Color';

import './StatusRed.scss';

interface Props {
  condition: string;
  size?: Sizes.Small | Sizes.Medium;
  className?: string;
}

const StatusRed: React.FC<Props> = ({ condition, size = Sizes.Medium, className = '' }) => {
  const { t } = useTranslation();
  return (
    <div className={`status-red status-red--${size} ${className}`}>
      <span className="status-red__info-text">
        {t('component.status.statusDefinition1')}
        <StatusTextIcon size={size} color={Color.Red} />
        {t('component.status.statusDefinition2')}
        {condition}
      </span>
    </div>
  );
};
export default StatusRed;
