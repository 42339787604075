import React from 'react';

interface Props {
  content: string;
}

const TableCellOverflowEllipses: React.FC<Props> = ({ content }) => {
  return (
    <div className="table__text-overflow-container" title={content}>
      {content}
    </div>
  );
};
export default TableCellOverflowEllipses;
