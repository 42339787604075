export const HOME_ROUTE = '/';
export const PAGE_NOT_FOUND = '/page-not-found';

export const LOGIN = '/login';

export const BOARDS = '/boards';
export const BOARDS_ID = '/boards/:id';
export const SHOPFLOOR_BOARD = '/boards/1';
export const BOARDS_TILE_DETAIL = '/boards/:id/detail';
export const REPORT_SCREENSHOT_VIEW = '/report-screenshot-view';
export const BOARD_SCREENSHOT_VIEW = '/board-screenshot-view/:id';

export const BOARD_HISTORY_VIEW = '/boards/:boardId/:boardType/history-view/:selectedHistoryListItemIndex';

export const UNASSIGNED_BOARD = '/unassigned-board';
export const FORBIDDEN = '/forbidden';

export const BACK_OFFICE = '/boards/:id/back-office';
export const BACK_OFFICE_DETAIL = '/boards/:id/back-office/:tileId';
export const BACK_OFFICE_PERMISSIONS = '/boards/:id/back-office/permissions';
