import { createAction } from '../../utils/action';
import ActionTypes from '../actionTypes';
import { ActionsUnion } from '../types';
import { Notification } from './types';

export const Actions = {
  spawnNotification: (notification: Notification) => createAction(ActionTypes.SPAWN_NOTIFICATION, notification),
  dismissNotification: (notification: Notification) => createAction(ActionTypes.DISMISS_NOTIFICATION, notification)
};

export type NotificationsActions = ActionsUnion<typeof Actions>;
