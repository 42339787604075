import * as React from 'react';
import { useTranslation } from 'react-i18next';

import TD from '../../../components/Table/TD/TD';
import TileProcessConfirmationIndicator from './TileProcessConfirmationIndicator';

interface Indicator {
  suffix?: string;
  size?: 'sm';
}

interface Props {
  success?: Indicator;
  warning?: Indicator;
  light?: Indicator;
}

const FORMS_PROJECT_CONFIRMATION_LEGEND_INDICATOR = 'forms.processConfirmation.tableHeader.legend.indicator';

const TileProcessDescriptionItem: React.FC<Props> = ({ success, warning, light }) => {
  const { t } = useTranslation();

  const successText = t(`${FORMS_PROJECT_CONFIRMATION_LEGEND_INDICATOR}.${success?.suffix ?? ''}`);
  const warningText = t(`${FORMS_PROJECT_CONFIRMATION_LEGEND_INDICATOR}.${warning?.suffix ?? ''}`);
  const lightText = t(`${FORMS_PROJECT_CONFIRMATION_LEGEND_INDICATOR}.${light?.suffix ?? ''}`);
  return (
    <TD className="tile-process-confirmation__description-item">
      {success && <TileProcessConfirmationIndicator text={successText} color="green" size={success?.size} />}
      {warning && <TileProcessConfirmationIndicator text={warningText} color="red" size={warning?.size} />}
      {light && <TileProcessConfirmationIndicator text={lightText} color="white" size={light?.size} />}
    </TD>
  );
};

export default TileProcessDescriptionItem;
