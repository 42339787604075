import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { silentLogin } from '../../core/state/auth/asyncActions';
import { RootState } from '../../core/state/state';
import { AuthStatus } from '../../core/enums/AuthStatus';
import { MEDIA_QUERIES } from '../../core/constants/mediaQueries';

const PageWrap: React.FC<any> = ({ children }) => {
  const status = useSelector((state: RootState) => state.auth.status);

  const xlDownMediaQuery = window.matchMedia(MEDIA_QUERIES.XL_DOWN);

  const dispatch = useDispatch();
  React.useEffect(() => {
    dispatch(silentLogin());
    xlDownMediaQuery.addEventListener('change', () => {
      window.location.reload();
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  return status === AuthStatus.FirstLoad ? null : <>{children}</>;
};

export default PageWrap;
