import service from './RestService';
import { RestResponse } from '../types/response/RestResponse';
import { Board, Tile } from '../state/boards/types';
import { BoardPermissions } from '../state/back-office/types';

const path = '/boards';

class BoardsService {
  getAllTiles = (id: number): RestResponse<Board<Tile>> => service.get(`${path}/${id}`);

  getBoardPermissions = (boardId: number): RestResponse<BoardPermissions> => service.get(`${path}/${boardId}/permissions`);

  updateBoardPermissions = (boardId: number, permissions: BoardPermissions): RestResponse<any> =>
    service.post(`${path}/${boardId}/permissions`, permissions);
}

export default new BoardsService();
