import BoardsService from '../../services/BoardsService';
import { ReduxThunkType } from '../../types/ReduxThunkType';
import { Actions as BoardsActions } from './actions';

export const getAllTiles = (id: number): ReduxThunkType<void> => async (dispatch) => {
  try {
    dispatch(BoardsActions.getAllTiles());
    const response = await BoardsService.getAllTiles(id);
    dispatch(BoardsActions.getAllTilesSuccess(response.data));
  } catch (error) {
    dispatch(BoardsActions.getAllTilesError(error));
  }
};
