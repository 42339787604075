import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { match } from 'react-router-dom';
import { Sizes } from '../../core/enums/Sizes';
import StatusRed from '../../pages/components/StatusRed/StatusRed';
import StatusTextIcon from '../../pages/components/StatusTextIcon/StatusTextIcon';
import { Color } from '../../core/enums/Color';

interface Props {
  isDetailPage: match | null;
}

const TileInspectionPlansQAInfo: React.FC<Props> = ({ isDetailPage }) => {
  const { t } = useTranslation();
  return (
    <div
      className={`tile-inspection-plans-qa__info ${
        isDetailPage ? '' : 'tile-inspection-plans-qa__info--small justify-content-end'
      } d-flex flex-column align-items-end mb-2`}
    >
      <StatusRed
        condition={t('forms.inspectionPlansQAList.statusDefinitionSubtitle')}
        size={isDetailPage ? Sizes.Medium : Sizes.Small}
        className={`${isDetailPage ? '' : 'text-right'}`}
      />
      <div className="tile-inspection-plans-qa__info-bottom">
        <StatusTextIcon
          className="mr-2"
          text={t('forms.inspectionPlansQAList.legend1')}
          size={isDetailPage ? Sizes.Medium : Sizes.Small}
          color={Color.Black}
        />
        <StatusTextIcon text={t('forms.inspectionPlansQAList.legend2')} size={isDetailPage ? Sizes.Medium : Sizes.Small} color={Color.Green} />
      </div>
    </div>
  );
};

export default TileInspectionPlansQAInfo;
