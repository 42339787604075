import i18n from 'i18next';
import { initReactI18next, TFunction } from 'react-i18next';

import translationDE from './locales/de/translationDE.json';

// eslint-disable-next-line import/no-mutable-exports
let translate: TFunction<string>;
const resources = {
  de: {
    translation: translationDE
  }
};

i18n.use(initReactI18next).init(
  {
    resources,
    lng: 'de',
    keySeparator: false,
    interpolation: {
      escapeValue: false
    }
  },
  (err, t) => {
    translate = t;
  }
);

export { translate as t };

export default i18n;
