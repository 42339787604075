import { cloneDeep } from 'lodash';

import { TableFormFieldStatus } from '../../core/enums/TableFormFieldStatus';
import { updateKVPForm } from '../../core/state/table-forms/asyncActions';
import { TableForm, TableFormRow } from '../../core/state/table-forms/types';
import { Icon as IconObject } from '../../core/constants/Icon';
import store from '../../core/state/store';

export const toggleMeasureStatus = (status: TableFormFieldStatus) => {
  switch (status) {
    case TableFormFieldStatus.Quarter:
      return TableFormFieldStatus.Half;
    case TableFormFieldStatus.Half:
      return TableFormFieldStatus.ThreeQuarters;
    case TableFormFieldStatus.ThreeQuarters:
      return TableFormFieldStatus.Full;
    case TableFormFieldStatus.Undefined:
      return TableFormFieldStatus.Quarter;
    default:
      return TableFormFieldStatus.Undefined;
  }
};

export const clearAllFields = (list: TableForm | null) => {
  const newList = cloneDeep(list);
  if (newList) {
    newList.rows = [];
    store.dispatch<any>(updateKVPForm(newList));
  }
};

const generateEmptyRowFields = () => {
  const container = [];
  for (let index = 1; index < 8; index += 1) {
    container.push({
      columnNumber: index,
      status: TableFormFieldStatus.Undefined,
      text: index === 2 ? new Date().toString() : '',
      title: ''
    });
  }
  return container;
};

export const addEmptyRow = (list: TableForm | null) => {
  const newList = cloneDeep(list);
  const index = list && list.rows.length > 0 ? list.rows.length + 1 : 1;
  const emptyRow: TableFormRow = {
    rowNumber: index,
    columns: generateEmptyRowFields()
  };

  if (newList) {
    newList.rows.push(emptyRow);
    store.dispatch<any>(updateKVPForm(newList));
  }
};

export const handleDeleteRow = (rowIndex: number, list: TableForm | null) => {
  const newList = cloneDeep(list);
  if (newList) {
    newList.rows.splice(rowIndex, 1);
    newList.rows = newList.rows.map((row, index) => ({ ...row, rowNumber: index + 1 }));
    store.dispatch<any>(updateKVPForm(newList));
  }
};

export const getKVPStatusIcon = (status?: TableFormFieldStatus): keyof typeof IconObject => {
  switch (status) {
    case TableFormFieldStatus.Quarter:
      return 'oneFourth';
    case TableFormFieldStatus.Half:
      return 'twoFourths';
    case TableFormFieldStatus.ThreeQuarters:
      return 'threeFourths';
    case TableFormFieldStatus.Full:
      return 'fourFourths';
    default:
      return 'zeroFourths';
  }
};
