import * as React from 'react';

import Tooltip from '../../components/Tooltip/Tooltip';
import { isDesktopScreen } from '../../core/utils/isDesktopScreen';

import { t } from '../../../i18n';

export const headerItems = [
  {
    tooltip: t('form.projectOverview.tableHeader.tooltip.resultPreview'),
    title: t('form.projectOverview.tableHeader.title.resultPreview')
  },
  {
    tooltip: t('form.projectOverview.tableHeader.tooltip.planning'),
    title: t('form.projectOverview.tableHeader.title.planning')
  },
  {
    tooltip: t('form.projectOverview.tableHeader.tooltip.sampling'),
    title: t('form.projectOverview.tableHeader.title.sampling')
  },
  {
    tooltip: t('form.projectOverview.tableHeader.tooltip.tender'),
    title: t('form.projectOverview.tableHeader.title.tender')
  },
  {
    tooltip: t('form.projectOverview.tableHeader.tooltip.award'),
    title: t('form.projectOverview.tableHeader.title.award')
  },
  {
    tooltip: t('form.projectOverview.tableHeader.tooltip.term'),
    title: t('form.projectOverview.tableHeader.title.term')
  },
  {
    tooltip: t('form.projectOverview.tableHeader.tooltip.performance'),
    title: t('form.projectOverview.tableHeader.title.performance')
  },
  {
    tooltip: t('form.projectOverview.tableHeader.tooltip.disability'),
    title: t('form.projectOverview.tableHeader.title.disability')
  },
  {
    tooltip: t('form.projectOverview.tableHeader.tooltip.creditLineUtilization'),
    title: t('form.projectOverview.tableHeader.title.creditLineUtilization')
  },
  {
    tooltip: t('form.projectOverview.tableHeader.tooltip.aaz'),
    title: t('form.projectOverview.tableHeader.title.aaz')
  },
  {
    tooltip: t('form.projectOverview.tableHeader.tooltip.quality'),
    title: t('form.projectOverview.tableHeader.title.quality')
  },
  {
    tooltip: t('form.projectOverview.tableHeader.tooltip.finance'),
    title: t('form.projectOverview.tableHeader.title.finance')
  }
];

const ProjectOverviewHeader = () => {
  const isDesktop = isDesktopScreen();
  return (
    <div className={`tile-project-overview__header tile-project-overview__header--${isDesktop ? 'desktop-screen' : 'tv-screen'}`}>
      <div className="tile-project-overview__header-item" />
      {headerItems.map((headerItem) => (
        <div key={headerItem.title} className="tile-project-overview__header-item">
          <Tooltip text={headerItem.tooltip}>{headerItem.title}</Tooltip>
        </div>
      ))}
    </div>
  );
};

export default ProjectOverviewHeader;
