import * as MSAL from 'msal';
import { ReduxThunkType } from '../../types/ReduxThunkType';
import { Actions as AuthActions } from './actions';
import { getMenuList } from '../menu/asyncActions';
import { msalConfig, tokenRequest } from '../../constants/login/loginConfig';
import { history } from '../history';
import { LOGIN } from '../../constants/routes';

const client = new MSAL.UserAgentApplication(msalConfig);

export const login = (): ReduxThunkType<void> => async (dispatch) => {
  try {
    dispatch(AuthActions.login());
    await client.loginRedirect(tokenRequest);
    dispatch(AuthActions.loginSuccess());
  } catch (error) {
    dispatch(AuthActions.loginError(error));
  }
};

export const silentLogin = (): ReduxThunkType<void> => async (dispatch) => {
  try {
    dispatch(AuthActions.silentLogin());
    const response = await client.acquireTokenSilent(tokenRequest);
    dispatch(getMenuList());
    dispatch(AuthActions.silentLoginSuccess(response));
  } catch (error) {
    dispatch(AuthActions.silentLoginError(error));
    history.push(LOGIN);
  }
};

export const logout = (): ReduxThunkType<void> => async (dispatch) => {
  try {
    dispatch(AuthActions.logout());
    await client.logout();
    dispatch(AuthActions.logoutSuccess());
  } catch (error) {
    dispatch(AuthActions.logoutError(error));
  }
};
