import { ScreenSize } from '../../enums/ScreenSize';
import { TilePositions } from '../../enums/TilePositions';
import { Tile } from '../../state/boards/types';
import { isDesktopScreen } from '../isDesktopScreen';

export const getFilteredTiles = (tiles?: Tile[], activePositions?: TilePositions[]) => {
  const isDesktop = isDesktopScreen();
  if (activePositions) {
    return tiles?.filter(
      (tile) =>
        activePositions.includes(tile.position) &&
        (isDesktop
          ? tile.screenSize === ScreenSize.All || tile.screenSize === ScreenSize.Desktop
          : tile.screenSize === ScreenSize.All || tile.screenSize === ScreenSize.Tv)
    );
  }

  return tiles?.filter((tile) =>
    isDesktop
      ? tile.screenSize === ScreenSize.All || tile.screenSize === ScreenSize.Desktop
      : tile.screenSize === ScreenSize.All || tile.screenSize === ScreenSize.Tv
  );
};
