// eslint-disable-next-line import/no-cycle
import { MenuList, MenuType } from '../../state/menu/types';

export interface Breadcrumb {
  boardId?: number | null;
  tileId?: number | null;
  settingId?: number | null;
  name: string;
  url?: string;
  width: number;
  type?: MenuType;
  tiles?: BreadCrumbTile[];
  backOffice?: string;
  children?: BreadcrumbChild[];
}

export interface BreadCrumbTile {
  tileId: number | null;
  name: string;
  url?: string;
}

export interface BreadcrumbChild {
  boardId: number | null;
  name: string;
  url?: string;
  type?: MenuType;
  tiles?: BreadCrumbTile[];
  backOffice?: string;
  children?: BreadcrumbChild[];
}

export function findMenuListById(id: number, menuListArray: MenuList[] | null): any {
  return menuListArray?.reduce((previousValue, currentValue) => {
    if (previousValue) {
      return previousValue;
    }
    if (currentValue.boardId === id) {
      return currentValue;
    }
    if (currentValue.children) {
      return findMenuListById(id, currentValue.children);
    }
    return undefined;
  }, null);
}
