export enum TableFormFieldStatus {
  Undefined = 'Undefined',
  Green = 'Green',
  Red = 'Red',
  True = 'True',
  False = 'False',
  Checked = 'Checked',
  Unchecked = 'Unchecked',
  Quarter = 'Quarter',
  Half = 'Half',
  ThreeQuarters = 'ThreeQuarters',
  Full = 'Full'
}
