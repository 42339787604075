import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Board as BoardModel, Tile as TileModel } from '../../core/state/boards/types';
import { Color } from '../../core/enums/Color';

import TVScreen from '../components/MediaQueries/TVScreen';
import DesktopScreen from '../components/MediaQueries/DesktopScreen';
import Board from '../components/Board/Board';
import BoardHeader from '../components/Board/BoardHeader/BoardHeader';
import BoardContent from '../components/Board/BoardContent/BoardContent';
import SectionHeader from '../components/SectionHeader/SectionHeader';
import BoardHeaders from '../components/Board/BoardHeaders/BoardHeaders';
import ShopFloorManagementTVGrid from './ShopFloorManagementTVGrid';
import ShopFloorManagementDesktopGrid from './ShopFloorManagementDesktopGrid';
import Icon from '../../components/Icon/Icon';
import { TileTypes } from '../../core/enums/TileTypes';
import getTileDetailPageUrl from '../../core/utils/tile/getTileDetailPageUrl';

interface Props {
  board: BoardModel<TileModel>;
}

const ShopFloorManagement: React.FC<Props> = ({ board }) => {
  const { t } = useTranslation();
  const projectOverviewTile = React.useMemo(() => board.tiles.find((tile) => tile.type === TileTypes.ProjectOverviewForm), [board]);

  return (
    <Board>
      <BoardHeader text={board.name} boardType={board.type} boardId={board.boardId} />
      <BoardContent>
        <BoardHeaders>
          <div className="container-fluid">
            <div className="row">
              <div className="col-5">
                <SectionHeader text={t('mainBoard.title.general')} color={Color.Grey} />
              </div>
              <div className="col-15">
                <SectionHeader text={t('mainBoard.title.currentProjects')} color={Color.GreenLight}>
                  {projectOverviewTile && (
                    <Link to={getTileDetailPageUrl(projectOverviewTile, board.boardId)}>
                      <Icon icon="enter" />
                    </Link>
                  )}
                </SectionHeader>
              </div>
              <div className="col-5">
                <SectionHeader text={t('mainBoard.title.processConfirmation')} color={Color.GreenLight} />
              </div>
            </div>
          </div>
        </BoardHeaders>
        <TVScreen>
          <ShopFloorManagementTVGrid board={board} />
        </TVScreen>
        <DesktopScreen>
          <ShopFloorManagementDesktopGrid board={board} />
        </DesktopScreen>
      </BoardContent>
    </Board>
  );
};

export default ShopFloorManagement;
