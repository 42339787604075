import service from './RestService';
import { RestResponse } from '../types/response/RestResponse';
import { ProcessConfirmationForm } from '../state/process-confirmation/types';

const path = '/process-confirmation';

class ProcessConfirmationService {
  saveProcessConfirmationForm = (list: ProcessConfirmationForm, boardId: number): RestResponse<ProcessConfirmationForm> =>
    service.post(`${path}/area/${boardId}`, list);

  getProcessConfirmationFormByUrl = (tileUrl: string): RestResponse<ProcessConfirmationForm> => service.get(`${tileUrl}`);
}

export default new ProcessConfirmationService();
