import service from './RestService';
import { RestResponse } from '../types/response/RestResponse';
import { GoalsForm } from '../state/table-forms/types';

const path = '/goals-forms';

class GoalsFormsService {
  getGoalsFromByUrl = (url: string): RestResponse<GoalsForm> => service.get(`${url}`);

  saveGoalsForm = (list: GoalsForm): RestResponse<GoalsForm> => service.post(`${path}`, list);
}

export default new GoalsFormsService();
