import React from 'react';
import { useTranslation } from 'react-i18next';
import ConfirmPopup, { ConfirmPopupProps } from '../../../components/popup/ConfirmPopup/ConfirmPopup';
import Button from '../../../components/Button/Button';
import NavArrow from '../../../pages/components/NavArrow/NavArrow';

import './TableFormToolbar.scss';

interface NavButton {
  onClick: () => void;
  disabled?: boolean;
}

interface NavButtons {
  left: NavButton;
  right: NavButton;
}

interface Props {
  clearAll?: () => void;
  submit: (...args: any) => void;
  addNewRow?: () => void;
  navButtons?: NavButtons;
}

const TableFormToolbar: React.FC<Props> = ({ clearAll, submit, addNewRow, navButtons }) => {
  const { t } = useTranslation();
  const [confirmDialog, setConfirmDialog] = React.useState<ConfirmPopupProps>();

  return (
    <div className="table-form-toolbar d-flex justify-content-end align-items-end">
      {navButtons && (
        <NavArrow
          onClick={() => {
            navButtons?.left.onClick();
          }}
          disabled={navButtons?.left.disabled}
        />
      )}
      {navButtons && (
        <NavArrow
          onClick={() => {
            navButtons?.right.onClick();
          }}
          disabled={navButtons?.right.disabled}
          isRight
        />
      )}
      {clearAll && (
        <Button
          buttonTypes={['secondary', 'big']}
          onClick={() => {
            setConfirmDialog({
              heading: t('dialogs.confirmDialog.heading'),
              cancelText: t('dialogs.confirmDialog.cancelText'),
              confirmText: t('dialogs.confirmDialog.confirmText'),
              show: true,
              onCancel: () => {
                setConfirmDialog({ show: false });
              },
              onConfirm: () => {
                clearAll();
                setConfirmDialog({ show: false });
              }
            });
          }}
        >
          {t('buttons.clearAll')}
        </Button>
      )}

      {addNewRow && (
        <Button buttonTypes={['primary', 'big']} onClick={addNewRow}>
          {t('buttons.addNewRow')}
        </Button>
      )}

      {submit && (
        <Button buttonTypes={['primary', 'big']} onClick={submit}>
          {t('buttons.save')}
        </Button>
      )}

      <ConfirmPopup
        show={confirmDialog?.show}
        confirmText={confirmDialog?.confirmText}
        cancelText={confirmDialog?.cancelText}
        heading={confirmDialog?.heading}
        onCancel={confirmDialog?.onCancel}
        onConfirm={confirmDialog?.onConfirm}
      />
    </div>
  );
};

export default TableFormToolbar;
