import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { match, Route, useRouteMatch } from 'react-router-dom';
import { cloneDeep } from 'lodash';
import { useTranslation } from 'react-i18next';
import {
  addDays,
  addMonths,
  addYears,
  differenceInDays,
  eachWeekOfInterval,
  getDaysInMonth,
  getMonth,
  getYear,
  isSameDay,
  isSameMonth,
  isSameYear,
  isWeekend,
  lastDayOfMonth,
  setDate,
  setMonth,
  setYear,
  subMonths
} from 'date-fns';

import { BOARDS_TILE_DETAIL } from '../../core/constants/routes';
import { CheckboxListItemStatus } from '../../core/enums/CheckboxListItemStatus';
import { CheckboxTileTypes } from '../../core/enums/CheckboxTileTypes';
import { useTableForm } from '../../core/hooks/useTableForm';
import { RootState } from '../../core/state/state';
import store from '../../core/state/store';
import { Actions as SettingsActions } from '../../core/state/settings/actions';
import generateAsyncActions from '../../core/state/checkbox-list/asyncActions';
import { CheckboxAndMeasureData, CheckboxInput, CheckboxList, CheckboxListItem } from '../../core/state/checkbox-list/types';
import { getCurrentDayNumber, getCurrentWeekNumber } from '../../core/utils/getCurrentWeekNumber';
import { isDesktopScreen } from '../../core/utils/isDesktopScreen';

import ConfirmPopup, { ConfirmPopupProps } from '../../components/popup/ConfirmPopup/ConfirmPopup';
import Icon from '../../components/Icon/Icon';
import Button from '../../components/Button/Button';
import TD from '../../components/Table/TD/TD';
import TR from '../../components/Table/TR/TR';
import Table from '../../components/Table/Table';
import THead from '../../components/Table/THead/THead';
import TH from '../../components/Table/TH/TH';
import UnsavedChangesPopup from '../../components/popup/UnsavedChangesPopup/UnsavedChangesPopup';
import TableInput from '../../components/form/TableInput/TableInputHook';
import { getCheckboxListClass } from './TileCheckboxListHelpers';
import { FormStatuses } from '../../core/enums/FormStatuses';
import { getValueByKey } from '../../core/state/helpers';
import { useRowNumber } from '../../core/hooks/useRowNumber';
import { cleanStringIds } from '../../core/utils/cleanStringIds';
import NavArrow from '../../pages/components/NavArrow/NavArrow';
import Select, { SelectOption } from '../../components/form/Select/Select';
import { getNameOfTheMonth } from '../TileOccupationalSafety/helpers';
import { resetTime } from '../TileSupplySchedule/TileSupplySchedule';
import { toISODateTimeString } from '../../core/utils/formatDate';

import './TileCheckboxList.scss';

interface Props {
  url: string;
  boardId?: number;
  tileType: CheckboxTileTypes;
  getNextStatus: any;
  InfoComponent?: React.FC<any>;
}

export const START_YEAR = 2021;

export const getMonths = () => {
  const months: SelectOption[] = [];
  for (let i = 0; i < 12; i += 1) {
    const date = setMonth(new Date(), i);
    const month = getNameOfTheMonth(date);
    months.push({ label: month, value: i });
  }
  return months;
};

export const getEndYear = () => {
  return addYears(new Date(), 4);
};

export const getYears = () => {
  const years: SelectOption[] = [];
  const lastYear = getYear(getEndYear());
  for (let i = START_YEAR; i <= lastYear; i += 1) {
    years.push({ label: i.toString(), value: i });
  }
  return years;
};

export const getStartDate = () => {
  return setDate(resetTime(new Date()), 1);
};

const TileAttendanceList: React.FC<Props> = ({ url, boardId, tileType, getNextStatus, InfoComponent }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const isDetailPage = useRouteMatch(BOARDS_TILE_DETAIL);

  const wholeState = useSelector((state: RootState) => state[tileType].list) as CheckboxAndMeasureData;

  const list = useSelector((state: RootState) =>
    tileType === CheckboxTileTypes.Confirmation ? getValueByKey(state[tileType].list, 'attendance') : state[tileType].list
  ) as CheckboxList;

  const { isFormDirty, isFormEditing } = useSelector((state: RootState) => state.settings);
  const [date, setSelectedDate] = React.useState(getStartDate());
  const [status, setStatus] = React.useState('');
  const [confirmDialog, setConfirmDialog] = React.useState<ConfirmPopupProps>();

  const COLUMN_COUNT = 31;

  const actions = React.useMemo(() => generateAsyncActions(tileType.toUpperCase()), [tileType]);

  const nextRowNumber = useRowNumber(list);

  React.useEffect(() => {
    dispatch(actions.getListByUrl(url));

    return () => {
      setStatus('');
    };
  }, [actions, dispatch, url]);

  React.useEffect(() => {
    if (list) {
      setStatus(list.status ? list.status : FormStatuses.Undefined);
    }
  }, [list]);

  const handleIndicatorStatus = (prevStatus: CheckboxListItemStatus, rowIndex: number, oldlist: CheckboxList, day: Date) => {
    const newList = cloneDeep(oldlist);
    const itemIndex = newList.rows[rowIndex].inputs.findIndex((item) => item && isSameDay(new Date(item.date ?? ''), day));
    if (newList && newList.rows.length > 0) {
      if (itemIndex > -1) {
        newList.rows[rowIndex].inputs[itemIndex] = {
          date: toISODateTimeString(day),
          status: getNextStatus(prevStatus)
        };
      } else {
        newList.rows[rowIndex].inputs.push({
          date: toISODateTimeString(day),
          status: getNextStatus(prevStatus)
        });
      }
    }
    if (newList && newList.rows.length > 0) {
      const newWholeState = cloneDeep(wholeState);
      newWholeState.attendance = newList;
      store.dispatch<any>(actions.updateList(tileType === CheckboxTileTypes.Confirmation ? newWholeState : newList));
    }
  };

  const handleDeleteRow = (index: number, oldlist: CheckboxList) => {
    const newList = cloneDeep(oldlist);

    if (newList) {
      newList.rows.splice(index, 1);
      const newWholeState = cloneDeep(wholeState);
      newWholeState.attendance = newList;
      store.dispatch<any>(actions.updateList(tileType === CheckboxTileTypes.Confirmation ? newWholeState : newList));
    }
  };

  const addEmptyRow = (suppressDirtyStatus = false, oldlist: CheckboxList) => {
    const newList = cloneDeep(oldlist);
    const emptyRow: CheckboxListItem = {
      rowNumber: nextRowNumber(),
      firstColumn: '',
      inputs: []
    };

    if (newList) {
      newList.rows.push(emptyRow);
      const newWholeState = cloneDeep(wholeState);
      newWholeState.attendance = newList;
      store.dispatch<any>(actions.updateList(tileType === CheckboxTileTypes.Confirmation ? newWholeState : newList, true, suppressDirtyStatus));
    }
  };

  const clearAllFields = (oldlist: CheckboxList) => {
    const newList = cloneDeep(oldlist);
    if (newList) {
      newList.rows = newList.rows.map((row) => ({
        ...row,
        inputs: row.inputs.filter((item) => {
          return item.date && !(isSameMonth(new Date(item.date), date) && isSameYear(new Date(item.date), date));
        })
      }));
      const newWholeState = cloneDeep(wholeState);
      newWholeState.attendance = newList;
      store.dispatch<any>(actions.updateList(tileType === CheckboxTileTypes.Confirmation ? newWholeState : newList));
    }
  };

  const getDayNumbers = (isDetailPageActive: match | null) => {
    const cells = [];
    const currentDay = getCurrentDayNumber();
    const firstDayOfMonth = date;
    const currentMonth = getYear(getStartDate()) === getYear(date) && getMonth(getStartDate()) === getMonth(date);
    const numberOfDaysInCurrentMonth = getDaysInMonth(date);
    for (let index = 1; index <= COLUMN_COUNT; index += 1) {
      const thisDay = addDays(firstDayOfMonth, index - 1);

      cells.push(
        <TH
          key={index}
          className={`text-center ${currentMonth && index === currentDay ? 'tile-attendance-list-helpers-current-week' : ''}
           ${isWeekend(thisDay) ? 'tile-attendance-list--disabled' : ''}`}
        >
          {numberOfDaysInCurrentMonth >= index ? index : null}
        </TH>
      );
    }
    if (isDetailPageActive) {
      cells.push(<TH key="delete" />);
    }
    return <TR>{cells}</TR>;
  };

  const getWeekNumbers = (isDetailPageActive: match | null) => {
    const cells = [];
    const currentDay = date;
    const lastDay = lastDayOfMonth(date);
    let currentWeekStart = currentDay;

    const interval = eachWeekOfInterval({ start: addDays(currentDay, 1), end: lastDay }, { weekStartsOn: 1 }).filter(
      (item) => getMonth(currentDay) === getMonth(item)
    );
    let numberOfDays = 0;
    for (let index = 0; index <= interval.length; index += 1) {
      if (differenceInDays(interval[index], currentWeekStart) === 0) {
        index += 1;
      }
      let colSpan = differenceInDays(interval[index], currentWeekStart);
      const isLastWeek = index === interval.length;
      colSpan = colSpan === 0 ? 1 : colSpan;

      if (isLastWeek) {
        colSpan = 31 - numberOfDays;
      }
      numberOfDays += colSpan;
      cells.push(
        <TH colSpan={colSpan} key={index} className="text-center w-auto">
          {colSpan === 1 ? '' : 'KW '}
          {isLastWeek ? getCurrentWeekNumber(lastDay) : getCurrentWeekNumber(currentWeekStart)}
        </TH>
      );
      currentWeekStart = interval[index];
    }
    if (isDetailPageActive) {
      cells.push(<TH className="no-border" key="delete" />);
    }
    return <TR>{cells}</TR>;
  };

  const { submit, useTableValue } = useTableForm({
    data: wholeState,
    columnPropertyName: null,
    nestedTablePropertyName: tileType === CheckboxTileTypes.Confirmation ? 'attendance' : null,
    updateActions: [actions.updateList],
    submitActions: [
      actions.updateList,
      (data) => {
        const newData = cloneDeep(data);
        if (newData.measures) {
          newData.measures = cleanStringIds(data.measures);
        }
        return actions.saveList(newData, url);
      }
    ],
    boardId
  });

  const handleSubmit = () => {
    const data = {};

    submit(tileType === CheckboxTileTypes.Confirmation ? { attendance: data } : data);
  };

  const getCheckboxes = (inputs: CheckboxInput[], rowIndex: number) => {
    const container = [];
    const firstDayOfMonth = date;
    const numberOfDaysInCurrentMonth = getDaysInMonth(date);
    for (let index = 0; index < COLUMN_COUNT; index += 1) {
      const thisDay = addDays(firstDayOfMonth, index);
      const thisInput = inputs.find((item) => item && isSameDay(new Date(item.date ?? ''), thisDay));
      const thisStatus = thisInput?.status ?? CheckboxListItemStatus.Undefined;
      const isDisabled = index >= numberOfDaysInCurrentMonth || isWeekend(thisDay);
      container.push(
        <TD key={index}>
          <div
            role="button"
            aria-pressed="false"
            aria-label="indicatior"
            tabIndex={0}
            onClick={() => {
              if (!isDisabled) {
                handleIndicatorStatus(thisStatus, rowIndex, list, thisDay);
              }
            }}
            onKeyDown={() => {}}
            className={`table__indicator ${getCheckboxListClass(isDisabled ? CheckboxListItemStatus.Undefined : thisStatus)}`}
          />
        </TD>
      );
    }

    return <>{container}</>;
  };

  const getAllRows = () => {
    if (!list?.rows) {
      return [];
    }

    return list.rows.map((row, index) => (
      <TR key={row.rowNumber}>
        <TD className="active-focus">
          <TableInput type={isDetailPage ? undefined : 'small'} row={index} column="firstColumn" useTableValue={useTableValue} />
        </TD>
        {getCheckboxes(row.inputs, index)}
        {isDetailPage && (
          <TD>
            <button className="btn btn-link" onClick={() => handleDeleteRow(index, list)} title={t('buttons.deleteRow')}>
              <Icon icon="delete" svg />
              <span className="sr-only">{t('buttons.deleteRow')}</span>
            </button>
          </TD>
        )}
      </TR>
    ));
  };

  const handleStatusChange = async () => {
    await dispatch(SettingsActions.setIsFormDirty(true));
    switch (status) {
      case FormStatuses.Undefined:
        setStatus(FormStatuses.Green);
        break;
      case FormStatuses.Green:
        setStatus(FormStatuses.Red);
        break;
      case FormStatuses.Red:
        setStatus(FormStatuses.Undefined);
        break;
      default:
        setStatus(FormStatuses.Undefined);
        break;
    }
  };

  const tableClasses = () => {
    if (isDetailPage) {
      if (isDesktopScreen()) {
        return `tile-attendance-list__table-desktop-screen cal-${COLUMN_COUNT + 1}`;
      }

      return `tile-attendance-list__table-tv-screen cal-${COLUMN_COUNT + 1}`;
    }

    return 'table--small';
  };

  const isCurrentMonth = getMonth(date) === getMonth(new Date()) && getYear(date) === getYear(new Date());

  return (
    <div className={`tile-attendance-list ${isDetailPage ? '' : 'tile-attendance-list--small'}`}>
      <div className="tile-attendance-list__content">
        <div className={`${isDetailPage ? 'container-fluid my-3' : 'my-1'}`}>
          <div className={isDetailPage ? '' : 'px-1'}>
            {!isDetailPage && (
              <div className="d-flex justify-content-between">
                <div className="tile-attendance-list__title">{t(`forms.${tileType}List.title`)}</div>
                {tileType === CheckboxTileTypes.Presence && (
                  <div className="d-flex align-items-center mr-2">
                    <span className="tile-attendance-list__status-text mr-1">Status:</span>
                    <div className={`tile-attendance-list__status-indicator tile-attendance-list__status-indicator--${status.toLowerCase()}`} />
                  </div>
                )}
              </div>
            )}
            <div className="d-flex justify-content-between align-items-center">
              <Route exact path={BOARDS_TILE_DETAIL}>
                <div className="d-flex">
                  <div className="d-flex d-flex align-items-center justify-content-center tile-attendance-list__month-wrap">
                    <span className="tile-attendance-list__date-label m-2">{t('sharedText.month')}</span>
                    <Select
                      className="select--date mb-0"
                      onChange={(selectedMonth) => {
                        setSelectedDate(setMonth(date, selectedMonth));
                      }}
                      name="month"
                      id="month"
                      options={getMonths()}
                      defaultValue={getMonth(date)}
                      value={getMonth(date)}
                    />
                  </div>
                  <div className="d-flex d-flex align-items-center justify-content-center">
                    <span className="tile-attendance-list__date-label m-2">{t('sharedText.year')}</span>
                    <Select
                      className="select--date mb-0"
                      name="year"
                      id="year"
                      options={getYears()}
                      defaultValue={getYear(date)}
                      value={getYear(date)}
                      onChange={(selectedYear) => {
                        setSelectedDate(setYear(date, selectedYear));
                      }}
                    />
                  </div>
                </div>
              </Route>
              <Route exact path={BOARDS_TILE_DETAIL}>
                <div className="d-flex just-content-end align-items-center">
                  {isDetailPage && !isCurrentMonth && (
                    <Button
                      className="tile-attendance-list__today"
                      buttonTypes={['primary', 'big']}
                      onClick={() => {
                        setSelectedDate(getStartDate());
                      }}
                    >
                      Today
                    </Button>
                  )}
                  {isDetailPage && (
                    <div className="tile-attendance-list__nav">
                      <NavArrow
                        onClick={() => {
                          setSelectedDate(subMonths(date, 1));
                        }}
                        disabled={getMonth(date) === 0 && getYear(date) === START_YEAR}
                      />
                      <NavArrow
                        onClick={() => {
                          setSelectedDate(addMonths(date, 1));
                        }}
                        disabled={getMonth(date) === 11 && getYear(date) === getYear(getEndYear())}
                        isRight
                      />
                    </div>
                  )}
                  {tileType === CheckboxTileTypes.Presence && (
                    <div className="d-flex align-items-center mr-2">
                      <span className="tile-attendance-list__status-text mr-1">Status:</span>
                      <div
                        role="button"
                        aria-pressed="false"
                        aria-label="indicatior"
                        tabIndex={0}
                        onClick={handleStatusChange}
                        onKeyDown={() => {}}
                        className={`tile-attendance-list__status-indicator tile-attendance-list__status-indicator--${status.toLowerCase()}`}
                      />
                    </div>
                  )}
                  <Button
                    buttonTypes={['secondary', 'big']}
                    onClick={() => {
                      setConfirmDialog({
                        heading: t('dialogs.confirmDialog.heading'),
                        cancelText: t('dialogs.confirmDialog.cancelText'),
                        confirmText: t('dialogs.confirmDialog.confirmText'),
                        show: true,
                        onCancel: () => {
                          setConfirmDialog({ show: false });
                        },
                        onConfirm: () => {
                          clearAllFields(list);
                          setConfirmDialog({ show: false });
                        }
                      });
                    }}
                  >
                    {t('buttons.clearAll')}
                  </Button>
                  <Button buttonTypes={['primary', 'big']} className="ml-3" onClick={handleSubmit}>
                    {t('buttons.save')}
                  </Button>
                </div>
              </Route>

              {!isDetailPage && (
                <>
                  {tileType === CheckboxTileTypes.Vacation && InfoComponent && (
                    <div>
                      <InfoComponent isDetailPage={isDetailPage} />
                      <div className="tile-attendance-list__date-small mb-1 d-flex  align-items-center">
                        {date && (
                          <>
                            {t('sharedText.year')} <span className="tile-attendance-list__date-input-small mr-2">{getYear(date)}</span>
                          </>
                        )}
                      </div>
                    </div>
                  )}

                  {tileType !== CheckboxTileTypes.Vacation && InfoComponent && (
                    <div className="d-flex w-100">
                      <div className="tile-attendance-list__date-small mb-1 d-flex  align-items-center">
                        {tileType !== CheckboxTileTypes.Confirmation && (
                          <>
                            {date && (
                              <>
                                {t('sharedText.month')} <span className="tile-attendance-list__date-input-small">{getMonth(date)}</span>
                              </>
                            )}
                            {date && (
                              <>
                                {t('sharedText.year')} <span className="tile-attendance-list__date-input-small">{getYear(date)}</span>
                              </>
                            )}
                          </>
                        )}
                      </div>
                      <InfoComponent isDetailPage={isDetailPage} />
                    </div>
                  )}
                </>
              )}
            </div>
            {isDetailPage && InfoComponent && <InfoComponent isDetailPage={isDetailPage} />}
          </div>
          <div>
            <Table className={tableClasses()}>
              <THead>
                <TR>
                  <TH rowSpan={3} className="w-auto">
                    <div className="d-flex flex-column align-items-center">
                      <span className="table__title">{t(`forms.${tileType}List.tableHeader.title.attended`)}</span>
                      <span className="table__subtitle">{t(`forms.${tileType}List.tableHeader.subtitle.attended`)}</span>
                    </div>
                  </TH>
                  <TH colSpan={isDetailPage ? COLUMN_COUNT : COLUMN_COUNT} className="table__table-header text-center">
                    <span className="table__title">
                      {getNameOfTheMonth(date)} {getYear(date)}
                    </span>
                  </TH>
                  {isDetailPage && <TH className="no-border" />}
                </TR>
                {getWeekNumbers(isDetailPage)}
                {getDayNumbers(isDetailPage)}
              </THead>
              <tbody>{getAllRows()}</tbody>
            </Table>
          </div>
          <Route exact path={BOARDS_TILE_DETAIL}>
            <div className="d-flex justify-content-center my-4">
              <Button buttonTypes={['primary', 'big']} onClick={() => addEmptyRow(false, list)}>
                {t('buttons.addNewRow')}
              </Button>
            </div>
          </Route>
        </div>
      </div>
      <ConfirmPopup
        show={confirmDialog?.show}
        confirmText={confirmDialog?.confirmText}
        cancelText={confirmDialog?.cancelText}
        heading={confirmDialog?.heading}
        onCancel={confirmDialog?.onCancel}
        onConfirm={confirmDialog?.onConfirm}
      />
      {isDetailPage && <UnsavedChangesPopup showIf={isFormDirty || isFormEditing} />}
    </div>
  );
};

export default TileAttendanceList;
