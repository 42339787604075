import { escalationLevels } from '../../constants/escalationLevelsArray';
import { EscalationLevel } from '../../state/table-forms/types';

export const isEscalationDeletable = (itemLevel?: EscalationLevel | null, currentLevel?: EscalationLevel) => {
  const indexOfCurrentLevel = escalationLevels.findIndex((item) => item === currentLevel);
  const indexOfItemLevel = escalationLevels.findIndex((item) => item === itemLevel);

  const difference = indexOfItemLevel - indexOfCurrentLevel;
  return difference > -1 && difference < 2;
};
