import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';

import HistoryButton from '../BoardHeader/HistoryButton/HistoryButton';
import { HistoryButtonType } from '../../../../core/enums/HistoryButtonType';
import Icon from '../../../../components/Icon/Icon';
import BoardHeading from '../BoardHeading/BoardHeading';
import { setSelectedHistoryListItem } from '../../../../core/state/history/asyncActions';
import { history } from '../../../../core/state/history';
import { BOARDS, BOARDS_TILE_DETAIL, UNASSIGNED_BOARD } from '../../../../core/constants/routes';
import { BoardTypes } from '../../../../core/enums/BoardTypes';
import { RootState } from '../../../../core/state/state';

import './BoardHistory.scss';

interface Props {
  text?: string;
  boardId?: number;
  boardType?: BoardTypes;
}

const BoardHistory: React.FC<Props> = ({ text, boardId, boardType }) => {
  const dispatch = useDispatch();

  const historyState = useSelector((state: RootState) => state.history);
  const { list, selectedHistoryListItemIndex } = historyState;

  const isDetailPage = useRouteMatch(BOARDS_TILE_DETAIL);
  const isUnassigned = useRouteMatch(UNASSIGNED_BOARD);

  const isHistoryVisible = !isDetailPage;

  const historyActionHandler = (historyAction: HistoryButtonType) => {
    switch (historyAction) {
      case HistoryButtonType.Left:
        if (selectedHistoryListItemIndex < 9) {
          const nextSelectedIndex = selectedHistoryListItemIndex + 1;
          if (nextSelectedIndex < list.length) {
            dispatch(setSelectedHistoryListItem(nextSelectedIndex));
            history.push(`${BOARDS}/${boardId}/${boardType}/history-view/${nextSelectedIndex}`);
          }
        }
        break;
      case HistoryButtonType.Right:
        if (selectedHistoryListItemIndex > 0) {
          const nextSelectedIndex = selectedHistoryListItemIndex - 1;
          dispatch(setSelectedHistoryListItem(nextSelectedIndex));
          if (nextSelectedIndex === 0) {
            history.push(`${BOARDS}/${boardId}`);
          } else {
            history.push(`${BOARDS}/${boardId}/${boardType}/history-view/${nextSelectedIndex}`);
          }
        }
        break;
      case HistoryButtonType.BackToBoard:
        dispatch(setSelectedHistoryListItem(0));
        history.push(`${BOARDS}/${boardId}`);
        break;
      default:
        break;
    }
  };

  return (
    <div className="board-history">
      {isHistoryVisible && selectedHistoryListItemIndex > 0 && (
        <HistoryButton historyButtonType={HistoryButtonType.Left} historyActionHandler={historyActionHandler}>
          <Icon icon="historyLeft" size="full-size" />
        </HistoryButton>
      )}
      {!isUnassigned && <BoardHeading text={text} boardId={boardId} boardType={boardType} />}
      {isHistoryVisible && selectedHistoryListItemIndex > 0 && (
        <HistoryButton historyButtonType={HistoryButtonType.Right} historyActionHandler={historyActionHandler}>
          <Icon icon="historyRight" size="full-size" />
        </HistoryButton>
      )}
      {isHistoryVisible && selectedHistoryListItemIndex > 0 && (
        <HistoryButton historyButtonType={HistoryButtonType.BackToBoard} historyActionHandler={historyActionHandler}>
          <Icon icon="historyBackToBoard" size="full-size" />
        </HistoryButton>
      )}
    </div>
  );
};

export default BoardHistory;
