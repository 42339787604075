import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { RootState } from '../../../core/state/state';
import { getProcessConfirmationForm } from '../../../core/state/process-confirmation/asyncActions';
import { MultiLineDataItem, ProcessConfirmationFormColumn, ProcessConfirmationFormRow } from '../../../core/state/process-confirmation/types';
import TableCellOverflowEllipses from '../../../pages/components/TableCellOverflowEllipses/TableCellOverflowEllipses';
import Table from '../../../components/Table/Table';
import TBody from '../../../components/Table/TBody/TBody';
import TR from '../../../components/Table/TR/TR';
import TD from '../../../components/Table/TD/TD';
import Tooltip from '../../../components/Tooltip/Tooltip';

import './TileProcessConfirmationDesktopScreen.scss';

interface Props {
  url: string;
}

const TileProcessConfirmationDesktopScreen: React.FC<Props> = ({ url }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const processConfirmationForm = useSelector((state: RootState) => state.processConfirmation.processConfirmationForm);

  React.useEffect(() => {
    dispatch(getProcessConfirmationForm(url));
  }, [url, dispatch]);

  const getTooltipTextboxForStatus = (columnNumber: number) => {
    switch (columnNumber) {
      case 1:
        return t('forms.processConfirmation.tableHeader.title.projectCast');
      case 2:
        return t('forms.processConfirmation.tableHeader.title.projectHandover');
      case 3:
        return t('forms.processConfirmation.tableHeader.title.awardSchedule');
      case 4:
        return t('forms.processConfirmation.tableHeader.title.workshop');
      case 5:
        return t('forms.processConfirmation.tableHeader.title.planManager');
      case 6:
        return t('forms.processConfirmation.tableHeader.title.docma');
      case 7:
        return t('forms.processConfirmation.tableHeader.title.scheduling');
      case 8:
        return t('forms.processConfirmation.termins.pp5');
      case 9:
        return t('forms.processConfirmation.termins.pp6');
      case 10:
        return t('forms.processConfirmation.termins.pp7');
      case 11:
        return t('forms.processConfirmation.tableHeader.title.bsg');
      default:
        return '';
    }
  };

  const getTooltip = (text: string, children: any, rowNumber: number, columnNumber: number) => {
    return (
      <Tooltip placement="auto-end" key={`${rowNumber}-${columnNumber}`} text={text}>
        {children}
      </Tooltip>
    );
  };

  const getProcessConfirmationsRowForSmallScreen = (row: ProcessConfirmationFormRow) => {
    const container = [];
    container.push(
      <div key={row.rowNumber} className="d-flex mt-1">
        {row.items.map((column: ProcessConfirmationFormColumn) => {
          if (column.multiLineData && column.multiLineData?.length === 0) {
            return getTooltip(
              getTooltipTextboxForStatus(column.columnNumber),
              <div
                role="button"
                aria-pressed="false"
                aria-label="indicator"
                tabIndex={0}
                className={`tile-process-confirmation__project-status ${
                  column.status !== null && `tile-process-confirmation__project-status--${column.status.toLowerCase()}`
                }`}
              />,
              row.rowNumber,
              column.columnNumber
            );
          }
          return column.multiLineData?.map((multiLineDataItem: MultiLineDataItem) =>
            getTooltip(
              getTooltipTextboxForStatus(multiLineDataItem.columnNumber),
              <div
                role="button"
                aria-pressed="false"
                aria-label="indicator"
                tabIndex={0}
                className={`tile-process-confirmation__project-status ${
                  multiLineDataItem.status !== null && `tile-process-confirmation__project-status--${multiLineDataItem.status.toLowerCase()}`
                }`}
              />,
              row.rowNumber,
              multiLineDataItem.columnNumber
            )
          );
        })}
      </div>
    );
    return container;
  };

  return (
    <div className="tile-process-confirmation no-font-scaling">
      <Table>
        <TBody>
          {processConfirmationForm?.processConfirmations?.map((row: ProcessConfirmationFormRow) => (
            <TR key={row.rowNumber}>
              <TD>
                <TableCellOverflowEllipses content={row.projectName} />
                {getProcessConfirmationsRowForSmallScreen(row)}
              </TD>
            </TR>
          ))}
        </TBody>
      </Table>
    </div>
  );
};

export default TileProcessConfirmationDesktopScreen;
