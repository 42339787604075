import React, { useCallback, useEffect, useState } from 'react';
import AsyncSelect from 'react-select/async';
import { FormatOptionLabelMeta, Styles } from 'react-select';
import deburr from 'lodash/deburr';
import debounce from 'lodash/debounce';

import { Sizes } from '../../../core/enums/Sizes';

import './Autocomplete.scss';

export interface AutocompleteOption {
  value: string;
  label: string;
  autocompleteLabel?: string;
  data?: any;
}

interface Props {
  id: string;
  className?: string;
  defaultValue?: any;
  value?: any;
  name: string;
  options: (input: string) => Promise<AutocompleteOption[]>;
  label?: string;
  labelSize?: Sizes;
  error?: any;
  showErrorMessage?: boolean;
  onBlur?: () => void;
  onChange?: (value: any) => void;
}

const customStyles: Styles<AutocompleteOption, true> = {
  control: (provided) => ({
    ...provided,
    border: 'none',
    backgroundColor: undefined,
    boxShadow: 'none'
  }),
  valueContainer: (provided) => ({
    ...provided,
    padding: undefined
  }),
  multiValue: (provided) => ({
    ...provided,
    borderRadius: undefined,
    backgroundColor: undefined
  }),
  multiValueLabel: (provided) => ({
    ...provided,
    fontSize: '100%'
  }),
  multiValueRemove: (provided) => ({
    ...provided,
    color: undefined,
    backgroundColor: undefined,
    ':hover': {
      color: undefined,
      backgroundColor: undefined
    }
  })
};

const normalize = (input: string): string => {
  // Replace accented characters with plain equivalents
  return deburr(input.toLowerCase());
};

const replaceBetween = (origin: string, startIndex: number, endIndex: number, insertion: string) =>
  `${origin.substring(0, startIndex)}${insertion}${origin.substring(endIndex)}`;

const formatOptionLabel = (
  { label, autocompleteLabel }: AutocompleteOption,
  { inputValue, context }: FormatOptionLabelMeta<AutocompleteOption, true>
) => {
  if (context === 'value') {
    return label;
  }

  // Normalize display and search values then create array of matches
  const inputNormalized = normalize(inputValue);
  const optionLabel = autocompleteLabel ?? label;
  const optionLabelNormalized = normalize(optionLabel);
  const regExp = new RegExp(inputNormalized, 'gi');
  const matches = Array.from(optionLabelNormalized.matchAll(regExp), (match) => ({ index: match.index, value: match[0] }));

  if (!matches.length) {
    return optionLabel;
  }

  // Highlight the positions of the matches in the original string in reverse so we don't clobber indices
  let match = matches.pop();
  let highlighted = optionLabel;
  while (match) {
    const startIndex = match.index ?? 0;
    let endIndex = startIndex + match.value.length;
    let originalText = optionLabel.substring(startIndex, endIndex);

    // Special case for German ß which is normalized to 'ss' and throws off the indexing by 1
    while (normalize(originalText).length > match.value.length) {
      endIndex -= 1;
      originalText = optionLabel.substring(startIndex, endIndex);
    }

    const replacementText = `<span class="autocomplete__search-highlight">${originalText}</span>`;
    highlighted = replaceBetween(highlighted, startIndex, endIndex, replacementText);
    match = matches.pop();
  }

  // eslint-disable-next-line react/no-danger
  return <span dangerouslySetInnerHTML={{ __html: highlighted }} />;
};

const Autocomplete: React.FC<Props> = ({
  id,
  label,
  error,
  defaultValue,
  value,
  options,
  name,
  onChange,
  labelSize,
  className,
  showErrorMessage = true
}) => {
  const [inputValue, setInputValue] = useState<string>('');
  const [selectValue, setSelectValue] = useState<any>(value);

  const handleInputChange = useCallback((newValue: string) => {
    setInputValue(newValue.replace(/\s/g, ''));
  }, []);

  useEffect(() => {
    setSelectValue(value);
  }, [value]);

  const hasErrorMessage = showErrorMessage && error && typeof error === 'string';

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const loadOptions = useCallback(
    debounce((input: string, callback) => {
      options(input).then(callback);
    }, 250),
    [options]
  );

  return (
    <div className={`input ${error ? 'input--error' : ''} ${className ?? ''}`}>
      {label && (
        <label className={`input__label ${labelSize ? `input__label--${labelSize}` : ''}`} htmlFor={id}>
          {label}
        </label>
      )}
      <AsyncSelect
        id={id}
        name={name}
        className="input__autocomplete autocomplete"
        classNamePrefix="autocomplete"
        styles={customStyles}
        components={{
          ClearIndicator: () => null,
          IndicatorSeparator: () => null,
          DropdownIndicator: () => null,
          LoadingIndicator: () => null
        }}
        inputValue={inputValue}
        onInputChange={handleInputChange}
        defaultValue={defaultValue}
        value={selectValue}
        loadOptions={loadOptions}
        formatOptionLabel={formatOptionLabel}
        noOptionsMessage={() => null}
        loadingMessage={() => 'Loading...'}
        onChange={onChange}
        ignoreAccents={false}
        hideSelectedOptions
        isMulti
      />
      {hasErrorMessage && <div className="input__error">{error}</div>}
    </div>
  );
};

export default Autocomplete;
