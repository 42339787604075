import * as React from 'react';
import { useTranslation } from 'react-i18next';

const InspectionPlansQAQualityCheckHeader: React.FC<any> = () => {
  const { t } = useTranslation();
  return (
    <div className="tile-inspection-plans-qa__quality-check-header">
      <div>{t('forms.inspectionPlansQAList.column.qualityCheck')}</div>
      <div>{t('forms.inspectionPlansQAList.column.currentMonth')}</div>
    </div>
  );
};

export default InspectionPlansQAQualityCheckHeader;
