import * as React from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Toast from 'react-bootstrap/Toast';

import Icon from '../../../../components/Icon/Icon';
import { Notification as NotificationType } from '../../../../core/state/notifications/types';
import { Actions as NotificationActions } from '../../../../core/state/notifications/actions';
import { NotificationTypes } from '../../../../core/enums/NotificationTypes';

import './Notification.scss';

interface Props {
  notification: NotificationType;
}

const Notification: React.FC<Props> = ({ notification }) => {
  const { t } = useTranslation();

  const { type, message, autoHide, delay } = notification;
  const dispatch = useDispatch();
  const dismiss = () => dispatch(NotificationActions.dismissNotification(notification));

  let icon;
  switch (type) {
    case NotificationTypes.Success:
      icon = <Icon className="notification__status-icon" icon="success" svg />;
      break;

    case NotificationTypes.Error:
      icon = <Icon className="notification__status-icon" icon="exclamationError" svg />;
      break;

    default:
      icon = undefined;
  }

  if (autoHide !== false) {
    setTimeout(dismiss, delay);
  }

  return (
    <Toast className={`notification notification--${notification.type}`}>
      <Toast.Body className="notification__body">
        {icon}
        <span className="notification__content">{message}</span>
        <button className="notification__close close" type="button" data-dismiss="toast" onClick={dismiss}>
          <span aria-hidden="true">
            <Icon icon="close" size="small" svg />
          </span>
          <span className="sr-only">{t('sharedText.close')}</span>
        </button>
      </Toast.Body>
    </Toast>
  );
};

export default Notification;
