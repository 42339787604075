import { CSDay } from '../../core/state/capacity-subcontractors/types';

export type workersType = 'actual' | 'planned' | 'sum';

export const getNumberOfWorkers = (type: workersType, day?: CSDay) => {
  let sum = 0;
  switch (type) {
    case 'actual':
      return day?.actual;
    case 'planned':
      return day?.planned;
    case 'sum':
      sum = (day?.actual ?? 0) - (day?.planned ?? 0);
      return sum === 0 ? '-' : sum;
    default:
      return '-';
  }
};
