import service from './RestService';
import { RestResponse } from '../types/response/RestResponse';
import { StandingRulesForm } from '../state/table-forms/types';

const path = '/rules-of-standing';

class StandingRulesService {
  getStandingRulesFrom = (url: string): RestResponse<StandingRulesForm> => service.get(`${url}`);

  saveStandingRulesFrom = (form: StandingRulesForm): RestResponse<StandingRulesForm> => service.post(`${path}`, form);
}

export default new StandingRulesService();
