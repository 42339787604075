import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { match } from 'react-router-dom';
import TileLegendBox from '../CheckboxListTile/TileLegendBox';

interface Props {
  isDetailPage: match | null;
}

const TileAttendanceListLegend: React.FC<Props> = ({ isDetailPage }) => {
  const { t } = useTranslation();
  return (
    <TileLegendBox
      className={`${isDetailPage ? 'my-3' : 'mb-1 mt-0'}`}
      items={[
        {
          color: 'green',
          text: t('forms.attendanceList.legend.present')
        },
        {
          color: 'red',
          text: t('forms.attendanceList.legend.missing')
        },
        {
          color: 'orange',
          text: t('forms.attendanceList.legend.excused')
        }
      ]}
    />
  );
};

export default TileAttendanceListLegend;
