import React from 'react';
import { Route, useRouteMatch } from 'react-router-dom';
import { cloneDeep } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { useTableForm } from '../../core/hooks/useTableForm';
import { BOARDS_TILE_DETAIL } from '../../core/constants/routes';
import { RootState } from '../../core/state/state';
import { getWeekTopics, saveWeekTopics, updateWeekTopics } from '../../core/state/table-forms/asyncActions';
import { TableForm, TableFormRow } from '../../core/state/table-forms/types';
import { TableFormFieldStatus } from '../../core/enums/TableFormFieldStatus';

import Table from '../../components/Table/Table';
import TR from '../../components/Table/TR/TR';
import TD from '../../components/Table/TD/TD';
import THead from '../../components/Table/THead/THead';
import TH from '../../components/Table/TH/TH';
import TBody from '../../components/Table/TBody/TBody';

import TableInput from '../../components/form/TableInput/TableInputHook';

import Icon from '../../components/Icon/Icon';
import Button from '../../components/Button/Button';
import ConfirmPopup, { ConfirmPopupProps } from '../../components/popup/ConfirmPopup/ConfirmPopup';
import UnsavedChangesPopup from '../../components/popup/UnsavedChangesPopup/UnsavedChangesPopup';
import { useRowNumber } from '../../core/hooks/useRowNumber';

import './TileWeekTopics.scss';

interface Props {
  boardId?: number;
  url: string;
}

const TileWeekTopics: React.FC<Props> = ({ url, boardId }) => {
  const dispatch = useDispatch();
  const tableForm = useSelector((state: RootState) => state.tableForm.weekTopicsForm.form);
  const { isFormDirty, isFormEditing } = useSelector((state: RootState) => state.settings);
  const isDetailPage = useRouteMatch(BOARDS_TILE_DETAIL);
  const { t } = useTranslation();
  const nextRowNumber = useRowNumber(tableForm ?? null);

  const { submit, useTableValue } = useTableForm<TableForm>({
    boardId,
    data: tableForm,
    valuePropertyName: 'text',
    updateActions: [updateWeekTopics],
    submitActions: [updateWeekTopics, saveWeekTopics]
  });

  const handleSubmit = () => submit();

  React.useEffect(() => {
    dispatch(getWeekTopics(url));
  }, [url, dispatch]);

  const clearAllFields = () => {
    const newTableForm = cloneDeep(tableForm);
    if (newTableForm) {
      newTableForm.rows = [];
      dispatch(updateWeekTopics(newTableForm));
    }
  };

  const addEmptyRow = (suppressDirtyStatus = false) => {
    const newTableForm = cloneDeep(tableForm);
    const emptyRow: TableFormRow = {
      rowNumber: nextRowNumber(),
      columns: [
        {
          columnNumber: 1,
          title: t('sharedText.number'),
          status: TableFormFieldStatus.Undefined,
          text: ''
        },
        {
          columnNumber: 2,
          status: TableFormFieldStatus.Undefined,
          title: t('sharedText.description'),
          text: ''
        }
      ]
    };

    if (newTableForm) {
      newTableForm.rows.push(emptyRow);
      dispatch(updateWeekTopics(newTableForm, true, suppressDirtyStatus));
    }
  };

  const handleDeleteRow = (index: number) => {
    const newTableForm = cloneDeep(tableForm);

    if (newTableForm) {
      newTableForm.rows.splice(index, 1);
      dispatch(updateWeekTopics(newTableForm));
    }
  };

  const getAllRows = () => {
    if (tableForm && tableForm.rows.length > 0) {
      return tableForm.rows.map((tableFormItem, index) => (
        <TR key={tableFormItem.rowNumber}>
          <TD className="text-center">{index + 1}</TD>
          <TD>
            <TableInput type={isDetailPage ? undefined : 'small'} row={index} column={1} useTableValue={useTableValue} />
          </TD>
          {isDetailPage && (
            <TD className="text-center shrink">
              <button title={t('buttons.deleteRow')} className="btn btn-link" onClick={() => handleDeleteRow(index)}>
                <Icon icon="delete" svg />
                <span className="sr-only">{t('buttons.deleteRow')}</span>
              </button>
            </TD>
          )}
        </TR>
      ));
    }
    return null;
  };

  const [confirmDialog, setConfirmDialog] = React.useState<ConfirmPopupProps>();

  return (
    <div className={`tile-week-topics ${isDetailPage ? '' : 'tile-week-topics--small'}`}>
      <div className={`${isDetailPage ? 'container-fluid' : ''}`}>
        <div className={isDetailPage ? '' : 'px-1 pt-1'}>
          {!isDetailPage && <div className="tile-week-topics__title">{t('forms.weekTopics.title')}</div>}
          <div className="d-flex justify-content-between align-items-center">
            <div className="tile-week-topics__sub-title">{t('forms.weekTopics.subtitle')}</div>
            <Route exact path={BOARDS_TILE_DETAIL}>
              <div className="my-4 d-flex justify-content-end align-items-end">
                <Button
                  buttonTypes={['secondary', 'big']}
                  onClick={() => {
                    setConfirmDialog({
                      heading: t('dialogs.confirmDialog.heading'),
                      cancelText: t('dialogs.confirmDialog.cancelText'),
                      confirmText: t('dialogs.confirmDialog.confirmText'),
                      show: true,
                      onCancel: () => {
                        setConfirmDialog({ show: false });
                      },
                      onConfirm: () => {
                        clearAllFields();
                        setConfirmDialog({ show: false });
                      }
                    });
                  }}
                >
                  {t('buttons.clearAll')}
                </Button>
                <Button buttonTypes={['primary', 'big']} className="ml-3" onClick={handleSubmit}>
                  {t('buttons.save')}
                </Button>
              </div>
            </Route>
          </div>
        </div>
        <div>
          <Table className={isDetailPage ? 'tile-week-topics__table' : 'tile-week-topics__table table--small'}>
            <THead>
              <TR>
                <TH className="first-cell">
                  <div className="table__title">{t('sharedText.number')}</div>
                </TH>
                <TH colSpan={isDetailPage ? 2 : undefined} className="table__table-header">
                  <div className={`table__title ${isDetailPage ? '' : 'text-left'}`}>{t('sharedText.description')}</div>
                </TH>
              </TR>
            </THead>
            <TBody>{getAllRows()}</TBody>
          </Table>
        </div>
        <Route exact path={BOARDS_TILE_DETAIL}>
          <div className="d-flex justify-content-center my-4">
            <Button buttonTypes={['primary', 'big']} onClick={() => addEmptyRow()}>
              {t('buttons.addNewRow')}
            </Button>
          </div>
        </Route>
      </div>
      <div className="container-fluid">
        <div />
      </div>
      <ConfirmPopup
        show={confirmDialog?.show}
        confirmText={confirmDialog?.confirmText}
        cancelText={confirmDialog?.cancelText}
        heading={confirmDialog?.heading}
        onCancel={confirmDialog?.onCancel}
        onConfirm={confirmDialog?.onConfirm}
      />
      {isDetailPage && <UnsavedChangesPopup showIf={isFormDirty || isFormEditing} />}
    </div>
  );
};

export default TileWeekTopics;
