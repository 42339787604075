import * as React from 'react';
import { useDispatch } from 'react-redux';
import NavigationPrompt from 'react-router-navigation-prompt';
import { useTranslation } from 'react-i18next';

import ConfirmPopup from '../ConfirmPopup/ConfirmPopup';
import { Actions as SettingsActions } from '../../../core/state/settings/actions';

interface Props {
  showIf: boolean;
}

const UnsavedChangesPopup: React.FC<Props> = ({ showIf = false }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  return (
    <NavigationPrompt when={showIf}>
      {({ onConfirm, onCancel }) => (
        <ConfirmPopup
          confirmText={t('dialogs.unsavedChangesDialog.confirmText')}
          cancelText={t('dialogs.unsavedChangesDialog.cancelText')}
          heading={t('dialogs.unsavedChangesDialog.heading')}
          onCancel={onCancel}
          onConfirm={() => {
            onConfirm();
            dispatch(SettingsActions.setIsFormDirty(false));
          }}
          show
        />
      )}
    </NavigationPrompt>
  );
};

export default UnsavedChangesPopup;
