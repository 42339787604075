import ActionTypes from '../actionTypes';
import Actions from '../Actions';
import { HistoryState, HistoryList } from './types';
import { StateStatus } from '../../enums/StateStatus';

export const defaultHistoryState: HistoryState = {
  list: [],
  status: StateStatus.Initial,
  selectedHistoryListItemIndex: 0,
  error: null
};

function sortByDateDesc(a: HistoryList, b: HistoryList) {
  const dateA = new Date(a.historyDate).getTime();
  const dateB = new Date(b.historyDate).getTime();
  if (dateA > dateB) return -1;
  if (dateA < dateB) return 1;
  return 0;
}

const historyScreenshotList = (list: HistoryList[]) => {
  const historyList = [...list];
  if (historyList.length === 0) {
    return historyList;
  }
  const todayItem: HistoryList = {
    screenshotId: 0,
    downloadLink: '',
    historyDate: new Date()
  };
  historyList.unshift(todayItem);
  historyList.sort(sortByDateDesc);
  return historyList.slice(0, 10);
};

export default function reducer(state: HistoryState = defaultHistoryState, action: Actions): HistoryState {
  switch (action.type) {
    case ActionTypes.GET_HISTORY_LIST:
      return { ...state, status: StateStatus.Busy };
    case ActionTypes.GET_HISTORY_LIST_SUCCESS:
      return { ...state, status: StateStatus.Loaded, list: historyScreenshotList(action.payload) };
    case ActionTypes.GET_HISTORY_LIST_ERROR:
      return { ...state, status: StateStatus.Error, error: action.payload };
    case ActionTypes.SET_SELECTED_HISTORY_LIST_ITEM_INDEX:
      return { ...state, status: StateStatus.Loaded, selectedHistoryListItemIndex: action.payload };
    default:
      return state;
  }
}
