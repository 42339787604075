import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { cloneDeep } from 'lodash';

import { RootState } from '../../core/state/state';
import { BOARDS_TILE_DETAIL } from '../../core/constants/routes';
import { generateAsyncActions, saveTableFormMeasure } from '../../core/state/table-forms/asyncActions';
import { TileTypes } from '../../core/enums/TileTypes';
import { useTableForm } from '../../core/hooks/useTableForm';
import { useRowNumber } from '../../core/hooks/useRowNumber';

import { ColumnType, MeasureAndEscalation, TableForm, TableFormRow, TableFormState } from '../../core/state/table-forms/types';
import { Sizes } from '../../core/enums/Sizes';
import { FormStatuses } from '../../core/enums/FormStatuses';
import { TableFormFieldStatus } from '../../core/enums/TableFormFieldStatus';
import ButtonToolbar from '../../pages/components/ButtonToolbar/ButtonToolbar';

import StatusRed from '../../pages/components/StatusRed/StatusRed';
import FormStatus from '../../pages/components/FormStatus/FormStatus';
import UnsavedChangesPopup from '../../components/popup/UnsavedChangesPopup/UnsavedChangesPopup';
import Table from '../../components/Table/Table';
import THead from '../../components/Table/THead/THead';
import TR from '../../components/Table/TR/TR';
import TH from '../../components/Table/TH/TH';
import TBody from '../../components/Table/TBody/TBody';
import TD from '../../components/Table/TD/TD';
import Button from '../../components/Button/Button';
import Icon from '../../components/Icon/Icon';
import TableInput from '../../components/form/TableInput/TableInputHook';
import Calendar from '../../components/form/Calendar/CalendarHook';
import MeasureIndicator from '../TileMeasure/TileMeasureIndicator/MeasureIndicatorHook';
import MeasureEscalationModal from '../../pages/components/MeasureEscalationModal/MeasureEscalationModal';
import { getMeasureInfo, MeasureViewModel } from '../../core/utils/measures/getMeasureInfo';
import { useMeasureTableFormChange } from '../../core/hooks/measure/useMeasureTableFormChange';
import { getUpdateTableFormWithMeasure } from '../../core/utils/measures/getUpdatedTableFromWithMeasure';
import { isColumnMeasure } from '../../core/utils/measures/isColumnMeasure';

import './TableFormMeasuresTile.scss';

export enum ColumnInputTypes {
  Index,
  Text,
  Calendar, // eslint-disable-line @typescript-eslint/no-shadow
  Label,
  Status,
  PlainText
}

interface HeaderDefinition {
  title: string;
  subtitle?: string;
  tileSpan?: number;
  detailSpan?: number;
  className?: string;
}

interface ColumnDefinition {
  inputType: ColumnInputTypes;
  columnType?: ColumnType;
  title: string;
  dataColumnIndex?: number;
  rowSpan?: number;
  className?: string;
}

interface Props {
  boardId?: number;
  url: string;
  stateKey: keyof RootState;
  tileType: TileTypes;
  title: string;
  subtitle1: string;
  subtitle2?: string;
  headers: HeaderDefinition[];
  columns: ColumnDefinition[][];
  showStatus?: boolean;
  statusText?: string;
}

const TableFormMeasuresTile: React.FC<Props> = ({
  boardId,
  url,
  stateKey,
  tileType,
  headers,
  columns,
  title,
  subtitle1,
  subtitle2,
  showStatus = false,
  statusText
}) => {
  const dispatch = useDispatch();
  const tableForm = useSelector((state: RootState) => (state[stateKey] as TableFormState<TableForm>).form);
  const { isFormDirty, isFormEditing } = useSelector((state: RootState) => state.settings);
  const isDetailPage = useRouteMatch(BOARDS_TILE_DETAIL);
  const { t } = useTranslation();
  const [isModalOpen, setModalOpen] = useState<boolean>(false);
  const [measuresArray, setMeasuresArray] = useState<MeasureViewModel[]>([]);
  const [measuresRow, setMeasuresRow] = useState(-1);
  const selectedMeasure = getMeasureInfo(tableForm, measuresRow);

  useMeasureTableFormChange(measuresArray, setMeasuresArray, tableForm);

  const { getTableForm, updateTableForm, saveTableForm } = React.useMemo(() => generateAsyncActions(tileType), [tileType]);

  const { submit, useTableValue } = useTableForm<TableForm>({
    boardId,
    data: tableForm,
    valuePropertyName: 'text',
    updateActions: [updateTableForm],
    submitActions: [updateTableForm, saveTableForm]
  });

  const { useTableValue: useTableStatus } = useTableForm<TableForm>({
    boardId,
    data: tableForm,
    valuePropertyName: 'status',
    updateActions: [updateTableForm],
    submitActions: [updateTableForm, saveTableForm]
  });

  const nextRowNumber = useRowNumber(tableForm);

  const handleSubmit = async () => {
    for (let i = 0; i < measuresArray.length; i += 1) {
      const item = measuresArray[i];
      if (item.measureURL) {
        // eslint-disable-next-line no-await-in-loop
        await dispatch(saveTableFormMeasure(item.measure, item.measureURL));
      }
    }
    submit();
  };

  React.useEffect(() => {
    dispatch(getTableForm(url));
  }, [url, dispatch, getTableForm]);

  const handleAddMeasure = async (data: MeasureAndEscalation) => {
    const newMeasuresArray = cloneDeep(measuresArray);
    const newTableForm = getUpdateTableFormWithMeasure(tableForm, data.measure, selectedMeasure?.measureURL);
    if (data.measure) {
      newMeasuresArray.push({
        measure: data.measure,
        measureURL: selectedMeasure?.measureURL
      });
    }

    if (newTableForm) {
      await dispatch(updateTableForm(newTableForm));
    }

    setMeasuresArray(newMeasuresArray);
    setModalOpen(false);
  };

  const clearAllFields = () => {
    if (tableForm) {
      const newTableForm = cloneDeep(tableForm);
      newTableForm.rows = [];
      dispatch(updateTableForm(newTableForm));
    }
  };

  const addEmptyRow = useCallback(
    (suppressDirtyStatus = false) => {
      if (tableForm) {
        const newTableForm = cloneDeep(tableForm);
        newTableForm.rows.push({
          rowNumber: nextRowNumber(),
          columns: columns
            .flat()
            .filter((column) =>
              [ColumnInputTypes.Text, ColumnInputTypes.Calendar, ColumnInputTypes.Status, ColumnInputTypes.PlainText].includes(column.inputType)
            )
            // eslint-disable-next-line @typescript-eslint/no-shadow
            .map(({ dataColumnIndex, title, columnType }) => ({
              columnNumber: (dataColumnIndex ?? 0) + 1,
              title: title ?? '',
              type: columnType,
              text: '',
              status: TableFormFieldStatus.Undefined
            }))
        });
        dispatch(updateTableForm(newTableForm, true, suppressDirtyStatus));
      }
    },
    [columns, dispatch, nextRowNumber, tableForm, updateTableForm]
  );

  const handleDeleteRow = (index: number) => {
    if (tableForm) {
      const newTableForm = cloneDeep(tableForm);
      newTableForm.rows.splice(index, 1);
      dispatch(updateTableForm(newTableForm));
    }
  };

  const getHeaders = () => {
    return (
      <TR>
        {/* eslint-disable-next-line @typescript-eslint/no-shadow */}
        {headers.map(({ title, subtitle, detailSpan, tileSpan, className }, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <TH key={index} colSpan={isDetailPage ? detailSpan ?? 1 : tileSpan ?? 1} className={`table__table-header text-center ${className}`}>
            <div className="table__title">{title}</div>
            {subtitle && <div className="table__subtitle">{subtitle}</div>}
          </TH>
        ))}
        {isDetailPage && (
          <TH className="w-5" colSpan={2}>
            <div className="table__title text-center">{t('sharedText.tableForm.actions')}</div>
          </TH>
        )}
      </TR>
    );
  };

  const getCell = (column: ColumnDefinition, row: TableFormRow, rowIndex: number, key: any) => {
    const columnIndex = column.dataColumnIndex ?? 0;
    let content: any;
    switch (column.inputType) {
      case ColumnInputTypes.Index:
        content = rowIndex + 1;
        break;
      case ColumnInputTypes.Text:
        content = <TableInput reInitDefaultValue row={rowIndex} column={columnIndex} useTableValue={useTableValue} />;
        break;
      case ColumnInputTypes.Calendar:
        content = <Calendar reInitDefaultValue row={rowIndex} column={columnIndex} useTableValue={useTableValue} />;
        break;
      case ColumnInputTypes.Status:
        content = (
          <MeasureIndicator
            row={rowIndex}
            column={columnIndex}
            useTableValue={useTableStatus}
            size={isDetailPage ? 'big' : 'small'}
            statuses={[
              TableFormFieldStatus.Undefined,
              TableFormFieldStatus.Quarter,
              TableFormFieldStatus.Half,
              TableFormFieldStatus.ThreeQuarters,
              TableFormFieldStatus.Full
            ]}
          />
        );
        break;
      case ColumnInputTypes.PlainText:
        content = <TableInput row={rowIndex} column={columnIndex} useTableValue={useTableValue} isText />;
        break;
      case ColumnInputTypes.Label:
      default:
        content = column.title ?? '';
    }
    const isDisabled = row.isMeasureEditable !== undefined && !row.isMeasureEditable && isColumnMeasure(column.columnType);
    return (
      <TD key={key} className={`${column.className ?? ''}${isDisabled ? ' table-item-disabled' : ''}`} rowSpan={column.rowSpan}>
        {content}
      </TD>
    );
  };

  const getRows = () => {
    if (!tableForm || tableForm.rows.length === 0) {
      return <></>;
    }

    return tableForm.rows.map((row, rowIndex) =>
      columns.map((columnDefGroups, colDefGroupIndex) => (
        // eslint-disable-next-line react/no-array-index-key
        <TR key={`${row.rowNumber}_${colDefGroupIndex}`}>
          {columnDefGroups.map((column, colDefIndex) => getCell(column, row, rowIndex, colDefIndex))}
          {isDetailPage && colDefGroupIndex === 0 && (
            <TD className={`text-center ${row.isMeasureEditable !== undefined && !row.isMeasureEditable ? 'table-item-disabled' : ''}`}>
              <button
                disabled={!row.canCreateMeasure || !!measuresArray.find((item) => item.measureURL === row.measureURL) || !row.isMeasureEditable}
                title={t('buttons.addMeasure')}
                className="btn btn-link"
                onClick={() => {
                  setMeasuresRow(rowIndex);
                  setModalOpen(true);
                }}
              >
                <Icon className="icon--table-plus" icon="plus" svg />
                <span className="sr-only">{t('buttons.addMeasure')}</span>
              </button>
            </TD>
          )}
          {isDetailPage && colDefGroupIndex === 0 && (
            <TD className="text-center">
              <button title={t('buttons.deleteRow')} className="btn btn-link" onClick={() => handleDeleteRow(rowIndex)}>
                <Icon icon="delete" svg />
                <span className="sr-only">{t('buttons.deleteRow')}</span>
              </button>
            </TD>
          )}
        </TR>
      ))
    );
  };

  React.useEffect(() => {
    if (tableForm?.rows.length === 0) {
      addEmptyRow(true);
    }
  }, [addEmptyRow, tableForm]);

  return (
    <div className={`tile-table-form-measures ${isDetailPage ? '' : 'tile-table-form-measures--small'}`}>
      <div className={`${isDetailPage ? 'container-fluid' : ''}`}>
        <div className={isDetailPage ? '' : 'px-1 pt-1'}>
          {!isDetailPage && <div className="tile-table-form-measures__title">{title}</div>}
          <div className="d-flex justify-content-center align-items-center">
            <div className="tile-table-form-measures__sub-titles">
              <div className="tile-table-form-measures__sub-title">{subtitle1}</div>
              {subtitle2 && <div className="tile-table-form-measures__sub-title bold">{subtitle2}</div>}
            </div>
            <div className="ml-auto d-flex">
              {showStatus && (
                <FormStatus
                  tableForm={tableForm}
                  updateTableForm={updateTableForm}
                  validStatuses={[FormStatuses.Green, FormStatuses.Red]}
                  isSmall={!isDetailPage}
                />
              )}
              {isDetailPage && <ButtonToolbar submit={handleSubmit} clearAll={clearAllFields} />}
            </div>
          </div>
          {showStatus && statusText && (
            <div className="tile-table-form-measures__status-text">
              <StatusRed condition={statusText} size={isDetailPage ? Sizes.Medium : Sizes.Small} />
            </div>
          )}
        </div>
        <Table className={isDetailPage ? 'tile-table-form-measures__table' : 'tile-table-form-measures__table table--small'}>
          <THead>{getHeaders()}</THead>
          <TBody>{getRows()}</TBody>
        </Table>
        {isDetailPage && (
          <div className="d-flex justify-content-center my-4">
            <Button buttonTypes={['primary', 'big']} onClick={() => addEmptyRow()}>
              {t('buttons.addNewRow')}
            </Button>
          </div>
        )}
      </div>
      <MeasureEscalationModal
        KVPText={{
          problem: tableForm?.title ?? '',
          measure: selectedMeasure?.measure?.measureText ?? '',
          assignedTo: selectedMeasure?.measure.assignedTo ?? '',
          deadline: selectedMeasure?.measure.deadline ?? null,
          status: selectedMeasure?.measure.status
        }}
        onConfirm={handleAddMeasure}
        onCancel={() => setModalOpen(false)}
        show={isModalOpen}
        hasOnlyMeasure
      />
      {isDetailPage && <UnsavedChangesPopup showIf={isFormDirty || isFormEditing} />}
    </div>
  );
};
export default TableFormMeasuresTile;
