import { ReduxThunkType } from '../../types/ReduxThunkType';
import { spawnSaveSuccessNotification } from '../notifications/asyncActions';
import ProcessConfirmationService from '../../services/ProcessConfirmationService';
import { Actions as SettingsActions } from '../settings/actions';
import { Actions as ProcessConfirmatioFormnActions } from './actions';
import { ProcessConfirmationForm } from './types';

export const getProcessConfirmationForm = (tileUrl: string): ReduxThunkType<void> => async (dispatch) => {
  try {
    dispatch(ProcessConfirmatioFormnActions.getProcessConfirmationForm());
    const response = await ProcessConfirmationService.getProcessConfirmationFormByUrl(tileUrl);
    dispatch(ProcessConfirmatioFormnActions.getProcessConfirmationFormSuccess(response.data));
  } catch (error) {
    dispatch(ProcessConfirmatioFormnActions.getProcessConfirmationFormError(error));
  }
};

export const saveProcessConfirmationForm = (processConfirmationForm: ProcessConfirmationForm, boardId: number): ReduxThunkType<void> => async (
  dispatch
) => {
  try {
    dispatch(ProcessConfirmatioFormnActions.saveProcessConfirmationForm());
    const response = await ProcessConfirmationService.saveProcessConfirmationForm(processConfirmationForm, boardId);
    await dispatch(SettingsActions.setIsFormDirty(false));
    dispatch(ProcessConfirmatioFormnActions.saveProcessConfirmationFormSuccess(response.data));
    dispatch(spawnSaveSuccessNotification());
  } catch (error) {
    dispatch(ProcessConfirmatioFormnActions.saveProcessConfirmationFormError(error));
  }
};

export const updateProcessConfirmationForm = (
  tableForm: ProcessConfirmationForm,
  isFormDirty = true,
  suppressDirtyStatus = false
): ReduxThunkType<void> => async (dispatch) => {
  if (!suppressDirtyStatus) {
    await dispatch(SettingsActions.setIsFormDirty(isFormDirty));
  }
  await dispatch(ProcessConfirmatioFormnActions.updateProcessConfirmationForm(tableForm));
};
