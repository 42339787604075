import TableFormsService from '../../services/TableFormsService';
import GoalsFormsService from '../../services/GoalsFormsService';
import { ReduxThunkType } from '../../types/ReduxThunkType';
import { Actions as TableFormActions, generateActions } from './actions';
import {
  DualTableForm,
  GoalsForm,
  MeasureAndEscalation,
  MeasureAndEscalationForm,
  MeasureDetail,
  ProjectOverviewForm,
  StandingRulesForm,
  TableForm
} from './types';
import { spawnSaveSuccessNotification } from '../notifications/asyncActions';
import { Actions as SettingsActions } from '../settings/actions';
import StandingRulesService from '../../services/StandingRulesService';
import MeasuresEscalationsService from '../../services/MeasuresEscalationsService';
import ProjectOverviewService from '../../services/ProjectOverviewService';

export function generateAsyncActions(type: string) {
  const Actions = generateActions(type);

  return {
    getTableForm: (tileUrl: string): ReduxThunkType<void> => async (dispatch) => {
      try {
        dispatch(Actions.getTableForm());
        const response = await TableFormsService.getTableFormByUrlGeneric(tileUrl);
        dispatch(Actions.getTableFormSuccess(response.data));
      } catch (error) {
        dispatch(Actions.getTableFormError(error));
      }
    },

    updateTableForm: (tableForm: TableForm | DualTableForm, isFormDirty = true, suppressDirtyStatus = false): ReduxThunkType<void> => async (
      dispatch
    ) => {
      if (!suppressDirtyStatus) {
        await dispatch(SettingsActions.setIsFormDirty(isFormDirty));
      }
      await dispatch(Actions.updateTableForm(tableForm));
    },

    saveTableForm: (tableForm: TableForm | DualTableForm): ReduxThunkType<void> => async (dispatch) => {
      try {
        dispatch(Actions.saveTableForm());
        const response = await TableFormsService.saveTableFormGeneric(tableForm);
        await dispatch(SettingsActions.setIsFormDirty(false));
        dispatch(Actions.saveTableFormSuccess(response.data));
        dispatch(spawnSaveSuccessNotification());
      } catch (error) {
        dispatch(Actions.saveTableFormError(error));
      }
    }
  };
}

export const getTableForm = (tileUrl: string): ReduxThunkType<void> => async (dispatch) => {
  try {
    dispatch(TableFormActions.getTableForm());
    const response = await TableFormsService.getTableFormByUrl(tileUrl);
    dispatch(TableFormActions.getTableFormSuccess(response.data));
  } catch (error) {
    dispatch(TableFormActions.getTableFormError(error));
  }
};

export const saveTableForm = (list: TableForm): ReduxThunkType<void> => async (dispatch) => {
  try {
    dispatch(TableFormActions.saveTableForm());
    const response = await TableFormsService.saveTableForm(list);
    await dispatch(SettingsActions.setIsFormDirty(false));
    dispatch(TableFormActions.saveTableFormSuccess(response.data));
    dispatch(spawnSaveSuccessNotification());
  } catch (error) {
    dispatch(TableFormActions.saveTableFormError(error));
  }
};

export const saveTableFormMeasure = (measure: MeasureDetail, url: string): ReduxThunkType<void> => async (dispatch) => {
  try {
    dispatch(TableFormActions.saveTableFormMeasure());
    await TableFormsService.saveTableFormMeasure(measure, url);
    dispatch(TableFormActions.saveTableFormMeasureSuccess());
  } catch (error) {
    dispatch(TableFormActions.saveTableFormMeasureError(error));
  }
};

export const getKVPForm = (tileUrl: string): ReduxThunkType<void> => async (dispatch) => {
  try {
    dispatch(TableFormActions.getKVPForm());
    const response = await TableFormsService.getTableFormByUrl(tileUrl);
    dispatch(TableFormActions.getKVPFormSuccess(response.data));
  } catch (error) {
    dispatch(TableFormActions.getKVPFormError(error));
  }
};

export const saveKVPForm = (kvpForm: TableForm): ReduxThunkType<void> => async (dispatch) => {
  try {
    dispatch(TableFormActions.saveKVPForm());
    const response = await TableFormsService.saveTableForm(kvpForm);
    await dispatch(SettingsActions.setIsFormDirty(false));
    dispatch(TableFormActions.saveKVPFormSuccess(response.data));
    dispatch(spawnSaveSuccessNotification());
  } catch (error) {
    dispatch(TableFormActions.saveKVPFormError(error));
  }
};

export const getProjectOverviewForm = (tileUrl: string): ReduxThunkType<void> => async (dispatch) => {
  try {
    dispatch(TableFormActions.getProjectOverviewForm());
    const response = await TableFormsService.getTableFormByUrl(tileUrl);
    dispatch(TableFormActions.getProjectOverviewFormSuccess(response.data));
  } catch (error) {
    dispatch(TableFormActions.getProjectOverviewFormError(error));
  }
};

export const saveProjectOverviewForm = (projectOverviewForm: ProjectOverviewForm, url: string): ReduxThunkType<void> => async (
  dispatch,
  getState
) => {
  try {
    dispatch(TableFormActions.saveProjectOverviewForm());
    const response = await ProjectOverviewService.saveProjectOverviewForm(projectOverviewForm, url);
    await dispatch(SettingsActions.setIsFormDirty(false));
    dispatch(TableFormActions.saveProjectOverviewFormSuccess(response.data));
    const state = getState();
    if (!state.notifications.notifications.length) {
      dispatch(spawnSaveSuccessNotification());
    }
  } catch (error) {
    dispatch(TableFormActions.saveProjectOverviewFormError(error));
  }
};

export const getGoalsForm = (url: string): ReduxThunkType<void> => async (dispatch) => {
  try {
    dispatch(TableFormActions.getGoalsForm());
    const response = await GoalsFormsService.getGoalsFromByUrl(url);
    dispatch(TableFormActions.getGoalsFormSuccess(response.data));
  } catch (error) {
    dispatch(TableFormActions.getGoalsFormError(error));
  }
};

export const saveGoalsForm = (list: GoalsForm): ReduxThunkType<void> => async (dispatch) => {
  try {
    dispatch(TableFormActions.saveGoalsForm());
    const response = await GoalsFormsService.saveGoalsForm(list);
    dispatch(TableFormActions.saveGoalsFormSuccess(response.data));
    await dispatch(SettingsActions.setIsFormDirty(false));
    dispatch(spawnSaveSuccessNotification());
  } catch (error) {
    dispatch(TableFormActions.saveGoalsFormError(error));
  }
};

export const getSickLeaveForm = (tileUrl: string): ReduxThunkType<void> => async (dispatch) => {
  try {
    dispatch(TableFormActions.getSickLeaveForm());
    const response = await TableFormsService.getTableFormByUrl(tileUrl);
    dispatch(TableFormActions.getSickLeaveFormSuccess(response.data));
  } catch (error) {
    dispatch(TableFormActions.getSickLeaveFormError(error));
  }
};

export const saveSickLeaveForm = (sickLeaveForm: TableForm): ReduxThunkType<void> => async (dispatch) => {
  try {
    dispatch(TableFormActions.saveSickLeaveForm());
    const response = await TableFormsService.saveTableForm(sickLeaveForm);
    await dispatch(SettingsActions.setIsFormDirty(false));
    dispatch(TableFormActions.saveSickLeaveFormSuccess(response.data));
    dispatch(spawnSaveSuccessNotification());
  } catch (error) {
    dispatch(TableFormActions.saveSickLeaveFormError(error));
  }
};

export const getWeekTopics = (tileUrl: string): ReduxThunkType<void> => async (dispatch) => {
  try {
    dispatch(TableFormActions.getWeekTopics());
    const response = await TableFormsService.getTableFormByUrl(tileUrl);
    dispatch(TableFormActions.getWeekTopicsSuccess(response.data));
  } catch (error) {
    dispatch(TableFormActions.getWeekTopicsError(error));
  }
};

export const saveWeekTopics = (weekTopics: TableForm): ReduxThunkType<void> => async (dispatch) => {
  try {
    dispatch(TableFormActions.saveWeekTopics());
    const response = await TableFormsService.saveTableForm(weekTopics);
    await dispatch(SettingsActions.setIsFormDirty(false));
    dispatch(TableFormActions.saveWeekTopicsSuccess(response.data));
    dispatch(spawnSaveSuccessNotification());
  } catch (error) {
    dispatch(TableFormActions.saveWeekTopicsError(error));
  }
};

export const getStandingRules = (url: string): ReduxThunkType<void> => async (dispatch) => {
  try {
    dispatch(TableFormActions.getStandingRules());
    const response = await StandingRulesService.getStandingRulesFrom(url);
    dispatch(TableFormActions.getStandingRulesSuccess(response.data));
  } catch (error) {
    dispatch(TableFormActions.getStandingRulesError(error));
  }
};

export const saveStandingRules = (form: StandingRulesForm): ReduxThunkType<void> => async (dispatch) => {
  try {
    dispatch(TableFormActions.saveStandingRules());
    const response = await StandingRulesService.saveStandingRulesFrom(form);
    dispatch(TableFormActions.saveStandingRulesSuccess(response.data));
    await dispatch(SettingsActions.setIsFormDirty(false));
    dispatch(spawnSaveSuccessNotification());
  } catch (error) {
    dispatch(TableFormActions.saveStandingRulesError(error));
  }
};

export const createMeasureAndEscalation = (measuresUrl: string, measure: MeasureAndEscalation): ReduxThunkType<void> => async (dispatch) => {
  try {
    dispatch(TableFormActions.saveMeasures());
    await MeasuresEscalationsService.createMeasureAndEscalation(measuresUrl, measure);
    dispatch(TableFormActions.saveMeasuresSuccess());
    dispatch(spawnSaveSuccessNotification());
  } catch (error) {
    dispatch(TableFormActions.saveMeasuresError(error));
  }
};

export const getMeasuresAndEscalations = (url: string): ReduxThunkType<void> => async (dispatch) => {
  try {
    dispatch(TableFormActions.getMeasuresAndEscalations());
    const response = await MeasuresEscalationsService.getMeasuresAndEscalations(url);
    dispatch(TableFormActions.getMeasuresAndEscalationsSuccess(response.data));
  } catch (error) {
    dispatch(TableFormActions.getMeasuresAndEscalationsError(error));
  }
};

export const updateMeasuresAndEscalations = (
  measureAndEscalationForm: MeasureAndEscalationForm,
  isFormDirty = true,
  suppressDirtyStatus = false
): ReduxThunkType<void> => async (dispatch) => {
  if (!suppressDirtyStatus) {
    await dispatch(SettingsActions.setIsFormDirty(isFormDirty));
  }
  await dispatch(TableFormActions.updateMeasuresAndEscalations(measureAndEscalationForm));
};

export const saveMeasuresAndEscalations = (measureAndEscalationForm: MeasureAndEscalationForm, url: string): ReduxThunkType<void> => async (
  dispatch
) => {
  try {
    dispatch(TableFormActions.saveMeasuresAndEscalations());
    const response = await MeasuresEscalationsService.saveMeasuresAndEscalationsForm(url, measureAndEscalationForm);
    await dispatch(SettingsActions.setIsFormDirty(false));
    dispatch(TableFormActions.saveMeasuresAndEscalationsSuccess(response.data));
    dispatch(spawnSaveSuccessNotification());
  } catch (error) {
    dispatch(TableFormActions.saveWeekTopicsError(error));
  }
};

export const updateKVPForm = (tableForm: TableForm, isFormDirty = true, suppressDirtyStatus = false): ReduxThunkType<void> => async (dispatch) => {
  if (!suppressDirtyStatus) {
    await dispatch(SettingsActions.setIsFormDirty(isFormDirty));
  }
  await dispatch(TableFormActions.updateKVPForm(tableForm));
};

export const updateSickLeaveForm = (tableForm: TableForm, isFormDirty = true, suppressDirtyStatus = false): ReduxThunkType<void> => async (
  dispatch
) => {
  if (!suppressDirtyStatus) {
    await dispatch(SettingsActions.setIsFormDirty(isFormDirty));
  }
  await dispatch(TableFormActions.updateSickLeaveForm(tableForm));
};

export const updateTableForm = (tableForm: TableForm, isFormDirty = true, suppressDirtyStatus = false): ReduxThunkType<void> => async (dispatch) => {
  if (!suppressDirtyStatus) {
    await dispatch(SettingsActions.setIsFormDirty(isFormDirty));
  }
  await dispatch(TableFormActions.updateTableForm(tableForm));
};

export const updateProjectOverviewForm = (
  tableForm: ProjectOverviewForm,
  isFormDirty = true,
  suppressDirtyStatus = false
): ReduxThunkType<void> => async (dispatch) => {
  if (!suppressDirtyStatus) {
    await dispatch(SettingsActions.setIsFormDirty(isFormDirty));
  }
  await dispatch(TableFormActions.updateProjectOverviewForm(tableForm));
};

export const updateGoalsForm = (goalsForm: GoalsForm, isFormDirty = true, suppressDirtyStatus = false): ReduxThunkType<void> => async (dispatch) => {
  if (!suppressDirtyStatus) {
    await dispatch(SettingsActions.setIsFormDirty(isFormDirty));
  }
  await dispatch(TableFormActions.updateGoalsForm(goalsForm));
};

export const updateWeekTopics = (weekTopicsForm: TableForm, isFormDirty = true, suppressDirtyStatus = false): ReduxThunkType<void> => async (
  dispatch
) => {
  if (!suppressDirtyStatus) {
    await dispatch(SettingsActions.setIsFormDirty(isFormDirty));
  }
  await dispatch(TableFormActions.updateWeekTopics(weekTopicsForm));
};

export const updateStandingRules = (form: StandingRulesForm, isFormDirty = true, suppressDirtyStatus = false): ReduxThunkType<void> => async (
  dispatch
) => {
  if (!suppressDirtyStatus) {
    await dispatch(SettingsActions.setIsFormDirty(isFormDirty));
  }
  await dispatch(TableFormActions.updateStandingRules(form));
};
