import service from './RestService';
import { RestResponse } from '../types/response/RestResponse';
import { CheckboxList, CheckboxAndMeasureData } from '../state/checkbox-list/types';

class ListService {
  path: string;

  constructor(path: string) {
    this.path = path;
  }

  getList = (url: string): RestResponse<CheckboxList | CheckboxAndMeasureData> => service.get(`${url}`);

  getListByTileId = (tileId: number): RestResponse<CheckboxList | CheckboxAndMeasureData> => service.get(`${this.path}?TileId=${tileId}`);

  saveList = (list: CheckboxList | CheckboxAndMeasureData): RestResponse<CheckboxList | CheckboxAndMeasureData> => service.post(`${this.path}`, list);
}

export default ListService;
