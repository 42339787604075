import React from 'react';
import { useLocation } from 'react-router-dom';

import TilePowerBI from '../../tiles/TilePowerBI/TilePowerBI';

import './ReportScreenshotView.scss';

const ReportScreenshotView = () => {
  const location = useLocation();

  const query = new URLSearchParams(location.search);
  const tileUrl = query.get('url');
  const tileUrlDecoded = tileUrl ? decodeURIComponent(tileUrl) : '';

  return (
    <div className="report-screenshot-view d-flex flex-column vh-100">
      <div className="flex-grow-1">
        <TilePowerBI url={tileUrlDecoded} />
      </div>
    </div>
  );
};

export default ReportScreenshotView;
