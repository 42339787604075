import * as React from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { generateAsyncActions } from '../../../core/state/table-forms/asyncActions';
import { TileTypes } from '../../../core/enums/TileTypes';
import TileCompletionDateDesktopScreen from '../../TIleCompletionDate/DesktopScreen/TileCompletionDateDesktopScreen';
import TileOrderAmountDesktopScreen from '../../TileOrderAmount/DesktopScreen/TileOrderAmountDesktopScreen';

import './TileCompletionDateOrderAmountDesktopScreen.scss';

interface Props {
  url: string;
  boardId?: number;
}

const TileCompletionDateOrderAmountDesktopScreen: React.FC<Props> = ({ url }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { getTableForm } = React.useMemo(() => generateAsyncActions(TileTypes.CompletionDateOrderAmount), []);

  React.useEffect(() => {
    dispatch(getTableForm(url));
  }, [url, dispatch, getTableForm]);

  return (
    <div className="tile-completion-date-order-amount-desktop no-font-scaling">
      <div className="tile-completion-date-order-amount-desktop__header">{t('forms.completionDate.orderAmount.title')}</div>
      <div className="tile-completion-date-order-amount-desktop__completion-date">
        <TileCompletionDateDesktopScreen url={url} />
      </div>
      <div className="tile-completion-date-order-amount-desktop__order-amount">
        <TileOrderAmountDesktopScreen url={url} />
      </div>
    </div>
  );
};

export default TileCompletionDateOrderAmountDesktopScreen;
