import ActionTypes from '../actionTypes';
import Actions from '../Actions';
import { SettingsState } from './types';

export const defaultSettingsState: SettingsState = {
  isFormDirty: false,
  isFormEditing: false
};

export default function reducer(state: SettingsState = defaultSettingsState, action: Actions): SettingsState {
  switch (action.type) {
    case ActionTypes.SET_IS_FORM_DIRTY:
      return { ...state, isFormDirty: action.payload };
    case ActionTypes.SET_IS_FORM_EDITING:
      return { ...state, isFormEditing: action.payload };
    default:
      return state;
  }
}
