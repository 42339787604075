import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { TileTypes } from '../../../core/enums/TileTypes';
import { getBackOfficeList } from '../../../core/state/back-office/asyncActions';
import { RootState } from '../../../core/state/state';
import { findItem } from '../../../core/utils/breadcrumb/findItem';

import BoardHeader from '../../components/Board/BoardHeader/BoardHeader';

import BackOfficeProjectOverview from './back-office-detail-pages/BackOfficeProjectOverview/BackOfficeProjectOverview';
import BackOfficeAreaInformation from './back-office-detail-pages/BackOfficeAreaInformation/BackOfficeAreaInformation';
import BackOfficeCapacitySubcontractors from './back-office-detail-pages/BackOfficeCapacitySubcontractors/BackOfficeCapacitySubcontractors';

export const getBackOfficeDetailPage = (boardId: number, tileId: number, type: TileTypes, url: string) => {
  switch (type) {
    case TileTypes.ProjectOverviewForm:
      return <BackOfficeProjectOverview url={url} boardId={boardId} />;
    case TileTypes.Pdf:
      return <BackOfficeAreaInformation url={url} boardId={boardId} tileId={tileId} />;
    case TileTypes.CapacitySubcontractor:
      return <BackOfficeCapacitySubcontractors url={url} boardId={boardId} />;
    default:
      return null;
  }
};

const BackOfficeDetail: React.FC<any> = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { t } = useTranslation();
  const { id, tileId } = useParams<{ id: any; tileId: any }>();
  const { list } = useSelector((state: RootState) => state.backOffice);
  const [title, setTitle] = useState<string>(t('backOffice.headerText'));

  const query = new URLSearchParams(location.search);
  const tileType = query.get('type') as TileTypes;
  const tileUrlDecoded = decodeURIComponent(query.get('url') ?? '');

  useEffect(() => {
    dispatch(getBackOfficeList(id));
  }, [dispatch, id]);

  useEffect(() => {
    if (!list) {
      return;
    }

    const activeTile = findItem(list, tileId, tileUrlDecoded);
    if (activeTile && activeTile.tileTitle !== title) {
      setTitle(activeTile.tileTitle ?? '');
    }
  }, [list, tileId, title, tileUrlDecoded]);

  return (
    <>
      <BoardHeader boardId={id} text={title} boardType={undefined} />
      <div className="tile-detail">
        <div className="tile-detail__content">
          <div className="container-fluid">{getBackOfficeDetailPage(id, tileId, tileType, tileUrlDecoded)}</div>
        </div>
      </div>
    </>
  );
};

export default BackOfficeDetail;
