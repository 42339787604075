import * as React from 'react';

import Background from '../../../../components/Background/Background';
import { Color } from '../../../../core/enums/Color';
import { BoardTypes } from '../../../../core/enums/BoardTypes';
import BoardHeaderLeft from './BoardHeaderLeft/BoardHeaderLeft';
import BoardHeaderCenter from './BoardHeaderCenter/BoardHeaderCenter';
import BoardHeaderRight from './BoardHeaderRight/BoardHeaderRight';

import './BoardHeader.scss';

interface Props {
  text?: string;
  boardId?: number;
  boardType?: BoardTypes;
  noBottomMargin?: boolean;
}

const BoardHeader: React.FC<Props> = ({ text, boardId, boardType }) => {
  return (
    <header className="board-header">
      <Background color={Color.Red3}>
        <div className="container-fluid">
          <div className="board-header__wrap d-flex flex-row justify-content-between position-relative">
            <BoardHeaderLeft boardId={boardId} />
            <BoardHeaderCenter boardId={boardId} text={text} boardType={boardType} />
            <BoardHeaderRight />
          </div>
        </div>
      </Background>
    </header>
  );
};

export default BoardHeader;
