import * as React from 'react';
import { useCallback } from 'react';

import { TableFormFieldStatus } from '../../../core/enums/TableFormFieldStatus';
import * as KVPHelpers from '../TileMeasureHelpers';
import Icon from '../../../components/Icon/Icon';

import './MeasureIndicator.scss';

interface Props {
  size?: 'big' | 'medium' | 'small';
  statuses: TableFormFieldStatus[];
  row: string | number;
  column: string | number;

  useTableValue: (row: string | number, column: string | number) => any[]; // () => [T, (value: T) => void]
  onClick?: (status: TableFormFieldStatus) => void;
}

const MeasureIndicator: React.FC<Props> = ({ size, row, column, useTableValue, statuses }) => {
  const [value, saveValue] = useTableValue(row, column);

  const getNextStatus = useCallback(
    (status) => {
      const index = statuses.indexOf(status);
      if (index === statuses.length - 1) {
        return statuses[0];
      }
      return statuses[index + 1];
    },
    [statuses]
  );

  const handleChange = useCallback(() => {
    saveValue(getNextStatus(value));
  }, [getNextStatus, saveValue, value]);

  return (
    <div
      className={`measure-indicator m-auto ${size ? ` measure-indicator--${size}` : ''}`}
      role="button"
      aria-pressed="false"
      aria-label="indicator"
      tabIndex={0}
      onClick={handleChange}
      onKeyDown={handleChange}
    >
      <Icon svg icon={KVPHelpers.getKVPStatusIcon(value)} />
    </div>
  );
};

export default MeasureIndicator;
