import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { addDays, format, isSameDay } from 'date-fns';

import TR from '../../../components/Table/TR/TR';
import TH from '../../../components/Table/TH/TH';
import { RootState } from '../../../core/state/state';
import TD from '../../../components/Table/TD/TD';
import Table from '../../../components/Table/Table';
import THead from '../../../components/Table/THead/THead';
import TBody from '../../../components/Table/TBody/TBody';
import { getMondayOfTheWeek } from '../../TileSupplySchedule/TileSupplySchedule';
import { generateAsyncActions } from '../../../core/utils/state/generateAsyncActions';
import { TileTypes } from '../../../core/enums/TileTypes';
import { TileService } from '../../../core/utils/state/TileService';
import { getNumberOfWorkers, workersType } from '../helpers';
import { CapacitySubcontractor, CSROW } from '../../../core/state/capacity-subcontractors/types';
import { DATE_FORMAT } from '../../../core/constants/date';

import './TileCapacitySubcontractorDesktopScreen.scss';

interface Props {
  url: string;
}

const TileCapacitySubcontractorDesktopScreen: React.FC<Props> = ({ url }) => {
  const dispatch = useDispatch();
  const form = useSelector((state: RootState) => state.capacitySubcontractor.form);
  const { t } = useTranslation();
  const date = getMondayOfTheWeek();
  const endOfWeek = addDays(date, 5);

  const { getTileForm } = React.useMemo(() => generateAsyncActions<CapacitySubcontractor>(TileTypes.CapacitySubcontractor, new TileService()), []);

  React.useEffect(() => {
    dispatch(getTileForm(url));
  }, [url, dispatch, getTileForm]);

  const generateRow = (currentDate: Date, type: workersType, row: CSROW) => {
    return Array(6)
      .fill(0)
      .map((item, index) => {
        const thisDate = addDays(date, index);
        const rowDays = row.days;
        const itemDay = rowDays?.find((day) => isSameDay(new Date(day.date), thisDate));

        const numberOfWorkers = getNumberOfWorkers(type, itemDay);

        if (type === 'sum') {
          return (
            <TD key={thisDate.toString()} className={`${(numberOfWorkers ?? 0) < 0 ? 'negative' : 'positive'}`}>
              {itemDay ? numberOfWorkers : ''}
            </TD>
          );
        }

        return <TD key={thisDate.toString()}>{itemDay ? numberOfWorkers : ''}</TD>;
      });
  };

  return (
    <div className="tile-capacity-subcontractor-desktop-screen tile-desktop-screen no-font-scaling">
      <Table className="tile-table__table" type="small">
        <THead>
          <TR>
            <TH colSpan={7} className="table__table-header">
              {t('forms.capacitySubcontractor.title')}
            </TH>
          </TR>
        </THead>
        <TBody>
          <TR className="tile-capacity-subcontractor-desktop-screen__header">
            <TD className="pl-3 text-left text-600">
              {format(date, 'dd.')}-{format(endOfWeek, DATE_FORMAT)}
            </TD>
            {Array(6)
              .fill('')
              .map((item, index) => {
                return <TD key={t(`sharedText.day.${index + 1}`)}>{t(`sharedText.day.${index + 1}`).substr(0, 2)}</TD>;
              })}
          </TR>
          {form?.rows?.map((row, rowIndex) => (
            <Fragment key={row.id}>
              <TR>
                <TD className="first-cell pl-3 text-left vertical-top w-40" rowSpan={3}>
                  {rowIndex + 1}. {row.subcontractor}
                </TD>
                {generateRow(date, 'planned', row)}
              </TR>
              <TR>{generateRow(date, 'actual', row)}</TR>
              <TR>{generateRow(date, 'sum', row)}</TR>
            </Fragment>
          ))}
        </TBody>
      </Table>
    </div>
  );
};

export default TileCapacitySubcontractorDesktopScreen;
