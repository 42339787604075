import backIcon, { ReactComponent as backSvg } from '../../../img/icon/back-icon.svg';
import infoIcon, { ReactComponent as infoSvg } from '../../../img/icon/info-icon.svg';
import alertIcon, { ReactComponent as alertSvg } from '../../../img/icon/alert-icon.svg';
import closeIcon, { ReactComponent as closeSvg } from '../../../img/icon/close-icon.svg';
import successIcon, { ReactComponent as successSvg } from '../../../img/icon/success-icon.svg';
import deleteIcon, { ReactComponent as deleteSvg } from '../../../img/icon/delete-icon.svg';
import menuIcon, { ReactComponent as menuSvg } from '../../../img/icon/menu-icon.svg';
import downArrowIcon, { ReactComponent as downArrowSvg } from '../../../img/icon/down-arrow-icon.svg';
import zeroFourthsIcon, { ReactComponent as zeroFourthsSvg } from '../../../img/icon/0-4-icon.svg';
import oneFourthIcon, { ReactComponent as oneFourthSvg } from '../../../img/icon/1-4-icon.svg';
import twoFourthsIcon, { ReactComponent as twoFourthsSvg } from '../../../img/icon/2-4-icon.svg';
import threeFourthsIcon, { ReactComponent as threeFourthsSvg } from '../../../img/icon/3-4-icon.svg';
import fourFourthsIcon, { ReactComponent as fourFourthsSvg } from '../../../img/icon/4-4-icon.svg';
import pdfIcon, { ReactComponent as pdfSvg } from '../../../img/icon/pdf-icon.svg';
import filePdfIcon, { ReactComponent as filePdfSvg } from '../../../img/icon/file-pdf-icon.svg';
import fileImageIcon, { ReactComponent as fileImageSvg } from '../../../img/icon/file-image-icon.svg';
import menuIcon2, { ReactComponent as menuSvg2 } from '../../../img/icon/menu-2-icon.svg';
import cogIcon, { ReactComponent as cogSvg } from '../../../img/icon/cog-icon.svg';
import historyLeftIcon, { ReactComponent as historyLeftSvg } from '../../../img/icon/history-left-icon.svg';
import historyRightIcon, { ReactComponent as historyRightSvg } from '../../../img/icon/history-right-icon.svg';
import historyBackToBoardIcon, { ReactComponent as historyBackToBoardSvg } from '../../../img/icon/history-back-to-board-icon.svg';
import breadcrumbDividerIcon, { ReactComponent as breadcrumbDividerSvg } from '../../../img/icon/breadcrumb-divider-icon.svg';
import loginLogoSIcon, { ReactComponent as loginLogoSSvg } from '../../../img/icon/login-logo-s.svg';
import loginLogoLIcon, { ReactComponent as loginLogoLSvg } from '../../../img/icon/login-logo-l.svg';
import logoutIcon, { ReactComponent as logoutSvg } from '../../../img/icon/logout-icon.svg';
import enterIcon, { ReactComponent as enterSvg } from '../../../img/icon/enter-icon.svg';
import boardDetailsDropdownArrowSmallIcon, {
  ReactComponent as boardDetailsDropdownArrowSmallSvg
} from '../../../img/icon/board-details-dropdown-arrow-small-icon.svg';
import boardDetailsDropdownArrowLargeIcon, {
  ReactComponent as boardDetailsDropdownArrowLargeSvg
} from '../../../img/icon/board-details-dropdown-arrow-large-icon.svg';
import backOfficeIcon, { ReactComponent as backOfficeSvg } from '../../../img/icon/back-office-icon.svg';
import plusIcon, { ReactComponent as plusSvg } from '../../../img/icon/plus-icon.svg';
import exclamationErrorIcon, { ReactComponent as exclamationErrorSvg } from '../../../img/icon/exclamation-error.svg';
import levelUpIcon, { ReactComponent as levelUpSvg } from '../../../img/icon/level-up.svg';
import brlIcon, { ReactComponent as brlSvg } from '../../../img/icon/brl-icon.svg';
import gfIcon, { ReactComponent as gfSvg } from '../../../img/icon/gf-icon.svg';
import plIcon, { ReactComponent as plSvg } from '../../../img/icon/pl-icon.svg';
import tileIcon, { ReactComponent as tileSvg } from '../../../img/icon/tile-icon.svg';
import backOfficeSmallIcon, { ReactComponent as backOfficeSmallSvg } from '../../../img/icon/back-office-small-icon.svg';
import arrowDownIcon, { ReactComponent as arrowDownSvg } from '../../../img/icon/arrow-down-icon.svg';
import arrowUpIcon, { ReactComponent as arrowUpSvg } from '../../../img/icon/arrow-up-icon.svg';
import deescalateIcon, { ReactComponent as deescalateSvg } from '../../../img/icon/deescalate-icon.svg';

export const Icon = {
  back: {
    icon: backIcon,
    svg: backSvg
  },
  close: {
    icon: closeIcon,
    svg: closeSvg
  },
  success: {
    icon: successIcon,
    svg: successSvg
  },
  info: {
    icon: infoIcon,
    svg: infoSvg
  },
  alert: {
    icon: alertIcon,
    svg: alertSvg
  },
  delete: {
    icon: deleteIcon,
    svg: deleteSvg
  },
  menu: {
    icon: menuIcon,
    svg: menuSvg
  },
  downArrow: {
    icon: downArrowIcon,
    svg: downArrowSvg
  },
  zeroFourths: {
    icon: zeroFourthsIcon,
    svg: zeroFourthsSvg
  },
  oneFourth: {
    icon: oneFourthIcon,
    svg: oneFourthSvg
  },
  twoFourths: {
    icon: twoFourthsIcon,
    svg: twoFourthsSvg
  },
  threeFourths: {
    icon: threeFourthsIcon,
    svg: threeFourthsSvg
  },
  fourFourths: {
    icon: fourFourthsIcon,
    svg: fourFourthsSvg
  },
  pdf: {
    icon: pdfIcon,
    svg: pdfSvg
  },
  menu2: {
    icon: menuIcon2,
    svg: menuSvg2
  },
  cog: {
    icon: cogIcon,
    svg: cogSvg
  },
  historyLeft: {
    icon: historyLeftIcon,
    svg: historyLeftSvg
  },
  historyRight: {
    icon: historyRightIcon,
    svg: historyRightSvg
  },
  historyBackToBoard: {
    icon: historyBackToBoardIcon,
    svg: historyBackToBoardSvg
  },
  breadcrumbDivider: {
    icon: breadcrumbDividerIcon,
    svg: breadcrumbDividerSvg
  },
  loginLogoS: {
    icon: loginLogoSIcon,
    svg: loginLogoSSvg
  },
  loginLogoL: {
    icon: loginLogoLIcon,
    svg: loginLogoLSvg
  },
  logout: {
    icon: logoutIcon,
    svg: logoutSvg
  },
  enter: {
    icon: enterIcon,
    svg: enterSvg
  },
  boardDeteilsDropdownArrowSmall: {
    icon: boardDetailsDropdownArrowSmallIcon,
    svg: boardDetailsDropdownArrowSmallSvg
  },
  boardDeteilsDropdownArrowLarge: {
    icon: boardDetailsDropdownArrowLargeIcon,
    svg: boardDetailsDropdownArrowLargeSvg
  },
  backOffice: {
    icon: backOfficeIcon,
    svg: backOfficeSvg
  },
  plus: {
    icon: plusIcon,
    svg: plusSvg
  },
  filePdf: {
    icon: filePdfIcon,
    svg: filePdfSvg
  },
  fileImage: {
    icon: fileImageIcon,
    svg: fileImageSvg
  },
  exclamationError: {
    icon: exclamationErrorIcon,
    svg: exclamationErrorSvg
  },
  levelUp: {
    icon: levelUpIcon,
    svg: levelUpSvg
  },
  brl: {
    icon: brlIcon,
    svg: brlSvg
  },
  gf: {
    icon: gfIcon,
    svg: gfSvg
  },
  pl: {
    icon: plIcon,
    svg: plSvg
  },
  tile: {
    icon: tileIcon,
    svg: tileSvg
  },
  backOfficeSmall: {
    icon: backOfficeSmallIcon,
    svg: backOfficeSmallSvg
  },
  arrowDown: {
    icon: arrowDownIcon,
    svg: arrowDownSvg
  },
  arrowUp: {
    icon: arrowUpIcon,
    svg: arrowUpSvg
  },
  deescalate: {
    icon: deescalateIcon,
    svg: deescalateSvg
  }
};
