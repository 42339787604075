import * as React from 'react';
import { useRouteMatch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Color } from '../../core/enums/Color';
import SectionHeader from '../../pages/components/SectionHeader/SectionHeader';
import ProjectAreaRow from './ProjectAreaRow/ProjectAreaRow';
import { getProjectOverviewByUrl } from '../../core/state/project-area/asyncActions';
import { RootState } from '../../core/state/state';
import { BOARDS_TILE_DETAIL } from '../../core/constants/routes';

import './TileProjectAreaOverview.scss';

interface Props {
  url: string;
}

const TileProjectAreaOverview: React.FC<Props> = ({ url }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const overview = useSelector((state: RootState) => state.projectArea.overview);
  const isDetailPage = useRouteMatch(BOARDS_TILE_DETAIL);

  React.useEffect(() => {
    dispatch(getProjectOverviewByUrl(url));
  }, [dispatch, url]);

  return (
    <>
      <div className={`project-area-overview__grid ${isDetailPage && 'project-area-overview__grid--small'}`}>
        <div />
        <SectionHeader size="h3" text={t('tileProjectAreaOverview.strategicSales')} className="font-weight-bold" color={Color.Grey1} />
        <SectionHeader size="h3" text={t('tileProjectAreaOverview.salesTactical')} color={Color.Grey1} />
        <SectionHeader size="h3" text={t('tileProjectAreaOverview.reserve')} color={Color.Grey1} />
        <SectionHeader size="h3" text={t('tileProjectAreaOverview.staff')} color={Color.Grey1} />
        <SectionHeader size="h3" text={t('tileProjectAreaOverview.finances')} color={Color.Grey1} />
        <SectionHeader size="h3" text={t('tileProjectAreaOverview.production')} color={Color.Grey1} />
        {overview.rows.map((row) => (
          <ProjectAreaRow key={row.title} row={row} />
        ))}
      </div>
    </>
  );
};

export default TileProjectAreaOverview;
