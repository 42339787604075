import { ReduxThunkType } from '../../types/ReduxThunkType';
import { Actions as ProjectAreaActions } from './actions';
import ProjectAreaService from '../../services/ProjectAreaService';
import PowerBIService from '../../services/PowerBIService';
import { PowerBIData } from './types';

export const getProjectOverviewByUrl = (url: string): ReduxThunkType<void> => async (dispatch) => {
  try {
    dispatch(ProjectAreaActions.getProjectAreaOverview());
    const response = await ProjectAreaService.getProjectAreaOverviewByUrl(url);
    dispatch(ProjectAreaActions.getProjectAreaOverviewSuccess(response.data));
  } catch (error) {
    dispatch(ProjectAreaActions.getProjectAreaOverviewError(error));
  }
};

export const getProjectAreaStatusesByAreaUrl = (areaId: number, url: string): ReduxThunkType<void> => async (dispatch) => {
  try {
    dispatch(ProjectAreaActions.getProjectAreaStatuses());
    const response = await ProjectAreaService.getProjectAreaStatusesByAreaUrl(url);
    dispatch(ProjectAreaActions.getProjectAreaStatusesSuccess({ areaId, projectStatuses: response.data }));
  } catch (error) {
    dispatch(ProjectAreaActions.getProjectAreaStatusesError(error));
  }
};

export const getPowerBITile = (tileUrl: string): ReduxThunkType<Promise<PowerBIData | undefined>> => async () => {
  try {
    const response = await PowerBIService.getPowerBITileById(tileUrl);
    return response.data;
  } catch (error) {
    return undefined;
  }
};
