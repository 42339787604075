import React from 'react';

import { cloneDeep } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { Route, useRouteMatch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Table from '../../components/Table/Table';
import THead from '../../components/Table/THead/THead';
import TBody from '../../components/Table/TBody/TBody';
import TR from '../../components/Table/TR/TR';
import TH from '../../components/Table/TH/TH';
import TD from '../../components/Table/TD/TD';

import { useTableForm } from '../../core/hooks/useTableForm';
import { BOARDS_TILE_DETAIL } from '../../core/constants/routes';
import { RootState } from '../../core/state/state';
import { GoalKeys, GoalsForm, TableFormColumn, TableFormRow } from '../../core/state/table-forms/types';
import { getGoalsForm, saveGoalsForm, updateGoalsForm } from '../../core/state/table-forms/asyncActions';
import { TableFormFieldStatus } from '../../core/enums/TableFormFieldStatus';
import { BoardTypes } from '../../core/enums/BoardTypes';
import { useRowNumber } from '../../core/hooks/useRowNumber';

import TableInput from '../../components/form/TableInput/TableInputHook';
import ConfirmPopup, { ConfirmPopupProps } from '../../components/popup/ConfirmPopup/ConfirmPopup';
import UnsavedChangesPopup from '../../components/popup/UnsavedChangesPopup/UnsavedChangesPopup';
import Button from '../../components/Button/Button';
import Icon from '../../components/Icon/Icon';
import Calendar from '../../components/form/Calendar/CalendarHook';

import './TileGoalSetting.scss';

interface Props {
  url: string;
  boardId?: number;
}

const TileGoalSetting: React.FC<Props> = ({ url, boardId }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const newDetailsColumn = [
    {
      columnNumber: 1,
      title: t('forms.goalSetting.tableHeader.title.measure'),
      status: TableFormFieldStatus.Undefined,
      text: ''
    },
    {
      columnNumber: 2,
      status: TableFormFieldStatus.Undefined,
      title: t('forms.goalSetting.tableHeader.title.until'),
      text: new Date().toString()
    },
    {
      columnNumber: 3,
      status: TableFormFieldStatus.Undefined,
      title: t('forms.goalSetting.tableHeader.title.responsible'),
      text: ''
    }
  ];

  const [confirmDialog, setConfirmDialog] = React.useState<ConfirmPopupProps>();
  const goalsForm = useSelector((state: RootState) => state.tableForm.goalsForm.form);
  const boardType = useSelector((state: RootState) => state.boards.board?.type);

  const { isFormDirty, isFormEditing } = useSelector((state: RootState) => state.settings);
  const isDetailPage = useRouteMatch(BOARDS_TILE_DETAIL);

  const nextRowNumber = useRowNumber(goalsForm?.details ?? null);

  const tableFormConfig = {
    boardId,
    data: goalsForm,
    nestedColumnPropertyName: 'columns',
    valuePropertyName: 'text',
    updateActions: [updateGoalsForm],
    submitActions: [updateGoalsForm, saveGoalsForm]
  };

  const { submit, useTableValue: useDetailsTableValue } = useTableForm<GoalsForm>({
    ...tableFormConfig,
    nestedTablePropertyName: 'details'
  });

  const handleSubmit = () => submit();

  const { useTableValue: usePlanningRightTableValue } = useTableForm<GoalsForm>({
    ...tableFormConfig,
    nestedTablePropertyName: 'planningRight'
  });

  React.useEffect(() => {
    dispatch(getGoalsForm(url));
  }, [dispatch, url]);

  const handleStatus = (row: TableFormRow, column: TableFormColumn, formName: GoalKeys) => {
    if (!goalsForm) {
      return null;
    }
    const newGoalsForm = cloneDeep(goalsForm);
    const newTableForm = cloneDeep(goalsForm[formName]);
    const rowIndex = newTableForm?.rows.findIndex((rowItem) => rowItem.rowNumber === row?.rowNumber);
    const columnIndex = newTableForm?.rows[Number(rowIndex)].columns.findIndex((columnItem) => columnItem.columnNumber === column?.columnNumber);

    if (newTableForm) {
      let newStatus;
      if (column.status === TableFormFieldStatus.Green) {
        newStatus = TableFormFieldStatus.Red;
      } else if (column.status === TableFormFieldStatus.Red) {
        newStatus = TableFormFieldStatus.Undefined;
      } else {
        newStatus = TableFormFieldStatus.Green;
      }
      newTableForm.rows[Number(rowIndex)].columns[Number(columnIndex)].status = newStatus;
      newGoalsForm[formName] = newTableForm;
      dispatch(updateGoalsForm(newGoalsForm));
    }
    return null;
  };

  const addEmptyRow = (suppressDirtyStatus = false) => {
    if (!goalsForm) {
      return null;
    }
    const newGoalsForm = cloneDeep(goalsForm);
    const newTableForm = cloneDeep(goalsForm.details);
    const emptyRow: TableFormRow = {
      rowNumber: nextRowNumber(),
      columns: newDetailsColumn
    };

    if (newTableForm) {
      newTableForm.rows.push(emptyRow);
      newGoalsForm.details = newTableForm;
      dispatch(updateGoalsForm(newGoalsForm, true, suppressDirtyStatus));
    }
    return null;
  };

  const clearAllFields = () => {
    const newGoalsForm = cloneDeep(goalsForm);
    if (!newGoalsForm) {
      return;
    }
    Object.keys(newGoalsForm).map((key) => {
      if (!['planningLeft', 'planningRight', 'details'].includes(key)) {
        return null;
      }
      const newTableForm = newGoalsForm[key as GoalKeys];
      if (newTableForm) {
        if (key === 'details') {
          newTableForm.rows = [];
        } else {
          newTableForm.rows = newTableForm.rows.map((row) => {
            const newRow = cloneDeep(row);
            newRow.columns = row.columns.map((column) => {
              const newColumn = cloneDeep(column);
              newColumn.status = TableFormFieldStatus.Undefined;
              if (key !== 'planningLeft') {
                newColumn.text = '';
              }
              return newColumn;
            });
            return newRow;
          });
        }
      }
      newGoalsForm[key as GoalKeys] = newTableForm;
      return null;
    });

    dispatch(updateGoalsForm(newGoalsForm));
  };

  const handleDeleteRow = (index: number) => {
    const newGoalsForm = cloneDeep(goalsForm);

    if (newGoalsForm && newGoalsForm.details) {
      newGoalsForm.details.rows.splice(index, 1);
      dispatch(updateGoalsForm(newGoalsForm));
    }
  };

  const getPlanningLeftRows = () => {
    if (!goalsForm) {
      return null;
    }
    const tableForm = goalsForm.planningLeft;
    if (tableForm && tableForm.rows.length > 0) {
      return tableForm.rows.map((tableFormRow) => (
        <TR key={tableForm.rows.indexOf(tableFormRow)}>
          {tableFormRow.columns.map((tableFormColumn) => (
            <React.Fragment key={tableFormColumn.columnNumber}>
              <TD key={tableFormColumn.columnNumber}>
                <div className={` font-weight-bold ${isDetailPage ? 'mx-2' : 'mx-1'}`}>{tableFormColumn.text}</div>
              </TD>
              <TD>
                <div className="tile-goal-setting__status-wrap">
                  <button
                    className={`tile-goal-setting__status
                        ${tableFormColumn.status ? ` tile-goal-setting__status--${tableFormColumn.status.toLowerCase()}` : ''}`}
                    onClick={() => {
                      handleStatus(tableFormRow, tableFormColumn, 'planningLeft');
                    }}
                  >
                    {tableFormColumn.status}
                  </button>
                </div>
              </TD>
            </React.Fragment>
          ))}
        </TR>
      ));
    }
    return null;
  };

  const getPlanningRightRows = () => {
    if (!goalsForm) {
      return null;
    }
    const tableForm = goalsForm.planningRight;
    if (tableForm && tableForm.rows.length > 0) {
      return tableForm.rows.map((tableFormRow, rowIndex) => (
        <TR key={tableForm.rows.indexOf(tableFormRow)}>
          {tableFormRow.columns.map((tableFormColumn, columnIndex) => (
            <React.Fragment key={tableFormColumn.columnNumber}>
              <TD key={tableFormColumn.columnNumber}>
                <TableInput
                  type={isDetailPage ? undefined : 'small'}
                  row={rowIndex}
                  column={columnIndex}
                  useTableValue={usePlanningRightTableValue}
                />
              </TD>
              <TD>
                <div className="tile-goal-setting__status-wrap">
                  <button
                    className={`tile-goal-setting__status
                        ${tableFormColumn.status ? ` tile-goal-setting__status--${tableFormColumn.status.toLowerCase()}` : ''}
                        ${boardType === BoardTypes.GFBoard ? 'tile-goal-setting__status--small' : ''}`}
                    onClick={() => {
                      handleStatus(tableFormRow, tableFormColumn, 'planningRight');
                    }}
                  >
                    {tableFormColumn.status}
                  </button>
                </div>
              </TD>
            </React.Fragment>
          ))}
        </TR>
      ));
    }
    return null;
  };

  const getDetailRows = () => {
    if (!goalsForm) {
      return null;
    }
    const tableForm = goalsForm.details;
    if (tableForm && tableForm.rows.length > 0) {
      return tableForm.rows.map((tableFormRow, rowIndex) => (
        <TR key={tableFormRow.rowNumber}>
          {tableFormRow.columns.map((tableFormColumn, columnIndex) => (
            <React.Fragment key={tableFormColumn.columnNumber}>
              <TD key={tableFormColumn.columnNumber}>
                {columnIndex === 1 ? (
                  <Calendar isSmall={!isDetailPage} row={rowIndex} column={columnIndex} useTableValue={useDetailsTableValue} />
                ) : (
                  <TableInput type={isDetailPage ? undefined : 'small'} row={rowIndex} column={columnIndex} useTableValue={useDetailsTableValue} />
                )}
              </TD>
            </React.Fragment>
          ))}
          {isDetailPage && (
            <TD className="text-center shrink">
              <button title={t('buttons.deleteRow')} className="btn btn-link" onClick={() => handleDeleteRow(rowIndex)}>
                <Icon icon="delete" svg />
                <span className="sr-only">{t('buttons.deleteRow')}</span>
              </button>
            </TD>
          )}
        </TR>
      ));
    }
    return null;
  };

  return (
    <div className={`tile-goal-setting ${isDetailPage ? '' : 'tile-goal-setting--small'}`}>
      <div className={isDetailPage ? 'container-fluid' : ''}>
        <div className={`d-flex justify-content-between my-1 mx-1 ${isDetailPage && 'mt-4'}`}>
          <div>
            {isDetailPage ? (
              <div className="tile-goal-setting__sub-title">
                {boardType && boardType === BoardTypes.GFBoard ? t('forms.goalSetting.titleGF') : t('forms.goalSetting.subtitle')}
              </div>
            ) : (
              <>
                <div className={`tile-goal-setting__title tile-goal-setting__title--${boardType?.toLowerCase()}`}>
                  {boardType && boardType === BoardTypes.GFBoard ? t('forms.goalSetting.titleGF') : t('forms.goalSetting.titleBRL')}
                </div>
                <div className="tile-goal-setting__sub-title">{boardType === BoardTypes.BRLBoard && t('forms.goalSetting.subtitle')}</div>
              </>
            )}
          </div>
          {isDetailPage ? (
            <div>
              <Route exact path={BOARDS_TILE_DETAIL}>
                <div className="mb-3 d-flex justify-content-end align-items-end">
                  <Button
                    buttonTypes={['secondary', 'big']}
                    onClick={() => {
                      setConfirmDialog({
                        heading: t('dialogs.confirmDialog.heading'),
                        cancelText: t('dialogs.confirmDialog.cancelText'),
                        confirmText: t('dialogs.confirmDialog.confirmText'),
                        show: true,
                        onCancel: () => {
                          setConfirmDialog({ show: false });
                        },
                        onConfirm: () => {
                          clearAllFields();
                          setConfirmDialog({ show: false });
                        }
                      });
                    }}
                  >
                    {t('buttons.clearAll')}
                  </Button>
                  <Button buttonTypes={['primary', 'big']} className="ml-3" onClick={handleSubmit}>
                    {t('buttons.save')}
                  </Button>
                </div>
              </Route>
            </div>
          ) : (
            <div className="tile-goal-setting__top-status-wrap">
              <span className="font-weight-bold">
                {t('forms.goalSetting.statusRed')}
                &nbsp;
                <span className={`tile-goal-setting__top-status ${isDetailPage ? '' : 'tile-goal-setting__top-status--small'}`} />
                &nbsp;
                {t('forms.goalSetting.if')}
              </span>
              <span>
                {boardType === BoardTypes.GFBoard && <br />}
                &nbsp;{t('forms.goalSetting.target')}
              </span>
            </div>
          )}
        </div>
        {isDetailPage && (
          <div className="tile-goal-setting__top-status-wrap">
            <span className="font-weight-bold">
              {t('forms.goalSetting.statusRed')}
              &nbsp;
              <span className={`tile-goal-setting__top-status ${isDetailPage ? '' : 'tile-goal-setting__top-status--small'}`} />
              &nbsp;
              {t('forms.goalSetting.if')}
            </span>
            <span>
              {!isDetailPage && boardType === BoardTypes.GFBoard && <br />}
              &nbsp;{t('forms.goalSetting.target')}
            </span>
          </div>
        )}
        <div className="d-flex justify-content-between mb-2">
          <div className="tile-goal-setting__table-top-wrap">
            <div className="mt-1 mx-1">
              <div className="tile-goal-setting__table-top-heading font-weight-bold">{goalsForm?.planningLeft?.title}</div>
              <div className="tile-goal-setting__table-top-subheading">{t('forms.goalSetting.todayStatus')}</div>
            </div>
            <Table className={`tile-goal-setting__table-top ${boardType === BoardTypes.GFBoard && 'tile-goal-setting__table-top--left'} mt-1`}>
              <THead>
                <TR>
                  <TH />
                  <TH>{t('sharedText.status')}</TH>
                </TR>
              </THead>
              <TBody>{getPlanningLeftRows()}</TBody>
            </Table>
          </div>
          <div className="tile-goal-setting__table-top-wrap">
            <div className="mt-1 mx-1">
              <div className="tile-goal-setting__table-top-heading font-weight-bold">{goalsForm?.planningRight?.title}</div>
              <div className="tile-goal-setting__table-top-subheading">{t('forms.goalSetting.todayStatus')}</div>
            </div>
            <Table className="tile-goal-setting__table-top mt-1">
              <THead>
                <TR>
                  <TH />
                  <TH>{t('sharedText.status')}</TH>
                </TR>
              </THead>
              <TBody>{getPlanningRightRows()}</TBody>
            </Table>
          </div>
        </div>
        {isDetailPage && <hr />}
        <div>
          <Table className="tile-goal-setting__table-bottom">
            <THead>
              <TR>
                <TH>{t('forms.goalSetting.tableHeader.title.measure')}</TH>
                <TH>{t('forms.goalSetting.tableHeader.title.until')}</TH>
                <TH>{t('forms.goalSetting.tableHeader.title.responsible')}</TH>
                {isDetailPage && <TH />}
              </TR>
            </THead>
            <TBody>{getDetailRows()}</TBody>
          </Table>
        </div>
        <Route exact path={BOARDS_TILE_DETAIL}>
          <div className="d-flex justify-content-center my-4">
            <Button buttonTypes={['primary', 'big']} onClick={() => addEmptyRow()}>
              {t('buttons.addNewRow')}
            </Button>
          </div>
        </Route>
      </div>
      <ConfirmPopup
        show={confirmDialog?.show}
        confirmText={confirmDialog?.confirmText}
        cancelText={confirmDialog?.cancelText}
        heading={confirmDialog?.heading}
        onCancel={confirmDialog?.onCancel}
        onConfirm={confirmDialog?.onConfirm}
      />
      {isDetailPage && <UnsavedChangesPopup showIf={isFormDirty || isFormEditing} />}
    </div>
  );
};

export default TileGoalSetting;
