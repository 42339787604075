import service from '../RestService';
import { RestResponse } from '../../types/response/RestResponse';
import { BackOfficeList } from '../../state/back-office/types';

const path = '/boards';

class BackOfficeService {
  getBackOfficeList = (boardId: number): RestResponse<BackOfficeList[]> => service.get(`${path}/${boardId}/settings`);
}

export default new BackOfficeService();
