import React, { LegacyRef } from 'react';

import './TextArea.scss';

interface Props {
  defaultValue?: string;
  label?: string;
  id: string;
  name: string;
  rows?: number;
  error?: string;
  onChange?: () => void;
  onBlur?: () => void;
  register?: LegacyRef<any>;
}

const TextArea: React.FC<Props> = ({ defaultValue = '', label, error, id, name, rows, onChange, onBlur, register }) => {
  return (
    <div className={`textarea ${error ? 'textarea--error' : ''}`}>
      {label && (
        <label className="textarea__label" htmlFor={id}>
          {label}
        </label>
      )}
      <textarea
        ref={register}
        rows={rows}
        className="textarea__textarea"
        defaultValue={defaultValue}
        id={id}
        name={name}
        onChange={onChange}
        onBlur={onBlur}
      />
      {error && <div className="textarea__error">{error}</div>}
    </div>
  );
};

export default TextArea;
