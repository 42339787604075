import React from 'react';
import { useTranslation } from 'react-i18next';
import { TileTypes } from '../../core/enums/TileTypes';
import { TableTileTypes } from '../../core/enums/TableTileTypes';
import TileDefectProcessingInfo from './TileDefectProcessingInfo';
import TableFormTile from '../TableFormTile/TableFormTile';

interface Props {
  boardId?: number;
  url: string;
}

const TileDefectProcessing: React.FC<Props> = ({ url, boardId }) => {
  const { t } = useTranslation();

  return (
    <TableFormTile
      url={url}
      boardId={boardId}
      tileType={TileTypes.DefectProcessing}
      tableTileType={TableTileTypes.DefectProcessing}
      columns={[
        {
          field: 'id',
          title: t('forms.defectProcessing.column.id'),
          type: 'index',
          headerClass: 'w-6'
        },
        {
          field: 'subcontractor',
          title: t('forms.defectProcessing.column.subcontractor')
        },
        {
          field: 'received',
          title: t('forms.defectProcessing.column.received'),
          type: 'calendar',
          headerClass: 'w-13'
        },
        {
          field: 'due',
          title: t('forms.defectProcessing.column.due'),
          type: 'calendar',
          headerClass: 'w-13'
        },
        {
          field: 'released',
          title: t('forms.defectProcessing.column.released'),
          type: 'calendar',
          headerClass: 'w-13'
        },
        {
          field: 'rework',
          title: t('forms.defectProcessing.column.rework'),
          type: 'calendar',
          headerClass: 'w-13'
        },
        {
          field: 'releaseConfirmation',
          title: t('forms.defectProcessing.column.releaseConfirmation'),
          type: 'calendar',
          headerClass: 'w-13'
        }
      ]}
      InfoComponent={TileDefectProcessingInfo}
      showStatus
    />
  );
};

export default TileDefectProcessing;
