import useMedia from 'use-media';

import { MEDIA_QUERIES, MediaQueriesType } from '../constants/mediaQueries';

export function isDesktopScreen(): boolean {
  const mediaQueries: MediaQueriesType[] = ['XL_DOWN'];

  const screens = mediaQueries.map((mq) => useMedia(MEDIA_QUERIES[mq]));
  const isInScreens = screens.find((screen) => screen);

  return !!isInScreens;
}

export function isDesktopScreenSize() {
  return window.matchMedia(MEDIA_QUERIES.XL_DOWN).matches;
}
