import React from 'react';
import { useTranslation } from 'react-i18next';

import Button from '../../../components/Button/Button';
import ConfirmPopup, { ConfirmPopupProps } from '../../../components/popup/ConfirmPopup/ConfirmPopup';

interface Props {
  clearAll?: () => void;
  submit: (...args: any) => void;
  addNewRow?: () => void;
}

const ButtonToolbar: React.FC<Props> = ({ clearAll, submit, addNewRow }) => {
  const { t } = useTranslation();
  const [confirmDialog, setConfirmDialog] = React.useState<ConfirmPopupProps>();

  return (
    <div className="my-4 d-flex justify-content-end align-items-end">
      {clearAll && (
        <Button
          buttonTypes={['secondary', 'big']}
          onClick={() => {
            setConfirmDialog({
              heading: t('dialogs.confirmDialog.heading'),
              cancelText: t('dialogs.confirmDialog.cancelText'),
              confirmText: t('dialogs.confirmDialog.confirmText'),
              show: true,
              onCancel: () => {
                setConfirmDialog({ show: false });
              },
              onConfirm: () => {
                clearAll();
                setConfirmDialog({ show: false });
              }
            });
          }}
        >
          {t('buttons.clearAll')}
        </Button>
      )}

      {addNewRow && (
        <Button buttonTypes={['primary', 'big']} className="ml-3" onClick={addNewRow}>
          {t('buttons.addNewRow')}
        </Button>
      )}

      {submit && (
        <Button buttonTypes={['primary', 'big']} className="ml-3" onClick={submit}>
          {t('buttons.save')}
        </Button>
      )}

      <ConfirmPopup
        show={confirmDialog?.show}
        confirmText={confirmDialog?.confirmText}
        cancelText={confirmDialog?.cancelText}
        heading={confirmDialog?.heading}
        onCancel={confirmDialog?.onCancel}
        onConfirm={confirmDialog?.onConfirm}
      />
    </div>
  );
};

export default ButtonToolbar;
