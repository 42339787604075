enum ActionTypes {
  // boards
  GET_TILE = 'boards/GET_TILE',
  GET_TILE_SUCCESS = 'boards/GET_TILE_SUCCESS',
  GET_TILE_ERROR = 'boards/GET_TILE_ERROR',

  GET_ALL_TILES = 'boards/GET_ALL_TILES',
  GET_ALL_TILES_SUCCESS = 'boards/GET_ALL_TILES_SUCCESS',
  GET_ALL_TILES_ERROR = 'boards/GET_ALL_TILES_ERROR',

  // attendance
  GET_ATTENDANCE_LIST = 'attendance/GET_ATTENDANCE_LIST',
  GET_ATTENDANCE_LIST_SUCCESS = 'attendance/GET_ATTENDANCE_LIST_SUCCESS',
  GET_ATTENDANCE_LIST_ERROR = 'attendance/GET_ATTENDANCE_LIST_ERROR',

  SAVE_ATTENDANCE_LIST = 'attendance/SAVE_ATTENDANCE_LIST',
  SAVE_ATTENDANCE_LIST_SUCCESS = 'attendance/SAVE_ATTENDANCE_LIST_SUCCESS',
  SAVE_ATTENDANCE_LIST_ERROR = 'attendance/SAVE_ATTENDANCE_LIST_ERROR',

  UPDATE_ATTENDANCE_LIST = 'attendance/UPDATE_ATTENDANCE_LIST',

  // vacation
  GET_VACATION_LIST = 'vacation/GET_VACATION_LIST',
  GET_VACATION_LIST_SUCCESS = 'vacation/GET_VACATION_LIST_SUCCESS',
  GET_VACATION_LIST_ERROR = 'vacation/GET_VACATION_LIST_ERROR',

  SAVE_VACATION_LIST = 'vacation/SAVE_VACATION_LIST',
  SAVE_VACATION_LIST_SUCCESS = 'vacation/SAVE_VACATION_LIST_SUCCESS',
  SAVE_VACATION_LIST_ERROR = 'vacation/SAVE_VACATION_LIST_ERROR',

  UPDATE_VACATION_LIST = 'vacation/UPDATE_VACATION_LIST',

  // tidiness
  GET_TIDINESS_LIST = 'tidiness/GET_TIDINESS_LIST',
  GET_TIDINESS_LIST_SUCCESS = 'tidiness/GET_TIDINESS_LIST_SUCCESS',
  GET_TIDINESS_LIST_ERROR = 'tidiness/GET_TIDINESS_LIST_ERROR',

  SAVE_TIDINESS_LIST = 'tidiness/SAVE_TIDINESS_LIST',
  SAVE_TIDINESS_LIST_SUCCESS = 'tidiness/SAVE_TIDINESS_LIST_SUCCESS',
  SAVE_TIDINESS_LIST_ERROR = 'tidiness/SAVE_TIDINESS_LIST_ERROR',

  UPDATE_TIDINESS_LIST = 'tidiness/UPDATE_TIDINESS_LIST',

  // presence
  GET_PRESENCE_LIST = 'presence/GET_PRESENCE_LIST',
  GET_PRESENCE_LIST_SUCCESS = 'presence/GET_PRESENCE_LIST_SUCCESS',
  GET_PRESENCE_LIST_ERROR = 'presence/GET_PRESENCE_LIST_ERROR',

  SAVE_PRESENCE_LIST = 'presence/SAVE_PRESENCE_LIST',
  SAVE_PRESENCE_LIST_SUCCESS = 'presence/SAVE_PRESENCE_LIST_SUCCESS',
  SAVE_PRESENCE_LIST_ERROR = 'presence/SAVE_PRESENCE_LIST_ERROR',

  UPDATE_PRESENCE_LIST = 'presence/UPDATE_PRESENCE_LIST',

  // confirmation
  GET_CONFIRMATION_LIST = 'confirmation/GET_CONFIRMATION_LIST',
  GET_CONFIRMATION_LIST_SUCCESS = 'confirmation/GET_CONFIRMATION_LIST_SUCCESS',
  GET_CONFIRMATION_LIST_ERROR = 'confirmation/GET_CONFIRMATION_LIST_ERROR',

  SAVE_CONFIRMATION_LIST = 'confirmation/SAVE_CONFIRMATION_LIST',
  SAVE_CONFIRMATION_LIST_SUCCESS = 'confirmation/SAVE_CONFIRMATION_LIST_SUCCESS',
  SAVE_CONFIRMATION_LIST_ERROR = 'confirmation/SAVE_CONFIRMATION_LIST_ERROR',

  UPDATE_CONFIRMATION_LIST = 'confirmation/UPDATE_CONFIRMATION_LIST',

  // Order Placement
  GET_ORDER_PLACEMENT_TABLE_FORM = 'orderPlacement/GET_ORDER_PLACEMENT_TABLE_FORM',
  GET_ORDER_PLACEMENT_TABLE_FORM_SUCCESS = 'orderPlacement/GET_ORDER_PLACEMENT_TABLE_FORM_SUCCESS',
  GET_ORDER_PLACEMENT_TABLE_FORM_ERROR = 'orderPlacement/GET_ORDER_PLACEMENT_TABLE_FORM_ERROR',

  SAVE_ORDER_PLACEMENT_TABLE_FORM = 'orderPlacement/SAVE_ORDER_PLACEMENT_TABLE_FORM',
  SAVE_ORDER_PLACEMENT_TABLE_FORM_SUCCESS = 'orderPlacement/SAVE_ORDER_PLACEMENT_TABLE_FORM_SUCCESS',
  SAVE_ORDER_PLACEMENT_TABLE_FORM_ERROR = 'orderPlacement/SAVE_ORDER_PLACEMENT_TABLE_FORM_ERROR',

  UPDATE_ORDER_PLACEMENT_TABLE_FORM = 'orderPlacement/UPDATE_ORDER_PLACEMENT_TABLE_FORM',

  // defectProcessing
  GET_DEFECT_PROCESSING_TABLE_FORM = 'defectProcessing/GET_DEFECT_PROCESSING_TABLE_FORM',
  GET_DEFECT_PROCESSING_TABLE_FORM_SUCCESS = 'defectProcessing/GET_DEFECT_PROCESSING_TABLE_FORM_SUCCESS',
  GET_DEFECT_PROCESSING_TABLE_FORM_ERROR = 'defectProcessing/GET_DEFECT_PROCESSING_TABLE_FORM_ERROR',

  SAVE_DEFECT_PROCESSING_TABLE_FORM = 'defectProcessing/SAVE_DEFECT_PROCESSING_TABLE_FORM',
  SAVE_DEFECT_PROCESSING_TABLE_FORM_SUCCESS = 'defectProcessing/SAVE_DEFECT_PROCESSING_TABLE_FORM_SUCCESS',
  SAVE_DEFECT_PROCESSING_TABLE_FORM_ERROR = 'defectProcessing/SAVE_DEFECT_PROCESSING_TABLE_FORM_ERROR',

  UPDATE_DEFECT_PROCESSING_TABLE_FORM = 'defectProcessing/UPDATE_DEFECT_PROCESSING_TABLE_FORM',

  // defectProcessingNU
  GET_DEFECT_PROCESSING_NU_TABLE_FORM = 'defectProcessingNU/GET_DEFECT_PROCESSING_NU_TABLE_FORM',
  GET_DEFECT_PROCESSING_NU_TABLE_FORM_SUCCESS = 'defectProcessingNU/GET_DEFECT_PROCESSING_NU_TABLE_FORM_SUCCESS',
  GET_DEFECT_PROCESSING_NU_TABLE_FORM_ERROR = 'defectProcessingNU/GET_DEFECT_PROCESSING_NU_TABLE_FORM_ERROR',

  SAVE_DEFECT_PROCESSING_NU_TABLE_FORM = 'defectProcessingNU/SAVE_DEFECT_PROCESSING_NU_TABLE_FORM',
  SAVE_DEFECT_PROCESSING_NU_TABLE_FORM_SUCCESS = 'defectProcessingNU/SAVE_DEFECT_PROCESSING_NU_TABLE_FORM_SUCCESS',
  SAVE_DEFECT_PROCESSING_NU_TABLE_FORM_ERROR = 'defectProcessingNU/SAVE_DEFECT_PROCESSING_NU_TABLE_FORM_ERROR',

  UPDATE_DEFECT_PROCESSING_NU_TABLE_FORM = 'defectProcessingNU/UPDATE_DEFECT_PROCESSING_NU_TABLE_FORM',

  // Inspection plans
  GET_INSPECTION_PLANS_TABLE_FORM = 'defectProcessingNU/GET_INSPECTION_PLANS_TABLE_FORM',
  GET_INSPECTION_PLANS_TABLE_FORM_SUCCESS = 'defectProcessingNU/GET_INSPECTION_PLANS_TABLE_FORM_SUCCESS',
  GET_INSPECTION_PLANS_TABLE_FORM_ERROR = 'defectProcessingNU/GET_INSPECTION_PLANS_TABLE_FORM_ERROR',

  SAVE_INSPECTION_PLANS_TABLE_FORM = 'defectProcessingNU/SAVE_INSPECTION_PLANS_TABLE_FORM',
  SAVE_INSPECTION_PLANS_TABLE_FORM_SUCCESS = 'defectProcessingNU/SAVE_INSPECTION_PLANS_TABLE_FORM_SUCCESS',
  SAVE_INSPECTION_PLANS_TABLE_FORM_ERROR = 'defectProcessingNU/SAVE_INSPECTION_PLANS_TABLE_FORM_ERROR',

  UPDATE_INSPECTION_PLANS_TABLE_FORM = 'defectProcessingNU/UPDATE_INSPECTION_PLANS_TABLE_FORM',

  // trackingAAZ
  GET_TRACKING_AAZ_TABLE_FORM = 'trackingAAZ/GET_TRACKING_AAZ_TABLE_FORM',
  GET_TRACKING_AAZ_TABLE_FORM_SUCCESS = 'trackingAAZ/GET_TRACKING_AAZ_TABLE_FORM_SUCCESS',
  GET_TRACKING_AAZ_TABLE_FORM_ERROR = 'trackingAAZ/GET_TRACKING_AAZ_TABLE_FORM_ERROR',

  SAVE_TRACKING_AAZ_TABLE_FORM = 'trackingAAZ/SAVE_TRACKING_AAZ_TABLE_FORM',
  SAVE_TRACKING_AAZ_TABLE_FORM_SUCCESS = 'trackingAAZ/SAVE_TRACKING_AAZ_TABLE_FORM_SUCCESS',
  SAVE_TRACKING_AAZ_TABLE_FORM_ERROR = 'trackingAAZ/SAVE_TRACKING_AAZ_TABLE_FORM_ERROR',

  UPDATE_TRACKING_AAZ_TABLE_FORM = 'trackingAAZ/UPDATE_TRACKING_AAZ_TABLE_FORM',

  // planningQuality
  GET_PLANNING_QUALITY_TABLE_FORM = 'planningQuality/GET_PLANNING_QUALITY_TABLE_FORM',
  GET_PLANNING_QUALITY_TABLE_FORM_SUCCESS = 'planningQuality/GET_PLANNING_QUALITY_TABLE_FORM_SUCCESS',
  GET_PLANNING_QUALITY_TABLE_FORM_ERROR = 'planningQuality/GET_PLANNING_QUALITY_TABLE_FORM_ERROR',

  SAVE_PLANNING_QUALITY_TABLE_FORM = 'planningQuality/SAVE_PLANNING_QUALITY_TABLE_FORM',
  SAVE_PLANNING_QUALITY_TABLE_FORM_SUCCESS = 'planningQuality/SAVE_PLANNING_QUALITY_TABLE_FORM_SUCCESS',
  SAVE_PLANNING_QUALITY_TABLE_FORM_ERROR = 'planningQuality/SAVE_PLANNING_QUALITY_TABLE_FORM_ERROR',

  UPDATE_PLANNING_QUALITY_TABLE_FORM = 'planningQuality/UPDATE_PLANNING_QUALITY_TABLE_FORM',

  // additionalServicesNU
  GET_ADDITIONAL_SERVICES_NU_TABLE_FORM = 'additionalServicesNU/GET_ADDITIONAL_SERVICES_NU_TABLE_FORM',
  GET_ADDITIONAL_SERVICES_NU_TABLE_FORM_SUCCESS = 'additionalServicesNU/GET_ADDITIONAL_SERVICES_NU_TABLE_FORM_SUCCESS',
  GET_ADDITIONAL_SERVICES_NU_TABLE_FORM_ERROR = 'additionalServicesNU/GET_ADDITIONAL_SERVICES_NU_TABLE_FORM_ERROR',

  SAVE_ADDITIONAL_SERVICES_NU_TABLE_FORM = 'additionalServicesNU/SAVE_ADDITIONAL_SERVICES_NU_TABLE_FORM',
  SAVE_ADDITIONAL_SERVICES_NU_TABLE_FORM_SUCCESS = 'additionalServicesNU/SAVE_ADDITIONAL_SERVICES_NU_TABLE_FORM_SUCCESS',
  SAVE_ADDITIONAL_SERVICES_NU_TABLE_FORM_ERROR = 'additionalServicesNU/SAVE_ADDITIONAL_SERVICES_NU_TABLE_FORM_ERROR',

  UPDATE_ADDITIONAL_SERVICES_NU_TABLE_FORM = 'additionalServicesNU/UPDATE_ADDITIONAL_SERVICES_NU_TABLE_FORM',

  // To Do Short Term
  GET_TO_DO_SHORT_TERM_TABLE_FORM = 'todo/GET_TO_DO_SHORT_TERM_TABLE_FORM',
  GET_TO_DO_SHORT_TERM_TABLE_FORM_SUCCESS = 'todo/GET_TO_DO_SHORT_TERM_TABLE_FORM_SUCCESS',
  GET_TO_DO_SHORT_TERM_TABLE_FORM_ERROR = 'todo/GET_TO_DO_SHORT_TERM_TABLE_FORM_ERROR',

  SAVE_TO_DO_SHORT_TERM_TABLE_FORM = 'todo/SAVE_TO_DO_SHORT_TERM_TABLE_FORM',
  SAVE_TO_DO_SHORT_TERM_TABLE_FORM_SUCCESS = 'todo/SAVE_TO_DO_SHORT_TERM_TABLE_FORM_SUCCESS',
  SAVE_TO_DO_SHORT_TERM_TABLE_FORM_ERROR = 'todo/SAVE_TO_DO_SHORT_TERM_TABLE_FORM_ERROR',

  UPDATE_TO_DO_SHORT_TERM_TABLE_FORM = 'todo/UPDATE_TO_DO_SHORT_TERM_TABLE_FORM',

  // Required Material
  GET_REQUIRED_MATERIAL_TABLE_FORM = 'required/GET_REQUIRED_MATERIAL_TABLE_FORM',
  GET_REQUIRED_MATERIAL_TABLE_FORM_SUCCESS = 'required/GET_REQUIRED_MATERIAL_TABLE_FORM_SUCCESS',
  GET_REQUIRED_MATERIAL_TABLE_FORM_ERROR = 'required/GET_REQUIRED_MATERIAL_TABLE_FORM_ERROR',

  SAVE_REQUIRED_MATERIAL_TABLE_FORM = 'required/SAVE_REQUIRED_MATERIAL_TABLE_FORM',
  SAVE_REQUIRED_MATERIAL_TABLE_FORM_SUCCESS = 'required/SAVE_REQUIRED_MATERIAL_TABLE_FORM_SUCCESS',
  SAVE_REQUIRED_MATERIAL_TABLE_FORM_ERROR = 'required/SAVE_REQUIRED_MATERIAL_TABLE_FORM_ERROR',

  UPDATE_REQUIRED_MATERIAL_TABLE_FORM = 'required/UPDATE_REQUIRED_MATERIAL_TABLE_FORM',

  // Completion Date Order Amount
  GET_COMPLETION_DATE_ORDER_AMOUNT_TABLE_FORM = 'required/GET_COMPLETION_DATE_ORDER_AMOUNT_TABLE_FORM',
  GET_COMPLETION_DATE_ORDER_AMOUNT_TABLE_FORM_SUCCESS = 'required/GET_COMPLETION_DATE_ORDER_AMOUNT_TABLE_FORM_SUCCESS',
  GET_COMPLETION_DATE_ORDER_AMOUNT_TABLE_FORM_ERROR = 'required/GET_COMPLETION_DATE_ORDER_AMOUNT_TABLE_FORM_ERROR',

  SAVE_COMPLETION_DATE_ORDER_AMOUNT_TABLE_FORM = 'required/SAVE_COMPLETION_DATE_ORDER_AMOUNT_TABLE_FORM',
  SAVE_COMPLETION_DATE_ORDER_AMOUNT_TABLE_FORM_SUCCESS = 'required/SAVE_COMPLETION_DATE_ORDER_AMOUNT_TABLE_FORM_SUCCESS',
  SAVE_COMPLETION_DATE_ORDER_AMOUNT_TABLE_FORM_ERROR = 'required/SAVE_COMPLETION_DATE_ORDER_AMOUNT_TABLE_FORM_ERROR',

  UPDATE_COMPLETION_DATE_ORDER_AMOUNT_TABLE_FORM = 'required/UPDATE_COMPLETION_DATE_ORDER_AMOUNT_TABLE_FORM',

  // Planner Risks
  GET_PLANNER_RISKS_TABLE_FORM = 'plannerrisks/GET_PLANNER_RISKS_TABLE_FORM',
  GET_PLANNER_RISKS_TABLE_FORM_SUCCESS = 'plannerrisks/GET_PLANNER_RISKS_TABLE_FORM_SUCCESS',
  GET_PLANNER_RISKS_TABLE_FORM_ERROR = 'plannerrisks/GET_PLANNER_RISKS_TABLE_FORM_ERROR',

  SAVE_PLANNER_RISKS_TABLE_FORM = 'plannerrisks/SAVE_PLANNER_RISKS_TABLE_FORM',
  SAVE_PLANNER_RISKS_TABLE_FORM_SUCCESS = 'plannerrisks/SAVE_PLANNER_RISKS_TABLE_FORM_SUCCESS',
  SAVE_PLANNER_RISKS_TABLE_FORM_ERROR = 'plannerrisks/SAVE_PLANNER_RISKS_TABLE_FORM_ERROR',

  UPDATE_PLANNER_RISKS_TABLE_FORM = 'plannerrisks/UPDATE_PLANNER_RISKS_TABLE_FORM',

  // Performance NU
  GET_PERFORMANCE_NU_TABLE_FORM = 'performancenu/GET_PERFORMANCE_NU_TABLE_FORM',
  GET_PERFORMANCE_NU_TABLE_FORM_SUCCESS = 'performancenu/GET_PERFORMANCE_NU_TABLE_FORM_SUCCESS',
  GET_PERFORMANCE_NU_TABLE_FORM_ERROR = 'performancenu/GET_PERFORMANCE_NU_TABLE_FORM_ERROR',

  SAVE_PERFORMANCE_NU_TABLE_FORM = 'performancenu/SAVE_PERFORMANCE_NU_TABLE_FORM',
  SAVE_PERFORMANCE_NU_TABLE_FORM_SUCCESS = 'performancenu/SAVE_PERFORMANCE_NU_TABLE_FORM_SUCCESS',
  SAVE_PERFORMANCE_NU_TABLE_FORM_ERROR = 'performancenu/SAVE_PERFORMANCE_NU_TABLE_FORM_ERROR',

  UPDATE_PERFORMANCE_NU_TABLE_FORM = 'performancenu/UPDATE_PERFORMANCE_NU_TABLE_FORM',

  // Obstacles
  GET_OBSTACLES_TABLE_FORM = 'obstacles/GET_OBSTACLES_TABLE_FORM',
  GET_OBSTACLES_TABLE_FORM_SUCCESS = 'obstacles/GET_OBSTACLES_TABLE_FORM_SUCCESS',
  GET_OBSTACLES_TABLE_FORM_ERROR = 'obstacles/GET_OBSTACLES_TABLE_FORM_ERROR',

  SAVE_OBSTACLES_TABLE_FORM = 'obstacles/SAVE_OBSTACLES_TABLE_FORM',
  SAVE_OBSTACLES_TABLE_FORM_SUCCESS = 'obstacles/SAVE_OBSTACLES_TABLE_FORM_SUCCESS',
  SAVE_OBSTACLES_TABLE_FORM_ERROR = 'obstacles/SAVE_OBSTACLES_TABLE_FORM_ERROR',

  UPDATE_OBSTACLES_TABLE_FORM = 'obstacles/UPDATE_OBSTACLES_TABLE_FORM',

  // Plan Approval Customer
  GET_PLAN_APPROVAL_CUSTOMER_TABLE_FORM = 'planApprovalCustomer/GET_PLAN_APPROVAL_CUSTOMER_TABLE_FORM',
  GET_PLAN_APPROVAL_CUSTOMER_TABLE_FORM_SUCCESS = 'planApprovalCustomer/GET_PLAN_APPROVAL_CUSTOMER_TABLE_FORM_SUCCESS',
  GET_PLAN_APPROVAL_CUSTOMER_TABLE_FORM_ERROR = 'planApprovalCustomer/GET_PLAN_APPROVAL_CUSTOMER_TABLE_FORM_ERROR',

  SAVE_PLAN_APPROVAL_CUSTOMER_TABLE_FORM = 'planApprovalCustomer/SAVE_PLAN_APPROVAL_CUSTOMER_TABLE_FORM',
  SAVE_PLAN_APPROVAL_CUSTOMER_TABLE_FORM_SUCCESS = 'planApprovalCustomer/SAVE_PLAN_APPROVAL_CUSTOMER_TABLE_FORM_SUCCESS',
  SAVE_PLAN_APPROVAL_CUSTOMER_TABLE_FORM_ERROR = 'planApprovalCustomer/SAVE_PLAN_APPROVAL_CUSTOMER_TABLE_FORM_ERROR',

  UPDATE_PLAN_APPROVAL_CUSTOMER_TABLE_FORM = 'planApprovalCustomer/UPDATE_PLAN_APPROVAL_CUSTOMER_TABLE_FORM',

  // Planning
  GET_PLANNING_TABLE_FORM = 'planning/GET_PLANNING_TABLE_FORM',
  GET_PLANNING_TABLE_FORM_SUCCESS = 'planning/GET_PLANNING_TABLE_FORM_SUCCESS',
  GET_PLANNING_TABLE_FORM_ERROR = 'planning/GET_PLANNING_TABLE_FORM_ERROR',

  SAVE_PLANNING_TABLE_FORM = 'planning/SAVE_PLANNING_TABLE_FORM',
  SAVE_PLANNING_TABLE_FORM_SUCCESS = 'planning/SAVE_PLANNING_TABLE_FORM_SUCCESS',
  SAVE_PLANNING_TABLE_FORM_ERROR = 'planning/SAVE_PLANNING_TABLE_FORM_ERROR',

  UPDATE_PLANNING_TABLE_FORM = 'planning/UPDATE_PLANNING_TABLE_FORM',

  // Sampling
  GET_SAMPLING_TABLE_FORM = 'sampling/GET_SAMPLING_TABLE_FORM',
  GET_SAMPLING_TABLE_FORM_SUCCESS = 'sampling/GET_SAMPLING_TABLE_FORM_SUCCESS',
  GET_SAMPLING_TABLE_FORM_ERROR = 'sampling/GET_SAMPLING_TABLE_FORM_ERROR',

  SAVE_SAMPLING_TABLE_FORM = 'sampling/SAVE_SAMPLING_TABLE_FORM',
  SAVE_SAMPLING_TABLE_FORM_SUCCESS = 'sampling/SAVE_SAMPLING_TABLE_FORM_SUCCESS',
  SAVE_SAMPLING_TABLE_FORM_ERROR = 'sampling/SAVE_SAMPLING_TABLE_FORM_ERROR',

  UPDATE_SAMPLING_TABLE_FORM = 'sampling/UPDATE_SAMPLING_TABLE_FORM',

  // Checkpoints Master Plan
  GET_CHECKPOINTS_MASTERPLAN_TABLE_FORM = 'checkpointsMasterPlan/GET_CHECKPOINTS_MASTERPLAN_TABLE_FORM',
  GET_CHECKPOINTS_MASTERPLAN_TABLE_FORM_SUCCESS = 'checkpointsMasterPlan/GET_CHECKPOINTS_MASTERPLAN_TABLE_FORM_SUCCESS',
  GET_CHECKPOINTS_MASTERPLAN_TABLE_FORM_ERROR = 'checkpointsMasterPlan/GET_CHECKPOINTS_MASTERPLAN_TABLE_FORM_ERROR',

  SAVE_CHECKPOINTS_MASTERPLAN_TABLE_FORM = 'checkpointsMasterPlan/SAVE_CHECKPOINTS_MASTERPLAN_TABLE_FORM',
  SAVE_CHECKPOINTS_MASTERPLAN_TABLE_FORM_SUCCESS = 'checkpointsMasterPlan/SAVE_CHECKPOINTS_MASTERPLAN_TABLE_FORM_SUCCESS',
  SAVE_CHECKPOINTS_MASTERPLAN_TABLE_FORM_ERROR = 'checkpointsMasterPlan/SAVE_CHECKPOINTS_MASTERPLAN_TABLE_FORM_ERROR',

  UPDATE_CHECKPOINTS_MASTERPLAN_TABLE_FORM = 'checkpointsMasterPlan/UPDATE_CHECKPOINTS_MASTERPLAN_TABLE_FORM',

  // Announcement
  GET_ANNOUNCEMENT_TABLE_FORM = 'announcement/GET_ANNOUNCEMENT_TABLE_FORM',
  GET_ANNOUNCEMENT_TABLE_FORM_SUCCESS = 'announcement/GET_ANNOUNCEMENT_TABLE_FORM_SUCCESS',
  GET_ANNOUNCEMENT_TABLE_FORM_ERROR = 'announcement/GET_ANNOUNCEMENT_TABLE_FORM_ERROR',

  SAVE_ANNOUNCEMENT_TABLE_FORM = 'announcement/SAVE_ANNOUNCEMENT_TABLE_FORM',
  SAVE_ANNOUNCEMENT_TABLE_FORM_SUCCESS = 'announcement/SAVE_ANNOUNCEMENT_TABLE_FORM_SUCCESS',
  SAVE_ANNOUNCEMENT_TABLE_FORM_ERROR = 'announcement/SAVE_ANNOUNCEMENT_TABLE_FORM_ERROR',

  UPDATE_ANNOUNCEMENT_TABLE_FORM = 'announcement/UPDATE_ANNOUNCEMENT_TABLE_FORM',

  // Inspection Plans QA List
  GET_INSPECTION_PLANS_QA_LIST_TABLE_FORM = 'inspectionPlansQAList/GET_INSPECTION_PLANS_QA_LIST_TABLE_FORM',
  GET_INSPECTION_PLANS_QA_LIST_TABLE_FORM_SUCCESS = 'inspectionPlansQAList/GET_INSPECTION_PLANS_QA_LIST_TABLE_FORM_SUCCESS',
  GET_INSPECTION_PLANS_QA_LIST_TABLE_FORM_ERROR = 'inspectionPlansQAList/GET_INSPECTION_PLANS_QA_LIST_TABLE_FORM_ERROR',

  SAVE_INSPECTION_PLANS_QA_LIST_TABLE_FORM = 'inspectionPlansQAList/SAVE_INSPECTION_PLANS_QA_LIST_TABLE_FORM',
  SAVE_INSPECTION_PLANS_QA_LIST_TABLE_FORM_SUCCESS = 'inspectionPlansQAList/SAVE_INSPECTION_PLANS_QA_LIST_TABLE_FORM_SUCCESS',
  SAVE_INSPECTION_PLANS_QA_LIST_TABLE_FORM_ERROR = 'inspectionPlansQAList/SAVE_INSPECTION_PLANS_QA_LIST_TABLE_FORM_ERROR',

  UPDATE_INSPECTION_PLANS_QA_LIST_TABLE_FORM = 'inspectionPlansQAList/UPDATE_INSPECTION_PLANS_QA_LIST_TABLE_FORM',

  // Deadline Compliance
  GET_DEADLINE_COMPLIANCE_TABLE_FORM = 'deadlineCompliance/GET_DEADLINE_COMPLIANCE_TABLE_FORM',
  GET_DEADLINE_COMPLIANCE_TABLE_FORM_SUCCESS = 'deadlineCompliance/GET_DEADLINE_COMPLIANCE_TABLE_FORM_SUCCESS',
  GET_DEADLINE_COMPLIANCE_TABLE_FORM_ERROR = 'deadlineCompliance/GET_DEADLINE_COMPLIANCE_TABLE_FORM_ERROR',

  SAVE_DEADLINE_COMPLIANCE_TABLE_FORM = 'deadlineCompliance/SAVE_DEADLINE_COMPLIANCE_TABLE_FORM',
  SAVE_DEADLINE_COMPLIANCE_TABLE_FORM_SUCCESS = 'deadlineCompliance/SAVE_DEADLINE_COMPLIANCE_TABLE_FORM_SUCCESS',
  SAVE_DEADLINE_COMPLIANCE_TABLE_FORM_ERROR = 'deadlineCompliance/SAVE_DEADLINE_COMPLIANCE_TABLE_FORM_ERROR',

  UPDATE_DEADLINE_COMPLIANCE_TABLE_FORM = 'deadlineCompliance/UPDATE_DEADLINE_COMPLIANCE_TABLE_FORM',

  // Supply Schedule
  GET_SUPPLY_SCHEDULE = 'supplySchedule/GET_SUPPLY_SCHEDULE',
  GET_SUPPLY_SCHEDULE_SUCCESS = 'supplySchedule/GET_SUPPLY_SCHEDULE_SUCCESS',
  GET_SUPPLY_SCHEDULE_ERROR = 'supplySchedule/GET_SUPPLY_SCHEDULE_ERROR',

  SAVE_SUPPLY_SCHEDULE = 'supplySchedule/SAVE_SUPPLY_SCHEDULE',
  SAVE_SUPPLY_SCHEDULE_SUCCESS = 'supplySchedule/SAVE_SUPPLY_SCHEDULE_SUCCESS',
  SAVE_SUPPLY_SCHEDULE_ERROR = 'supplySchedule/SAVE_SUPPLY_SCHEDULE_ERROR',

  UPDATE_SUPPLY_SCHEDULE = 'supplySchedule/UPDATE_SUPPLY_SCHEDULE',

  // Capacity Subcontractor
  GET_CAPACITY_SUBCONTRACTOR = 'capacitySubcontractor/GET_CAPACITY_SUBCONTRACTOR',
  GET_CAPACITY_SUBCONTRACTOR_SUCCESS = 'capacitySubcontractor/GET_CAPACITY_SUBCONTRACTOR_SUCCESS',
  GET_CAPACITY_SUBCONTRACTOR_ERROR = 'capacitySubcontractor/GET_CAPACITY_SUBCONTRACTOR_ERROR',

  SAVE_CAPACITY_SUBCONTRACTOR = 'capacitySubcontractor/SAVE_CAPACITY_SUBCONTRACTOR',
  SAVE_CAPACITY_SUBCONTRACTOR_SUCCESS = 'capacitySubcontractor/SAVE_CAPACITY_SUBCONTRACTOR_SUCCESS',
  SAVE_CAPACITY_SUBCONTRACTOR_ERROR = 'capacitySubcontractor/SAVE_CAPACITY_SUBCONTRACTOR_ERROR',

  UPDATE_CAPACITY_SUBCONTRACTOR = 'capacitySubcontractor/UPDATE_CAPACITY_SUBCONTRACTOR',

  // weekTopics
  GET_WEEK_TOPICS = 'forms/GET_WEEK_TOPICS',
  GET_WEEK_TOPICS_SUCCESS = 'forms/GET_WEEK_TOPICS_SUCCESS',
  GET_WEEK_TOPICS_ERROR = 'forms/GET_WEEK_TOPICS_ERROR',

  SAVE_WEEK_TOPICS = 'forms/SAVE_WEEK_TOPICS',
  SAVE_WEEK_TOPICS_SUCCESS = 'forms/SAVE_WEEK_TOPICS_SUCCESS',
  SAVE_WEEK_TOPICS_ERROR = 'forms/SAVE_WEEK_TOPICS_ERROR',

  UPDATE_WEEK_TOPICS = 'forms/UPDATE_WEEK_TOPICS',

  // table forms
  GET_TABLE_FORM = 'forms/GET_TABLE_FORM',
  GET_TABLE_FORM_SUCCESS = 'forms/GET_TABLE_FORM_SUCCESS',
  GET_TABLE_FORM_ERROR = 'forms/GET_TABLE_FORM_ERROR',

  UPDATE_TABLE_FORM = 'forms/UPDATE_TABLE_FORM',

  SAVE_TABLE_FORM = 'forms/SAVE_TABLE_FORM',
  SAVE_TABLE_FORM_SUCCESS = 'forms/SAVE_TABLE_FORM_SUCCESS',
  SAVE_TABLE_FORM_ERROR = 'forms/SAVE_TABLE_FORM_ERROR',

  SAVE_TABLE_FORM_MEASURE = 'forms/SAVE_TABLE_FORM_MEASURE',
  SAVE_TABLE_FORM_MEASURE_SUCCESS = 'forms/SAVE_TABLE_FORM_MEASURE_SUCCESS',
  SAVE_TABLE_FORM_MEASURE_ERROR = 'forms/SAVE_TABLE_FORM_MEASURE_ERROR',

  GET_KVP_FORM = 'forms/GET_KVP_FORM',
  GET_KVP_FORM_SUCCESS = 'forms/GET_KVP_FORM_SUCCESS',
  GET_KVP_FORM_ERROR = 'forms/GET_KVP_FORM_ERROR',

  UPDATE_KVP_FORM = 'forms/UPDATE_KVP_FORM',

  SAVE_KVP_FORM = 'forms/SAVE_KVP_FORM',
  SAVE_KVP_FORM_SUCCESS = 'forms/SAVE_KVP_FORM_SUCCESS',
  SAVE_KVP_FORM_ERROR = 'forms/SAVE_KVP_FORM_ERROR',

  GET_PROJECT_OVERVIEW_FORM = 'forms/GET_PROJECT_OVERVIEW_FROM',
  GET_PROJECT_OVERVIEW_FORM_SUCCESS = 'forms/GET_PROJECT_OVERVIEW_FORM_SUCCESS',
  GET_PROJECT_OVERVIEW_FORM_ERROR = 'forms/GET_PROJECT_OVERVIEW_FORM_ERROR',

  UPDATE_PROJECT_OVERVIEW_FORM = 'forms/UPDATE_PROJECT_OVERVIEW_FORM',

  SAVE_PROJECT_OVERVIEW_FORM = 'forms/SAVE_PROJECT_OVERVIEW_FORM',
  SAVE_PROJECT_OVERVIEW_FORM_SUCCESS = 'forms/SAVE_PROJECT_OVERVIEW_FORM_SUCCESS',
  SAVE_PROJECT_OVERVIEW_FORM_ERROR = 'forms/SAVE_PROJECT_OVERVIEW_FORM_ERROR',

  GET_GOALS_FORM = 'forms/GET_GOALS_FORM_FROM',
  GET_GOALS_FORM_SUCCESS = 'forms/GET_GOALS_FORM_SUCCESS',
  GET_GOALS_FORM_ERROR = 'forms/GET_GOALS_FORM_ERROR',

  UPDATE_GOALS_FORM = 'forms/UPDATE_GOALS_FORM',

  SAVE_GOALS_FORM = 'forms/SAVE_GOALS_FORM',
  SAVE_GOALS_FORM_SUCCESS = 'forms/SAVE_GOALS_FORM_SUCCESS',
  SAVE_GOALS_FORM_ERROR = 'forms/SAVE_GOALS_FORM_ERROR',

  GET_SICK_LEAVE_FORM = 'forms/GET_SICK_LEAVE_FORM_FROM',
  GET_SICK_LEAVE_FORM_SUCCESS = 'forms/GET_SICK_LEAVE_FORM_SUCCESS',
  GET_SICK_LEAVE_FORM_ERROR = 'forms/GET_SICK_LEAVE_FORM_ERROR',

  UPDATE_SICK_LEAVE_FORM = 'forms/UPDATE_SICK_LEAVE_FORM',

  SAVE_SICK_LEAVE_FORM = 'forms/SAVE_SICK_LEAVE_FORM',
  SAVE_SICK_LEAVE_FORM_SUCCESS = 'forms/SAVE_SICK_LEAVE_FORM_SUCCESS',
  SAVE_SICK_LEAVE_FORM_ERROR = 'forms/SAVE_SICK_LEAVE_FORM_ERROR',

  GET_PROCESS_CONFIRMATION_FORM = 'forms/GET_PROCESS_CONFIRMATION_FORM',
  GET_PROCESS_CONFIRMATION_FORM_SUCCESS = 'forms/GET_PROCESS_CONFIRMATION_FORM_SUCCESS',
  GET_PROCESS_CONFIRMATION_FORM_ERROR = 'forms/GET_PROCESS_CONFIRMATION_FORM_ERROR',

  UPDATE_PROCESS_CONFIRMATION_FORM = 'forms/UPDATE_PROCESS_CONFIRMATION_FORM',

  SAVE_PROCESS_CONFIRMATION_FORM = 'forms/SAVE_PROCESS_CONFIRMATION_FORM',
  SAVE_PROCESS_CONFIRMATION_FORM_SUCCESS = 'forms/SAVE_PROCESS_CONFIRMATION_FORM_SUCCESS',
  SAVE_PROCESS_CONFIRMATION_FORM_ERROR = 'forms/SAVE_PROCESS_CONFIRMATION_FORM_ERROR',

  GET_STANDING_RULES = 'forms/GET_STANDING_RULES',
  GET_STANDING_RULES_SUCCESS = 'forms/GET_STANDING_RULES_SUCCESS',
  GET_STANDING_RULES_ERROR = 'forms/GET_STANDING_RULES_ERROR',

  UPDATE_STANDING_RULES = 'forms/UPDATE_STANDING_RULES',

  SAVE_STANDING_RULES = 'forms/SAVE_STANDING_RULES',
  SAVE_STANDING_RULES_SUCCESS = 'forms/SAVE_STANDING_RULES_SUCCESS',
  SAVE_STANDING_RULES_ERROR = 'forms/SAVE_STANDING_RULES_ERROR',

  SAVE_MEASURES = 'forms/SAVE_MEASURES',
  SAVE_MEASURES_SUCCESS = 'forms/SAVE_MEASURES_SUCCESS',
  SAVE_MEASURES_ERROR = 'forms/SAVE_MEASURES_ERROR',

  GET_MEASURES_AND_ESCALATIONS = 'forms/GET_MEASURES_AND_ESCALATIONS',
  GET_MEASURES_AND_ESCALATIONS_SUCCESS = 'forms/GET_MEASURES_AND_ESCALATIONS_SUCCESS',
  GET_MEASURES_AND_ESCALATIONS_ERROR = 'forms/GET_MEASURES_AND_ESCALATIONS_ERROR',

  UPDATE_MEASURES_AND_ESCALATIONS = 'forms/UPDATE_MEASURES_AND_ESCALATIONS',

  SAVE_MEASURES_AND_ESCALATIONS = 'forms/SAVE_MEASURES_AND_ESCALATIONS',
  SAVE_MEASURES_AND_ESCALATIONS_SUCCESS = 'forms/SAVE_MEASURES_AND_ESCALATIONS_SUCCESS',
  SAVE_MEASURES_AND_ESCALATIONS_ERROR = 'forms/SAVE_MEASURES_AND_ESCALATIONS_ERROR',

  // GF Area / Project Area
  GET_PROJECT_AREA_OVERVIEW = 'projectArea/GET_PROJECT_OVERVIEW',
  GET_PROJECT_AREA_OVERVIEW_SUCCESS = 'projectArea/GET_PROJECT_AREA_OVERVIEW_SUCCESS',
  GET_PROJECT_AREA_OVERVIEW_ERROR = 'projectArea/GET_PROJECT_OVERVIEW_ERROR',

  GET_PROJECT_AREA_STATUS_SUMMARY = 'projectArea/GET_PROJECT_AREA_STATUS_SUMMARY',
  GET_PROJECT_AREA_STATUS_SUMMARY_SUCCESS = 'projectArea/GET_PROJECT_AREA_STATUS_SUMMARY_SUCCESS',
  GET_PROJECT_AREA_STATUS_SUMMARY_ERROR = 'projectArea/GET_PROJECT_AREA_STATUS_SUMMARY_ERROR',

  // Occupational Safety
  GET_OCCUPATIONAL_SAFETY = 'occupationalSafety/GET_OCCUPATIONAL_SAFETY',
  GET_OCCUPATIONAL_SAFETY_SUCCESS = 'occupationalSafety/GET_OCCUPATIONAL_SAFETY_SUCCESS',
  GET_OCCUPATIONAL_SAFETY_ERROR = 'occupationalSafety/GET_OCCUPATIONAL_SAFETY_ERROR',

  SAVE_OCCUPATIONAL_SAFETY = 'occupationalSafety/SAVE_OCCUPATIONAL_SAFETY',
  SAVE_OCCUPATIONAL_SAFETY_SUCCESS = 'occupationalSafety/SAVE_OCCUPATIONAL_SAFETY_SUCCESS',
  SAVE_OCCUPATIONAL_SAFETY_ERROR = 'occupationalSafety/SAVE_OCCUPATIONAL_SAFETY_ERROR',

  UPDATE_OCCUPATIONAL_SAFETY = 'occupationalSafety/UPDATE_OCCUPATIONAL_SAFETY',

  // notifications
  SPAWN_NOTIFICATION = 'notifications/SPAWN_NOTIFICATION',
  DISMISS_NOTIFICATION = 'notifications/DISMISS_NOTIFICATION',

  // powerBI
  GET_POWERBI_TILE = 'powerbi/GET_POWERBI_TILE',
  GET_POWERBI_TILE_SUCCESS = 'powerbi/GET_POWERBI_TILE_SUCCESS',
  GET_POWERBI_TILE_ERROR = 'powerbi/GET_POWERBI_TILE_ERROR',

  // settings
  SET_IS_FORM_DIRTY = 'settings/SET_IS_FORM_DIRTY',
  SET_IS_FORM_EDITING = 'settings/SET_IS_FORM_EDITING',

  // menu
  GET_MENU_LIST = 'menu/GET_MENU_LIST',
  GET_MENU_LIST_SUCCESS = 'menu/GET_MENU_LIST_SUCCESS',
  GET_MENU_LIST_ERROR = 'menu/GET_MENU_LIST_ERROR',
  SET_FIRST_NAV_OPENED = 'menu/SET_FIRST_NAV_OPENED',
  SET_IS_MENU_OPENED = 'menu/SET_IS_MENU_OPENED',

  // history
  GET_HISTORY_LIST = 'history/GET_HISTORY_LIST',
  GET_HISTORY_LIST_SUCCESS = 'history/GET_HISTORY_LIST_SUCCESS',
  GET_HISTORY_LIST_ERROR = 'history/GET_HISTORY_LIST_ERROR',
  SET_SELECTED_HISTORY_LIST_ITEM_INDEX = 'history/SET_SELECTED_HISTORY_LIST_ITEM_INDEX',

  // login
  SILENT_LOGIN = 'login/SILENT_LOGIN',
  SILENT_LOGIN_SUCCESS = 'login/SILENT_LOGIN_SUCCESS',
  SILENT_LOGIN_ERROR = 'login/SILENT_LOGIN_ERROR',

  LOGIN = 'login/LOGIN',
  LOGIN_SUCCESS = 'login/LOGIN_SUCCESS',
  LOGIN_ERROR = 'login/LOGIN_ERROR',

  LOGOUT = 'login/LOGOUT',
  LOGOUT_SUCCESS = 'login/LOGOUT_SUCCESS',
  LOGOUT_ERROR = 'login/LOGOUT_ERROR',

  SET_NOT_AUTHENTICATED = 'login/SET_NOT_AUTHENTICATED',

  //
  // BACK_OFFICE
  //

  GET_BACK_OFFICE_LIST = 'back-office/GET_BACK_OFFICE_LIST',
  GET_BACK_OFFICE_LIST_SUCCESS = 'back-office/GET_BACK_OFFICE_LIST_SUCCESS',
  GET_BACK_OFFICE_LIST_ERROR = 'back-office/GET_BACK_OFFICE_LIST_ERROR',

  GET_AREA_PROJECTS = 'back-office/GET_AREA_PROJECTS',
  GET_AREA_PROJECTS_SUCCESS = 'back-office/GET_AREA_PROJECTS_SUCCESS',
  GET_AREA_PROJECTS_ERROR = 'back-office/GET_AREA_PROJECTS_ERROR',

  SAVE_AREA_PROJECTS = 'back-office/SAVE_AREA_PROJECTS',
  SAVE_AREA_PROJECTS_SUCCESS = 'back-office/SAVE_AREA_PROJECTS_SUCCESS',
  SAVE_AREA_PROJECTS_ERROR = 'back-office/SAVE_AREA_PROJECTS_ERROR',

  UPDATE_AREA_PROJECTS = 'back-office/UPDATE_AREA_PROJECTS',

  GET_BOARD_PERMISSIONS = 'back-office/GET_BOARD_PERMISSIONS',
  GET_BOARD_PERMISSIONS_SUCCESS = 'back-office/GET_BOARD_PERMISSIONS_SUCCESS',
  GET_BOARD_PERMISSIONS_ERROR = 'back-office/GET_BOARD_PERMISSIONS_ERROR',

  SAVE_BOARD_PERMISSIONS = 'back-office/SAVE_BOARD_PERMISSIONS',
  SAVE_BOARD_PERMISSIONS_SUCCESS = 'back-office/SAVE_BOARD_PERMISSIONS_SUCCESS',
  SAVE_BOARD_PERMISSIONS_ERROR = 'back-office/SAVE_BOARD_PERMISSIONS_ERROR',

  // Capacity Subcontractors backoffice
  GET_CAPACITY_SUBCONTRACTORS = 'capacitySubcontractor/GET_CAPACITY_SUBCONTRACTORS',
  GET_CAPACITY_SUBCONTRACTORS_SUCCESS = 'capacitySubcontractor/GET_CAPACITY_SUBCONTRACTORS_SUCCESS',
  GET_CAPACITY_SUBCONTRACTORS_ERROR = 'capacitySubcontractor/GET_CAPACITY_SUBCONTRACTORS_ERROR',

  SAVE_CAPACITY_SUBCONTRACTORS = 'capacitySubcontractor/SAVE_CAPACITY_SUBCONTRACTORS',
  SAVE_CAPACITY_SUBCONTRACTORS_SUCCESS = 'capacitySubcontractor/SAVE_CAPACITY_SUBCONTRACTORS_SUCCESS',
  SAVE_CAPACITY_SUBCONTRACTORS_ERROR = 'capacitySubcontractor/SAVE_CAPACITY_SUBCONTRACTORS_ERROR',

  UPDATE_CAPACITY_SUBCONTRACTORS = 'capacitySubcontractor/UPDATE_CAPACITY_SUBCONTRACTORS'
}

export default ActionTypes;
