import React from 'react';
import { useRouteMatch } from 'react-router-dom';
import { cloneDeep } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { TileTypes } from '../../core/enums/TileTypes';
import { useTableForm } from '../../core/hooks/useTableForm';
import { BOARDS_TILE_DETAIL } from '../../core/constants/routes';
import { RootState } from '../../core/state/state';
import { generateAsyncActions } from '../../core/state/table-forms/asyncActions';
import { DualTableForm, TableFormRow } from '../../core/state/table-forms/types';
import { TableFormFieldStatus } from '../../core/enums/TableFormFieldStatus';

import Table from '../../components/Table/Table';
import TR from '../../components/Table/TR/TR';
import TD from '../../components/Table/TD/TD';
import THead from '../../components/Table/THead/THead';
import TH from '../../components/Table/TH/TH';
import TBody from '../../components/Table/TBody/TBody';

import TableInput from '../../components/form/TableInput/TableInputHook';
import Calendar from '../../components/form/Calendar/CalendarHook';
import { useRowNumber } from '../../core/hooks/useRowNumber';

import Icon from '../../components/Icon/Icon';
import Button from '../../components/Button/Button';

import './TileCompletionDate.scss';

interface Props {
  boardId?: number;
  url?: string;
}

const TileCompletionDate: React.FC<Props> = ({ boardId }) => {
  const dispatch = useDispatch();
  const tableForm = useSelector((state: RootState) => state.cDateOAmount.form);
  const isDetailPage = useRouteMatch(BOARDS_TILE_DETAIL);
  const { t } = useTranslation();
  const nextRowNumber = useRowNumber(tableForm?.first ?? null);

  const { updateTableForm, saveTableForm } = React.useMemo(() => generateAsyncActions(TileTypes.CompletionDateOrderAmount), []);

  const { useTableValue } = useTableForm<DualTableForm>({
    boardId,
    data: tableForm,
    nestedTablePropertyName: 'first',
    valuePropertyName: 'text',
    updateActions: [updateTableForm],
    submitActions: [updateTableForm, saveTableForm]
  });

  const addEmptyRow = (suppressDirtyStatus = false) => {
    const newTableForm = cloneDeep(tableForm);
    const emptyRow: TableFormRow = {
      rowNumber: nextRowNumber(),
      columns: [
        {
          columnNumber: 0,
          title: t('sharedText.number'),
          status: TableFormFieldStatus.Undefined,
          text: ''
        },
        {
          columnNumber: 1,
          status: TableFormFieldStatus.Undefined,
          title: t('forms.completionDate.newCompletionDate'),
          text: ''
        },
        {
          columnNumber: 2,
          status: TableFormFieldStatus.Undefined,
          title: t('forms.completionDate.contractCompletionDate'),
          text: ''
        }
      ]
    };

    if (newTableForm) {
      newTableForm.first.rows.push(emptyRow);
      dispatch(updateTableForm(newTableForm, true, suppressDirtyStatus));
    }
  };

  const handleDeleteRow = (index: number) => {
    const newTableForm = cloneDeep(tableForm);

    if (newTableForm) {
      newTableForm.first.rows.splice(index + 1, 1);
      dispatch(updateTableForm(newTableForm));
    }
  };

  const getAllRows = () => {
    if (tableForm && tableForm.first.rows.length > 1) {
      return tableForm.first.rows.slice(1).map((tableFormItem, index) => (
        <TR key={tableFormItem.rowNumber}>
          <TD className="text-center">{index + 1}</TD>
          <TD>
            <TableInput type={isDetailPage ? undefined : 'small'} row={index + 1} column={1} useTableValue={useTableValue} />
          </TD>
          <TD className="text-left">
            <Calendar isSmall={!isDetailPage} row={index + 1} column={2} useTableValue={useTableValue} />
          </TD>
          {isDetailPage && (
            <TD className="text-center">
              <button title={t('buttons.deleteRow')} className="btn btn-link" onClick={() => handleDeleteRow(index)}>
                <Icon icon="delete" svg />
                <span className="sr-only">{t('buttons.deleteRow')}</span>
              </button>
            </TD>
          )}
        </TR>
      ));
    }
    return null;
  };

  return (
    <div className={`tile-completion-date ${isDetailPage ? '' : 'tile-completion-date--small'}`}>
      {isDetailPage && (
        <div className={isDetailPage ? '' : 'pt-1'}>
          <div className="d-flex justify-content-between align-items-center">
            <div className="tile-completion-date__title">{t('forms.completionDate.title')}</div>
            <div className="d-flex align-items-center">
              <div className="mr-3">{t('forms.completionDate.contractCompletionDate')}</div>
              {tableForm && tableForm.first.rows.length > 0 && (
                <Calendar isFormInput isSmall={!isDetailPage} row={0} column={0} useTableValue={useTableValue} />
              )}
            </div>
          </div>
        </div>
      )}
      {!isDetailPage && (
        <div className="tile-completion-date__table-wrap-top">
          <Table className={isDetailPage ? 'tile-completion-date__table' : 'tile-completion-date__table table--small'}>
            <THead>
              <TR>
                <TH className="first-cell">
                  <div className="table__title">{t('sharedText.number')}</div>
                </TH>
                <TH className="table__table-header" colSpan={2}>
                  <div className="table__title text-left">{t('forms.completionDate.title')}</div>
                </TH>
                {isDetailPage && <TH />}
              </TR>
            </THead>
            <TBody>
              <TR>
                <TD className="text-center">1</TD>
                <TD className="text-left">
                  <div className="table__text">{t('forms.completionDate.contractCompletionDate')}</div>
                </TD>
                <TD className="text-left">
                  {tableForm && tableForm.second.rows.length > 0 && (
                    <Calendar isSmall={!isDetailPage} row={0} column={0} useTableValue={useTableValue} />
                  )}
                </TD>
              </TR>
            </TBody>
          </Table>
        </div>
      )}
      <div className="tile-completion-date__table-wrap">
        <Table className={isDetailPage ? 'tile-completion-date__table' : 'tile-completion-date__table table--small'}>
          <THead>
            <TR>
              <TH className="first-cell">
                <div className="table__title">{t('sharedText.number')}</div>
              </TH>
              <TH className="table__table-header">
                <div className="table__title text-left">{t('forms.completionDate.basisScheduleChange')}</div>
              </TH>
              <TH className="table__table-header">
                <div className={`table__title ${isDetailPage ? 'text-left' : 'text-center'}`}>{t('forms.completionDate.newCompletionDate')}</div>
              </TH>
              {isDetailPage && <TH />}
            </TR>
          </THead>
          <TBody>{getAllRows()}</TBody>
        </Table>
      </div>
      {isDetailPage && (
        <div className="d-flex justify-content-center my-4">
          <Button buttonTypes={['primary', 'big']} onClick={() => addEmptyRow()}>
            {t('buttons.addNewRow')}
          </Button>
        </div>
      )}
    </div>
  );
};

export default TileCompletionDate;
