import { format } from 'date-fns';
import { ColumnType, MeasureDetail, TableForm } from '../../state/table-forms/types';
import { TableFormFieldStatus } from '../../enums/TableFormFieldStatus';
import { CapacitySubcontractor } from '../../state/capacity-subcontractors/types';
import { DATE_FORMAT_BACKEND } from '../../constants/date';

export interface MeasureViewModel {
  measure: MeasureDetail;
  measureURL?: string;
}

export const getMeasureInfo = (tableForm: TableForm | null, rowIndex: number): MeasureViewModel | null => {
  if (rowIndex > -1) {
    const row = tableForm?.rows[rowIndex];

    return {
      measure: {
        measureText: row?.columns.find((item) => item.type === ColumnType.Measure)?.text ?? '',
        problemText: '',
        deadline: row?.columns.find((item) => item.type === ColumnType.Deadline)?.text ?? '',
        assignedTo: row?.columns.find((item) => item.type === ColumnType.AssignedTo)?.text ?? '',
        type: '',
        status: row?.columns.find((item) => item.type === ColumnType.MeasureStatus)?.status ?? TableFormFieldStatus.Undefined,
        date: format(new Date(), DATE_FORMAT_BACKEND)
      },
      measureURL: row?.measureURL
    };
  }
  return null;
};

export const getMeasureInfoCS = (form: CapacitySubcontractor | null, rowIndex: number): MeasureViewModel | null => {
  if (rowIndex > -1) {
    const row = form?.rows[rowIndex];

    return {
      measure: {
        measureText: row?.measure ?? '',
        problemText: '',
        deadline: '',
        assignedTo: '',
        type: '',
        status: TableFormFieldStatus.Quarter,
        date: null
      },
      measureURL: row?.measureURL
    };
  }
  return null;
};
