import { combineReducers } from 'redux';

import boardsReducer from './boards/reducer';
import TableFormReducer, { generateTableFormReducer } from './table-forms/reducer';
import notificationsReducer from './notifications/reducer';
import projectAreaReducer from './project-area/reducer';
import settingsReducer from './settings/reducer';
import menuReducer from './menu/reducer';
import processConfirmationReducer from './process-confirmation/reducer';
import historyReducer from './history/reducer';
import authReducer from './auth/reducer';
import backOfficeReducer from './back-office/reducer';

import generateCheckboxTileReducer from './checkbox-list/reducer';
import { CheckboxTileTypes } from '../enums/CheckboxTileTypes';
import { DualTableForm, TableForm } from './table-forms/types';
import { TileTypes } from '../enums/TileTypes';
import { generateTileReducer } from '../utils/state/generateTileReducer';
import { OccupationalSafety } from './occupational-safety/types';
import { SupplySchedule } from './supply-schedule/types';
import { CapacitySubcontractor } from './capacity-subcontractors/types';

const rootReducer = combineReducers({
  boards: boardsReducer,
  attendance: generateCheckboxTileReducer(CheckboxTileTypes.Attendance.toUpperCase()),
  vacation: generateCheckboxTileReducer(CheckboxTileTypes.Vacation.toUpperCase()),
  tidiness: generateCheckboxTileReducer(CheckboxTileTypes.Tidiness.toUpperCase()),
  presence: generateCheckboxTileReducer(CheckboxTileTypes.Presence.toUpperCase()),
  confirmation: generateCheckboxTileReducer(CheckboxTileTypes.Confirmation.toUpperCase()),
  tableForm: TableFormReducer,
  requiredMaterial: generateTableFormReducer<TableForm>(TileTypes.RequiredMaterial),
  toDo: generateTableFormReducer<TableForm>(TileTypes.ToDoShortTerm),
  cDateOAmount: generateTableFormReducer<DualTableForm>(TileTypes.CompletionDateOrderAmount),
  plannerRisks: generateTableFormReducer<TableForm>(TileTypes.PlannerRisks),
  performanceNU: generateTableFormReducer<TableForm>(TileTypes.PerformanceNU),
  planApprovalCustomer: generateTableFormReducer<TableForm>(TileTypes.PlanApprovalCustomer),
  planning: generateTableFormReducer<TableForm>(TileTypes.Planning),
  announcement: generateTableFormReducer<TableForm>(TileTypes.Announcement),
  sampling: generateTableFormReducer<TableForm>(TileTypes.Sampling),
  deadlineCompliance: generateTableFormReducer<TableForm>(TileTypes.DeadlineCompliance),
  notifications: notificationsReducer,
  projectArea: projectAreaReducer,
  settings: settingsReducer,
  menuItems: menuReducer,
  processConfirmation: processConfirmationReducer,
  history: historyReducer,
  auth: authReducer,
  backOffice: backOfficeReducer,
  defectProcessing: generateTableFormReducer<TableForm>(TileTypes.DefectProcessing),
  trackingAAZ: generateTableFormReducer<TableForm>(TileTypes.TrackingAAZ),
  planningQuality: generateTableFormReducer<TableForm>(TileTypes.PlanningQuality),
  additionalServicesNU: generateTableFormReducer<TableForm>(TileTypes.AdditionalServicesNU),
  obstacles: generateTableFormReducer<TableForm>(TileTypes.Obstacles),
  inspectionPlansQAList: generateTableFormReducer<TableForm>(TileTypes.InspectionPlansQAList),
  defectProcessingNU: generateTableFormReducer<TableForm>(TileTypes.DefectProcessingNU),
  orderPlacement: generateTableFormReducer<TableForm>(TileTypes.OrderPlacement),
  inspectionPlans: generateTableFormReducer<TableForm>(TileTypes.InspectionPlans),
  occupationalSafety: generateTileReducer<OccupationalSafety>(TileTypes.OccupationalSafety),
  supplySchedule: generateTileReducer<SupplySchedule>(TileTypes.SupplySchedule),
  capacitySubcontractor: generateTileReducer<CapacitySubcontractor>(TileTypes.CapacitySubcontractor),
  checkpointsMasterPlan: generateTableFormReducer<TableForm>(TileTypes.CheckpointsMasterPlan)
});

export default rootReducer;
