import * as React from 'react';
import { useTranslation } from 'react-i18next';
import TH from '../../../components/Table/TH/TH';
import TileProcessConfirmationHeaderTitle from './TileProcessConfirmationHeaderTitle';
import TileProcessConfirmationHeaderSubTitle from './TileProcessConfirmationHeaderSubTitle';

interface Props {
  translationSuffix: string;
}

const PROJECT_CONFIRMATION_TABLE_HEADER = 'forms.processConfirmation.tableHeader';

const TileProcessConfirmationHeaderItem: React.FC<Props> = ({ translationSuffix }) => {
  const { t } = useTranslation();

  const title = t(`${PROJECT_CONFIRMATION_TABLE_HEADER}.title.${translationSuffix}`);
  const subTitle = t(`${PROJECT_CONFIRMATION_TABLE_HEADER}.subtitle.${translationSuffix}`);

  return (
    <TH>
      <div className="tile-process-confirmation__header-item">
        <TileProcessConfirmationHeaderTitle text={title} />
        <TileProcessConfirmationHeaderSubTitle text={subTitle} />
      </div>
    </TH>
  );
};

export default TileProcessConfirmationHeaderItem;
