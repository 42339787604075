import * as React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../core/state/state';

import Notification from './Notification/Notification';

import './Notifications.scss';

const Notifications: React.FC<any> = () => {
  const { notifications } = useSelector((state: RootState) => state.notifications);

  return (
    <div className="notifications">
      {notifications.map((notification) => (
        <Notification key={notification.id} notification={notification} />
      ))}
    </div>
  );
};

export default Notifications;
