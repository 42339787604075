import { createAction } from '../../utils/action';
import ActionTypes from '../actionTypes';
import { ActionsUnion } from '../types';
import { RestErrorResponse } from '../../types/response/RestErrorResponse';
import { MenuList } from './types';

export const Actions = {
  getMenuList: () => createAction(ActionTypes.GET_MENU_LIST),
  getMenuListSuccess: (list: MenuList[]) => createAction(ActionTypes.GET_MENU_LIST_SUCCESS, list),
  getMenuListError: (error: RestErrorResponse) => createAction(ActionTypes.GET_MENU_LIST_ERROR, error),
  setMenuFirstNavOpened: (index: number) => createAction(ActionTypes.SET_FIRST_NAV_OPENED, index),
  setIsMenuOpened: (opened: boolean) => createAction(ActionTypes.SET_IS_MENU_OPENED, opened)
};

export type MenuActions = ActionsUnion<typeof Actions>;
