import { RestResponse } from '../../types/response/RestResponse';
import { CapacitySubcontractors } from '../../state/back-office/types';
import service from '../RestService';

class BackOfficeCapacitySubcontractorsService {
  getCapacitySubcontractors = (url: string): RestResponse<CapacitySubcontractors[]> => service.get(`${url}`);

  saveCapacitySubcontractors = (url: string, capacitySubcontractors: CapacitySubcontractors[]): RestResponse<CapacitySubcontractors[]> =>
    service.post(`${url}`, capacitySubcontractors);
}

export default new BackOfficeCapacitySubcontractorsService();
