import * as React from 'react';

interface Props {
  rowSpan?: number;
  colSpan?: number;
  className?: string;
  onClick?: () => void;
  onDoubleClick?: () => void;
}

const TD: React.FC<Props> = ({ children, rowSpan, onClick, className, onDoubleClick, colSpan }) => (
  <td
    onDoubleClick={onDoubleClick}
    role="presentation"
    onKeyDown={onClick}
    onClick={onClick}
    className={className}
    rowSpan={rowSpan}
    colSpan={colSpan}
  >
    {children}
  </td>
);

export default TD;
