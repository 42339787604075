import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { Sizes } from '../../../core/enums/Sizes';
import { Actions as SettingsActions } from '../../../core/state/settings/actions';

import './Input.scss';

interface Props {
  defaultValue?: string;
  label?: string;
  error?: string;
  labelSize?: Sizes;
  row: string | number;
  background?: 'grey';
  className?: string;

  column: string | number;
  useTableValue: (row: string | number, column: string | number) => any[]; // () => [T, (value: T) => void]
}

const Input: React.FC<Props> = ({ label, error, labelSize, row, column, useTableValue, background, className }) => {
  const dispatch = useDispatch();
  const [initialValue, saveValue] = useTableValue(row, column);
  const [value, setValue] = useState(initialValue);
  const [, setEditing] = useState<boolean>(false);

  const onFocus = async () => {
    setEditing(true);
    dispatch(SettingsActions.setIsFormEditing(true));
  };

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
  };

  const onBlur = () => {
    setEditing(false);
    dispatch(SettingsActions.setIsFormEditing(false));
    saveValue(value);
  };

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  return (
    <div className={`input ${error ? 'input--error' : ''} ${background ? `input--${background}` : ''} ${className}`}>
      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
      {label && <label className={`input__label ${labelSize ? `input__label--${labelSize}` : ''}`}>{label}</label>}
      <input className="input__input" value={value || ''} onChange={onChange} onBlur={onBlur} onFocus={onFocus} />
      <div className="input__error">{error}</div>
    </div>
  );
};

export default Input;
