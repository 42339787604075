import * as React from 'react';
import { Link } from 'react-router-dom';
import Background from '../../../components/Background/Background';
import { Color } from '../../../core/enums/Color';

import './ProjectAreaRowTitle.scss';

interface Props {
  color?: Color;
  text: string;
  className?: string;
  url?: string;
}

const ProjectAreaRowTitle: React.FC<Props> = ({ color, text, className = '', url }) => {
  const content = (
    <Background color={color} className="h-100 d-flex justify-content-center align-items-center">
      <span className="project-area-row-title__text">{text}</span>
    </Background>
  );
  return (
    <>
      {url ? (
        <Link to={url} className={`project-area-row-title ${className}`}>
          {content}
        </Link>
      ) : (
        <div className={`project-area-row-title ${className}`}>{content}</div>
      )}
    </>
  );
};

export default ProjectAreaRowTitle;
