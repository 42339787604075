import ActionTypes from '../actionTypes';
import Actions from '../Actions';
import { NotificationsState } from './types';

export const defaultNotificationsState: NotificationsState = {
  notifications: []
};

export default function reducer(state: NotificationsState = defaultNotificationsState, action: Actions): NotificationsState {
  switch (action.type) {
    case ActionTypes.SPAWN_NOTIFICATION:
      return { ...state, notifications: [...state.notifications, action.payload] };
    case ActionTypes.DISMISS_NOTIFICATION:
      return { ...state, notifications: state.notifications.filter((n) => n.id !== action.payload.id) };
    default:
      return state;
  }
}
