import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { match } from 'react-router-dom';

import './TilePlanningQuality.scss';

interface Props {
  isDetailPage: match | null;
}

const TilePlanningQualityInfo: React.FC<Props> = ({ isDetailPage }) => {
  const { t } = useTranslation();
  return (
    <div
      className={`tile-planningquality ${
        isDetailPage ? '' : 'tile-planningquality--small justify-content-end'
      } d-flex flex-column align-items-end mb-2`}
    >
      <div className={`tile-planningquality__info-text ${isDetailPage ? '' : 'text-right'}`}>
        <span className="tile-planningquality__info-text tile-planningquality__info-text-title">{t('forms.planningQuality.statusDefinition')}</span>
        {t('forms.planningQuality.statusDefinitionSubtitle')}
      </div>
    </div>
  );
};

export default TilePlanningQualityInfo;
