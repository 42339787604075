import { format, isValid } from 'date-fns';
import { DATE_FORMAT, DATE_FORMAT_BACKEND, DATE_TIME_FORMAT_BACKEND, TIME_FORMAT } from '../constants/date';

export function toISODateString(date: Date | string): string {
  if (typeof date === 'string') {
    // eslint-disable-next-line no-param-reassign
    date = new Date(date);
  }
  return format(date, DATE_FORMAT_BACKEND);
}

export function fromISODateString(isoString: string): Date {
  return new Date(isoString);
}

export function toDisplayDateString(date: Date | string) {
  if (typeof date === 'string') {
    // eslint-disable-next-line no-param-reassign
    date = new Date(date);
  }
  if (isValid(date)) {
    return format(date, DATE_FORMAT);
  }
  return '';
}

export function toDisplayTimeString(date: Date | string) {
  if (typeof date === 'string') {
    // eslint-disable-next-line no-param-reassign
    date = new Date(date);
  }
  if (isValid(date)) {
    return format(date, TIME_FORMAT);
  }
  return '';
}

export function toISODateTimeString(date: Date | string): string {
  if (typeof date === 'string') {
    // eslint-disable-next-line no-param-reassign
    date = new Date(date);
  }
  return format(date, DATE_TIME_FORMAT_BACKEND);
}
