import HistoryService from '../../services/HistoryService';
import { ReduxThunkType } from '../../types/ReduxThunkType';
import { Actions as HistoryActions } from './actions';

export const getHistoryList = (boardId: number): ReduxThunkType<void> => async (dispatch) => {
  try {
    dispatch(HistoryActions.getHistoryList());
    const response = await HistoryService.getHistoryList(boardId);
    dispatch(HistoryActions.getHistoryListSuccess(response.data));
  } catch (error) {
    dispatch(HistoryActions.getHistoryListError(error));
  }
};

export const setSelectedHistoryListItem = (index: number): ReduxThunkType<void> => (dispatch) => {
  dispatch(HistoryActions.setSelectedHistoryListItemIndex(index));
};
