import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { addDays, format, isAfter, isBefore, subDays } from 'date-fns';

import { TileTypes } from '../../../core/enums/TileTypes';
import Table from '../../../components/Table/Table';
import THead from '../../../components/Table/THead/THead';
import TR from '../../../components/Table/TR/TR';
import TH from '../../../components/Table/TH/TH';
import TBody from '../../../components/Table/TBody/TBody';
import { RootState } from '../../../core/state/state';
import { generateAsyncActions } from '../../../core/utils/state/generateAsyncActions';
import { TileService } from '../../../core/utils/state/TileService';
import { getMondayOfTheWeek, getNameOfTheWeekDay, useDateTitle } from '../TileSupplySchedule';
import TD from '../../../components/Table/TD/TD';
import { toDisplayTimeString } from '../../../core/utils/formatDate';
import { DATE_MONTH_FORMAT } from '../../../core/constants/date';
import TableCellOverflowEllipses from '../../../pages/components/TableCellOverflowEllipses/TableCellOverflowEllipses';
import { SupplySchedule } from '../../../core/state/supply-schedule/types';

interface Props {
  url: string;
}

const TileSupplyScheduleDesktopScreen: React.FC<Props> = ({ url }) => {
  const dispatch = useDispatch();
  const form = useSelector((state: RootState) => state.supplySchedule.form);
  const { t } = useTranslation();
  const date = getMondayOfTheWeek();
  const dateBeforeWeek = subDays(date, 1);
  const dateAfterWeek = addDays(date, 6);
  const dateTitle = useDateTitle(date);

  const { getTileForm } = React.useMemo(() => generateAsyncActions<SupplySchedule>(TileTypes.SupplySchedule, new TileService()), []);

  const data = form?.data?.filter((dataItem) => {
    const currentDate = new Date(dataItem.date);
    return isBefore(dateBeforeWeek, currentDate) && isAfter(dateAfterWeek, currentDate);
  });

  React.useEffect(() => {
    dispatch(getTileForm(url));
  }, [url, dispatch, getTileForm]);

  return (
    <div className="tile-desktop-screen no-font-scaling">
      <Table className="tile-table__table" type="small">
        <THead>
          <TR>
            <TH colSpan={3} className="table__table-header">
              {t('forms.supplySchedule.title')}
            </TH>
          </TR>
        </THead>
        <TBody>
          <TR>
            <TD colSpan={3} className="text-left table-text-bold">
              {dateTitle}
            </TD>
          </TR>
          {data?.map((item) => {
            const currentDate = new Date(item.date);
            return (
              <TR key={currentDate.toISOString()}>
                <TD className="text-left w-5 pr-3">{toDisplayTimeString(currentDate)}</TD>
                <TD className="text-left w-5 p-0">
                  <span className="pr-1 table-text-bold">{format(currentDate, DATE_MONTH_FORMAT)}</span>
                  <span className="table-text-bold">{getNameOfTheWeekDay(currentDate)}</span>
                </TD>
                <TD className="text-left w-90">
                  <TableCellOverflowEllipses content={item.text ?? ''} />
                </TD>
              </TR>
            );
          })}
        </TBody>
      </Table>
    </div>
  );
};

export default TileSupplyScheduleDesktopScreen;
