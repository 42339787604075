import React, { LegacyRef, useEffect, useState } from 'react';
import { usePrevious } from '../../../core/hooks/usePrevious';

import './TextArea.scss';

interface Props {
  defaultValue?: string;
  label?: string;
  id: string;
  name: string;
  rows?: number;
  error?: string;
  onChange?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  onBlur?: (e: React.FocusEvent<HTMLTextAreaElement>) => void;
  register?: LegacyRef<any>;
  value?: string;
  maxSize?: number;
  usingDefaultValue?: boolean;
}

const TextArea: React.FC<Props> = ({
  defaultValue = '',
  label,
  error,
  id,
  name,
  rows,
  onChange,
  onBlur,
  register,
  maxSize,
  usingDefaultValue = false
}) => {
  const [value, setValue] = useState(defaultValue);
  const prevValue = usePrevious(defaultValue);

  useEffect(() => {
    if (usingDefaultValue && prevValue !== defaultValue) {
      setValue(defaultValue);
    }
  }, [defaultValue, prevValue, usingDefaultValue]);

  return (
    <div className={`textarea ${error ? 'textarea--error' : ''}`}>
      {label && (
        <label className="textarea__label" htmlFor={id}>
          {label}
        </label>
      )}
      <textarea
        ref={register}
        rows={rows}
        className="textarea__textarea"
        id={id}
        name={name}
        onChange={(e) => {
          if (onChange) {
            onChange(e);
          } else if (maxSize) {
            if (e.target.value.length < maxSize) {
              setValue(e.target.value);
            }
          } else {
            setValue(e.target.value);
          }
        }}
        onBlur={onBlur}
        value={value}
      />
      {error && <div className="textarea__error">{error}</div>}
    </div>
  );
};

export default TextArea;
