import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { TileTypes } from '../../../core/enums/TileTypes';
import { RootState } from '../../../core/state/state';
import { generateAsyncActions } from '../../../core/state/table-forms/asyncActions';

import Table from '../../../components/Table/Table';
import TR from '../../../components/Table/TR/TR';
import TD from '../../../components/Table/TD/TD';
import THead from '../../../components/Table/THead/THead';
import TH from '../../../components/Table/TH/TH';
import TBody from '../../../components/Table/TBody/TBody';
import TableCellOverflowEllipses from '../../../pages/components/TableCellOverflowEllipses/TableCellOverflowEllipses';

import './TileRequiredMaterialDesktopScreen.scss';

interface Props {
  boardId?: number;
  url: string;
}

const TileRequiredMaterialDesktopScreen: React.FC<Props> = ({ url }) => {
  const dispatch = useDispatch();
  const tableForm = useSelector((state: RootState) => state.requiredMaterial.form);
  const { t } = useTranslation();

  const { getTableForm } = React.useMemo(() => generateAsyncActions(TileTypes.RequiredMaterial), []);

  React.useEffect(() => {
    dispatch(getTableForm(url));
  }, [url, dispatch, getTableForm]);

  return (
    <div className="tile-required-material-desktop-screen no-font-scaling">
      <Table className="tile-required-material__table" type="small">
        <THead>
          <TR>
            <TH className="table__table-header" colSpan={3}>
              {t('forms.requiredMaterial.title')}
            </TH>
          </TR>
        </THead>
        <TBody>
          {tableForm?.rows?.map((tableFormItem, index) => (
            <TR key={tableFormItem.rowNumber}>
              <TD className="text-left">
                <TableCellOverflowEllipses content={`${index + 1}. ${tableFormItem.columns[2]?.text}`} />
              </TD>
              <TD className="text-left">
                <TableCellOverflowEllipses content={`${tableFormItem.columns[3]?.text}`} />
              </TD>
            </TR>
          ))}
        </TBody>
      </Table>
    </div>
  );
};

export default TileRequiredMaterialDesktopScreen;
