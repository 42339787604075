import { BOARDS } from '../../constants/routes';
import { getTileURL } from './getTile';
import { TileTypes } from '../../enums/TileTypes';

interface TileContent {
  tileId: number;
  type: TileTypes;
  onClickDataUrl: string | null;
  onClickPreviewUrl?: string;
}

export default function getTileDetailPageUrl<T extends TileContent>(tile: T, boardId: string | number): string {
  let path = `${BOARDS}/${boardId}/detail?id=${tile.tileId}&type=${tile.type}`;
  const tileUrl = getTileURL(tile);
  if (tileUrl) {
    path = `${path}&url=${encodeURIComponent(tileUrl)}`;
  }
  return path;
}
