import { cloneDeep } from 'lodash';

interface Data {
  id?: string | number;
}

export const cleanStringIds = (data: Data[]) => {
  return data?.map((item) => {
    const newItem = cloneDeep(item);
    if (typeof newItem.id === 'string') {
      delete newItem.id;
    }
    return newItem;
  });
};
